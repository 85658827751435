var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{ref:"modalCropImage",staticClass:"modal ead-modal nav-modal image-cropper-modal ",class:{
      'image-cropper-modal--dark': _vm.$theme.darkTheme
    }},[_c('div',{staticClass:"modal-header"},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s4"},[(_vm.name)?_c('h4',{style:({'color': _vm.colors.primary})},[_vm._v(" "+_vm._s(_vm.name)+" ")]):_vm._e(),(_vm.showDimensions)?_c('h4',{style:({'color': _vm.colors.primary})},[_vm._v(" "+_vm._s(_vm.width)+" x "+_vm._s(_vm.height)+" ")]):_vm._e()]),_c('div',{staticClass:"col s6"}),_c('div',{staticClass:"col s1"},[_c('i',{staticClass:"material-icons",staticStyle:{"cursor":"pointer"},style:({'color': _vm.colors.primary}),on:{"click":_vm.zoomIn}},[_vm._v(" zoom_in ")])]),_c('div',{staticClass:"col s1"},[_c('i',{staticClass:"material-icons",staticStyle:{"cursor":"pointer"},style:({'color': _vm.colors.primary}),on:{"click":_vm.zoomOut}},[_vm._v(" zoom_out ")])])])]),_c('div',{staticClass:"modal-content"},[_c('img',{ref:"imageToCrop",class:{
          'cropper-modal--round': _vm.round
        },staticStyle:{"max-width":"50vw","max-height":"50vh"},attrs:{"src":_vm.imageSt || _vm.image}})]),_c('div',{staticClass:"modal-footer",style:({ 'background-color': _vm.$theme.darkTheme ? '#0d0d13' : '#f2f3f7' })},[_c('div',{staticClass:"row"},[_c('div',{staticClass:"col s12 m6"},[_c('button',{staticClass:"waves-effect btn-profile",style:({
              'background': '#dbdbdb',
              'color': _vm.colors.primary
            }),on:{"click":_vm.cancel}},[_vm._v("Cancelar")])]),_c('div',{staticClass:"col s12 m6"},[_c('button',{staticClass:"waves-effect btn-profile",style:({
              'background': _vm.colors.primary,
              'color': _vm.$theme.primaryText
            }),on:{"click":_vm.confirm}},[_vm._v("Salvar")])])])])])])}
var staticRenderFns = []

export { render, staticRenderFns }