/**
 * HTTP request layer
 * if auth is required return patched axios instance(with access token in headers)
 * else return clear axios instance
 */

import axios from 'axios'

import { AuthService } from '@/services/auth.service';

var refreshPromise = null;

export class Http {
  constructor (status) {
    this.isAuth = status && status.auth ? status.auth : false
    this.instance = axios.create({
      baseURL: process.env.VUE_APP_EAD_API_URL
    })

    return this.init()
  }

  init () {
    if (this.isAuth) {
      this.instance.interceptors.response.use(
        response => response,
        error => {
          if (error.response.status !== 401) {
            return Promise.reject(error);
          }

          var tokenStatus = error.response.headers['x-token-status'];
          if (tokenStatus == 'Token bloqueado') {
            error.message = 'Acesso bloqueado por simultaneidade';
            return Promise.reject(error);
          }

          var userStatus = error.response.headers['x-blocked-message'];
          if (userStatus) {
            //error.message = decodeURIComponent(escape(userStatus));
            error.message = decodeURIComponent(userStatus);
            return Promise.reject(error);
          }

          var requestToRetry = error.config;
          if (refreshPromise == null) {
            refreshPromise = AuthService.refreshTokens();
          }
          //tentar pegar novo token
          return refreshPromise
            .then(token => {
                
                return new Promise((resolve, reject) => {
                  
                  requestToRetry.headers.authorization = `bearer ${token.data}`;

                  axios.request(requestToRetry).then(response => {
                    refreshPromise = null;
                    resolve(response);
                  }).catch(error => {
                    if (error.response.status == 401) {
                      refreshPromise = null;
                      reject(error);
                    } else {
                      refreshPromise = null;
                      resolve(error.response);
                    }
                  });
                })
            })
            .catch(async error => {
              refreshPromise = null;
              // tenta uma ultima vez, se não conseguir, termina
              await AuthService.clearToLogin();

              Promise.reject(error);
            });
        });
    }

    return this.instance;
  }
}
