import { GroupMasterService } from '@/services/groupmaster.service';

export default {
    namespaced: true,
    state: {
    },
    actions: {
        async inviteTelegram({ commit }, payload) {
            await this.dispatch('user/updateTelegramId', payload.userId);
            return await GroupMasterService.inviteTelegram(payload.courseId, payload.groupId, payload.userId)
                .then(async response => {
                    if (response.success) {
                        commit('toast/NEW', { type: 'success', message: 'Enviamos o link de convite do grupo no seu Telegram!' }, { root: true });
                        return;
                    }
                    commit('toast/NEW', { type: 'error', message: 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.' }, { root: true });
                })
                .catch(error => {
                    commit('toast/NEW', { type: 'error', message: 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.' }, { root: true });
                });
        },
        async enterTelegram({ commit }, payload) {
            return await GroupMasterService.enterTelegram(payload.courseId, payload.groupId, payload.enrollId)
                .then(async response => {
                    if (response.success) {
                        commit('toast/NEW', { type: 'success', message: 'Você será adicionado ao grupo em breve.' }, { root: true });
                        return;
                    }
                    commit('toast/NEW', { type: 'error', message: 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.' }, { root: true });
                })
                .catch(error => {
                    commit('toast/NEW', { type: 'error', message: 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.' }, { root: true });
                });
        },
        async enterWhatsApp({ commit }, payload) {
            return await GroupMasterService.enterWhatsApp(payload.courseId, payload.groupId, payload.phoneNumber)
                .then(async response => {
                    if (response.success) {
                        commit('toast/NEW', { type: 'success', message: 'Você será adicionado ao grupo daqui à alguns minutos.' }, { root: true });
                        return;
                    }else{
                        commit('toast/NEW', { type: 'warning', message: response.data, duration: 60000 }, { root: true });
                        return;
                    }
                })
                .catch((error, message) => {
                    commit('toast/NEW', { type: 'warning', message: error.message }, { root: true });
                });
        },
        async getInviteLinkWhatsApp({ commit }, payload) {
            return await GroupMasterService.getInviteLinkWhatsApp(payload.courseId, payload.groupId, payload.phoneNumber)
                .then(async response => {
                    if (response.success) {
                        commit('toast/NEW', { type: 'success', message: 'Você será redirecionado em instantes.' }, { root: true });
                        return response.data;
                    }else{
                        commit('toast/NEW', { type: 'warning', message: response.data, duration: 60000 }, { root: true });
                        return;
                    }
                })
                .catch((error, message) => {
                    commit('toast/NEW', { type: 'warning', message: error.message }, { root: true });
                });
        }
    }
}
