<template>
  <div
    id="box-login"
    :class="{ overlay: !logoVisible }"
    ref="boxLogin"
    :style="defaultStyle"
  >
    <UiToastList />

    <div class="login-container">
      <div class="login-logo">
        <div class="logo-cliente" v-show="!loadingLogo">
          <AppLogo
            v-if="showApplicationLogo"
            :darkMode="true"
            width="180px"
          />

          <img
            id="logoImg"
            ref="logoImg"
            :v-show="logoVisible"
            v-else
            @error="defaultImg"
          />
        </div>
      </div>

      <main class="login-main">
        <h1>Acesse sua conta</h1>
        <span id="form-divider" :class="{ 'waiting-data': fetchingData }" />

        <form class="login-form" v-if="showForm">
          <div class="input-field s6">
            <div class="input-field lighten-4 s6 row">
              <svg
                xmlns="http://www.w3.org/2000/svg"
                height="24px"
                viewBox="0 0 24 24"
                width="24px"
                fill="#FFFFFF"
              >
                <path d="M0 0h24v24H0z" fill="none" />
                <path
                  d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
                />
              </svg>
              <input
                id="login"
                :type="!showPass ? 'password' : 'text'"
                v-model="password"
                class="validate lighten-4"
                :class="{ 'overlay-txt': logoVisible }"
              />
              <label
                for="login"
                class="active"
                :class="{ 'overlay-txt': logoVisible }"
                >Nova Senha</label
              >
            </div>
          </div>

          <div class="input-field s12 row">
            <svg
              xmlns="http://www.w3.org/2000/svg"
              height="24px"
              viewBox="0 0 24 24"
              width="24px"
              fill="#FFFFFF"
            >
              <path d="M0 0h24v24H0z" fill="none" />
              <path
                d="M12.65 10C11.83 7.67 9.61 6 7 6c-3.31 0-6 2.69-6 6s2.69 6 6 6c2.61 0 4.83-1.67 5.65-4H17v4h4v-4h2v-4H12.65zM7 14c-1.1 0-2-.9-2-2s.9-2 2-2 2 .9 2 2-.9 2-2 2z"
              />
            </svg>
            <input
              id="password"
              :type="!showPass ? 'password' : 'text'"
              v-model="confirm"
              class="validate"
              :class="{ 'overlay-txt': logoVisible }"
            />
            <label
              for="login"
              class="active"
              :class="{ 'overlay-txt': logoVisible }"
              >Confirmar Senha</label
            >
          </div>
          <div class="login-password-options">
            <div class="login-mostrar-senha">
              <label>
                <input type="checkbox" class="filled-in" v-model="showPass" />
                <span
                  :class="{ 'checkbox-box': true, 'overlay-txt': logoVisible }"
                  >Mostrar senha</span
                >
              </label>
            </div>
          </div>
          <div class="row">
            <a
              v-if="!loading"
              class="waves-effect btn-large btn-login-trustflix"
              :class="{ 'waiting-data': fetchingData }"
              @click="confirmPasswordChange"
              >CONFIRMAR</a
            >
            <a
              v-else
              class="waves-effect btn-large btn-login-trustflix"
              :class="{ 'waiting-data': fetchingData }"
            >
              <div class="preloader-wrapper small active">
                <div class="spinner-layer">
                  <div class="circle-clipper left">
                    <div class="circle"></div>
                  </div>
                  <div class="gap-patch">
                    <div class="circle"></div>
                  </div>
                  <div class="circle-clipper right">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </form>

        <form class="login-form" v-else>
          <div class="row">
            <p class="message title center">Link inválido!</p>
            <p class="message center">
              Por favor, volte e clique em 'Esqueci minha senha'
              novamente para receber um novo link.
            </p>
          </div>
          <div class="row">
            <a
              v-if="!loading"
              class="waves-effect btn-large btn-login-trustflix"
              :class="{ 'waiting-data': fetchingData }"
              @click="toLogin"
              >VOLTAR</a
            >
            <a v-else class="btn-full waves-effect btn-large">
              <div class="preloader-wrapper small active">
                <div class="spinner-layer">
                  <div class="circle-clipper left">
                    <div class="circle"></div>
                  </div>
                  <div class="gap-patch">
                    <div class="circle"></div>
                  </div>
                  <div class="circle-clipper right">
                    <div class="circle"></div>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </form>
      </main>
    </div>

    <iframe
      v-if="isCourseUrl"
      ref="courseIframe"
      :src="appUrl + 'login-integration'"
      width="0"
      height="0"
      style="display: none"
    ></iframe>
    <div class="login-powered">
      Powered by
      <AppLogo darkMode monochrome width="113" />
    </div>

  </div>
</template>

<script>
import AppLogo from '@/components/UI/AppLogo.vue';
import { AuthService } from "@/services/auth.service";
import { mapState } from "vuex";
import UiToastList from "@/components/UI/UiToastList";

export default {
  name: "Login",
  data() {
    return {
      showForm: true,
      hash: "",
      password: "",
      confirm: "",
      showPass: false,
      loading: false,
      error: "",
      appUrl: process.env.VUE_APP_EAD_URL,
      isCourseUrl:
        window.location.protocol + "//" + window.location.host + "/" !=
        process.env.VUE_APP_EAD_URL,
      redirectToCourse: null,
      logoVisible: false,
      fetchingData: true,
      showApplicationLogo: false,
      loadingLogo: true,
      defaultStyle: {
        "--primary-color": "#272727",
      },
    };
  },

  components: {
    AppLogo,
    UiToastList,
  },
  async mounted() {
    if (!this.isCourseUrl) return this.$router.push({ name: "login" });

    this.loadCustomLoginPage();
    this.hash = this.$router.currentRoute.query.h;
    this.loadCustomBackgrounds();

    if (!this.hash) {
      this.$router.push("/");
    } else {
      var response = await AuthService.checkResetHash(this.hash);
      if (response.status == 204) {
        this.showForm = false;
      }
    }
  },
  computed: {
    ...mapState({
      enrolls: (state) => state.enroll.enrolls,
    }),

    isTrustflix() {
      return this.$router.history.current.name === "trustflix";
    },
  },
  methods: {
    postToIframe(isCourseLogin, shopWindow, accessToken) {
      this.$refs.courseIframe.contentWindow.postMessage(
        {
          courseUrl: isCourseLogin ? window.location.href : undefined,
          shopWindow: shopWindow || undefined,
          accessToken: accessToken,
          type: isCourseLogin ? "course-login" : "trustflix-login",
        },
        this.appUrl
      );
    },

    async confirmPasswordChange() {
      if (!this.validateChangePasswordFields()) return;

      try {
        this.loading = true;
        await AuthService.forgotPasswordConfirm({
          hash: this.hash,
          password: this.password,
        });
        this.$store.commit("toast/NEW", {
          message: "Nova senha cadastrada com sucesso",
        });
        this.error = "";

        await this.$store.dispatch("user/getCurrent"); // busca o usuário
        await this.$store.dispatch("enroll/getUserEnroll"); // busca a matricula
        await this.$store.dispatch("notification/getNotifications"); // busca as notificações
        await this.$store.dispatch("config/getConfig");
        await this.$store.dispatch("user/updateProfilePic");
        await this.$store.dispatch("notification/initLoopTimeout");
        await this.$store.dispatch("enroll/getUserEnroll");

        let shopWindow;
        let host = window.location.protocol + "//" + window.location.host + "/";

        if (host !== process.env.VUE_APP_EAD_URL) {
          try {
            shopWindow = await this.$store.dispatch(
              "shopWindow/getCurrent",
              window.location.host
            );
            if (!shopWindow)
              shopWindow = await this.$store.dispatch("shopWindow/getCurrent");
          } catch (error) {}
        }

        let redirectUrl = "";
        if (shopWindow || this.isCourseUrl) {
          const accessToken = AuthService.getAccessToken();

          const isCourseLogin = this.isCourseUrl && !shopWindow;
          this.postToIframe(isCourseLogin, shopWindow, accessToken);

          if (shopWindow) {
            redirectUrl = "p/trustflix";
            await this.$store.dispatch("shopWindow/setCurrent", shopWindow.id);
          } else if (this.isCourseUrl) {
            const courseId = this.redirectToCourse.courseId;
            const enroll = this.enrolls.find((e) => {
              return e.courseId == courseId;
            });

            redirectUrl = enroll ? `p/course/${enroll.id}` : "p/my-courses";
          }

          if (redirectUrl) {
            return (window.location = `${process.env.VUE_APP_EAD_URL}${redirectUrl}`);
          }
        }

        if (this.enrolls.length == 1 && this.enrolls[0].shopWindowId) {
          await this.$store.dispatch("shopWindow/setCurrent", this.enrolls[0].shopWindowId);
          redirectUrl = "p/trustflix";
          return (window.location = `${process.env.VUE_APP_EAD_URL}${redirectUrl}`);
        }

        this.$router.push("/p/my-courses");

        /*
        var shopWindows;
        try {
          shopWindows = await this.$store.dispatch("shopWindow/getAllEnabled");
        } catch (error) {}

        await this.$store.dispatch("shopWindow/clearCurrent");
        if (
          this.enrolls.length == 1 &&
          this.enrolls[0].shopWindowId &&
          shopWindows
        ) {
          var shopWindow = shopWindows.find((e) => {
            return e.id == this.enrolls[0].shopWindowId;
          });
          if (!shopWindow) {
            this.$router.push("/p/my-courses");
            return;
          }

          await this.$store.dispatch("shopWindow/setCurrent", shopWindow.id);
          this.$router.push("/p/trustflix");
          return;
        } else {
          if (this.isCourseUrl) {
            var accessToken = AuthService.getAccessToken();
            this.$refs.courseIframe.contentWindow.postMessage(
              {
                courseUrl: window.location.href,
                accessToken: accessToken,
                type: "course-login",
              },
              this.appUrl
            );

            const courseId = this.redirectToCourse.courseId;
            const enroll = this.enrolls.find((e) => {
              return e.courseId == courseId;
            });
            if (!enroll || enroll.id == 0) {
              this.$store.commit("toast/NEW", {
                type: "error",
                message: "Você não tem uma matrícula válida para esse curso.",
                page: "login",
              });
              this.loading = false;
              return;
            }
            var redirectUrl = `${process.env.VUE_APP_EAD_URL}p/course/${enroll.id}`;
            window.location = redirectUrl;
          } else {
            this.$router.push("/p/my-courses");
          }
        }
        */
      } catch (error) {
        if ((error && error.status === 401) || error.status === 404) {
          this.showForm = false;
          // this.error = "Link inválido. Por favor, clique em esqueci minha senha novamente para receber um novo link.";
          // this.$store.commit("toast/NEW", { message: this.error });
          // this.$router.push("/");
          return;
        }

        this.$store.commit("toast/NEW", {
          type: "error",
          message: error.message,
          page: "login",
        });
      }
      this.loading = false;
    },
    async loadCustomBackgrounds() {
      if (!this.isCourseUrl) {
        return;
      }

      var resp = await AuthService.getAppIdCourseId();
      this.redirectToCourse = resp.data;

      if (
        this.redirectToCourse.applicationId &&
        this.redirectToCourse.courseId
      ) {
        // var bgPath = `${process.env.VUE_APP_CDN_URL}${resp.data.applicationId}/backgrounds/${resp.data.courseId}.jpg`;
        var logoPath = `${process.env.VUE_APP_CDN_URL}${resp.data.applicationId}/logos/${resp.data.courseId}.png`;

        // this.$refs.boxLogin.style = `background-image: url(${bgPath})`;
      } else {
      }
    },
    defaultImg(event) {
      event.target.src = this.$appLogoSrc;
    },
    setElementValid(elementId, valid) {
      var element = document.getElementById(elementId);
      element.className =
        valid === true ? "validation valid" : "validation invalid";
    },
    showFieldsErrors(messages) {
      this.$store.commit("toast/CLEAR_LIST");
      setTimeout(() => {
        for (var i = 0, l = messages.length; i < l; i++) {
          this.$store.commit("toast/NEW", {
            message: messages[i],
            type: "error",
            page: "login",
          });
        }
      }, 500);
    },
    validateChangePasswordFields() {
      let changePasswordValid = true;
      let elementToFocus = "";
      let errorMessages = [];

      this.setElementValid("password", true);
      this.setElementValid("login", true);

      if (this.password != this.confirm) {
        errorMessages.push("Nova senha não confere com a confirmação");
        elementToFocus = "password";
        this.setElementValid("password", false);
        changePasswordValid = false;
      }

      if (this.confirm.length == 0) {
        errorMessages.push("A confirmação da nova senha é obrigatória");
        elementToFocus = "password";
        this.setElementValid("password", false);
        changePasswordValid = false;
      }

      if (this.password.length < 6) {
        errorMessages.push("A nova senha deve conter, no mínimo, 6 caracteres");
        elementToFocus = "login";
        this.setElementValid("login", false);
        changePasswordValid = false;
      }

      if (this.password.length == 0) {
        errorMessages.push("A nova senha é obrigatória");
        elementToFocus = "login";
        this.setElementValid("login", false);
        changePasswordValid = false;
      }

      if (errorMessages.length > 0) {
        document.getElementById(elementToFocus).focus();
        document.getElementById(elementToFocus).select();
        this.showFieldsErrors(errorMessages.reverse());
      }

      return changePasswordValid;
    },
    toLogin() {
      this.$router.push("/");
    },
    setPrimaryColor(color) {
      this.defaultStyle["--primary-color"] = color || "#8059ff";
    },
    async loadCustomLoginPage() {
      if (!this.isCourseUrl) {
        this.logoVisible = true;
        this.showApplicationLogo = true;
        this.loadingLogo = false;
        this.setPrimaryColor();
        this.fetchingData = false;
        return;
      }
      this.fetchingData = true;

      const resp = await AuthService.getAppIdCourseId();
      this.redirectToCourse = resp.data;

      const shopWindowData =
        (resp.data.shopWindow?.enabled && resp.data.shopWindow) || undefined;

      const logoPath = `${process.env.VUE_APP_CDN_URL}${resp.data.applicationId}/courses/${resp.data.courseId}.png`;
      this.setPrimaryColor(
        shopWindowData?.primaryColor || resp.data?.primaryColor
      );

      if (this.imageExists(logoPath)) {
        this.$refs.logoImg.src = logoPath;
        this.showApplicationLogo = false;
      } else {
        this.showApplicationLogo = true;
      }

      this.logoVisible = true;
      this.loadingLogo = false;
      this.fetchingData = false;
    },
    defaultImg(event) {
      this.showApplicationLogo = true;
      // event.target.src = require("@/assets/images/logo-pagtrust.png");
    },
    imageExists(image_url) {
      const http = new XMLHttpRequest();
      // Bypassing the cache according to Mozilla docs
      const key = (/\?/.test(image_url) ? "&" : "?") + new Date().getTime();
      http.open("HEAD", `${image_url}?key=${key}`, false);

      try {
        http.send();
      } catch (err) {}

      return http.status == 200 || http.status == 304;
    },
  },
};
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes walkIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    -webkit-opacity: 0;
  }

  to {
    -webkit-opacity: 1;
  }
}

@-webkit-keyframes walkIn {
  from {
    -webkit-transform: translateY(50px);
    -webkit-opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
}

@font-face {
  font-family: "Material Icons";
  font-display: block !important;
}

#box-login {
  background-color: black;
  height: unset;
  min-height: 100vh;
  padding: 0;

  .login-container {
    width: 500px;
    max-width: 90vw;

    animation: fadeIn 1s forwards;
    -webkit-animation: fadeIn 1s forwards;

    .login-logo {
      height: 120px;

      .vue-content-placeholders {
        width: 180px;
        height: 100px;

        .vue-content-placeholders-img {
          height: 100px;
        }
      }

      .logo-cliente {
        max-width: 180px;
        animation: walkIn 1s forwards;
        -webkit-animation: walkIn 1s forwards;
      }
    }

    .login-main {
      background-color: #141414;
      box-shadow: rgba(29, 29, 29, 0.81) -15px 15px 90px 10px;
      border: 1px solid rgba(255, 255, 255, 0.05);

      border-radius: 30px;

      h1 {
        user-select: none;
        padding: 0 48px;
        font-weight: 300;
        font-size: 36px;
        margin: 38px 0;
        text-align: center;
      }

      .btn-login-trustflix,
      #form-divider {
        transition: background 300ms ease;
      }

      #form-divider {
        height: 6px;
        width: 100%;
        background: linear-gradient(
          90deg,
          black 18%,
          var(--primary-color) 100%
        );
        background-size: 200%;
        display: block;

        &:not(.waiting-data) {
          background-size: 100%;
        }
      }

      .login-form {
        padding: 32px 72px 48px;
      }

      span,
      .overlay-txt {
        color: white !important;
      }

      input {
        background-color: rgba(255, 255, 255, 0.05);
        border: 1px solid transparent !important;

        &#login,
        &#password {
          border-radius: 12px;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          border: 1px solid gray !important;
          -webkit-text-fill-color: white !important;
          -webkit-box-shadow: #292929 0px 0px 0px 1000px inset !important;
          background-color: #292929 !important;
          background-clip: content-box !important;
        }

        &:focus,
        &:-webkit-autofill:focus {
          border-color: var(--primary-color) !important;
        }

        & ~ label {
          margin-left: 3rem;
          user-select: none;

          &.active {
            color: var(--primary-color) !important;
            filter: contrast(0.5);
          }
        }

        & ~ span.checkbox-box::after {
          background-color: transparent;
        }

        &:checked ~ span.checkbox-box::after {
          background-color: var(--primary-color);
          border-color: var(--primary-color);
        }
      }

      .input-field {
        position: relative;

        & > label:not(.label-icon).active {
          transform: translateY(-18px) scale(0.8);
        }

        & i,
        & svg {
          position: absolute;
          width: 3rem;
          font-size: 1.5rem;
          height: 1.5rem;
          top: 0.85rem;
          text-align: center;
        }

        input {
          padding-left: 3rem;
          width: calc(100% - 3rem);
        }
      }

      @media screen and (max-width: 500px) {
        h1 {
          font-size: 6vw;
        }

        .login-form {
          padding: 32px 28px;
        }
      }

      @media screen and (max-width: 320px) {
        .login-password-options {
          flex-direction: column;

          & > div {
            margin: 6px 0;
          }
        }

        .btn-login-trustflix {
          margin-top: 28px;
        }
      }
    }
  }
}

.login-container {
  user-select: none;
}

.login {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;

  .main {
    padding: 30px 15px;
    background: #fff;
    width: 400px;
    border-radius: 2px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

    .header {
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    .error {
      background-color: red;
      padding: 10px;
      font-size: 12px;
      opacity: 1;
      transition: all 0.5s;
    }
  }
}

.spinner-layer {
  border-color: #eee !important;
}

.forgot {
  border-color: #0564b1 !important;
}

.btn-login-trustflix {
  background-color: var(--primaryColor) !important;
  background-image: linear-gradient(
    180deg,
    var(--primary-color) 1%,
    rgba(0, 0, 0, 0.1) 400%
  );
  width: 100%;
  border-radius: 7px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0 40px;
  font-weight: 500;
  white-space: nowrap;
  margin-top: 32px;

  .preloader-wrapper.small {
    width: 24px;
    height: 24px;
    margin-top: 0;
  }
}


</style>
