<template>
  <modal
    name="mygroup"
    :classes="$theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light'"
    height="100%"
    :width="calculatedWidth"
    :adaptive="true"
    :pivotX="1"
    :shiftX="1"
    :pivotY="0"
    :shiftY="0"
    :scrollable="true"
    @opened="load()"
    @closed="loading = true"
    transition="slide-left"
    overlayTransition=""
  >
    <HeaderPage ref="header" pageName="Meus Grupos" modalName="mygroup" />
    <main
      v-if="loading"
      :class="$theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light'"
    >
      <!-- <div class="loading">
      <div class="lds-ripple">
        <div :style="{ borderColor: $theme.primaryColor }"></div>
        <div :style="{ borderColor: $theme.primaryColor }"></div>
      </div>
    </div> -->
      <content-placeholders>
        <content-placeholders-text
          class="carousel-title-placeholder"
          :lines="2"
          :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'"
        />
        <content-placeholders-text
          class="carousel-title-placeholder"
          :lines="4"
          :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'"
        />
      </content-placeholders>
    </main>
    <main
      v-else
      :class="$theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light'"
    >
      <div
        class="row container"
        v-if="
          !isMobile &&
          requestTelegramAuth
        "
        ref="TelegramContainer">
      </div>

      <template v-if="$route.name === 'trustflix'">
        <CourseGroups
          v-for="(course, index) in shopWindowCoursesGroups"
          :key="`course-group#${index}-#${course.courseId}`"
          :course="{
            courseId: course.courseId,
            courseName: course.courseName
          }"
          :whatsAppGroups="course.whatsAppGroups"
          :telegramGroups="course.telegramGroups"
          :facebookGroups="course.facebookGroups"
          :hasCustomTheme="hasCustomTheme"
          :requestTelegramAuth="requestTelegramAuth"
          :telegramUserId="telegramUserId"
          :isLast="(index + 1) === shopWindowCoursesGroups.length"
        />
      </template>
      <CourseGroups
        v-else-if="currentEnroll"
        :whatsAppGroups="currentEnroll.whatsAppGroups"
        :telegramGroups="currentEnroll.telegramGroups"
        :facebookGroups="currentEnroll.facebookGroups"
        :requestTelegramAuth="requestTelegramAuth"
        :telegramUserId="telegramUserId"
        :hasCustomTheme="hasCustomTheme"
      />
    </main>
  </modal>
</template>

<script>
import HeaderPage from "@/layout/HeaderPage";
import { mapState } from "vuex";
import { isMobile } from "mobile-device-detect";
import PhoneGroup from "@/components/PhoneGroup";
import CourseGroups from "@/components/CourseGroups"

export default {
  name: "Mygroup",
  props: ["layoutTheme"],
  components: {
    HeaderPage,
    PhoneGroup,
    CourseGroups
  },
  data() {
    return {
      loading: true,
      telegramUserId: null,
      env: process.env.VUE_APP_ENV
    };
  },
  async mounted() {},
  computed: {
    ...mapState({
      currentEnroll: (state) => state.enroll.current,
      shopWindowCoursesGroups: (state) => state.shopWindow.coursesGroups
    }),
    isTrustflix() {
      return this.$route.name === "trustflix"
    },
    calculatedWidth() {
      if (isMobile) return "100%";
      else return "30%";
    },
    hasCustomTheme() {
      return Boolean(this.isTrustflix || this.currentEnroll)
    },
    requestTelegramAuth() {
      const hasOnEnroll =
        this.currentEnroll?.telegramGroups?.length &&
        this.currentEnroll?.requestTelegramAuth;

      if (hasOnEnroll) return true;

      const hasOnCourses = this.shopWindowCoursesGroups?.some(
        (course) => course.telegramGroups?.length && course?.requestTelegramAuth
      );
      return hasOnCourses;
    }
  },
  methods: {
    async load() {
      this.loading = false;

      this.$nextTick(() => {
        if (
          !isMobile &&
          this.requestTelegramAuth
        ) {
          const script = document.createElement('script');
          script.async = true;
          script.src = 'https://telegram.org/js/telegram-widget.js?14';
          script.setAttribute('data-size', 'large');
          if (this.env == 'staging') {
            script.setAttribute('data-telegram-login', 'PagtrustStBot');
          } else {
            script.setAttribute('data-telegram-login', 'PagtrustBot');
          }
          script.setAttribute('data-request-access', 'write');

          window.onTelegramAuth = this.onTelegramAuth;
          script.setAttribute('data-onauth', 'window.onTelegramAuth(user)');
          this.$refs.TelegramContainer.appendChild(script);
        }
      });
    },
    onTelegramAuth(user) {
      this.telegramUserId = user.id;
    }
  }
};
</script>

<style scoped>
.group-id {
  display: flex;
}

.mail-group{
  word-wrap: break-word;
}
</style>
