import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class NotificationService extends BaseService {
  static get entity () {
    return 'notification'
  }

  
  static async read (notificationId) {
    try {
      
        const response = await this.request({ auth: true }).put(`${process.env.VUE_APP_EAD_API_URL}/notifications/${notificationId}/read`);
          
        // já pega pelo jwt
        return new ResponseWrapper({status:200, data:{success:true}}, response.data);
  
      } catch (error) {
        const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
        throw new ErrorWrapper(error, message)
      }
  }

  static async getNotifications () {

    try {
      
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/notifications`);
      
      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);

    } catch (error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }
}
