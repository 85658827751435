<template><component :is="logoComponent" v-bind="$attrs"/></template>

<script>
import PagTrustLogo from "./PagTrustLogo.vue";

export default {
  name: "AppLogo",
  components: { PagTrustLogo},
  props: {
    // darkMode: Boolean,
    // monochrome: Boolean
  },
  computed: {
    logoComponent() {
      switch (process.env.VUE_APP_DOMAIN_TITLE) {
        case "PagTrust":
          return "PagTrustLogo";
        default:
          return "PagTrustLogo";
      }
    }
  }
};
</script>
