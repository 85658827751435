<template>
  <button
    @click="$emit('click')"
    :class="{
      'waves-effect': true,
      'absolute-positioned': positioned
    }"
    type="button"
  ></button>
</template>

<script>
export default {
  name: 'CarouselNavPrev',
  props: {
    positioned: {
      type: Boolean,
      default: false
    },
    disabled: Boolean
  }
};
</script>

<style lang="scss" scoped>
button {
  &.absolute-positioned {
    position: absolute;
    top: 12px;
    right: 70px;
  }

  display: flex;
  justify-content: center;
  align-items: center;
  padding: 4px 18px;

  border-radius: 8px;
  background-color: var(--primary-color);
  border: none;

  margin-right: 8px;

  &:focus,
  &:hover {
    background-color: var(--primary-color);
    filter: brightness(1.2);
  }

  &.swiper-button-disabled {
    background: black;
    filter: opacity(0.6);

    &:hover {
      cursor: default;
    }
  }

  &::after {
    content: '\f104';
    font-family: FontAwesome;

    font-weight: bolder;
    font-size: 24px;
    color: var(--text-color);
    margin-right: 1px;
    /* mix-blend-mode: difference; */
  }

  @media screen and (max-width: 500px) {
    padding: 2px 8px;
    margin-right: 4px;
  }
}
</style>
