<template>
  <main v-if="loading" :class="{
    'page-main-content': true,
    'page-main-content-compressed': selectedEnroll && isCompressed(),
  }">
    <!-- <div class="loading">
      <div class="lds-ripple">
        <div :style="{ borderColor: $theme.primaryColor }"></div>
        <div :style="{ borderColor: $theme.primaryColor }"></div>
      </div>
    </div> -->

    <content-placeholders>
      <content-placeholders-img class="course-detail-placeholder"
        :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'" />
      <div class="container">
        <content-placeholders-text class="carousel-title-placeholder" :lines="1"
          :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'" />
        <content-placeholders-img class="carousel-image-placeholder"
          :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'" />
        <content-placeholders-text class="carousel-title-placeholder" :lines="1"
          :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'" />
        <content-placeholders-img class="carousel-image-placeholder"
          :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'" />
        <content-placeholders-text class="carousel-title-placeholder" :lines="1"
          :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'" />
        <content-placeholders-img class="carousel-image-placeholder"
          :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'" />
      </div>
    </content-placeholders>
  </main>
  <!-- <main :classes="layoutTheme == 'dark' ? 'bg-color-modal-dark' : 'bg-color-modal-light'" v-else > -->
  <main :style="$theme.darkTheme
    ? 'border-left-color: #0d0d13'
    : 'background-color: #f2f3f7; border-left-color: #F2F3F7'
    " :class="{
      'page-main-content': true,
      'page-main-content-compressed': isCompressed(),
    }" v-else>
    <div class="bloco" v-if="lastAccess">
      <div class="detail-course-image">
        <div class="course-detail">
          <div class="detail-module-name">{{ lastAccess.className }}</div>
          <div class="detail-class-name line-clamp-3">
            {{ lastAccess.learningObjectName }}
          </div>
          <div class="detail-course-start center">
            <a href class="waves-effect btn-start center" @click.prevent="navigateToLastAccess()" :style="!selectedEnroll
              ? '#0564b1'
              : { background: $theme.primaryColor }
              ">
              <IconPlaySingle />
              <!-- <img src="@/assets/icons/play.svg" alt /> -->
              Continuar
            </a>
          </div>
        </div>

        <div class="degrade"></div>
        <img v-lazy="lastAccessImage(lastAccess)" @error="
          $event.target.src = require('@/assets/images/default-course-image.png')
          " />
      </div>
    </div>
    <!--TODO: NOVOS CONTEÚDOS-->
    <!-- <div class="row">
      <div class="container">
        <div class="box-next-content">
          <div class="nextContentTitle">Novos conteúdos</div>
          <div class="nextCarousel">
            <Carousel
              :items="nextClasses"
              :metadata="{
                moduleId: lastAccess.moduleId,
                disciplineId: lastAccess.disciplineId
              }"
              @click="navigateToClass" />
          </div>
        </div>
      </div>
    </div>-->
    <div class="row" v-for="classData in carouselItemsFiltered" :key="classData.id">
      <div :ref="'swiper-container-' + classData.id" class="container">
        <Carousel :items="classData.learningObjects" :metadata="{
          title: classData.title,
          state: classData.state,
          isPreview: classData.isPreview,
          releasedDate: classData.releasedDate,
          classId: classData.id,
          moduleId: classData.moduleId ? classData.moduleId : null,
          disciplineId: classData.disciplineId
            ? classData.disciplineId
            : null,
        }" @click="navigateToClass" @openLeftMenu="openLeftMenu" />
        <!-- <div class="box-next-content">
          <div class="nextContentTitle">
            {{classData.title}}
            <div class="see-all" @click="openLeftMenu(classData.id)">
              <i class="material-icons">menu</i>Ver todos
            </div>
          </div>
          <div class="nextCarousel">
            <Carousel
              :items="classData.learningObjects"
              :metadata="{
                classId: classData.id,
                moduleId: classData.moduleId ? classData.moduleId : null,
                disciplineId: classData.disciplineId ? classData.disciplineId : null
              }"
              @click="navigateToClass"
              @openLeftMenu="openLeftMenu"
            />
          </div>
        </div>-->
      </div>
    </div>

    <div class="load-more center">
      <a href="javascript:;" class="waves-effect btn-load-more center"
        v-if="carouselItemsFiltered.length != carouselItems.length" @click="loadMore()">
        <i class="material-icons">add_box</i>Carregar mais
      </a>
    </div>

    <!-- <div class="hidden-dot">
      <a href="javascript:;" @click="enableChatBot()">.</a>
    </div> -->
  </main>
</template>

<script>
import Carousel from "@/components/Carousel";
import IconPlaySingle from "@/components/UI/IconPlaySingle";
import { mapState } from "vuex";

export default {
  name: "Course",
  props: ["layoutTheme"],
  components: {
    Carousel,
    IconPlaySingle,
  },
  data() {
    return {
      tabIndex: 1,
      pageCount: process.env.VUE_APP_COURSE_PAGE_COUNT,
      loading: true,
    };
  },
  computed: {
    ...mapState({
      currentEnroll: (state) => state.enroll.current,
      enrolls: (state) => state.enroll.enrolls,
      selectedEnroll: (state) => state.enroll.current,
      currentLearningObjects: (state) => state.enroll.currentLearningObjects,
      lastAccess: (state) => state.enroll.lastAccess,
      sidenavIsOpen: (state) => state.config.sidenavIsOpen,
      learningObjectsEnrolls: (state) =>
        state.shopWindow.learningObjectsEnrolls,
    }),
    carouselItems() {
      if (this.currentEnroll == null) return [];

      const structureType = this.currentEnroll.structureType.toLowerCase();

      var classes = [{}];
      switch (structureType) {
        case "modulesanddisciplines":
          //TODO: Nada no layout suporta essa organização
          break;
        case "modules":
        case "disciplinas":
          classes = this.currentEnroll[structureType].reduce(
            (result, modDisc) => result.concat(modDisc.classes),
            []
          );
          break;
        case "classes":
          classes = this.currentEnroll.classes;
          break;
      }

      return classes;
    },
    carouselItemsFiltered() {
      // paginação
      if (!this.carouselItems) return [];
      for (let i = 0; i < this.carouselItems.length; i++) {
        this.carouselItems[i].pageShow = true;
        if (i + 1 > this.pageCount - 1) break;
      }

      return this.carouselItems.filter((clss) => {
        return clss.pageShow;
      });
    },
  },
  async mounted() {
    await this.$store.dispatch("enroll/setCurrentEnroll", {
      enrollId: this.$route.params.id,
    });
    this.$gtag.pageview({
      page_title: this.currentEnroll.name,
      page_path: "/p/course/" + this.currentEnroll.courseId,
    });

    if (this.$route.query.redirect)
      this.redirectToClass(this.$route.query.redirect);
    this.loading = false;
    window.addEventListener("source-click", this.navigateToClassFromBubble);
    this.enableChatBot();
  },
  beforeRouteLeave(to, from, next) {
    window.removeEventListener("source-click", this.navigateToClassFromBubble);
    next();
  },
  methods: {
    loadMore() {
      this.pageCount += this.pageCount;
    },
    openLeftMenu(classId) {
      this.$emit("openLeftMenu", classId);
    },
    navigateToLastAccess() {
      const eventData = {
        moduleId: this.lastAccess.moduleId,
        disciplineId: this.lastAccess.disciplineId,
        classId: this.lastAccess.classId,
        id: this.lastAccess.learningObjectId,
      };

      this.navigateToClass(eventData);
    },
    navigateToClass(eventData, replace = false) {
      this.$router[!replace ? "push" : "replace"]({
        name: "class",
        params: { enrollId: this.currentEnroll.id, id: eventData.id },
      });
    },
    redirectToClass(target) {
      const targetIsClass = typeof target === "number";
      if (targetIsClass) return this.navigateToNonCompleteClass(Number(target));
      if (target === "last") return this.navigateToNextObject();
      this.clearRedirectQuery();
    },
    sortLos(item) {
      return item.state === "Published" ? item.sequence : Infinity;
    },
    getFirstLo() {
      const firstClass = _.minBy(this.currentEnroll.classes, this.sortLos);
      return _.minBy(firstClass.learningObjects, this.sortLos);
    },
    navigateToNextObject() {
      // la means lastAccessed
      if (this.lastAccess) {
        const { classId: laClassId, learningObjectId: laLoId } =
          this.lastAccess;
        const laClassIndex = this.currentEnroll.classes.findIndex(
          (cl) => cl.id === laClassId
        );
        const possibleClasses = this.currentEnroll.classes.slice(laClassIndex);
        const getIsWatchableLo = (lo) =>
          lo.progress < 100 && lo.state === "Published";

        for (const { learningObjects, ...clss } of possibleClasses) {
          const sameClass = clss.id === laClassId;
          let i = 0;

          if (sameClass)
            i = learningObjects.findIndex((lo) => lo.id === laLoId);

          const nextLo = learningObjects.slice(i).find(getIsWatchableLo);

          if (nextLo) return this.navigateToClass({ id: nextLo.id }, true);
        }
      }

      const firstLearningObject = this.getFirstLo();
      // User completed the last object of the course,
      // redirects it to the first Learning Object
      if (firstLearningObject) {
        return this.navigateToClass({ id: firstLearningObject.id }, true);
      }
      return this.clearRedirectQuery();
    },
    navigateToNonCompleteClass(classId) {
      function watchableClass(clss) {
        return clss.progress < 100 && clss.state === "Published";
      }
      const selectedClass =
        classId &&
        this.currentEnroll.classes.find((clss) => clss.id === classId);

      if (selectedClass) {
        const concludedCourse = selectedClass.progress === 100;
        let selectedObject = null;

        // User reached the end of the course either by completing it
        // or by watching the last object, so it should be redirected
        // to first object of the module/class
        const firstClass = _.minBy(selectedClass.learningObjects, this.sortLos);

        if (concludedCourse) {
          selectedObject = firstClass;
        } else {
          const lObjs = selectedClass.learningObjects;
          let completedInd = lObjs.length - 1;
          for (
            completedInd;
            completedInd > 0 && watchableClass(lObjs[completedInd]);
            completedInd--
          );

          if (completedInd + 1 === lObjs.length) selectedObject = firstClass;
          else
            selectedObject = lObjs
              .slice(completedInd)
              .find((lo) => watchableClass(lo));
        }
        if (selectedObject)
          return this.navigateToClass({ id: selectedObject.id }, true);
      }
      this.clearRedirectQuery();
    },
    clearRedirectQuery() {
      this.$router.replace({ query: {} });
    },
    findNextLearningObjectsInClass(classes) {
      var nextLearningObjs = [];

      for (var i = 0; i < classes.length; i++) {
        classes[i].learningObjects.forEach((lo) => {
          if (lo.id != this.lastAccess.learningObjectId) {
            nextLearningObjs.push(lo);
          }
        });
        if (nextLearningObjs.length > 3) break;
      }

      return nextLearningObjs;
    },
    findNextLearningObjectInDiscipline(disciplines) {
      var nextLearningObjs = [];

      for (var i = 0; i < disciplines.classes.length; i++) {
        nextLearningObjs = this.findNextLearningObjectsInClass(
          disciplines.classes[i]
        );
        if (nextLearningObjs.length > 0) break;
      }

      return nextLearningObjs;
    },
    findNextLearningObjectInModule(modules) {
      var nextLearningObjs = [];

      if (modules.disciplines) {
        for (var i = 0; i < modules.disciplines.length; i++) {
          nextLearningObjs = this.findNextLearningObjectInDiscipline(
            modules.disciplines[i]
          );
          if (nextLearningObjs.length > 0) break;
        }
      } else {
        nextLearningObjs = this.findNextLearningObjectsInClass(modules.classes);
      }

      return nextLearningObjs;
    },
    lastAccessImage(lastAccess) {
      if (lastAccess.learningObjectType == "Wcs") {
        return lastAccess.learningObjectImage + "?h=276";
      }
      return lastAccess.learningObjectImage;
    },
    isCompressed() {
      return this.sidenavIsOpen;
    },
    async enableChatBot() {
      if (!this.currentEnroll.agentId) return;
      var agentImage = this.currentEnroll.agentImage;
      if (agentImage && !agentImage.startsWith("http")) {
        agentImage = `${process.env.VUE_APP_CDN_URL}` + agentImage;
      }
      await window.enableChatBot(
        this.currentEnroll.agentId,
        agentImage,
        this.currentEnroll.id,
        this.$currentUser,
        null,
      );
    },
    async navigateToClassFromBubble(eventData) {
      try {
        var enrollId = null;
        const learningObjectId = +eventData.detail.source.custom_id;

        if (!this.currentEnroll.shopWindowId) {
          if (
            !this.currentLearningObjects?.some(
              (learningObject) => learningObject.id === learningObjectId
            )
          )
            throw new Error("Learning Object doesn't belong to this course");

          enrollId = this.currentEnroll.id;
        } else {
          if (!this.learningObjectsEnrolls.length)
            await this.$store.dispatch(
              "shopWindow/getLearningObjectsEnrolls",
              this.$currentUser.studentId
            );
          var learningObjectsEnrolls = this.learningObjectsEnrolls;
          const learningObject =
            learningObjectsEnrolls &&
            learningObjectsEnrolls.find(
              (obj) => obj.learningObjectId === learningObjectId
            );
          if (learningObject) {
            enrollId = learningObject.enrollId;
          }
        }

        await this.$router.push({
          name: "class",
          params: {
            enrollId: enrollId,
            id: learningObjectId,
          },
        });
        window.minimizeChatBot();
      } catch (error) {
        console.error(error);
        this.$store.commit("toast/NEW", {
          type: "warn",
          message: "Ops! Não foi possível navegar para esta aula.",
        });
      }
    },
  },
};
</script>

<style>
.hidden-dot {
  display: flex;
  align-items: center;
  justify-content: center;
}
</style>
