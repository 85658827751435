// single pages
import loginPage from '../pages/Login.vue'
import loadingPage from '../pages/Loading.vue'
import darkLoginPage from '../pages/DarkLogin.vue'
import loginIntegrationPage from '../pages/LoginIntegration.vue'
import forgotPasswordPage from '../pages/ForgotPassword.vue'
import darkForgotPasswordPage from '../pages/DarkForgotPassword.vue'
import notFoundPage from '../pages/NotFound.vue'

// paginas
import coursePreviewPage from '../pages/pages/CoursePreview.vue';
import coursePage from '../pages/pages/Course.vue'
import studentClass from '../pages/pages/StudentClass.vue'
import myCoursesPage from '../pages/pages/MyCourses.vue'
import aboutPage from '../pages/About.vue'
import playerSolo from '../pages/pages/PlayerSolo.vue'
import trustflix from '../pages/pages/Trustflix.vue'

const isCourseURL = !window.location.href.startsWith(process.env.VUE_APP_EAD_URL);

export const routes = [
  {
    path: '/',
    name: 'login',
    component: isCourseURL ? darkLoginPage : loginPage,
    meta: {
      title: `${process.env.VUE_APP_DOMAIN_TITLE} | Login`,
      header: 'no-menu',
      footer:'no-footer'
    }
  },
  {
    path: '/login',
    name: 'dark-login',
    component: darkLoginPage,
    meta: { title: `${process.env.VUE_APP_DOMAIN_TITLE} | Login`, header: 'no-menu', footer: 'no-footer' }
  },
  {
    path: '/login-producer',
    name: 'dark-login',
    component: loadingPage,
    meta: { title: `${process.env.VUE_APP_DOMAIN_TITLE} | Login`, header: 'no-menu', footer: 'no-footer' }
  },
  {
    path: '/login-integration',
    name: 'login-integration',
    component: loginIntegrationPage,
    meta: { title: `${process.env.VUE_APP_DOMAIN_TITLE} | Login`, header: 'no-menu', footer:'no-footer' }
  },
  {
    path: '/course-preview',
    name: 'course-preview',
    component: coursePreviewPage,
    meta: { title: `${process.env.VUE_APP_DOMAIN_TITLE} | Preview`, header: 'dark', footer:'dark' }
  },
  {
    path: '/forgot',
    name: 'forgot',
    component: isCourseURL ? darkForgotPasswordPage : forgotPasswordPage,
    meta: { title: `${process.env.VUE_APP_DOMAIN_TITLE} | Recuperar senha`, header: 'no-menu', footer:'no-footer' }
  },
  {
    path: '/p/course/:id',
    name: 'course',
    component: coursePage,
    meta: { isAuth: true, title: `${process.env.VUE_APP_DOMAIN_TITLE} | Curso`, header:'dark', footer:'dark' }
  },
  {
    path: '/p/course/:enrollId/class/:id',
    name: 'class',
    component: studentClass,
    meta: { isAuth: true, title: `${process.env.VUE_APP_DOMAIN_TITLE} | Aula` , header:'dark', footer:'dark'}
  },
  {
    path: '/p/my-courses',
    name: 'my-courses',
    component: myCoursesPage,
    meta: { isAuth: true, title: `${process.env.VUE_APP_DOMAIN_TITLE} | Meus Cursos`, header:'light', footer:'light' }
  },
  {
    path: '/p/trustflix',
    name: 'trustflix',
    component: trustflix,
    meta: { isAuth: true, title: `${process.env.VUE_APP_DOMAIN_TITLE} | Meus Cursos`, header:'no-menu', footer:'no-footer' }
  },
  {
    path: '/about',
    name: 'about',
    component: aboutPage,
    meta: { isAuth: true, title: `${process.env.VUE_APP_DOMAIN_TITLE} | Sobre`, header: 'no-menu', footer:'dark' }
  },
  {
    path: '/player',
    component: playerSolo,
    meta: { title: `${process.env.VUE_APP_DOMAIN_TITLE} | Player`, header:'dark', footer:'dark' }
  },
  {
    path: '*',
    component: notFoundPage,
    meta: { title: `${process.env.VUE_APP_DOMAIN_TITLE} | Não encontrado`, header:'dark', footer:'dark' }
  }
]
// */
