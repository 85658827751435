import { LearningObjectService } from "@/services/learningObject.service";

export default {
  namespaced: true,
  state: {
    learningObjects: [],
    drm: null,
    ratings: {
      value: 0,
      count: 0,
      updated: true,
    },
    aiContent: null,
  },
  getters: {
    //
  },
  actions: {
    getAI({ commit }, payload) {
      return LearningObjectService.getAI(payload.LearningObjectId)
        .then((response) => {
          const responseData = response.data;
          let parsedAiQuiz;

          try {
            const aiQuiz = JSON.parse(responseData.quiz);
            parsedAiQuiz = {
              questions: aiQuiz.perguntas.map((question) => ({
                question: question.pergunta,
                alternatives: question.alternativas,
                answer: question.resposta_correta,
              })),
            };
          } catch (error) {
            console.error("[AIQuiz-Parser]", error);
          }

          commit("SET_AI", { ...responseData, quiz: parsedAiQuiz });
        })
        .catch((error) => {
          commit(
            "toast/NEW",
            { type: "error", message: error.message },
            { root: true }
          );
        });
    },

    getRatings({ commit }, payload) {
      return LearningObjectService.getRatings(payload.LearningObjectId)
        .then((response) => {
          commit("SET_LEARNING_OBJECT_RATINGS", response.data);
          commit("SET_RATINGS_UPDATED", true);
        })
        .catch((error) => {
          commit(
            "toast/NEW",
            { type: "error", message: error.message },
            { root: true }
          );
        });
    },
    getDRM({ commit }, courseId) {
      return LearningObjectService.getDMRConfig(courseId)
        .then((response) => {
          commit("SET_DRM", response.data);
        })
        .catch((error) => {
          commit(
            "toast/NEW",
            { type: "error", message: error.message },
            { root: true }
          );
        });
    },

    getDMRAuth({ commit }, payload) {
      return LearningObjectService.getDMRAuth(payload.courseId, payload.fileId)
        .then((response) => {
          return response.data;
        })
        .catch((error) => {
          commit(
            "toast/NEW",
            { type: "error", message: error.message },
            { root: true }
          );
        });
    },

    setRatings({ commit }, payload) {
      if (!this.state.learningObject.ratings.updated) return;

      var setRatingP;
      commit("SET_RATINGS_UPDATED", false);

      if (this.state.learningObject.ratings.value == 0) {
        setRatingP = LearningObjectService.insertRatings(
          payload.LearningObjectId,
          payload.Rating
        ).catch((error) => {
          commit(
            "toast/NEW",
            { type: "error", message: error.message },
            { root: true }
          );
        });
      } else {
        setRatingP = LearningObjectService.updateRatings(
          payload.LearningObjectId,
          payload.Rating
        ).catch((error) => {
          commit(
            "toast/NEW",
            { type: "error", message: error.message },
            { root: true }
          );
        });
      }

      setRatingP
        .then((response) => {
          if (response && response.data) {
            this.dispatch("learningObject/getRatings", {
              LearningObjectId: payload.LearningObjectId,
            });
          }
        })
        .catch((error) => {
          commit(
            "toast/NEW",
            { type: "error", message: error.message },
            { root: true }
          );
          commit("SET_RATINGS_UPDATED", true);
        });
    },
  },
  mutations: {
    SET_LEARNING_OBJECT_RATINGS(state, data) {
      state.ratings.value = data.rating;
      state.ratings.count = data.total;
    },
    SET_RATINGS_UPDATED(state, updated) {
      state.ratings.updated = updated;
    },
    SET_AI(state, ai) {
      state.aiContent = ai;
    },
    SET_DRM(state, drm) {
      state.drm = drm;
    },
  },
};
