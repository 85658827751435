<template >
  <modal
    name="coursefiles"
    :classes="($theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light')"
    height="100%"
    :width="calculatedWidth"
    :adaptive="true"
    :scrollable="true"
    :pivotX="0"
    :shiftX="0"
    transition="slide-right"
    overlayTransition
    @opened="load()"
    @before-close="hasActiveDownloads($event)"
    @closed="loading = true"

  >
    <HeaderPage pageName="Downloads" modalName="coursefiles" />
    <main
      v-if="loading"
      :class="($theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light')"
    >
      <!-- <div class="loading">
        <div class="lds-ripple">
          <div :style="{ borderColor: $theme.primaryColor }"></div>
          <div :style="{ borderColor: $theme.primaryColor }"></div>
        </div>
      </div> -->
      <content-placeholders>
        <content-placeholders-img :class="($theme.darkTheme ? 'loading-dark' : 'loading-light')" class="course-files-placeholder" />
      </content-placeholders>
    </main>
    <main v-else  :class="($theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light')">
      <div>
        <ul>
          <li
            v-for="(cfile, index) in summary.files"
            :key="'course_file_' + index"
            class="down-class"
          >
            <div
              class="classe"
              :style="($theme.darkTheme ? 'background: #131318' : 'background: #F2F3F7')"
            >
              <span
                :style="($theme.darkTheme ? 'color: #ffffff' : 'color: #000; fontWeight: 600')"
                class="line-clamp-2"
                v-if="cfile.classe != ''"
              >{{cfile.classe}}</span>
            </div>
            <div class="objeto container">
              <span
                :style="($theme.darkTheme ? 'color: #ffffff' : 'color: #000; fontWeight: 500')"
                class="line-clamp-2"
                v-if="cfile.learningObject != ''"
              >{{cfile.learningObject}}</span>
            </div>

            <table class="container">
              <tbody>
                <tr
                  v-for="(ffile, findex) in cfile.files"
                  :key="'fFile_' + findex"
                  class="down-obj"
                >
                  <td :title="ffile.fileType">
                    <a href="javascript:;" @click="downloadCourseFile(ffile)">
                      <i class="fa fa-download" style="opacity: 1; margin-right: 10px;"></i>
                    </a>
                    <span
                      :style="($theme.darkTheme ? 'color: #ffffff' : 'color: #000; fontWeight: 400')"
                    >[{{ffile.fileType.toUpperCase()}}] {{ffile.name}} ({{ffile.size | bytes}})</span>
                    <div v-show="isDownloading(ffile)" class="progress">
                      <div
                        class="determinate"
                        :style="{width: progressPercent(ffile), 'background-color': primaryColor}"
                      ></div>
                    </div>
                  </td>
                  <td class="right"></td>
                </tr>
              </tbody>
            </table>
          </li>
        </ul>
      </div>
    </main>
  </modal>
</template>

<script>
import HeaderPage from "@/layout/HeaderPage";
import { isMobile } from "mobile-device-detect";
import { mapState } from "vuex";
import { AuthService } from "@/services/auth.service";

var userAgent = navigator.userAgent;
var platform = navigator.platform;
var maxTouchPoints = navigator.maxTouchPoints;
const isIE = /Trident/.test(userAgent);
const isIOS =
  /\b(iPad|iPhone|iPod)(?=;)/.test(userAgent) ||
  (platform === "MacIntel" && maxTouchPoints > 1);
const isIOSChrome = /CriOS/.test(userAgent);

export default {
  name: "CourseFiles",
  props: ["layoutTheme"],
  components: {
    HeaderPage,
  },
  data() {
    return {
      loading: true,
      activeDownloads: [],
    };
  },
  computed: {
    ...mapState({
      summary: (state) => state.enroll.current.summary,
      drmConfig: (state) => state.learningObject.drm,
      currentEnroll: (state) => state.enroll.current,
    }),
    calculatedWidth() {
      if (isMobile && (window.innerWidth < 600)) return "100%";
      else return "365px";
    },
    primaryColor() {
      return "#007aff";
    },
  },
  methods: {
    async downloadCourseFile(file) {

      if (this.isDownloading(file.name)) {
        this.$store.commit("toast/NEW", {
          message: "Download em progresso. Aguarde.",
        });
        return;
      }

      var options = {
        url: file.url,
        name: file.name,
        filename: file.name + "." + file.fileType,
        size: file.size,
      };

      var fileUrl = file.url;
      if(fileUrl.indexOf('.pdf') > -1 && this.drmConfig.ativo)
      {
        options.headers = {
          'authorization': 'bearer ' + AuthService.getAccessToken(),
          'x-applicationid':this.currentEnroll.applicationId
        }

        options.url = process.env.VUE_APP_EAD_API_URL + '/courses/' + this.currentEnroll.courseId + '/file/' + file.id + '/pdf';

      }

      if(fileUrl.indexOf('.pdf') > -1 && this.drmConfig.abrirNavegador)
      { 

        if(this.drmConfig.ativo)
        {
          this.loading = true;

          options.url = await this.$store.dispatch("learningObject/getDMRAuth", {
            courseId:this.currentEnroll.courseId,
            fileId:file.id
          });

          this.loading = false;
        }

        if (/iPad|iPhone|iPod/.test(userAgent) && !window.MSStream) {
          document.location = options.url;
          return;
        }

        window.open(options.url, '_blank').focus();
        return;
      }

      var downloader = new Downloader();

      this.activeDownloads.push({ fileName: file.name, progress: 0 });

      downloader.onprogress = this.downloadProgress;
      downloader.ontimeout = this.downloadTimeout;
      downloader.onfinished = this.downloadFinished;

      downloader.download(options);
    },
    downloadProgress(evt) {
      for (var i = 0; i < this.activeDownloads.length; i++) {
        if (this.activeDownloads[i].fileName == evt.filename) {
          this.activeDownloads[i].progress = evt.progress;
          break;
        }
      }
    },
    isDownloading(file) {
      if (!file) return false;

      return (
        this.activeDownloads.filter((ad) => {
          return ad.fileName == file.name;
        }).length > 0
      );
    },
    progressPercent(file) {
      if (!file) return;

      const activeDownload = this.activeDownloads.filter((ad) => {
        return ad.fileName == file.name;
      })[0];
      if (activeDownload) return activeDownload.progress + "%";

      return;
    },
    downloadFinished(filename) {
      for (var i = 0; i < this.activeDownloads.length; i++) {
        if (this.activeDownloads[i].fileName == filename) {
          this.activeDownloads.splice(i, 1);
          break;
        }
      }
    },
    downloadTimeout(evt) {
      this.downloadFinished(evt.filename);
      this.$store.commit("toast/NEW", {
        message:
          "Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.",
      });
    },
    hasActiveDownloads(evt) {
      if (this.activeDownloads.length > 0) {
        const payload = { message: "Aguarde os downloads", duration: 1000 };
        this.$store.commit("toast/NEW", payload);
        evt.cancel();
      }
    },
    async load() {
      await this.$store.dispatch("learningObject/getDRM", this.currentEnroll.courseId);

      this.loading = false;
    },
  },
  filters: {
    bytes: function (bytes) {
      if (bytes > 1048576) {
        return (bytes / 1024 / 1024).toFixed(1) + "MB";
      } else {
        return (bytes / 1024).toFixed(1) + "KB";
      }
    },
  },
};
</script>
<style scoped>
.course-files-placeholder {
  width: 100vw;
  height: 100vh;
}

.down-class {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
}

.down-class .classe {
  height: 4em;
  width: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  overflow: hidden;
  background-color: #19191d;
  text-align: center;
  padding: 0 25px;

  font-size: 12px;
  font-weight: 500;
}

.down-class .objeto {
  max-height: 3em;
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  margin-top: 1em;
  font-size: 12px;
  font-weight: 300;
  color: white;
}

.down-obj {
  padding-left: 2em;
  font-size: 12px;
  font-weight: 200;
  color: #d6d6d6;
}

.down-obj td {
  padding-left: 1em;
}
</style>
