<template>
  <span class="student-class__empty-tab-fallback">
    Ops! Não há nada por aqui.
  </span>
</template>

<script>
export default {
  name: "StudentClassEmptyTabFallback",
};
</script>

<style scoped>
.student-class__empty-tab-fallback {
  display: block;
  width: 100%;
  text-align: center;
  user-select: none;
}
</style>
