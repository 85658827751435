/**
 * global mixins
 */

import Vue from 'vue'

import currentUser from '../mixins/currentUser'
import uploader from '../mixins/uploader'
import utils from '../mixins/utils'

Vue.mixin(currentUser);
Vue.mixin(uploader);
Vue.mixin(utils);
