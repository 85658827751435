import { CertificateService } from '@/services/certificate.service';


export default {
  namespaced: true,
  state: {
    certificateList: []
  },
  actions: {
    getCertificate({ commit }) {
      return CertificateService.getCertificate()
      .then(response => {
        commit('SET_ALL_CERTIFICATE', response.data);
        return response.data;
      })
      .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },

    emitCertificate({ commit }, cert) {
      return CertificateService.emitCertificate(cert.applicationId, cert.courseId, cert.courseName, cert.enrollId, cert.studentId, cert.progress, cert.createAt, cert.availableForIssue)
          .then(response => {
              return true;
          })
          .catch(error => {
              commit('toast/NEW', { type: 'error', message: error.message }, { root: true });
          })
  }
    
  },
  mutations: {
    

    SET_ALL_CERTIFICATE(state, { data }) {
      state.certificateList = data;
    },

  },
  getters: {
   //
  }
}
