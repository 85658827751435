import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class NoteService extends BaseService {
  static get entity () {
    return 'note'
  }

  static async create (note, time, enrollId, courseId, moduleId, disciplineId, classId, learningObjectId) {
    try {
        const response = await this.request({ auth: true }).post(`${process.env.VUE_APP_EAD_API_URL}/enrolls/${enrollId}/annotations`, {
            "hierarchy": {
                "courseId": courseId,
                "moduleId": moduleId,
                "disciplineId": disciplineId,
                "classId": classId,
                "learningObjectId": learningObjectId
            },
            "timer": time,
            "value": note
        });
        
        return new ResponseWrapper({status:200, data:{success:true}});
  
      } catch (error) {
        const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
        throw new ErrorWrapper(error, message)
      }
  }

  static async getNotes (enrollId) {

    try {
      
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/enrolls/${enrollId}/annotations`);
      
      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);

    } catch (error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }

  static async remove(enrollId, noteId) {
    try {
      const response = await this.request({ auth: true }).delete(`${process.env.VUE_APP_EAD_API_URL}/enrolls/${enrollId}/annotations/${noteId}`);
      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);

    } catch (error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }
}
