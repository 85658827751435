function _getStatusMessage (status) {
  let message = ''
  switch (status) {
    case 200:
      message = 'Ok'
      break
    case 201:
      message = 'Criado'
      break
    case 400:
      message = 'Erro de requisição'
      break
    case 401:
      message = 'Não autorizado'
      break
    case 404:
      message = 'Não encontrado'
      break

    case 500:
    case 503:
      message = 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.'
      break
    default:
      message = 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.'
      break
  }
  return message
}

export class ResponseWrapper {
  constructor (response, data = {}, message) {
    this.data = data
    this.success = response  && response.data ? response.data.success : null
    this.status = response ? response.status : null
    this.statusMessage = _getStatusMessage(this.status)
    this.message = message || null
  }
}

export class ErrorWrapper extends Error {
  constructor (error, message) {


    super()
    this.success = error.response && error.response.data ? error.response.data.success : false
    this.meta = error.response && error.response.data ? error.response.data.meta : false
    this.code = error.response && error.response.data ? error.response.data.code : false
    this.status = error.response ? error.response.status : false
    this.statusMessage = _getStatusMessage(this.status)
    this.message = message || _getStatusMessage(this.status)

  }
}

const dataURItoBlob = (dataURI) => {
  const bytes = dataURI.split(',')[0].indexOf('base64') >= 0
    ? atob(dataURI.split(',')[1])
    : unescape(dataURI.split(',')[1]);
  const mime = dataURI.split(',')[0].split(':')[1].split(';')[0];
  const max = bytes.length;
  const ia = new Uint8Array(max);
  for (let i = 0; i < max; i += 1) ia[i] = bytes.charCodeAt(i);
  return new Blob([ia], { type: mime });
};

export const resizeImage = ({ file, maxSize, type = 'image/jpeg' }) => {
  const reader = new FileReader();
  const image = new Image();
  const canvas = document.createElement('canvas');

  const resize = () => {
    let { width, height } = image;

    if (width > height) {
      if (width > maxSize) {
        height *= maxSize / width;
        width = maxSize;
      }
    } else if (height > maxSize) {
      width *= maxSize / height;
      height = maxSize;
    }

    canvas.width = width;
    canvas.height = height;
    canvas.getContext('2d').drawImage(image, 0, 0, width, height);

    const dataUrl = canvas.toDataURL(type);

    return dataURItoBlob(dataUrl);
  };

  return new Promise((ok, no) => {
    if (!file.type.match(/image.*/)) {
      no(new Error('Not an image'));
      return;
    }

    reader.onload = (readerEvent) => {
      image.onload = () => ok(resize());
      image.src = readerEvent.target.result;
    };

    reader.readAsDataURL(file);
  });
};
