<template>
  <modal name="notes"
  :classes="$theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light'"
  height="100%" :width="calculatedWidth" :adaptive="true" :pivotX="1" :shiftX="1"
 :scrollable="true" @opened="load()" @closed="loading = true" transition="slide-left" overlayTransition="">
    <HeaderPage ref="header" pageName="Anotações" modalName="notes" />
    <main v-if="loading" :class="($theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light')">
          <!-- <div class="loading">
      <div class="lds-ripple">
        <div :style="{ borderColor: $theme.primaryColor }"></div>
        <div :style="{ borderColor: $theme.primaryColor }"></div>
      </div>
    </div> -->
      <content-placeholders>
        <content-placeholders-img class="notes-placeholder" :class="($theme.darkTheme ? 'loading-dark' : 'loading-light')"/>
      </content-placeholders>
    </main>
    <main v-else :class="($theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light')">
      <div v-if="!notes">
        <p class="empty-annotations" :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')">Você não possui anotações</p>
      </div>
      <ul v-else class="collapsible accordion-bg-4">
        <li v-for="note in notes" :key="note.id" class="container note" @click="navTo(note)">
          <div class="note-box" >
            <div class="note-time"  :style="(!selectedEnroll ? '#0564b1' : {background: $theme.primaryColor, color: $theme.primaryText})" >{{note.timerVideo}}</div>
            <div class="note-info">
              <div class="note-info-module truncate" :style="($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333; fontWeight: 500')">{{note.moduleName}}</div>
              <div class="note-info-classes truncate" :style="($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333; fontWeight: 500')">{{note.className}}</div>
              <div class="note-info-obj truncate" :style="($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333; fontWeight: 500')">{{note.learningObjectName}}</div>

            </div>
          </div>
          <div class="note-box note-text">
            <span :style="($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333')">{{note.annotation}}</span>
            <i class="material-icons delete-annotation " :style="($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333')" @click.stop="deleteAnnotation(note)">delete</i>
          </div>

        </li>
      </ul>
    </main>
  </modal>
</template>

<script>
import HeaderPage from "@/layout/HeaderPage";
import M from "materialize-css";
import { mapState } from "vuex";
import { isMobile } from 'mobile-device-detect';

export default {
  name: "Notes",
  props:['layoutTheme'],
  components: {
    HeaderPage
  },
  data() {
    return {
      loading: true
    };
  },
  async mounted() {

  },
  computed: {
    ...mapState({
      currentEnroll: state => state.enroll.current,
      notes: state => state.note.notes,
       selectedEnroll: state => state.enroll.current,
    }),
    calculatedWidth() {
            if(isMobile)
                return '100%';
            else
                return '20%';
        }
  },
  methods: {
    async load() {
      this.loading = true;

      await this.$store.dispatch("note/getNotes", this.currentEnroll);

      this.loading = false;

      this.$nextTick(() => {
        var accordion = document.querySelectorAll(".collapsible");
        M.Collapsible.init(accordion);
      });
    },
    navTo(note) {
      var time = note.timerVideo;
      var h = note.timerVideo.substr(0, 2);
      var m = note.timerVideo.substr(3, 2);
      var s = note.timerVideo.substr(6, 2);

      var time = +s + +m * 60 + +h * 3600;

      this.$router.push({
        name: "class",
        params: { enrollId: this.currentEnroll.id, id: note.learningObjectId },
        query: {
          time: time
        }
      });

      this.$refs.header.navigateBack();
    },
    deleteAnnotation(note) {
      this.$store.dispatch('note/remove', {EnrollId: note.enrollId, NoteId: note.id});
    }
  }
};
</script>

<style scoped>
.courseImg {
  opacity: 0;
  transition: opacity 0.5s ease-in-out;
}

.modules.active .courseImg {
  opacity: 0.5;
}

.note:last-child {
  padding-bottom: 15px;
  border-bottom: none !important;
}

.note {
  padding: 15px 0;
  border-bottom: solid 0.5px rgba(127, 127, 127, 0.2) !important;
  cursor: pointer;
}

.note-text {
  margin-bottom: 0 !important;
}

.accordion-bg-4 {
  /*background: #191920 !important;*/
}

.objects-header {
  height: 60px !important;
}

.objects-header .objects-info {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-orient: horizontal;
  -webkit-box-direction: normal;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-pack: justify;
  -ms-flex-pack: justify;
  justify-content: space-between;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  width: 100%;
  font-family: Montserrat;
  font-size: 12px;
  font-weight: 500;
  line-height: 1.5;
}

.objects-name {
  font-size: 13px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  /*color: #bfbfbf;*/
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
}

.objects-name i {
  font-size: 13px;
  margin-right: 0;
}

.annotations ul {
  padding: 0 26px;
}

.empty-annotations {
  text-align: center;
  font-family: Montserrat;
  font-size: 14px;
  font-weight: normal;
  letter-spacing: 2.38px;
  /*color: #bfbfbf;*/
}

.delete-annotation {
  font-size: 21px;
}
</style>
