var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"row"},[_c('div',{staticClass:"item-title col s10 left",style:(!_vm.hasCustomTheme ? '#0564b1' : { color: _vm.$theme.primaryColor })},[_vm._v(" Grupo de "+_vm._s(_vm.type)+" ")]),(
            (_vm.$currentUser.phones || []).length > 0 &&
            !_vm.entering &&
            (_vm.type == 'WhatsApp' || (_vm.type == 'Telegram' && !_vm.requestTelegramAuth))
          )?_c('i',{staticClass:"material-icons no-padding left icon-link",style:(!_vm.hasCustomTheme ? '#0564b1' : { color: _vm.$theme.primaryColor }),on:{"click":_vm.enterClicked}},[_vm._v(" exit_to_app ")]):(
            (_vm.$currentUser.phones || []).length > 0 &&
            _vm.entering &&
            (_vm.type == 'WhatsApp' ||
            (_vm.type == 'Telegram' && _vm.requestTelegramAuth))
          )?_c('div',{staticClass:"preloader-wrapper small active"},[_c('div',{staticClass:"spinner-layer",style:({ borderColor: !_vm.hasCustomTheme ? '#0564b1' : _vm.$theme.primaryColor })},[_vm._m(0),_vm._m(1),_vm._m(2)])]):_vm._e(),_c('div',{staticClass:"item-description col s10 left",style:(_vm.hasCustomTheme ? _vm.$theme.darkTheme? 'color: #bfbfbf': 'color: #333333': 'color: #333333')},[_vm._v(_vm._s(_vm.name))])]),(_vm.type == 'Telegram' && _vm.requestTelegramAuth)?_c('div',{ref:"TelegramContainer",staticClass:"row"},[(_vm.isMobile && this.hash)?_c('a',{staticClass:"waves-effect btn-profile",style:({ backgroundColor: _vm.$theme.primaryColor, color: _vm.$theme.color }),attrs:{"href":this.env == 'staging'
            ? 'https://t.me/pagtruststbot?start=' + this.hash + '_' + this.id
            : 'https://t.me/pagtrustbot?start=' + this.hash + '_' + this.id}},[_vm._v(" entrar ")]):(_vm.telegramId)?_c('a',{staticClass:"waves-effect btn-profile",style:({ backgroundColor: _vm.$theme.primaryColor, color: _vm.$theme.color }),attrs:{"href":"javascript:;"},on:{"click":_vm.enterTelegram}},[_vm._v(" entrar ")]):_c('div',{staticClass:"item-description col s10 left",style:({ color: _vm.$theme.primaryColor })},[_vm._v(" faça o login acima para solicitar o convite ")])]):_c('div',{staticClass:"row valign-wrapper"},[((_vm.$currentUser.phones || []).length > 0)?_c('div',{staticClass:"group-id col s12"},[_c('i',{staticClass:"material-icons no-padding left icon-color"},[_vm._v("phone")]),_c('div',{staticClass:"item-description no-padding col s10",style:(_vm.hasCustomTheme ? _vm.$theme.darkTheme? 'color: #bfbfbf': 'color: #333333': 'color: #333333')},[_vm._v(" + "+_vm._s(_vm.$currentUser.phones[0].ddi)+" "+_vm._s(_vm.$currentUser.phones[0].area)+" "+_vm._s(_vm.$currentUser.phones[0].number)+" ")]),_c('i',{staticClass:"material-icons no-padding left icon-color mouse-pointer",on:{"click":function($event){return _vm.$modal.show('profile')}}},[_vm._v(" create ")])]):_c('div',{staticClass:" col s12"},[_c('a',{staticClass:"waves-effect btn-profile left",style:(!_vm.hasCustomTheme ? '#0564b1': {background: _vm.$theme.primaryColor,color: _vm.$theme.primaryText}),on:{"click":function($event){return _vm.$modal.show('profile');}}},[_vm._v(" Adicionar telefone ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"circle-clipper left"},[_c('div',{staticClass:"circle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"gap-patch"},[_c('div',{staticClass:"circle"})])},function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"circle-clipper right"},[_c('div',{staticClass:"circle"})])}]

export { render, staticRenderFns }