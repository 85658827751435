<template>
  <div
    class="search-box-global"
    :class="{ 'search-box-global--dark': $theme.darkTheme }"
    :style="$theme.darkTheme ? 'color: #e7e7e7' : 'color: #121212'"
  >
    <div
      class="search-box-global__field"
      :style="$theme.darkTheme ? 'background: #363636' : 'background: #DCDBDC'"
    >
      <i class="material-icons"> search </i>

      <input
        type="search"
        name="search-global"
        placeholder=""
        v-model="searchTerm"
        autocomplete="off"
      />

      <SpinnerWave
        v-if="searchTerm && isLoading"
        class="search-box-global__loading"
      />
    </div>

    <div v-if="searchTerm" class="search-box-global__results">
      <ul
        :style="
          $theme.darkTheme ? 'background: #131318' : 'background: #efefef'
        "
      >
        <li
          v-if="hasNoResults || isLoading"
          class="header-search-result autocomplete-no-results"
          :style="
            $theme.darkTheme
              ? 'background: #131318; color: #efefef'
              : 'background: #efefef; color: #333333'
          "
        >
          <div class="row">
            <div class="col s0 m12 l12">
              <div class="row">
                <div class="col s0 m12 l12 center-align">
                  <template v-if="isLoading">Carregando...</template>
                  <template v-else-if="hasNoResults">
                    Nenhum resultado foi encontrado
                  </template>
                </div>
              </div>
            </div>
          </div>
        </li>
        <template v-for="(resultItem, index) in searchResults">
          <li
            :key="'opt_' + index"
            class="header-search-result"
            :style="
              $theme.darkTheme
                ? 'background: #131318; color: #efefef'
                : 'background: #efefef; color: #333333'
            "
          >
            <GlobalSearchResultCourse
              v-if="type === 'courses'"
              :course="resultItem"
              @click="itemClick"
            />
            <template v-else-if="type === 'learningObjects'">
              <GlobalSearchResultModule
                v-if="resultItem.resultType === 'module'"
                :module="resultItem"
                @click="itemClick"
              />
              <GlobalSearchResultLearningObjects
                v-else-if="resultItem.resultType === 'learningObjects'"
                :module="resultItem"
                @click="itemClick"
              />
            </template>
          </li>
          <hr v-if="index < searchResults.length - 1" />
        </template>
      </ul>
    </div>
  </div>
</template>
<script>
import GlobalSearchResultCourse from "@/components/GlobalSearchBar/ResultCourse.vue";
import GlobalSearchResultLearningObjects from "@/components/GlobalSearchBar/ResultLearningObjects.vue";
import GlobalSearchResultModule from "@/components/GlobalSearchBar/ResultModule.vue";
import SpinnerWave from "@/components/SpinnerWave.vue";
import clickOutside from "@/directives/clickOutside";
import { clearStringAccents } from "@/filters/clearStringAccents";

function normalizeString(string) {
  if (!string?.trim()) return "";
  return clearStringAccents(string.trim().toLowerCase());
}

export default {
  name: "GlobalSearchBar",
  directives: {
    clickOutside: clickOutside,
  },
  components: {
    GlobalSearchResultLearningObjects,
    GlobalSearchResultModule,
    GlobalSearchResultCourse,
    SpinnerWave,
  },
  emits: ["click"],
  props: {
    items: Array,
    filterPropName: String,
    type: {
      type: String,
      default: "learningObjects",
      validator: (type) => ["courses", "learningObjects"].includes(type),
    },
  },
  data() {
    return {
      searchTerm: "",

      isLoading: false,
      searchTimeout: null,

      searchResults: [],
    };
  },
  computed: {
    hasNoResults() {
      return !this.searchResults.length && this.searchTerm.length > 0;
    },
  },
  watch: {
    searchTerm() {
      if (this.searchTimeout) clearTimeout(this.searchTimeout);
      this.searchTimeout = setTimeout(() => {
        this.search();
      }, 300);
    },
  },
  methods: {
    clearSearch() {
      this.searchTerm = "";
      this.searchResults = [];
    },
    clickOutside() {
      this.clearSearch();
    },
    itemClick(item) {
      this.clearSearch();
      this.$emit("click", item);
    },
    async search() {
      if (!this.searchTerm.trim()) {
        this.searchResults = [];
        this.isLoading = false;
        return;
      }

      this.isLoading = true;

      switch (this.type) {
        case "courses":
          this.filterCourses();
          break;
        case "learningObjects":
          this.filterLearningObjects();
          break;
      }

      this.isLoading = false;
    },
    filterCourses() {
      const filterPropName = this.filterPropName;
      const normalizedSearchTerm = normalizeString(this.searchTerm);

      this.searchResults = this.items
        .filter((course) =>
          normalizeString(course[filterPropName]).includes(normalizedSearchTerm)
        )
        .map((course) => ({ ...course, resultType: "course" }));
    },
    filterLearningObjects() {
      const filterPropName = this.filterPropName;
      const normalizedSearchTerm = normalizeString(this.searchTerm);

      this.searchResults = this.items.reduce((resultModules, module) => {
        // Match module
        if (
          normalizeString(module[filterPropName]).includes(normalizedSearchTerm)
        )
          resultModules.push({
            ...module,
            learningObjects: [
              module.learningObjects.find(
                (learningObject) => learningObject.progress < 100
              ) || module.learningObjects[0],
            ],
            resultType: "module",
          });

        // Match learning objects
        const learningObjectsMatch = module.learningObjects.filter(
          (learningObject) =>
            normalizeString(learningObject[filterPropName]).includes(
              normalizedSearchTerm
            )
        );

        if (learningObjectsMatch.length === 0) return resultModules;

        resultModules.push({
          ...module,
          learningObjects: learningObjectsMatch,
          resultType: "learningObjects",
        });

        return resultModules;
      }, []);
    },
  },
};
</script>

<style lang="scss">
.search-box-global {
  border-radius: 10px;
  position: relative;
  z-index: 99;

  .row {
    margin-bottom: unset !important;
  }

  .row,
  .col {
    float: unset !important;
  }

  hr {
    margin: 0.25rem 2rem;
    opacity: 0.5;
  }

  .search-box-global__field {
    width: 45px;
    height: 45px;
    border-radius: 10px;
    overflow: hidden;
    position: relative;
    transition: width 0.3s ease, border-radius 0.1s ease;
    display: flex;
    align-items: center;
    justify-content: center;

    input {
      margin-bottom: 0 !important;
      color: currentColor;
      border: none !important;
    }

    i {
      padding-inline: 0.725rem;
      user-select: none;
    }

    .search-box-global__loading {
      position: absolute;
      height: 1rem;
      width: 1rem;
      top: 1rem;
      right: 1rem;
    }

    &:hover,
    &:focus-within,
    &:has(input:not(:placeholder-shown)) {
      width: 400px;
      justify-content: start;
    }

    &:has(input:not(:placeholder-shown)) {
      border-radius: 10px 10px 0 0;
    }
  }

  .search-box-global__results ul {
    display: flex;
    flex-direction: column;
    gap: 0.5rem;

    position: absolute;
    top: 100%;
    left: 0;
    right: 0;
    padding-block: 1rem;

    max-height: 300px;
    overflow: auto;
    border-radius: 0 0 10px 10px;
  }

  .row:after {
    content: unset !important;
  }

  .header-search-result {
    //background-color: #131318;
    width: 100% !important;
    padding: 0 0.725rem;
  }

  .input-field {
    border-radius: 5px;
    height: 45px;
  }

  .clickable {
    cursor: pointer;
    padding: 0.725rem;
    transition: background-color 0.2s ease;
    border-radius: 8px;
  }

  .clickable:hover {
    background-color: #E0E0E0FF;
  }

  &.search-box-global--dark .clickable:hover {
    background-color: #353542;
  }

  .global-result__item-label {
    font-family: robotomedium;
    font-size: 12px;
    line-height: 20px;
    font-weight: 600;
    opacity: 0.7;
  }

  .global-result__item-title {
    font-size: 14px;
    line-height: 18px;
    display: block;
  }

  .autocomplete-object-img {
    min-width: 57px;
  }

  .autocomplete-no-results {
    width: 100%;
  }
}
</style>
