<template>
  
  <svg xmlns="http://www.w3.org/2000/svg" width="25" height="25" viewBox="0 0 25 25" fill="none">
  <g clip-path="url(#clip0_155_15298)">
    <path d="M23.4375 12.5C23.4381 12.7653 23.3701 13.0262 23.2401 13.2574C23.11 13.4886 22.9223 13.6822 22.6953 13.8193L8.625 22.4268C8.38778 22.572 8.11608 22.6513 7.83797 22.6565C7.55985 22.6616 7.2854 22.5924 7.04297 22.4561C6.80284 22.3218 6.60281 22.126 6.46344 21.8888C6.32407 21.6516 6.2504 21.3816 6.25 21.1064V3.89355C6.2504 3.61844 6.32407 3.3484 6.46344 3.1112C6.60281 2.874 6.80284 2.6782 7.04297 2.54394C7.2854 2.40757 7.55985 2.33838 7.83797 2.34353C8.11608 2.34868 8.38778 2.42798 8.625 2.57324L22.6953 11.1807C22.9223 11.3178 23.11 11.5114 23.2401 11.7426C23.3701 11.9738 23.4381 12.2347 23.4375 12.5Z" fill="white"/>
  </g>
  <defs>
    <clipPath id="clip0_155_15298">
      <rect width="25" height="25" fill="white"/>
    </clipPath>
  </defs>
</svg>
</template>


<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      selectedEnroll: (state) => state.enroll.current,
    })
  },
};
</script>