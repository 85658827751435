<template>
  <div>
    <vd-coremedia
      id="coremedia"
      :token="customerToken"
      :broadcastId="learningObject.broadcast.id"
      :mute="mute"
      :customerId="customerId"
      :email="studentEmail"
      :autoplay="config.autoplay"
      :customFullscreen="false"
      :initialPlaybackRate="config.initialPlaybackRate"
      :env="coremediaEnv"
      showControlBar="true"
      skinClass="vjs-theme-vxl"
      :continue="config.startFromLastSavedState"
      :startFrom="this.$route.query.time"
      components='[ "playToggle","currentTimeDisplay","timeDivider","durationDisplay","progressControl","customControlSpacer","subsCapsButton","volumePanel","fullscreenToggle" ]'
      @time="updateTime($event)"
    >
      <div
        :class="{ player: true, fs: fullscreen }"
        @mouseover="hover = true"
        @mouseleave="hover = false"
      >
        <vd-player
          ref="vdplayer"
          :class="{ 'fs-z-index': fullscreen }"
          @useractive="handleUserActive"
          @userinactive="handleUserInactive"
          @started="handleStarted"
          @ended="handleEnded"
          @ready="playerReady($event)"
        >
        </vd-player>
        <div class="overlay" v-if="overlayVisible"></div>

        <div class="unmute" v-if="unmuteVisible" @click="handleUnmute">
          <a class="waves-effect btn-large">
            <i class="material-icons left">volume_up</i>Ativar som
          </a>
        </div>

        <div id="controllers">
          <div
            id="barRight"
            class="bar-right"
            :class="isVimeo ? (hover ? 'vimeo active' : 'vimeo') : ''"
          >
            <div
              v-show="!isVimeo"
              class="right-button"
              :class="{ move: userActive }"
              @click="showSettings"
            >
              <img src="@/assets/icons/settings.svg" />
            </div>
            <div
              class="right-button"
              :class="{ move: userActive }"
              @click="showNotes"
            >
              <img src="@/assets/icons/notes.svg" />
            </div>
            <!-- <div class="right-button" @click="toggleLibras">
              <img src="@/assets/icons/libras.svg" />
            </div>-->
          </div>
          <!-- <div class="libras-button" :class="{'upLibras' : userActive}" :style="{ display: librasVisible && (currentOrientation != 0 || fullscreen) ? 'flex' : 'none' }">
              <div class="box-Libras-FullScreen">
                <div class="libras">
                  <video src="@/assets/images/libras.mp4" autoplay loop></video>
                </div>
              </div>
          </div>-->
        </div>

        <div
          class="modals"
          :style="{ display: settingsVisible ? 'flex' : 'none' }"
        >
          <div class="modal-header">
            <div class="modal-title">Configurações</div>
            <button class="modal-close" @click="hideSettings">
              <img src="@/assets/icons/close.svg" alt />
            </button>

            <div class="toggles" v-if="false">
              <input
                type="checkbox"
                name="toggleSettings"
                id="checkbox1"
                class="modal-toggle"
                checked
              />
              <label
                for="checkbox1"
                class="checkbox-label"
                data-off="Desligado"
                data-on="Ligado"
              ></label>
            </div>
          </div>

          <div class="modal-content">
            <div class="modal-section">Velocidade de Reprodução</div>

            <vd-playback-rate-control
              class="playback-rate-control"
              v-slot="{ playbackRates, selectedPlaybackRate, setPlaybackRate }"
            >
              <div class="modal-options" id="speedOptions">
                <button
                  v-for="playbackRate in playbackRates"
                  class="modal-btn-option"
                  :class="{ selected: playbackRate == selectedPlaybackRate }"
                  @click="handlePlaybackRate(playbackRate, setPlaybackRate)"
                  :key="playbackRate"
                >
                  {{ playbackRate }}x
                </button>
              </div>
            </vd-playback-rate-control>

            <vd-bitrate-control
              class="bitrate-control"
              v-slot="{ levels, selectedBitrate, setBitrate }"
            >
              <div class="modal-section" v-if="levels && levels.length">
                Resolução
              </div>
              <div
                class="modal-options"
                id="qualityOptions"
                v-if="levels && levels.length"
              >
                <button
                  v-for="level in levels"
                  class="modal-btn-option"
                  :class="{ selected: level.bitrate == selectedBitrate }"
                  @click="setBitrate(level.bitrate)"
                  :key="level.bitrate"
                >
                  {{ level.height }}p
                </button>
                <button
                  class="modal-btn-option"
                  :class="{ selected: selectedBitrate == 'auto' }"
                  @click="setBitrate('auto')"
                >
                  auto
                </button>
              </div>
            </vd-bitrate-control>
          </div>
        </div>

        <div
          class="modals"
          :style="{ display: notesVisible ? 'flex' : 'none' }"
        >
          <div class="modal-header">
            <div class="modal-title">
              <img src="@/assets/icons/notes.png" alt />Anotações
            </div>
            <button class="modal-close" @click="hideNotes">
              <img src="@/assets/icons/close.svg" alt />
            </button>

            <div class="toggles" v-if="false">
              <input
                type="checkbox"
                name="toggleSettings"
                id="checkbox2"
                class="modal-toggle"
              />
              <label
                for="checkbox2"
                class="checkbox-label"
                data-off="Desligado"
                data-on="Ligado"
              ></label>
            </div>
          </div>

          <div class="modal-content">
            <div class="modal-box-notes">
              <textarea                
                id="box-notes"
                ref="boxnotes"
                maxlength="140"
                rows="4"
                v-model="notesText"
              ></textarea>
            </div>
            <div class="notes-group1">
              <div class="notes-count" id="noteCount">
                {{ 140 - notesText.length }}
              </div>
              <div class="notes-send" @click="createNote">
                <template v-if="notesSending">
                  <div class="preloader-wrapper smaller active">
                    <div class="spinner-layer">
                      <div class="circle-clipper left">
                        <div class="circle"></div>
                      </div>
                      <div class="gap-patch">
                        <div class="circle"></div>
                      </div>
                      <div class="circle-clipper right">
                        <div class="circle"></div>
                      </div>
                    </div>
                  </div>
                  ENVIANDO
                </template>
                <template v-else>
                  <img src="@/assets/icons/send.png" alt /> ENVIAR
                </template>
              </div>
            </div>

            <div class="notes-group2">
              <div class="note-time-video">{{ currentTime | time }}</div>
              <div class="note-msg">Nota disponível apenas para você.</div>
            </div>
          </div>
        </div>
      </div>

      <!-- <div class="box-libras" :style="{ display: librasVisible && (currentOrientation == 0 && !fullscreen) ? 'flex' : 'none' }">
        <div class="libras">
          <video src="@/assets/images/libras.mp4" autoplay loop></video>
        </div>
      </div>-->
    </vd-coremedia>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  props: [
    "learningObject",
    "studentEmail",
    "mute",
    "customerId",
    "customerToken",
  ],
  data() {
    return {
      userActive: true,
      settingsVisible: false,
      notesVisible: false,
      librasVisible: false,
      fullscreen: false,
      isVimeo: false,
      hover: false,
      notesText: "",
      notesSending: false,
      currentOrientation: 0,
      currentTime: 0,
      coremediaEnv: process.env.VUE_APP_ENV,
      unmuteVisible: false,
      seekPosition: this.$route.query.time,
      overlayVisible: true
    };
  },
  mounted() {
    this.currentOrientation = screen.orientation
      ? screen.orientation.angle
      : window.orientation;
    window.addEventListener("orientationchange", this.updateOrientation);
  },
  watch: {
    "$route.query.time": function () {
      if (this.seekPosition != this.$route.query.time) {
        this.seekPosition = this.$route.query.time;
        this.playerSeek();
      }
    },
  },
  computed: {
    ...mapState({
      config: (state) => state.config,
      currentEnroll: (state) => state.enroll.current,
      learningObjects: (state) => state.enroll.currentLearningObjects,
      currentLearningObject: (state) => state.enroll.currentLearningObject,
    }),

    // nextLearningObjects() {
    //   if (!this.currentLearningObject) return {};

    //   const structureType = this.currentEnroll.structureType.toLowerCase();

    //   const currentLearningObjectIndex = this.learningObjects
    //     .map(lo => lo.id)
    //     .indexOf(this.currentLearningObject.id);

    //   var nextLearningObjects = [];

    //   for (
    //     var i = currentLearningObjectIndex + 1;
    //     i < this.learningObjects.length;
    //     i++
    //   ) {
    //     nextLearningObjects.push(this.learningObjects[i]);

    //     if (nextLearningObjects.length > 2) break;
    //   }

    //   return nextLearningObjects[0];
    // }
  },
  methods: {
    updateTime(time) {
      this.currentTime = Math.floor(time);
    },
    playerSeek() {
      if (this.$refs.vdplayer.player.currentTime !== undefined) {
        this.$refs.vdplayer.player.currentTime(this.seekPosition);
      }
      if (this.$refs.vdplayer.player.setCurrentTime !== undefined) {
        this.$refs.vdplayer.player.setCurrentTime(this.seekPosition);
      }
    },
    playerReady(event) {
      // copia os controles para dentro do elemento bugfix: não aparecia no fullscreen
      var cElements = document.querySelectorAll(
        ".player .modals, .player #controllers"
      );
      for (var i in cElements) {
        if (typeof cElements[i] != "object") continue;

        document.getElementById("videojsPlayer").appendChild(cElements[i]);
      }

      if (event.IsVimeo) {
        this.isVimeo = true;
        document.querySelector("#barRight").classList += " vimeo";
      }

      this.$refs.vdplayer.player.on("fullscreenchange", (e) => {
        /// this.fullscreen = !this.fullscreen;
      });

      this.$emit("ready", event);
      this.$nextTick(() => this.overlayVisible = false);
    },
    updateOrientation() {
      this.currentOrientation = screen.orientation
        ? screen.orientation.angle
        : window.orientation;
    },
    handleUserActive() {
      this.userActive = true;
    },
    handleUserInactive() {
      this.userActive = false;
    },
    handlePlaybackRate(playbackRate, playbackRateCallback) {
      this.$store.dispatch("config/setInitialPlaybackRate", playbackRate);
      if (typeof playbackRateCallback === "function") {
        playbackRateCallback(playbackRate);
      }
    },
    showSettings() {
      this.settingsVisible = true;
    },
    hideSettings() {
      this.settingsVisible = false;
    },
    showNotes() {
      this.notesVisible = true;
      this.$refs.vdplayer.outFocus();
      this.$refs.vdplayer.pause();      
      /*
      setTimeout(() => {
        this.$refs.boxnotes.focus();
      });
      */
    },
    async createNote() {
      this.notesSending = true;
      if (!this.notesText) {
        this.$store.commit("toast/NEW", {
          type: "error",
          message: "A anotação está em branco",
        });
        this.notesSending = false;
        return;
      }

      await this.$store.dispatch("note/create", {
        note: this.notesText,
        time: this.currentTime,
        enrollId: this.currentEnroll.id,
        courseId: this.currentEnroll.courseId,
        moduleId: this.currentLearningObject.moduleId,
        disciplineId: this.currentLearningObject.disciplineId,
        classId: this.currentLearningObject.classId,
        learningObjectId: this.currentLearningObject.id,
        previousClass: this.currentLearningObject.previousId,
        nextClass: this.currentLearningObject.nextId,
      });

      this.hideNotes();
      this.notesText = "";

      this.$store.commit("toast/NEW", {
        type: "success",
        message: "Anotação feita com sucesso!",
      });
      this.notesSending = false;
    },
    hideNotes() {
      this.notesVisible = false;
      this.$refs.vdplayer.enterFocus();
      this.$refs.vdplayer.play();
    },
    handleStarted(e) {
      if (e.muted) {
        this.unmuteVisible = true;
      }
    },
    handleEnded() {
      this.$emit("ended");
    },
    handleUnmute() {
      this.$refs.vdplayer.setMute(false);
      this.unmuteVisible = false;
    },
    toggleLibras() {
      this.librasVisible = !this.librasVisible;
    },

    navigatePreviousClass() {
      if (!this.currentLearningObject.previousId) return;

      this.$router.push({
        name: "class",
        params: {
          enrollId: this.currentEnroll.id,
          id: this.currentLearningObject.previousId,
        },
      });
    },
    navigateNextClass(auto) {
      if (!this.currentLearningObject.nextId) return;

      var routerParams = {
        name: "class",
        params: {
          enrollId: this.currentEnroll.id,
          id: this.currentLearningObject.nextId,
        },
      };
      if (auto) routerParams.query = { time: 0 }; //pq não funciona 0?

      this.$router.push(routerParams);
    },
  },
  filters: {
    time(value) {
      var h = Math.floor(value / 60);
      var m = Math.floor(value % 60);

      if (h < 10) h = "0" + h;
      if (m < 10) m = "0" + m;

      return h + ":" + m;
    },
  },
};
</script>

<style scoped>
.player {
  position: relative;
  text-align: center;
  width: 100%;
  padding-bottom: 56.25%;
  max-height: 100vh;
  background-color: transparent;
  margin: auto;
  z-index: 10;
  border-radius: 10px;
  overflow: hidden;
}



.player .unmute {
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  height: 100%;
  width: 100%;
}

.player .video-nav-prev,
.player .video-nav-next {
  height: 100%;
  width: 21.4%;
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  /* overflow: hidden; */
}

.btn-nav-video {
  background: #0d0d13;
  border: 2px solid white;
  display: flex;
  align-items: center;
  justify-content: center;
}

.player .video-nav-prev {
  left: -21.4%;
}

.player .video-nav-next {
  right: -21.4%;
}

.player .video-nav-prev #video-nav-details-prev,
.player .video-nav-next #video-nav-details-next {
  width: 100%;
  height: 150px;
  z-index: 1;
  background: black;
  position: absolute;
  padding: 15px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}

.nav-details-img {
  width: 90%;
  height: 60%;
  margin-bottom: 10px;
  object-fit: contain;
}

.nav-details-name {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 400;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}

.player .video-nav-prev #video-nav-details-prev {
  /* left: -90%; */
  left: 30%;
  opacity: 0;
  transition-property: left, right;
  transition-duration: 2s;
}
.player .video-nav-next #video-nav-details-next {
  /* right: -90%; */
  right: 30%;
  opacity: 0;
  transition-property: right, left;
  transition-duration: 2s;
}

.player .video-nav-prev a:hover ~ #video-nav-details-prev {
  /* left: 0; */
  opacity: 1;
  pointer-events: none;
}

.player .video-nav-next a:hover ~ #video-nav-details-next {
  pointer-events: none;
  opacity: 1;
  /* right: 0; */
}

.player .unmute a.waves-effect.btn-large {
  background-color: rgba(0, 0, 0, 0.45);
}

.player video {
  width: 100vw;
}
.player #controllers {
  display: block;
}
.player .bar-right {
  display: flex;
  position: absolute;
  /* width: 30px; */
  /* height: 60%; */
  right: 5px;
  top: 0.5rem;
  /* bottom: 46px; */
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  align-items: flex-end;
  transition: 0.5s ease-in-out;
  z-index: 9;
  overflow: hidden;
}
.vimeo {
  /* right: 55px !important;
  top: 10px !important; */
  right: 10px !important;
  top: 140px !important;
  opacity: 0;
  transition: 140ms ease-in-out !important;
}

.vimeo .right-button {
  background: rgba(23, 35, 34, 0.75) !important;
  margin-bottom: 6px !important;
  width: 2.5em !important;
  height: 2.5em !important;
}
.active {
  opacity: 1;
}

#coremedia:hover ~ #barRight.vimeo {
  border: 1px solid red;
}

@media all and (max-width: 500px) {
  .vimeo {
    
    left: 0px !important;
    width: 50px;
    top: 10px !important;
  }

}

.player .bar-right .right-button {
  margin-bottom: 3px;
  border-radius: 5px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.5s ease-in-out;
  height: 2.5rem;
  width: 2.5rem;
  right: -40px;
  position: relative;
}
.player .bar-right .right-button img {
  /* height: 25px; */
  height: 60%;
  width: 60%;
}
.player .bar-right .right-button svg {
  width: 50%;
  height: 50%;
}
.player .move {
  right: 0px !important;
}
.player .modals {
  z-index: 10;
  flex-direction: column;
  position: absolute;
  text-align: center;
  width: 100%;
  height: 100%; /* 56.25vw;*/
  background-color: rgba(0, 0, 0, 0.9);
  top: 0;
  right: 0;
  /* padding: 8px 18px; */
  padding: 2px 6px;
  box-sizing: border-box;
  display: none;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
}
.player .modals .modal-header {
  display: flex;
  flex-direction: row;
  width: 100%;
  height: 20%;
  align-items: center;
  justify-content: space-between;
  border-bottom: 1px solid #fff;
  /* Rounded sliders */
}
.player .modals .modal-header .modal-title {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  display: flex;
  align-items: center;
}
.player .modals .modal-header .modal-title img {
  padding-right: 10px;
}
.player .modals .modal-header .modal-close {
  right: 12px;
  background-color: Transparent;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 24px;
  font-weight: 600;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #fff;
}
.player .modals .modal-header .modal-switch {
  position: absolute;
  display: inline-block;
  width: 50px;
  height: 24px;
  right: 17vw;
}
.player .modals .modal-header .switch input {
  opacity: 0;
  width: 0;
  height: 0;
}
.player .modals .modal-header .slider {
  position: absolute;
  cursor: pointer;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: #bfbfbf;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.player .modals .modal-header .slider::after {
  content: "";
}
.player .modals .modal-header .slider:before {
  position: absolute;
  content: "";
  height: 17px;
  width: 17px;
  left: 4px;
  bottom: 4px;
  background-color: white;
  -webkit-transition: 0.4s;
  transition: 0.4s;
}
.player .modals .modal-header input:checked + .slider {
  background-color: #2196f3;
}
.player .modals .modal-header input:focus + .slider {
  box-shadow: 0 0 1px #2196f3;
}
.player .modals .modal-header input:checked + .slider:before {
  -webkit-transform: translateX(26px);
  -ms-transform: translateX(26px);
  transform: translateX(26px);
}
.player .modals .modal-header .slider.round {
  border-radius: 24px;
}
.player .modals .modal-header .texto {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  position: absolute;
  width: 65px;
  /* text-align: end; */
  right: 55px;
  height: 22px;
  display: flex;
  align-items: center;
  justify-content: flex-end;
}
.player .modals .modal-header .slider.round:before {
  border-radius: 50%;
}
.player .modal-content {
  width: 100%;
  height: 85%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 10px;
}
.player .modal-box-notes {
  height: 60%;
  overflow: hidden;
}
.player .modal-box-notes textarea {
  width: 100%;
  height: 100%;
  font-family: "Montserrat";
  font-size: 14px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #ffffff;
  background: transparent;
  border: none;
  resize: none;
}
.player .modal-box-notes textarea:focus,
.player .modal-box-notes input:focus {
  outline: none;
}
.player .notes-group1 {
  height: 15%;
  display: flex;
  flex-direction: row;
  justify-content: space-between;
  align-items: center;
}
.player .notes-group1 .notes-count {
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f7f7f;
}
.player .notes-group1 .notes-send {
  display: flex;
  align-items: center;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #48daac;
  cursor: pointer;
}
.player .notes-group1 .notes-send .spinner-layer {
  border-color: #48daac;
}
.player .notes-group2 {
  height: 25%;
  padding: 5px 0px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: start;
}
.player .notes-group2 .note-time-video {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 60px;
  height: 25px;
  background: blue;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: bold;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  text-align: center;
  color: #ffffff;
}
.player .notes-group2 .note-msg {
  padding-left: 20px;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: normal;
  font-stretch: normal;
  font-style: italic;
  line-height: normal;
  letter-spacing: normal;
  color: #7f7f7f;
}
.player .modal-section,
.player .modal-options {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  width: 65%;
}
.player .modal-section {
  padding-top: 20px;
}
.player .modal-options {
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  padding-top: 5px;
}
.player .modal-btn-option {
  background-color: Transparent;
  color: white;
  float: left;
  border: none;
  outline: none;
  cursor: pointer;
  font-family: "Montserrat";
  font-size: 12px;
  font-weight: 500;
  font-stretch: normal;
  font-style: normal;
  line-height: normal;
  letter-spacing: normal;
  color: #7f7f7f;
}
.player .active {
  color: #fff;
  font-weight: 600;
}

#player {
  width: 100%;
  height: 100%;
  position: absolute;
  z-index: 0;
}

.fs-z-index {
  z-index: -1 !important;
  position: relative;
}

.fs {
  transform: rotate(90deg);
  transform-origin: bottom left;
  position: absolute;
  top: -100vw;
  height: 100vw;
  width: 100vh;
  left: 0;
}

.full {
  padding-top: 100vw !important;
}

.bitrate-control button.selected,
.playback-rate-control button.selected {
  font-weight: bold;
  color: #ffffff;
}

.toggles {
  position: absolute;
  right: 50px;
}

.modal-toggle,
.modal-toggle:active {
  position: absolute;
  top: -5000px;
  height: 0;
  width: 0;
  opacity: 0;
  border: none;
  outline: none;
}

.checkbox-label {
  display: block;
  position: relative;
  padding: 10px;
  font-size: 12px;
  line-height: 16px;
  width: 50px;
  height: 20px;
  -webkit-border-radius: 18px;
  -moz-border-radius: 18px;
  border-radius: 18px;
  background: #bfbfbf;
  cursor: pointer;
}

.checkbox-label:before {
  content: "";
  display: block;
  position: absolute;
  z-index: 1;
  line-height: 34px;
  text-indent: 40px;
  height: 20px;
  width: 20px;
  -webkit-border-radius: 100%;
  -moz-border-radius: 100%;
  border-radius: 100%;
  top: 0px;
  left: 0px;
  right: auto;
  background: white;
  -webkit-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2), 0 0 0 2px #bfbfbf;
  -moz-box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2), 0 0 0 2px #bfbfbf;
  box-shadow: 0 3px 3px rgba(0, 0, 0, 0.2), 0 0 0 2px #bfbfbf;
}

.checkbox-label:after {
  content: attr(data-off);
  display: block;
  position: absolute;
  z-index: 0;
  top: 3px;
  left: -305px;
  height: 100%;
  width: 300px;
  text-align: right;
  color: #bfbfbf;
  white-space: nowrap;
}

.modal-toggle:checked + .checkbox-label {
  -webkit-box-shadow: inset 0 0 0 20px #2196f3, 0 0 0 2px #2196f3;
  -moz-box-shadow: inset 0 0 0 20px #2196f3, 0 0 0 2px #2196f3;
  box-shadow: inset 0 0 0 20px #2196f3, 0 0 0 2px #2196f3;
}

.modal-toggle:checked + .checkbox-label:before {
  left: calc(100% - 20px);
  -webkit-box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0, 0, 0, 0.3);
  -moz-box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0, 0, 0, 0.3);
  box-shadow: 0 0 0 2px transparent, 0 3px 3px rgba(0, 0, 0, 0.3);
}

.modal-toggle:checked + .checkbox-label:after {
  content: attr(data-on);
  left: -48px;
  width: 26px;
}

#checkbox1,
#checkbox2 + .checkbox-label {
  -webkit-box-shadow: inset 0 0 0 0px #2196f3, 0 0 0 2px #bfbfbf;
  -moz-box-shadow: inset 0 0 0 0px #2196f3, 0 0 0 2px #bfbfbf;
  box-shadow: inset 0 0 0 0px #2196f3, 0 0 0 2px #bfbfbf;
}

#checkbox1:checked,
#checkbox2:checked + .checkbox-label {
  -webkit-box-shadow: inset 0 0 0 18px #2196f3, 0 0 0 2px #2196f3;
  -moz-box-shadow: inset 0 0 0 18px #2196f3, 0 0 0 2px #2196f3;
  box-shadow: inset 0 0 0 18px #2196f3, 0 0 0 2px #2196f3;
}

#checkbox1:checked,
#checkbox2:checked + .checkbox-label:after {
  color: #2196f3;
}

#boxLibras {
  display: none;
}

#btnLibrasFullscreen {
  display: none;
}

#boxLibrasFullScreen {
  display: none;
  height: 130px;
}

.libras-button {
  position: absolute;
  bottom: 0.5rem;
  right: 0.5rem;
  transition: 0.5s ease-in-out;
  /* width: 120px !important; */
  /* height: 120px !important; */
  /* background-color: transparent !important;*/
}
.upLibras {
  bottom: 5.5rem;
  transition: 0.5s ease-in-out;
}

.box-Libras-FullScreen {
  width: 120px;
  height: 120px;
  position: relative;
  border-radius: 9999px;
  overflow: hidden;
}
.box-Libras-FullScreen .libras {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background: white;
  border-radius: 50%;
  overflow: hidden;
}
.box-Libras-FullScreen .libras video {
  max-width: 200px;
  max-height: 200px;
}

.box-libras {
  align-items: center;
  justify-content: center;
  width: 100%;
  height: 150px;
  background: #3b3b3b;
  -webkit-transition: 0.9s;
  transition: 0.9s;
  /* position: fixed; */
}
.box-libras .libras {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 120px;
  height: 120px;
  background: white;
  border-radius: 50%;
  overflow: hidden;
}
.box-libras .libras video {
  max-width: 200px;
  max-height: 200px;
}

.preloader-wrapper.smaller {
  width: 25px;
  height: 25px;
  margin-right: 5px;
}
</style>

<style>
#videojsPlayer:not(.video-js)>div:first-child {
  padding-top: 56.25% !important;
}
#videojsPlayer.video-js {
  /* padding-top: 56.25% !important; */
}
</style>