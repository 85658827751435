var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"ai-quiz"},[_c('form',{staticClass:"ai-quiz-form",on:{"submit":function($event){$event.preventDefault();return _vm.handleSubmit($event)}}},[_c('ol',{staticClass:"ai-quiz-questions-list"},_vm._l((_vm.questions),function(question,$questionIndex){return _c('AiQuizQuestion',{key:'question#' + $questionIndex + 1,attrs:{"questionId":$questionIndex,"question":question.question,"alternatives":question.alternatives,"correctAnswer":question.answer,"userChoice":_vm.answers[$questionIndex],"isSubmitted":_vm.submitted},on:{"update:userChoice":function($event){return _vm.handleUserChoice($questionIndex, $event)}}})}),1),(_vm.submitted)?_c('div',{staticClass:"quiz-grade",style:({ borderColor: _vm.$theme.primaryColor })},[_c('span',{staticStyle:{"font-weight":"600"}},[_vm._v("Resultado:")]),_vm._v(" Você acertou "),_c('span',{staticClass:"quiz-grade-grade"},[_vm._v(_vm._s(_vm.correctAnswersQty))]),_vm._v(" "+_vm._s(_vm.questions.length > 1 ? "das" : "de")+" "+_vm._s(_vm.questions.length)+" "+_vm._s(_vm.questions.length > 1 ? "questões" : "questão")+" ")]):_vm._e(),_c('div',{staticClass:"quiz-action-buttons"},[_c('button',{staticClass:"quiz-clear-button waves-effect waves-light btn",style:([
          _vm.submitted
            ? {
                backgroundColor: _vm.$theme.primaryColor,
                color: _vm.$theme.primaryText,
              }
            : { backgroundColor: 'gray' } ]),attrs:{"type":"button"},on:{"click":_vm.clearQuiz}},[_vm._v(" "+_vm._s(_vm.submitted ? "Tentar novamente" : "Limpar")+" ")]),(!_vm.submitted)?_c('button',{staticClass:"quiz-submit-button waves-effect waves-light btn",style:({
          background: _vm.$theme.primaryColor,
          color: _vm.$theme.primaryText,
        }),attrs:{"type":"submit"}},[_vm._v(" Conferir resultado ")]):_vm._e()])])])}
var staticRenderFns = []

export { render, staticRenderFns }