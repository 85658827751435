<template>
    <div class="progress-bar-container">
        <div class="progress-bar-value" :style="{ width: value + '%', background: color  }"></div>
    </div>
</template>

<script>
export default {
    props: {
        value: {
            type: Number,
            default: 0
        },
        color: {
            type: String,
            default: '#8059ff'
        }
    }
}
</script>

<style scoped>
    .progress-bar-container {
        position: relative;
        width: 100%;
        height: 5px;
        background: var(--main-on-surface-disable, linear-gradient(0deg, rgba(255, 255, 255, 0.15) 0%, rgba(255, 255, 255, 0.15) 100%), #121212);
        vertical-align: baseline;
        top: -7px;
    }

    .progress-bar-value {
        height: 100%;
        background: var(--primary-color);
        transition: width 300ms ease;
        border-radius: 0px 5px 5px 0px;
    }
</style>
