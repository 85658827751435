import auth from './auth.js';
import comment from './comment.js';
import user from './user.js';
import enroll from './enroll.js';
import note from './note.js';
import config from './config.js';
import learningObject from './learningObject.js';
import notification from './notification.js';
import module from './module.js';
import dom from './dom.js';
import toast from './toast.js';
import forms from './forms.js';
import groupmaster from './groupmaster.js';
import shopWindow from './shopWindow.js';
import certificate from './certificate.js';

export default {
  auth,
  comment,
  config,
  user,
  note,
  learningObject,
  notification,
  module,
  dom,
  enroll,
  toast,
  forms,
  groupmaster,
  shopWindow,
  certificate
};
