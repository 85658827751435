<template>
  <div class="ai-quiz">
    <form class="ai-quiz-form" @submit.prevent="handleSubmit">
      <ol class="ai-quiz-questions-list">
        <AiQuizQuestion
          v-for="(question, $questionIndex) in questions"
          :key="'question#' + $questionIndex + 1"
          :questionId="$questionIndex"
          :question="question.question"
          :alternatives="question.alternatives"
          :correctAnswer="question.answer"
          :userChoice="answers[$questionIndex]"
          :isSubmitted="submitted"
          @update:userChoice="handleUserChoice($questionIndex, $event)"
        />
      </ol>

      <div
        v-if="submitted"
        class="quiz-grade"
        :style="{ borderColor: $theme.primaryColor }"
      >
        <span style="font-weight: 600">Resultado:</span> Você acertou
        <span class="quiz-grade-grade">{{ correctAnswersQty }}</span>
        {{ questions.length > 1 ? "das" : "de" }} {{ questions.length }}
        {{ questions.length > 1 ? "questões" : "questão" }}
      </div>

      <div class="quiz-action-buttons">
        <button
          class="quiz-clear-button waves-effect waves-light btn"
          type="button"
          :style="[
            submitted
              ? {
                  backgroundColor: $theme.primaryColor,
                  color: $theme.primaryText,
                }
              : { backgroundColor: 'gray' },
          ]"
          @click="clearQuiz"
        >
          {{ submitted ? "Tentar novamente" : "Limpar" }}
        </button>
        <button
          v-if="!submitted"
          class="quiz-submit-button waves-effect waves-light btn"
          type="submit"
          :style="{
            background: $theme.primaryColor,
            color: $theme.primaryText,
          }"
        >
          Conferir resultado
        </button>
      </div>
    </form>
  </div>
</template>

<script>
import AiQuizQuestion from "@/components/LearningObject/ArtificialInteligence/Quiz/Question.vue";

export default {
  name: "AiQuiz",
  components: { AiQuizQuestion },
  props: {
    quiz: { type: Object, default: null },
  },
  data() {
    return {
      currentQuestionIndex: null,
      answers: [],

      submitted: false,
    };
  },
  watch: {
    quiz: {
      handler: function () {
        this.clearQuiz();
      },
      immediate: true,
    },
  },
  computed: {
    questions() {
      if (!this.quiz) return [];
      return this.quiz.questions;
    },
    correctAnswersQty() {
      if (!this.submitted) return;
      return this.answers.reduce((acc, answer, index) => {
        if (answer === this.questions[index].answer) {
          return acc + 1;
        }
        return acc;
      }, 0);
    },
  },
  methods: {
    handleSubmit() {
      this.submitted = true;
    },
    clearQuiz() {
      this.answers = Array(this.questions.length).fill(null);
      this.submitted = false;
    },
    handleUserChoice(questionId, answer) {
      this.$set(this.answers, questionId, answer);
    },
  },
  filters: {
    formatQuestionOptionId(questionIndex, alternativeIndex) {
      return `question#${questionIndex + 1}-alternative#${
        alternativeIndex + 1
      }`;
    },
  },
};
</script>

<style scoped lang="scss">
.ai-quiz {
  .ai-quiz-question:not(:last-child) {
    margin-bottom: 1rem;
  }

  .question-label {
    font-weight: 600;
    font-size: 1.025rem;
  }

  .quiz-grade {
    text-align: center;
    border: 1px solid;
    width: fit-content;
    padding: 4px 1rem;
    border-radius: 8px;
    margin: 1rem auto;

    .quiz-grade-grade {
      font-weight: 600;
    }
  }

  .quiz-action-buttons {
    display: flex;
    justify-content: flex-end;
    gap: 1rem;
  }
}
</style>
