import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class GroupMasterService extends BaseService {
    static get entity() {
        return 'groupmaster'
    }

    static async inviteTelegram(courseId, groupId, userId) {
        try {

            const response = await this.request({ auth: true }).post(`${process.env.VUE_APP_API_GROUPMASTER_TELEGRAM_URL}/courses/${courseId}/groups/${groupId}/users/${userId}/invite`);

            return new ResponseWrapper({ status: 200, data: { success: true } }, response.data);

        } catch (error) {
            const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
            throw new ErrorWrapper(error, message);
        }
    }

    static async enterTelegram(courseId, groupId, enrollId) {
        try {

            const response = await this.request({ auth: true }).post(`${process.env.VUE_APP_API_GROUPMASTER_TELEGRAM_URL}/courses/${courseId}/groups/${groupId}/invite/${enrollId}`);

            return new ResponseWrapper({ status: 200, data: { success: true } }, response.data);

        } catch (error) {
            const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
            throw new ErrorWrapper(error, message);
        }
    }

    static async enterWhatsApp(courseId, groupId, phoneNumber) {
        try {
            const response = await this.request({ auth: true }).post(`${process.env.VUE_APP_API_GROUPMASTER_WHATSAPP_URL}/course/${courseId}/group/${groupId}/add/${phoneNumber}`);
            return new ResponseWrapper({ status: 200, data: { success: true } }, response.data);

        } catch (error) {
            // const message = error.response.data; // error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
            const message = 'Não foi possível solicitar a entrada no grupo. Por favor, tente novamente mais tarde.'; 
            throw new ErrorWrapper(error, message);
        }
    }

    static async getInviteLinkWhatsApp(courseId, groupId, phoneNumber) {
        try {
            const response = await this.request({ auth: true }).post(`${process.env.VUE_APP_API_GROUPMASTER_WHATSAPP_URL}/course/${courseId}/group/${groupId}/add/${phoneNumber}/invite`);
            return new ResponseWrapper({ status: 200, data: { success: true } }, response.data);

        } catch (error) {
            // const message = error.response.data; // error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
            const message = 'Não foi possível recuperar o link do grupo. Por favor, tente novamente mais tarde.'; 
            throw new ErrorWrapper(error, message);
        }
    }
}
