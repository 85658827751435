import { ShopWindowsService } from '@/services/shopWindow.service';


export default {
    namespaced: true,
    state: {
        id: null,
        agentId: null,
        agentImage: null,
        enrolledCoursesIds: null,
        progressSection: null,
        dynamicSections: [],
        buySection: null,
        isValid: false,
        primaryColor: null,
        image: null,
        logo: null,
        loading: false,
        coursesGroups: [],
        shopwindows: [],
        learningObjectsEnrolls: []
    },
    actions: {
        getCurrent({ commit }, payload) {
            return ShopWindowsService.getCurrent(payload)
                .then(response => {
                    if (response.data && response.data.length)
                        commit('SET_CURRENT_SHOPWINDOW', response.data[0].id);
                    return response.data;
                })
                .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
        },
        getAllEnabled({ commit }) {
            return ShopWindowsService.getCurrent()
                .then(response => {
                    if (response.data)
                        commit('SET_ALL_SHOPWINDOW', response.data);
                    return response.data;
                })
                .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
        },
        getCurrentFullGrid({ commit, state }) {
            commit('SET_IS_LOADING', true);
            return ShopWindowsService.getCurrentFullGrid(state.id)
                .then(response => {
                    commit('SET_CURRENT_DATA', response.data);
                    commit('SET_IS_LOADING', false);
                    return response.data;
                })
                .catch(error => {
                    commit('toast/NEW', { type: 'error', message: error.message }, { root: true });
                    commit('SET_IS_LOADING', false);
                });
        },
        getLearningObjectsEnrolls({ commit, state }, studentId) {
            return ShopWindowsService.getLearningObjectsEnrolls(studentId, state.id)
                .then(response => {
                    commit('SET_LEARNING_OBJECTS', response.data);
                    return response.data;
                })
                .catch(error => {
                    commit('toast/NEW', { type: 'error', message: error.message }, { root: true });
                });
        },
        setCurrent({ commit }, id) {
            commit('SET_CURRENT_SHOPWINDOW', id);
        },
        clearCurrent({ commit }, id) {
            commit('CLEAR_CURRENT_SHOPWINDOW');
        },
    },
    mutations: {
        CLEAR_CURRENT_SHOPWINDOW(state) {
            state.id = null;
            sessionStorage.removeItem('shopWindowId');
        },
        SET_CURRENT_SHOPWINDOW(state, id) {
            state.id = id;
            sessionStorage.setItem('shopWindowId', id);
        },
        SET_IS_LOADING(state, data) {
            state.loading = data;
        },
        SET_LEARNING_OBJECTS(state, data) {
            if (!data) {
                state.learningObjectsEnrolls = [];
            }
            else {
                state.learningObjectsEnrolls = data;
            }
        },
        SET_CURRENT_DATA(state, data) {
            if (!data) {
                state.enrolledCoursesIds = null;
                state.progressSection = null;
                state.dynamicSections = [];
                state.primaryColor = null;
                state.buySection = null;
                state.isValid = false;
                state.id = null;
                state.image = null;
                state.logo = null;
                state.coursesGroups = [];
                state.shopwindows = [];
                return;
            }

            if (data.sections != null && data.sections.length > 0) {
                state.dynamicSections = [];
                state.buySection = null;
                state.isValid = true;
                state.id = data.id;
                state.agentId = data.agentId;
                state.agentImage = data.agentImage;
                state.primaryColor = data.primaryColor;
                state.image = data.image;
                state.logo = data.logo;
                state.enrolledCoursesIds = data.enrolledCoursesIds;
                state.coursesGroups =
                    data.groups?.filter(
                        course => course.telegramGroups?.length || course.whatsAppGroups?.length
                    ) || [];

                for (let ix = 0, l = data.sections.length; ix < l; ix++) {
                    const section = data.sections[ix];
                    const type = section.type.toLowerCase();

                    if (type == "progress") {
                        state.progressSection = section;
                    } else if (type == "buy") {
                        state.buySection = {
                            ...section,
                            content: section.content.filter(content => !content.enrollId)
                        };
                    } else {
                        // type === 'dynamic'
                        state.dynamicSections.push(section);
                    }
                }
            }
        },
        SET_ALL_SHOPWINDOW(state, { data }) {
            state.shopwindows = data;
        },
    },
    getters: {
        offerByCourseId: (state) => (courseId) => {
            return state.buySection.content.find(
                offer => offer.courseId === courseId
            );
        },
        hasBuySection: (state) => {
            return Boolean(state.buySection && state.buySection.content.length);
        },
        buySectionTitle: (state) => {
            return state.buySection?.title || "";
        },
        isLoading: (state) => {
            const sameShopwindow = state.id == ShopWindowsService.getCurrentShopWindow();
            return state.loading && !sameShopwindow;
        },
        hasGroups: (state) => {
            return (
                Boolean(state.coursesGroups?.length) &&
                state.coursesGroups.some(
                    course => course.telegramGroups || course.whatsAppGroups
                )
            );
        }
    }
};
