<template>
    <div>
        Teste
    </div>
</template>

<script>
import { isIOS } from "mobile-device-detect";
import { AuthService } from '@/services/auth.service'
import { ShopWindowsService } from '@/services/shopWindow.service';

export default {
    mounted() {
        this.$gtag.pageview({
            page_title: 'login',
            page_path: '/'
        });
        window.addEventListener('message', function(e) {
          switch (e.data.type) {
            case 'course-login':
              var courseUrl = e.data.courseUrl;
              var accessToken = e.data.accessToken;
              var refreshToken = e.data.refreshToken;

              AuthService.setAccessToken(accessToken);
              AuthService.setRefreshToken(refreshToken);

              if (isIOS) {
                document.cookie = "accessToken=" + accessToken + ";domain=pagtrust.com.br"
                document.cookie = "refreshToken=" + refreshToken + ";domain=pagtrust.com.br"
              }
              break;

            case 'trustflix-login':
              var shopWindow = e.data.shopWindow;
              var accessToken = e.data.accessToken;
              var refreshToken = e.data.refreshToken;

              AuthService.setAccessToken(accessToken);
              AuthService.setRefreshToken(refreshToken);
              ShopWindowsService.setCurrentShopWindow(shopWindow);

              if (isIOS) {
                document.cookie = "accessToken=" + accessToken + ";domain=pagtrust.com.br"
                document.cookie = "refreshToken=" + refreshToken + ";domain=pagtrust.com.br"
                document.cookie = "shopWindowId=" + shopWindow.id + ";domain=pagtrust.com.br";
              }
              break;

            default:
              break;
          }
        });
    }
}
</script>
