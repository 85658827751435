import axios from 'axios';
import { EnrollService } from '@/services/enroll.service';
import Vue from 'vue';
import { reject } from 'lodash';
import $router from '@/router'

export default {
  namespaced: true,
  state: {
    enrolls: [],
    filter: null,
    headerSearch: null,
    current: null,
    currentClass: null,
    currentLearningObjects: null,
    currentLearningObject: null,
    lastAccess: null,
    inFullscreen: false,
    isPreview: false
  },
  actions: {
    setFullscreen({ commit }, value) {
      commit('SET_FULLSCREEN', value);
    },
    getUserEnroll ({ commit }) {
      return EnrollService.getUserEnroll()
        .then(response => {
          commit('SET_ENROLLS', response.data);
        })
        .catch(error => {
          commit('toast/NEW', { type: 'error', message: error.message }, { root: true });
          reject(false);
        })
    },
    setLastAccess({ commit }, {enroll, learningObject}) {
      EnrollService.setLastAccess(enroll.id, enroll.courseId, learningObject.moduleId, learningObject.disciplineId, learningObject.classId, learningObject.id)
        .then(response => this.dispatch('enroll/getLastAccess', enroll.id));
    },
    setLastAccessNextContent({commit}, {enroll, learningObjectNextId}) {
      const learningObject = this.state.enroll.currentLearningObjects.find(x => x.id == learningObjectNextId);
      EnrollService.setLastAccess(enroll.id, enroll.courseId, learningObject.moduleId, learningObject.disciplineId, learningObject.classId, learningObject.id)
        .then(response => this.dispatch('enroll/getLastAccess', enroll.id));
    },
    getLastAccess({ commit }, enrollId) {
      return EnrollService.getLastAccess(enrollId)
          .then(response => {
            if(response.data.learningObjectType == 'BdQ') {
              switch(response.data.formType) {
                case 'Proof':
                case 'Simulated':
                  response.data.learningObjectImage = require('@/assets/images/posters/proof-simulated.png');
                case 'SurveyResearch':
                  response.data.learningObjectImage = require('@/assets/images/posters/surveyresearch.png');
                case 'Exercise':
                  response.data.learningObjectImage = require('@/assets/images/posters/exercise.png');
              }
            }
            if(response.data.learningObjectType == 'Pdf') {
              response.data.learningObjectImage = require('@/assets/images/posters/pdf.png');
            }
            if(response.data.learningObjectType == 'HtmlContent') {
              response.data.learningObjectImage = require('@/assets/images/posters/texto.png');
            }
            if(response.data.learningObjectType == 'ExternalContent') {
              response.data.learningObjectImage = require('@/assets/images/posters/oferta.png');
            }
            commit('SET_LAST_ACCESS', response.data);
          })
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
    },
    presetCurrentEnroll({ commit }, { enrollId }) {
      var enroll = this.state.enroll.enrolls.find(x => x.id == enrollId);
      commit("SET_CURRENT_ENROLL", { enroll, learningObjects: [] });
    },
    setCurrentEnroll({ commit }, { enrollId, preview }) {
      var getModulesClasses = null;
      if (enrollId) {
        commit('SET_LAST_ACCESS', null);

        var enroll = this.state.enroll.enrolls.find(x => x.id == enrollId);
        if (enroll)
          axios.defaults.headers.common["X-ApplicationId"] = enroll.applicationId;

        getModulesClasses = EnrollService.getModulesClasses(enroll.id);
      } else {
        getModulesClasses = Promise.resolve({
          data: preview
        });
      }

      getModulesClasses.then(response => {
          var enroll = response.data;
          if(!enroll) throw { message: 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.' }

          var learningObjects = [];
          var structureType = enroll.structureType.toLowerCase();

          var getLearningObjectImage = function(learningObj) {
            if(learningObj.broadcast)
              return learningObj.image;

            if (learningObj.htmlContent)
              return require('@/assets/images/posters/texto.png');
            if (learningObj.externalContent)
              return require('@/assets/images/posters/oferta.png');
            if(learningObj.pdf)
              return require('@/assets/images/posters/pdf.png');
            if(learningObj.forms){
              switch(learningObj.forms.type) {
                case 'Proof':
                case 'Simulated':
                  return require('@/assets/images/posters/proof-simulated.png');
                case 'SurveyResearch':
                  return require('@/assets/images/posters/surveyresearch.png');
                case 'Exercise':
                  return require('@/assets/images/posters/exercise.png');
              }
            }
          };

          switch (structureType) {
            case "modulesanddisciplines":
              for (var ixModule = 0, lenModules = (enroll.modules || []).length; ixModule < lenModules; ixModule++) {
                var currentModule = enroll.modules[ixModule];

                for (var ixDiscipline = 0, lenDisciplines = (currentModule.disciplines || []).length; ixDiscipline < lenDisciplines; ixDiscipline++) {
                  var currentDiscipline = currentModule.disciplines[ixDiscipline];

                  for (var ixClass = 0, lenClasses = (currentDiscipline.classes || []).length; ixClass < lenClasses; ixClass++) {
                    var currentClass = currentDiscipline.classes[ixClass];
                    currentClass.isPreview = enroll.isPreview;

                    for (var ixLearningObject = 0, lenLearningObjects = (currentClass.learningObjects || []).length; ixLearningObject < lenLearningObjects; ixLearningObject++) {
                      var currentLearningObject = currentClass.learningObjects[ixLearningObject];

                      currentLearningObject.moduleId = currentModule.id;
                      currentLearningObject.disciplineId = currentDiscipline.id;
                      currentLearningObject.category = currentClass.title;
                      currentLearningObject.state = enroll.isPreview ? null : currentLearningObject.state;
                      currentLearningObject.image = getLearningObjectImage(currentLearningObject);

                      learningObjects.push(currentLearningObject);
                    }

                  }

                }
              }
              break;
            case "modules":
              for (var ixModule = 0, lenModules = (enroll.modules || []).length; ixModule < lenModules; ixModule++) {
                var currentModule = enroll.modules[ixModule];
                for (var ixClass = 0, lenClasses = (currentModule.classes || []).length; ixClass < lenClasses; ixClass++) {
                  var currentClass = currentModule.classes[ixClass];
                  currentClass.isPreview = enroll.isPreview;
                  for (var ixLearningObject = 0, lenLearningObjects = (currentClass.learningObjects || []).length; ixLearningObject < lenLearningObjects; ixLearningObject++) {
                    var currentLearningObject = currentClass.learningObjects[ixLearningObject];

                    currentLearningObject.moduleId = currentModule.id;
                    currentLearningObject.category = currentClass.title;
                    currentLearningObject.isPreview = enroll.isPreview;
                    currentLearningObject.image = getLearningObjectImage(currentLearningObject);

                    if(currentClass.state == 'Unpublished' || (currentClass.state == 'Scheduled' && currentClass.releasedDate)) {
                      currentLearningObject.state = currentClass.state;
                      currentLearningObject.releasedDate = currentClass.releasedDate;
                    }
                    learningObjects.push(currentLearningObject);
                  }
                }
              }
              break;
            case "disciplines":
              for (var ixDiscipline = 0, lenDisciplines = (enroll.disciplines || []).length; ixDiscipline < lenDisciplines; ixDiscipline++) {
                var currentDiscipline = enroll.disciplines[ixDiscipline];

                for (var ixClass = 0, lenClasses = (currentDiscipline.classes || []).length; ixClass < lenClasses; ixClass++) {
                  var currentClass = currentDiscipline.classes[ixClass];
                  currentClass.isPreview = enroll.isPreview;

                  for (var ixLearningObject = 0, lenLearningObjects = (currentClass.learningObjects || []).length; ixLearningObject < lenLearningObjects; ixLearningObject++) {
                    var currentLearningObject = currentClass.learningObjects[ixLearningObject];

                    currentLearningObject.disciplineId = currentDiscipline.id;
                    currentLearningObject.category = currentClass.title;
                    currentLearningObject.isPreview = enroll.isPreview;
                    currentLearningObject.image = getLearningObjectImage(currentLearningObject);

                    if(currentClass.state == 'Unpublished' || (currentClass.state == 'Scheduled' && currentClass.releasedDate)) {
                      currentLearningObject.state = currentClass.state;
                      currentLearningObject.releasedDate = currentClass.releasedDate;
                    }
                    learningObjects.push(currentLearningObject);
                  }
                }
              }
              break;
            case "classes":
              for (var ixClass = 0, lenClasses = (enroll.classes || []).length; ixClass < lenClasses; ixClass++) {
                var currentClass = enroll.classes[ixClass];
                currentClass.isPreview = enroll.isPreview;
                for (var ixLearningObject = 0, lenLearningObjects = (currentClass.learningObjects || []).length; ixLearningObject < lenLearningObjects; ixLearningObject++) {
                  var currentLearningObject = currentClass.learningObjects[ixLearningObject];
                  currentLearningObject.category = currentClass.title;
                  currentLearningObject.isPreview = enroll.isPreview;
                  currentLearningObject.image = getLearningObjectImage(currentLearningObject);

                  if(currentClass.state == 'Unpublished' || (currentClass.state == 'Scheduled' && currentClass.releasedDate)) {
                    currentLearningObject.state = currentClass.state;
                    currentLearningObject.releasedDate = currentClass.releasedDate;
                  }
                  learningObjects.push(currentLearningObject);
                }
              }
              break;
          }

          var releasedLearningObjects = (learningObjects || []).filter(lo => { return lo.state != 'Unpublished' && (lo.state != 'Scheduled' || !lo.releasedDate) });

          for (var i = 0; i < releasedLearningObjects.length; i++) {
            if (i > 0) {
              releasedLearningObjects[i].previousIndex = i - 1;
              releasedLearningObjects[i].previousId = releasedLearningObjects[i - 1].id;
              releasedLearningObjects[i].previousName = releasedLearningObjects[i - 1].title;
              releasedLearningObjects[i].previousImage = getLearningObjectImage(releasedLearningObjects[i - 1]);
            }

            if (i < releasedLearningObjects.length - 1) {
              releasedLearningObjects[i].nextIndex = i + 1;
              releasedLearningObjects[i].nextId = releasedLearningObjects[i + 1].id;
              releasedLearningObjects[i].nextName = releasedLearningObjects[i + 1].title;
              releasedLearningObjects[i].nextImage = getLearningObjectImage(releasedLearningObjects[i + 1]);
            }
          }

          commit("SET_CURRENT_ENROLL", { enroll, learningObjects });
        })
        .catch(async error => {
          await $router.push("/p/my-courses");
          await commit('toast/NEW', { type: 'error', message: error.message }, { root: true });
        });

      var getLastAccess = null;
      if (preview) {
        getLastAccess = Promise.resolve(null);
      } else {
        getLastAccess = this.dispatch('enroll/getLastAccess', enroll.id);
      }

      return Promise.all([ getModulesClasses, getLastAccess ]);
    },
    clearCurrentEnroll({commit}) {
      commit('CLEAR_CURRENT');
    },
    updateFilter({ commit }, filter) {
      commit('UPDATE_FILTER', filter);
    },
    setCurrentLearningObject({ commit }, learningObjectId) {
      var selectedLearningObject = this.state.enroll.currentLearningObjects.find(x => x.id == learningObjectId);
      commit('SET_CURRENT_LEARNING_OBJECT', selectedLearningObject);
    },
    setProgress({ commit }, { enrollId, courseId, moduleId, disciplineId, classId, learningObjectId, broadcastId, progress }) {
      EnrollService.setProgress(enrollId, courseId, moduleId, disciplineId, classId, learningObjectId, broadcastId, progress)
        .then(response => {
          commit('SET_PROGRESS', progress);
          if (response.data) {
            commit('SET_OBJ_PROGRESS', {classId, learningObjectId, progress });
          }
        });
    },
    refreshProgress({ commit }, { enrollId, courseId, moduleId, disciplineId, classId, learningObjectId, broadcastId }) {
      EnrollService.refreshProgress(enrollId, courseId, moduleId, disciplineId, classId, learningObjectId, broadcastId)
      .then(response => {
        if(response && response.data) {
          commit('SET_OBJ_PROGRESS', {classId, learningObjectId, progress: response.data });
        }
      })
    },
    getCourseProgress({ commit }, enrollId) {
      EnrollService.getCourseProgress(enrollId)
      .then(response => {
        if(response.data) {
          commit('SET_COURSE_PROGRESS', response.data);
        }
      })
      .catch(error => { });
    },
    updateLoadingFile({ commit }, data){
      commit('SET_LOADING_FILE', data);
    },
  },
  getters: {
    filtered(state) {
      if (!state.filter) return state.current;

      var filtered = _.cloneDeep(state.current);

      const filterByLObjectTitle = classes => {
        return classes.filter(clss => {
          const filteredLos = clss.learningObjects.filter(lo => {
            return lo.title.toLowerCase().indexOf(state.filter.toLowerCase()) > -1;
          });
          clss.learningObjects = filteredLos;
          return filteredLos.length > 0;
        });
      };

      switch (filtered.structureType.toLowerCase()) {
        case "classes":
          filtered.classes = filterByLObjectTitle(filtered.classes);
          break;

        case "modules":
          for (let i in filtered.modules) {
            filtered.modules[i].classes = filterByLObjectTitle(
              filtered.modules[i].classes
            );
          }

          // se algum modulo não tiver classes, não mostra
          filtered.modules = filtered.modules.filter(mod => {
            return mod.classes.length;
          });

          break;
      }

      return filtered;
    },
    autocomplete(state) {
      if(!state.current) return [];
      var justClassesAndLearningObjs = [];
      const currentEnroll = _.cloneDeep(state.current);
      if(currentEnroll.structureType.toLowerCase() != 'classes') {
        for (let index = 0, l = currentEnroll.modules.length; index < l; index++) {
          justClassesAndLearningObjs = justClassesAndLearningObjs.concat(currentEnroll.modules[index].classes);
        }
      }
      else {
        justClassesAndLearningObjs = currentEnroll.classes;
      }

      return justClassesAndLearningObjs;
    },
    getAll(state) {
      return state.enrolls;
    }
  },
  mutations: {
    SET_LOADING_FILE (state, data) {
      Vue.set(state.currentLearningObject.files[data.i], 'loadingItem', data.value);
    },
    SET_FULLSCREEN (state, data) {
      state.inFullscreen = data;
    },
    SET_ENROLLS (state, data) { // seta a lista
      state.enrolls = data.items
    },
    SET_CURRENT_ENROLL (state, { enroll, learningObjects }) { // o selecionado
      Vue.set(state, 'current', enroll);
      state.currentLearningObjects = learningObjects;
      if(enroll.isPreview)
        Vue.set(state, 'isPreview', enroll.isPreview);
    },
    CLEAR_CURRENT(state) {
      state.current = null;
    },
    SET_CURRENT_LEARNING_OBJECT (state, data) {
      state.currentLearningObject = data
    },
    UPDATE_FILTER (state, data) {
      state.filter = data;
    },
    SET_LAST_ACCESS (state, data) {
      state.lastAccess = data;
    },
    SET_PROGRESS (state, data) {
      if (state.currentClass) {
        Vue.set(state.currentClass, 'progress', data);
      }
    },
    SET_OBJ_PROGRESS( state, data) {
      var cls = state.current.classes.find(c=>c.id == data.classId);
      if (!cls) return;
      var learningObject = cls.learningObjects.find(lo => lo.id == data.learningObjectId);
      if (!learningObject) return;

      Vue.set(learningObject, 'progress', data.progress);
    },
    SET_COURSE_PROGRESS(state, data) {
      state.current.progress = data;
    }
  }
}
