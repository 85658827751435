<template>
  <div v-if="loading">
        <!-- <div class="loading">
      <div class="lds-ripple">
        <div :style="{ borderColor: $theme.primaryColor }"></div>
        <div :style="{ borderColor: $theme.primaryColor }"></div>
      </div>
    </div> -->
    <content-placeholders>
      <content-placeholders-img :class="($theme.darkTheme ? 'loading-dark' : 'loading-light')" class="video-placeholder" />
    </content-placeholders>
  </div>
  <div class="quiz" v-else>
    <div v-show="!quizSubmitted" class="quiz-questions container">
      <div v-if="currentForm">
        <div class="quiz-info">
          <div class="quiz-title" :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')">{{currentForm.title}}</div>
          <!-- <div
            v-show="currentForm.description"
            class="quiz-description"
          >{{currentForm.description}}</div>-->
        </div>
        <div>
          <div
            class="quiz-question"
            v-for="(question, index) in currentForm.questions"
            :key="'questionId_' + question.id"
          >
            <div class="question-number" :style="{color: $theme.primaryColor}">{{index+1}}.</div>
            <div class="question-title" v-html="question.title" :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')"></div>
            <div
              v-if="currentForm.type != 'SurveyResearch'"
              class="question-level" :style="($theme.darkTheme ? 'color: #cccccc' : 'color: #333333')"
            >Nível: {{question.level | ptLevel}} ({{question.type | ptType}})</div>
            <div v-if="question.type == 'SingleAnswer'" class="questions-single-answer">
              <form>
                <p
                  v-for="alternative in question.alternatives"
                  :key="'question_alternative_' + alternative.id"
                >
                  <label :for="'rdo_' + alternative.id">
                    <input
                      :id="'rdo_' + alternative.id"
                      type="radio"
                      class="with-gap"
                      name="singleanswer"
                      :value="alternative.id"
                      v-model="answers[index]"
                    />
                    <span :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')">{{alternative.title}}</span>
                  </label>
                </p>
              </form>
            </div>
            <div v-if="question.type == 'MultipleAnswer'" class="questions-multiple-answer">
              <form>
                <p
                  v-for="alternative in question.alternatives"
                  :key="'question_alternative_' + alternative.id"
                >
                  <label :for="'rdo_' + alternative.id">
                    <input
                      :id="'chk_' + alternative.id"
                      type="checkbox"
                      class="with-gap"
                      name="multipleanswer"
                      :value="alternative.id"
                      v-model="answers[index]"
                    />
                    <span :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')">{{alternative.title}}</span>
                  </label>
                </p>
              </form>
            </div>
            <div v-if="question.type == 'Essay'" class="questions-multiple-answer">
              <form>
                <p
                  v-for="alternative in question.alternatives"
                  :key="'question_alternative_' + alternative.id"
                >
                  <label :for="'rdo_' + alternative.id">
                    <input
                      :id="'rdo_' + alternative.id"
                      type="textarea"
                      class="with-gap"
                      v-model="answers[index]"
                    />
                    <span :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')">{{alternative.title}}</span>
                  </label>
                </p>
              </form>
            </div>
          </div>
        </div>
      </div>
      <div class="quiz-insert-coin" v-if="allowAnswer === false && formResult === ''">Você já respondeu esse formulário.</div>
      <div class="quiz-finish" v-else>
        <a
          v-if="!quizSubmitted && !rightAnswers"
          href
          class="waves-effect btn-full center"
          @click.prevent="sendAnswer"
          :style="{background: $theme.primaryColor, color: $theme.primaryText}"
        >Terminei! Ver resultado</a>
      </div>
    </div>
    <div v-show="quizSubmitted" class="quiz-result container">
      <div class="result-loading row valign-wrapper" v-if="!quizResponse">
        <div class="col s12 m12 l12">
          <div class="result-label" :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333')">Aguarde</div>
          <div class="progress">
            <div class="indeterminate"></div>
          </div>
        </div>
      </div>
      <div v-else-if="quizResponse.Errors">
        <div
          class="result-label result-survey" :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')"
        >Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.</div>
      </div>
      <div v-else-if="quizResponse.SurveyResearch">
        <div class="result-label result-survey" :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')">Obrigado por participar</div>
        <div v-if="quizResponse.Results">
          <div
            class="quiz-question"
            v-for="question in currentForm.questions"
            :key="'survey_distribution' + question.id"
          >
            <div class="question-title" v-html="question.title" :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')"></div>
            <form>
              <p v-for="alternative in question.alternatives" :key="'a_survey_' + alternative.id">
                <label for :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')">
                  <strong :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')">{{getSurveyPercentage(alternative.id)}}%</strong> -
                </label>
                <label for :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')">{{alternative.title}}</label>
              </p>
            </form>
          </div>
        </div>
      </div>
      <div v-else-if="quizResponse.hasResponse">
        <div class="result-label" :style="($theme.darkTheme ? 'color: #fff' : 'color: #333333; fontWeight: 500')">Seu resultado é:</div>
        <div class="result-percent" :style="{color: $theme.primaryColor}">Você acertou {{quizResponse.percentage}}%</div>
        <div class="result-label" :style="($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333')">
          Você acertou
          <span :style="{color: $theme.primaryColor}">{{quizResponse.rightsCount}} de {{quizResponse.total}} questões</span>
          <br />do nosso Quiz
        </div>
        <div v-if="currentForm.unlimitedAttemps" class="quiz-again">Refazer Quiz</div>
      </div>
      <div v-else>
        <div class="result-label result-survey">Obrigado por participar</div>
      </div>
    </div>
  </div>
</template>

<script>
import { mapState, mapGetters } from "vuex";

export default {
  name: "Quiz",
  props: ["formId"],
  components: {},
  data() {
    return {
      quizSubmitted: false,
      answers: [],
      quizResponse: null,
    };
  },
  mounted() {
    this.load();
  },
  watch: {
    allowAnswer: function (newVal, oldVal) {
      if (newVal === false) {
        
      }
    }
  },
  computed: {
    ...mapState({
      currentForm: (state) => state.forms.current,
      currentAnswerFormId: (state) => state.forms.answerFormId,
      allowAnswer: (state) => state.forms.allowAnswer,
      rightAnswers: (state) => state.forms.currentAnswers,
      currentEnroll: (state) => state.enroll.current,
      currentLearningObject: (state) => state.enroll.currentLearningObject,
       selectedEnroll: state => state.enroll.current,
      formResult: (state) => state.forms.result
      
    }),
    loading() {
      if(this.currentForm == null && this.allowAnswer == null)
        return true;
      else if(this.allowAnswer === false && this.formResult == null) {
        return true;
      }
      else return false;
    },
  },
  methods: {
    async load() {
      await this.clearAnswers();
      
      await this.$store.dispatch("forms/canAnswer", {
        formId: this.formId,
      });

      if(this.allowAnswer === false) {
        this.loadFormResults();
      }
    },
    async clearAnswers() {
      this.quizSubmitted = false;
      this.answers = [];
      this.quizResponse = null;
      await this.$store.dispatch("forms/clearAnswer");
    },
    isValidAnswer() {
      if (this.answers.length < this.currentForm.questions.length) {
        this.$store.commit("toast/NEW", {
          message: "Responda todas as perguntas",
        });
        return false;
      }

      return true;
    },
    async sendAnswer() {
      if (!this.isValidAnswer()) return;

      window.scrollTo(0, 0);

      this.quizSubmitted = true;
      await this.$store.dispatch("forms/createResponseInstance", {
        formId: this.formId,
      });

      var formAnswers = [];

      for (var i = 0; i < this.answers.length; i++) {
        const question = this.currentForm.questions[i];

        var answer = {
          FormId: this.formId,
          QuestionId: question.id,
          AnswerFormId: this.currentAnswerFormId,
          Answer: null,
          Alternatives: [{ Id: this.answers[i], Value: true }],
        };
        formAnswers.push(answer);
      }

      var operationResult = await this.$store.dispatch("forms/answerForm", { FormId: this.formId, Answers: formAnswers });

      if (!operationResult) {
         this.quizResponse = {
          Errors: true
        };
      } else {
        this.loadFormResults();
      }
    },

    async loadFormResults() {
      this.setNextContent();
      await this.$store.dispatch('forms/getFormResult', { FormId: this.formId });
      if(!this.formResult || this.formResult == '') {
        this.quizResponse = {
          hasResponse: false
        }
      }
      else if (this.formResult) {
        if(!this.currentForm) {
          await this.$store.dispatch('forms/setCurrentForm', this.formResult);
        }
        
        if(this.formResult.type == 'SurveyResearch') {
          this.showSurveyResults(this.formResult.distributionOfAnswers);
        }
        else {
          this.answers = this.formResult.answeredAlternatives;
          this.showQuizResults();
        }
        this.quizSubmitted = true;
      }
    },
    
    showSurveyResults(result) {
      this.quizResponse = {
        SurveyResearch: true,
        Results: result,
      };
    },

    showQuizResults() {
      var numberOfCorrectAnswers = 0;
      for (var i = 0; i < this.currentForm.questions.length; i++) {
        var questionCheck = this.formResult.questions.find((q) => { return q.id == this.currentForm.questions[i].id });
        const rightOne = questionCheck.alternatives.find((a) => { return a.correct === true });
        if (this.answers.indexOf(rightOne.id) > -1) {
          numberOfCorrectAnswers++;
        }
      }

      this.quizResponse = {
        hasResponse: true,
        rightsCount: numberOfCorrectAnswers,
        total: this.currentForm.questions.length,
        percentage: (
          (numberOfCorrectAnswers / this.currentForm.questions.length) *
          100
        ).toFixed(2),
      };
    },

    getSurveyPercentage(alternativeId) {
      const alt = this.quizResponse.Results.find((a) => {
        return a.alternativeId == alternativeId;
      });
      return alt.percentage;
    },

    setNextContent() {
      if (this.currentLearningObject.nextId) {
        this.$store.dispatch("enroll/setLastAccessNextContent", {
          enroll: this.currentEnroll,
          learningObjectNextId: this.currentLearningObject.nextId,
        });
      }
    },
  },
  filters: {
    ptLevel(questionLevel) {
      switch (questionLevel) {
        case "Basic":
          return "Básico";
      }
    },
    ptType(questionType) {
      switch (questionType) {
        case "SingleAnswer":
          return "Resposta única";
      }
    },
  },
};
</script>
<style scoped>
</style>