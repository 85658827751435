<template>
  <li class="trustflix-menu">
    <div class="trustflix-menu-photo">
      <img
        class="trustflix-user-logo"
        v-if="userLogoVisible"
        :src="$profilePic | imageHandler"
        alt
        @error="userLogoVisible = false"
        @load="onUserLogoLoad"
      />
      <template v-else>{{ $firstLetter }}</template>
    </div>

    <div class="user-name">{{ $currentUser.studentName }}</div>
    <a
      data-v-ea41b138=""
      @click="clickItem($emit('showModal', 'profile'))"
      class="waves-effect btn-menu-trustflix"
    >
      Minha Conta
    </a>

    <ul class="trustflix-menu-links">
      <li class="trustflix-menu-link">
        <router-link to="/p/trustflix" active-class="active" title="Início">
          <i class="fa fa-home" />
          Início
        </router-link>
      </li>
      <li v-if="!isLoading && !sameProducer" class="trustflix-menu-link">
        <a
          href="javascript:;"
          @click="clickItem(navTo('/p/my-courses'))"
          title="Meus Cursos"
        >
          <i class="fa fa-book" />
          Meus Cursos
        </a>
      </li>
      <li v-if="!isLoading && hasGroups" class="trustflix-menu-link">
        <a
          href="javascript:;"
          @click="clickItem($emit('showModal', 'mygroup'))"
          title="Meus Grupos"
        >
          <i class="material-icons">
            <IconMyGroup  />
          </i>
          Meus Grupos
        </a>
      </li>

      <li v-show="emiteCertificado.length" class="trustflix-menu-link">
        <a
          href="javascript:;"
          @click="clickItem($emit('showModal', 'mycertificate'))"
          title="Meus Grupos"
        >
          <i class="material-icons icon-certificate">
            <IconMyCertificate  />
          </i>
          Meus Certificados
        </a>
      </li>
    </ul>

    <footer class="trustflix-menu-footer">
      <hr />
      <small class="copyright"> © {{ (new Date()).getFullYear() }} {{ $appName }} </small>
    </footer>
  </li>
</template>

<script>
import { mapGetters, mapState } from 'vuex';
import IconMyGroup from "@/components/UI/IconMyGroup";
import IconMyCertificate from '@/components/UI/IconMyCertificate';



export default {
  name: 'TrustflixSideNav',
  components: {
    IconMyGroup,
    IconMyCertificate
  },
  computed: {
    ...mapState({
      enrolledCoursesIds : (state) => state.shopWindow.enrolledCoursesIds ,
      enrolls: (state) => state.enroll.enrolls,
      isLoading: (state) => state.shopWindow.loading

    }),
    ...mapGetters({
      hasGroups: 'shopWindow/hasGroups'
    }),
    sameProducer() {
      // gets unique shopWindow Id
      if (!this.enrolls) return
      const firstProducer = this.enrolls[0];
      if (
        firstProducer?.shopWindowId &&
        this.enrolls.every(
          (item) =>
            item.applicationId === firstProducer?.applicationId &&
            item.shopWindowId
        )
      ) {
        return firstProducer.shopWindowId;
      }

      return false;
    },
    emiteCertificado(){
      return this.enrolls.filter(item => item.settings.enableCertificate == true && this.enrolledCoursesIds?.includes(item.courseId));
    },
  },
  data() {
    return {
      userLogoVisible: true
    };
  },
  watch: {
    $profilePic() {
      this.userLogoVisible = true;
    }
  },
  methods: {
    clickItem() {
      this.$emit("clickInside")
    },
    navTo(path) {
      if (this.isPreview) return;

      if (path === '/p/my-courses') {
        this.$updateTheme({
          darkTheme: false,
          primary: '#0564b1',
          secondary: '#ffffff'
        });
      }

      this.$router.push(path);
    },
    onUserLogoLoad(ev) {
      ev.target.style.opacity = '1';
    }
  },
  filters: {
    imageHandler(url) {
      const isStored = url.startsWith('https://');

      return isStored ? url + '?h=120' : url;
    }
  }
};
</script>

<style scoped lang="scss">
@keyframes slideIn {
  from {
    opacity: 0;
    top: -20px;
  }
  to {
    opacity: 1;
    top: 0;
  }
}

.trustflix-menu {
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  transition: all 200ms ease-in-out;

  .trustflix-menu-photo {
    display: flex;
    justify-content: center;
    align-items: center;

    background-color: black;

    margin-top: 50px;

    font-size: 3rem;
    font-weight: 500;

    .trustflix-user-logo {
      object-fit: cover;
      opacity: 0;
      transition: opacity 300ms ease-in-out;
    }

    &,
    img {
      width: 120px;
      height: 120px;
      border: 3px solid var(--primary-color);
      border-radius: 50%;
    }
  }

  .user-name {
    font-size: 13pt;
    font-weight: 600;
    margin-top: 15px;
    text-align: center;
    line-height: normal;
  }

  .btn-menu-trustflix {
    /* background-image: linear-gradient(180deg, var(--primary-color) 1%, calc(var(--primary-color) + var(100%)) 92%); */
    background-image: linear-gradient(
      180deg,
      var(--primary-color) 1%,
      rgba(0, 0, 0, 0.1) 400%
    );
    width: fit-content;
    border-radius: 7px;
    height: 2.5rem;
    display: flex;
    align-items: center;
    justify-content: center;
    font-size: 15px;
    line-height: 1.38;
    letter-spacing: normal;
    color: var(--text-color);
    padding: 0 40px;
    margin-top: 40px;
    font-weight: 500;
    white-space: nowrap;
    margin-bottom: 34px;
  }
}

.trustflix-menu-links {
  width: 100%;
}

.trustflix-menu-link {
  position: relative;
  animation: slideIn 300ms forwards;

  a {
    white-space: nowrap;

    &,
    i,
    svg  {
      color: #ffffff;
      fill: white !important;
    }

    &.active {
      font-weight: 400;
    }

    &.active,
    &:hover {
      background: rgba(255, 255, 255, 0.04);
      &,
      i,
      svg {
        color: var(--primary-color);
        fill: var(--primary-color) !important;
      }
      &::before {
        height: 100%;
      }
    }

    &:hover {
      &::after {
        opacity: 0.4;
        transform: translateX(0px);
      }
    }

    i {
      font-size: 18px;
    }

    &,
    i,
    svg,
    &::before {
      transition: all 200ms ease-in-out;
      transition-delay: 100ms;
    }

    &::before {
      position: absolute;
      content: '';
      width: 4px;
      height: 0%;
      bottom: 0;
      left: 0;
      opacity: 0.7;
      background: var(--primary-color);
    }

    &::after {
      position: absolute;
      width: 30px;
      height: 30px;
      line-height: 30px;
      top: 50%;
      margin-top: -15px;
      content: '>';
      font-weight: 300;
      font-size: 12px;
      opacity: 0.5;
      left: auto;
      right: 0;
      text-align: center;
      opacity: 0;
      transform: translateX(10px);
      transition: all 500ms ease-in-out;
    }
  }
}

.trustflix-menu-footer {
  padding: 20px;
  text-align: left;
  font-size: 13px;
  color: #cbcbcb;
  background-color: transparent !important;
  margin-top: auto;
  width: 100%;

  & > div {
    display: flex;
    justify-content: space-between;
    align-items: center;
  }

  hr {
    background-color: rgba(203, 203, 203, 0.3);
    border: 0;
    height: 1px;
    width: 100%;
    padding: 0;
  }

  small {
    font-size: 13px;
    color: rgba(203, 203, 203, 0.3);
  }
}
.icon-certificate{
      padding-top: 6px;
}
</style>
