<template>
  <modal
    name="configuration"
    :classes="(hasCustomTheme()? ($theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light') : 'bg-color-modal-light')"
    height="100%"
    :width="calculatedWidth"
    :adaptive="true"
    :pivotX="1"
    :shiftX="1"
    :pivotY="0"
    :shiftY="0"
    :scrollable="true"
    transition="slide-left"
    overlayTransition
  >
    <main :class="(hasCustomTheme() ? ($theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light') : 'bg-color-modal-light')">
      <HeaderPage pageName="Configurações" modalName="configuration" />
      <div class="container">
        <div class="row">
          <div class="item-title" :style="(!hasCustomTheme() ? '#0564b1' : {color: $theme.primaryColor})">Reprodução de vídeo</div>
        </div>
        <div class="row valign-wrapper">
          <i class="material-icons no-padding left icon-color">play_circle_outline</i>
          <div class="item-description no-padding col s10 left"   :style=" (hasCustomTheme() ? ($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333') : ('color: #333333'))">Auto Play</div>
          <div class="switch no-padding right">
            <label>
              <input type="checkbox" :checked="autoPlay" @change="toggleAutoPlay" />
              <span class="lever" :style="({backgroundColor: $theme.primaryColor})"></span>
            </label>
          </div>
        </div>

        <div class="row valign-wrapper">
          <i class="material-icons no-padding left icon-color">queue_play_next</i>
          <div class="item-description no-padding col s10 left"   :style=" (hasCustomTheme() ? ($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333') : ('color: #333333'))">Próximo vídeo automático</div>
          <div class="switch no-padding right">
            <label>
              <input type="checkbox" :checked="autoNext" @change="toggleAutoNext" />
              <span class="lever" :style="({backgroundColor: $theme.primaryColor})"></span>
            </label>
          </div>
        </div>

        <div class="row valign-wrapper">
          <i class="material-icons no-padding left icon-color">airplay</i>
          <div class="item-description no-padding col s10 left"   :style=" (hasCustomTheme() ? ($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333') : ('color: #333333'))">Continuar de onde parou</div>
          <div class="switch no-padding right">
            <label>
              <input type="checkbox" :checked="startFromLastSavedState" @change="toggleContinue" />
              <span class="lever" :style="({backgroundColor: $theme.primaryColor})"></span>
            </label>
          </div>
        </div>
        <div class="row"></div>
        <div class="row">
          <div class="item-title" :style="(!hasCustomTheme() ? '#0564b1' : {color: $theme.primaryColor})">Geral</div>
        </div>
        <div class="row valign-wrapper">
          <i class="material-icons no-padding left icon-color">menu</i>
          <div class="item-description no-padding col s10 left"   :style=" (hasCustomTheme() ? ($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333') : ('color: #333333'))">Fixar menu no topo</div>
          <div class="switch no-padding right">
            <label>
              <input type="checkbox" :checked="fixedMenu" @change="toggleFixedMenu" />
              <span class="lever" :style="({backgroundColor: $theme.primaryColor})"></span>
            </label>
          </div>
        </div>
      </div>
    </main>
  </modal>
</template>

<script>
import HeaderPage from "@/layout/HeaderPage";
import { mapState } from "vuex";

export default {
  name: "Configuration",
  props:['layoutTheme'],
  components: {
    HeaderPage
  },
  computed: {
    ...mapState({
      autoPlay: state => state.config.autoplay,
      autoNext: state => state.config.autoNext,
      fixedMenu: state => state.config.fixedMenu,
      startFromLastSavedState: state => state.config.startFromLastSavedState,
      selectedEnroll: state => state.enroll.current
    }),
    calculatedWidth() {
      if (
        navigator.userAgent.match(/Android/i) ||
        navigator.userAgent.match(/webOS/i) ||
        navigator.userAgent.match(/iPhone/i) ||
        navigator.userAgent.match(/iPad/i) ||
        navigator.userAgent.match(/iPod/i) ||
        navigator.userAgent.match(/BlackBerry/i) ||
        navigator.userAgent.match(/Windows Phone/i)
      )
        return "100%";
      else return "20%";
    },
  },
  methods: {
    hasCustomTheme() {
      const isTrustflix = this.$route.name === "trustflix";
      return this.selectedEnroll || isTrustflix;
    },
    toggleAutoPlay() {
      this.$store.dispatch("config/autoplay", !this.autoPlay);
    },
    toggleAutoNext() {
      this.$store.dispatch("config/autoNext", !this.autoNext);
    },
    toggleFixedMenu(){
      this.$store.dispatch("config/fixedMenu", !this.fixedMenu);
    },
    toggleContinue() {
      this.$store.dispatch(
        "config/startFromLastSavedState",
        !this.startFromLastSavedState
      );
    }
  }
};
</script>
