<template>
  <span
    :class="{
      'trustflix-item-labels': true,
      'trustflix-item-labels--small': small
    }"
  >
    <div class="left-labels" v-if="item.enrollId && isAvailable">
      <span v-if="showProgress">
        {{ item.progress | formatProgress }}
      </span>
      <span v-if="item.progress === 100">
        <i class="material-icons primary-colored">check</i>
      </span>
    </div>

    <div class="right-labels">
      <span v-if="isExternal">
        <i class="fa fa-external-link" title="Conteúdo externo" />
      </span>
      <span v-if="isBuyable && hasBuySection">
        <i class="fa fa-shopping-cart" title="Conteúdo bloqueado" />
      </span>
      <span v-else-if="isLocked || isBuyable">
        <i class="fa fa-lock" title="Conteúdo bloqueado" />
        <span v-if="item.comingSoon" class="label-comingsoon">EM BREVE</span>
      </span>
      <span v-else-if="item.unlocked"
        ><i class="fa fa-unlock" title="Conteúdo desbloqueado"
      /></span>

      <span
        v-for="(label, i) in [...getExtraLabels(item), ...extraItemLabels]"
        :key="`${item.id}-${label}-${i}`"
      >
        {{ label }}
      </span>
    </div>
  </span>
</template>

<script>
import { formatDate } from '@/filters/formatDate';
import { mapGetters } from 'vuex';

export default {
  name: 'ItemLabels',
  props: {
    item: Object,
    showProgress: {
      type: Boolean,
      default: true
    },
    isBuyable: Boolean,
    small: Boolean,
    getExtraLabels: {
      type: Function,
      default: () => []
    }
  },
  computed: {
    ...mapGetters('shopWindow', ['hasBuySection']),
    isAvailable() {
      const { item } = this;
      return !item.comingSoon && !this.isLocked && !this.isBuyable;
    },
    isExternal() {
      return this.item.type === 'External';
    },
    isNone() {
      const { item } = this;
      return item.type === 'None' || (this.isExternal && !item.link);
    },
    isCourse() {
      return this.item.type === 'Course';
    },
    isLocked() {
      const { item } = this;
      return Boolean(
        item.comingSoon ||
          item.state === 'Unpublished' ||
          (item.releaseDate && this.releasedInFuture) ||
          this.isNone ||
          item.pendingPayment
      );
    },
    isFutureScheduled() {
      const { item } = this;
      return (
        item.state === 'Scheduled' &&
        (item.daysOfReleased ||
          (item.releaseDate && this.dateIsFuture(item.releaseDate)))
      );
    },
    releasedInFuture() {
      const { releaseDate } = this.item;

      return this.dateIsFuture(releaseDate);
    },
    extraItemLabels() {
      const labels = [];
      const { releaseDate, startDate } = this.item;
      const availableDay = releaseDate || startDate;

      if (this.isFutureScheduled && availableDay)
        labels.push(this.getScheduledRelease(availableDay));

      return labels.filter(Boolean);
    }
  },
  methods: {
    getTimeDiff(date) {
      return Number(new Date(date)) - Number(new Date());
    },
    getDaysToRelease(days) {
      return days ? `DISPONÍVEL EM ${days} DIAS` : '';
    },
    dateIsFuture(date) {
      if (!date) return false;
      return this.getTimeDiff(date) > 0;
    },
    getScheduledRelease(date) {
      if (!date) return '';

      const dateObj = new Date(date);
      const todayObj = new Date();

      const formattedToday = formatDate(todayObj);

      const formattedDate = formatDate(dateObj);

      if (formattedDate === formattedToday && !this.dateIsPast(date)) {
        return `LIBERA ÀS ${dateObj.toLocaleTimeString([], {
          hour: '2-digit',
          minute: '2-digit',
          hour12: false
        })}`;
      } else {
        if (this.dateIsFuture(date)) {
          const sameYear = dateObj.getFullYear() === todayObj.getFullYear();
          return `DISPONÍVEL EM ${formattedDate.slice(
            0,
            sameYear ? 5 : undefined
          )}`;
        }
      }
    }
  },
  filters: {
    formatProgress(progress) {
      return `${progress.toLocaleString('pt-BR', {
        maximumFractionDigits: 2
      })} %`;
    }
  }
};
</script>

<style lang="scss" scoped>
.trustflix-item-labels {
  position: absolute;
  top: 12px;
  right: 12px;
  left: 12px;

  color: white;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;

  &,
  & i {
    font-size: 11px;
  }

  &.trustflix-item-labels--small {
    &,
    & i {
      font-size: 10px;
    }
  }

  & div {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    gap: 4px;

    &.right-labels {
      justify-content: flex-end;
      margin-left: auto;
    }

    &.left-labels {
      justify-content: flex-start;
      white-space: nowrap;
    }
  }

  & .right-labels span,
  & .left-labels span {
    height: auto;
    min-height: 16px;
    background: #383838;
    padding: 2px 8px;
    border-radius: 3px;

    margin: 0 2px;

    display: inherit;
    align-items: center;
  }
}

i.primary-colored {
  color: var(--primary-color);
  font-weight: bolder;
}
</style>
