import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class EnrollService extends BaseService {
  static get entity () {
    return 'enroll'
  }

  // busca a matriculas
  static async getUserEnroll () {

    try {
      
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/Marketplace/enrolls`, { headers: { 'Content-Range': 'items=0-300' } });

      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);

    } catch (error) {

      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }

  // busca a conteudo das matriculas
  static async getModulesClasses (enrollId) {

    try {
      
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/enrolls/${enrollId}/full-grid`);
      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);

    } catch (error) {
      let message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      if ((error.response || {}).status == 402) {
        message = error.response.headers["x-paymentrequired-message"];
        if (!message)
          message = 'Acesso restrito';
      }
      throw new ErrorWrapper(error, message)
    }
  }

  static async setLastAccess (enrollId, courseId, moduleId, disciplineId, classId, learningObjectId) {
    try {
      
      const response = await this.request({ auth: true }).put(
        `${process.env.VUE_APP_EAD_API_URL}/enrolls/${enrollId}/last-access`,
        {
          courseId: courseId,
          moduleId: moduleId,
          disciplineId: disciplineId,
          classId: classId,
          learningObjectId: learningObjectId
        }
      );

      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);
    } catch(error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }

  static async getLastAccess (enrollId) {
    try {
      
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/enrolls/${enrollId}/last-access`);

      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);
    } catch(error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }

  static async setProgress(enrollId, courseId, moduleId, disciplineId, classId, learningObjectId, broadcastId, progress) {
    try {
      
      const response = await this.request({ auth: true }).put(
        `${process.env.VUE_APP_EAD_API_URL}/enrolls/${enrollId}/progress`,
        {
          hierarchy: {
            courseId: courseId,
            moduleId: moduleId,
            disciplineId: disciplineId,
            classId: classId,
            learningObjectId: learningObjectId,
            broadcastId: broadcastId
          },
          complete: progress == 100
        }
      );

      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);
    } catch(error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }

  static async refreshProgress(enrollId, courseId, moduleId, disciplineId, classId, learningObjectId, broadcastId) {
    try {
      
      const response = await this.request({ auth: true }).post(
        `${process.env.VUE_APP_EAD_API_URL}/enrolls/${enrollId}/refresh-progress`,
        {
          hierarchy: {
            courseId: courseId,
            moduleId: moduleId,
            disciplineId: disciplineId,
            classId: classId,
            learningObjectId: learningObjectId
          },
          TransmissionId: broadcastId
        }
      );

      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);
    } catch(error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }

  static async getCourseProgress(enrollId) {
    try {
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/enrolls/${enrollId}/progress`);
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);
    } catch(error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }
}
