<template>
  <div
    v-show="visible"
    :class="{ 'video-nav-prev': true, 'video-nav-prev-centered': centered }"
  >
    <a
      class="btn-nav-video"
      @click="onClick"
      :style="($theme.darkTheme ? '' : 'color: #717171; background: #e8e8e8')"
      
    >
      <IconPrevious />
      Anterior
    </a>
    <div
      id="video-nav-details-prev"
      v-show="hasPreview"
      @click="onClick"
      :style="visible ? 'cursor:pointer;' : 'cursor:default'"
    >
      <img class="nav-details-img" :src="previewImage + '?h=70'" alt />
      <div class="nav-details-name truncate">
        <!-- <small> Conteúdo anterior </small> -->
        {{ previewTitle }}
      </div>
    </div>
  </div>
</template>

<script>
import IconPrevious from "@/components/UI/IconPrevious";
export default {
  name: 'NavPrevBt',
  props: {
    visible: Boolean,
    onClick: Function,
    centered: Boolean,
    hasPreview: Boolean,
    previewImage: String,
    previewTitle: String
  },
  components: {
    IconPrevious    
  }
};
</script>

<style scoped>
  .video-nav-prev-centered {
    top: 50%;
    transform: translateY(-50%);
    left: -14%;
  }
</style>
