import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class CommentService extends BaseService {
  static get entity () {
    return 'notification'
  }

  static async like (commentId, like, learningObjectId, enrollId) {
    try {
        
        const response = await this.request({ auth: true }).post(`${process.env.VUE_APP_EAD_API_URL}/comments/${commentId}/like`, { 
          like: like, 
          learningObjectId: learningObjectId, 
          enrollId : enrollId 
        });
        
        return new ResponseWrapper({status:200, data:{success:true}}, response.data);
  
      } catch (error) {
        const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
        throw new ErrorWrapper(error, message)
      }
  }
  
  static async comment (comment, courseId, parentId, learningObjectId, enrollId) {
    try {
      
        const response = await this.request({ auth: true }).post(`${process.env.VUE_APP_EAD_API_URL}/comments`, {
            parentId: parentId,
            courseId: courseId,
            learningObjectId:learningObjectId,
            comment:comment,
            enrollId:enrollId
        });
        
        return new ResponseWrapper({status:200, data:{success:true}});
  
      } catch (error) {
        const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
        throw new ErrorWrapper(error, message)
      }
  }

  static async getComments (courseId, learningObjectId) {

    try {
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/courses/${courseId}/learning-object/${learningObjectId}/comments`);
      return new ResponseWrapper({status:200, data:{success:true}}, response.data != "" ? response.data : []);

    } catch (error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }
}
