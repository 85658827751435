import { AuthService } from '@/services/auth.service';

/**
 * toast notification module
 */
export default {
  namespaced: true,
  state: {
    toastsList: []
  },

  // actions: {
  //   NEW({commit}, toast) {
  //     commit('NEW', toast);
  //   }
  // },

  mutations: {
    NEW (state, payload) {
      if (payload == null)
        return;

      const toast = {
        id: payload.id || new Date().getTime(),
        message: typeof payload === 'string' ? payload : payload.message,
        type: payload.type || 'default',
        duration: payload.duration || 5000
      }
      if (payload.type === 'error' && payload.page != "login") {

        // se não tem refresh token assume q é uma requisição invalida
        if(!AuthService.hasRefreshToken()) return;

        // se não tiver internet
        if(!navigator.onLine) return;

        //toast.duration = toast.duration * 2
        console.error(payload.message)
      }
      state.toastsList.push({ ...toast })
    },
    REMOVE (state, payload) {
      state.toastsList = state.toastsList.filter(({ id }) => id !== payload.id)
    },
    CLEAR_LIST (state) {
      state.toastsList = []
    }
  }
}
