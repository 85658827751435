import { UsersService } from '@/services/users.service';
import Vue from 'vue';

export default {
  namespaced: true,
  state: {
    currentUser: {
      // profile
      taxId:'',
      birthDate: (new Date()),
      phones: [],
      studentPhoto: '',
      // jwt
      studentId: "",
      name: "",
      email: "",
      iat: 0,
      nbf: 0,
      exp: 0,
      layoutTheme: 'dark'
    }
  },
  getters: {
    currentProfile: state => {

      return Object.assign({}, state.currentUser);
    },
    currentProfileFirstLetter: state => {
      return state.name ? state.name.substr(0,1).toUpperCase() : '';
    }
  },
  actions: {
    async updateProfilePic({ commit }){

      var imageName = this.state.user.currentUser.email;
      imageName = imageName.split("@").join("_").split(".").join("_") + ".jpg"; // filename
      imageName = 'marketplace/users/' + imageName;

      const fullImageName = process.env.VUE_APP_CDN_URL + imageName;
      var response = fetch(fullImageName).then(response =>{
        var profilePic = response.ok ? fullImageName : "";
        commit('SET_CURRENT_USER_PIC', profilePic);
      }).catch(error=>{
        commit('SET_CURRENT_USER_PIC', "");
      });
    },

    getCurrent ({ commit }) {
      return UsersService.getCurrent()
        .then(user => commit('SET_CURRENT_USER', user.data))
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },

    updateProfile({commit}, userObj) {
      return UsersService.updateProfile(userObj.name, userObj.email, userObj.taxId, userObj.birthDate, userObj.phones)
        .then(responseData => {
          this.dispatch('user/getCurrent')
            .then(reloaded => { })
            .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
          return responseData;
        })
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },

    changePassword({commit}, changePassword){
      return UsersService.changePassword(changePassword.email, changePassword.current, changePassword.new)
        .then((response) => {
          return (response != null && response.status == 200);
        })
        .catch(error => {
          if(error.status == 304)
            commit('toast/NEW', { type: 'error', message: 'A senha atual não confere' }, { root: true });
          else
            commit('toast/NEW', { type: 'error', message: error.message }, { root: true });
        });
    },

    updateTelegramId({commit}, telegramId) {
      return UsersService.updateTelegramId(telegramId)
        .then((response) => {
          return (response != null && response.status == 200);
        })
        .catch(error => {
          commit('toast/NEW', { type: 'error', message: error.message }, { root: true });
        });
    },

    setCurrentTheme({commit}, selectedTheme) {
      commit('SET_CURRENT_THEME', selectedTheme);
    }
  },
  mutations: {
    SET_CURRENT_USER (state, currentUserData) {
      Vue.set(state, 'currentUser', currentUserData);
    },
    SET_ENROLL(state, enroll){
      state.selectedEroll = enroll;
    },
    SET_LEARNING_OBJECT (state, data) {
      state.selectedLearningObject = data
    },
    SET_CURRENT_USER_PIC(state, data){
      state.currentUser.studentPhoto = data;
    },
    SET_CURRENT_THEME(state, data) {
      Vue.set(state.currentUser, 'layoutTheme', data);
    }
  }
}
