<template>
  <div
    class="trustflix-buy-section box-slider-courses"
    ref="container"
    v-if="section && section.content"
  >
    <div class="box-content">
      <header class="box-content-header">
        <div class="header-textual">
          <h4 class="content-title">
            <slot name="icon" />
            {{ section.title }}
          </h4>
          <span v-if="section.subtitle" class="content-tag">{{
            section.subtitle
          }}</span>
        </div>

        <div class="header-carousel-bts">
          <CarouselNavPrevBT :class="`swipe-button-prev-vert-${_uid}`" />
          <CarouselNavNextBT :class="`swipe-button-next-vert-${_uid}`" />
        </div>
      </header>

      <div class="box-trustflix-itens">
        <swiper
          ref="buySectionSwiper"
          :options="swiperOption"
          class="swiper-container"
        >
          <swiper-slide
            v-for="(item, index) in sortedSection"
            :key="'progressItem_' + item.Id + '_' + index"
          >
            <div class="trustflix-item" href="javascript:;">
              <div class="trustflix-item-visual">
                <div class="trustflix-item-img-container">
                  <div class="item-img">
                    <img
                      v-lazy="loadImage(item)"
                      :alt="`Curso ${item.name}`"
                    />
                  </div>
                </div>
                <div class="trustflix-item-badges trustflix-item-badges-img">
                  <span
                    v-for="badge in getBadges(item)"
                    :key="`${item.id}-${badge.label}`"
                  >
                    <i :class="badge.icon" />
                    {{ badge.label }}
                  </span>
                </div>
              </div>

              <div class="trustflix-item-data">
                <div class="trustflix-item-txt">
                  <h5 class="trustflix-item-name">{{ item.name }}</h5>
                  <!-- <p class="trustflix-item-sub" v-if="item.sub">
                    {{ item.sub }}
                  </p> -->

                  <p class="trustflix-item-desc" v-if="item.description">
                    {{ item.description }}
                  </p>

                  <div class="trustflix-item-badges trustflix-item-badges-txt">
                    <span
                      v-for="badge in getBadges(item)"
                      :key="`${item.id}-${badge.label}`"
                    >
                      <i :class="badge.icon" />
                      {{ badge.label }}
                    </span>
                  </div>
                </div>
                <a
                  class="waves-effect trustflix-item-prim-action"
                  v-if="item.labelBuyButton"
                  href="javascript:;"
                  @click="redirectBuyPage(item)"
                  >{{ item.labelBuyButton }}</a
                >

                <a
                  class="waves-effect trustflix-item-see-more"
                  v-if="item.linkButtonSeeMore"
                  href="javascript:;"
                  @click="redirectSeeMorePage(item)"
                  >Ver mais</a
                >
              </div>
            </div>
          </swiper-slide>

          <div class="swiper-pagination-vert" slot="pagination"></div>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from '@voxel/vue-awesome-swiper';
import { formatDate } from '@/filters/formatDate';
import { mapState } from 'vuex';
import '@voxel/swiper/css/swiper.css';
import CarouselNavPrevBT from '@/components/UI/CarouselNavPrevBT.vue';
import CarouselNavNextBT from '@/components/UI/CarouselNavNextBT.vue';

export default {
  name: 'BuySection',
  props: ['showCategory', 'metadata', 'hideProgress', 'title', 'sub'],
  components: { Swiper, SwiperSlide, CarouselNavPrevBT, CarouselNavNextBT },
  computed: {
    ...mapState({
      section: (state) => state.shopWindow.buySection,
      sidenavIsOpen: (state) => state.config.sidenavIsOpen,
      currentUser: (state) => state.user.currentUser
    }),
    sortedSection() {
      const content = this.section.content.filter(co => co.enabled);
      const sorted = _.sortBy(content, 'sequence');

      return sorted
    },
    userDataQuery() {
      const { name, email, /*taxId, phones*/ } = this.currentUser;
      // const [phone] = phones;
      // const isInternational = phone?.ddi && phone.ddi != 55;

      const userData = {
        name,
        email,
        // origin: isInternational ? 'international' : 'national',
        // cpf: taxId
      };

      // if (phone) {
      //   userData.ddi = phone.ddi;
      //   userData.ddd = phone.area;
      //   userData.phone = phone.number;
      // }

      return '?' + new URLSearchParams(userData).toString();
    }
  },
  data() {
    return {
      resizeDebounce: 0,
      swiperOption: {
        slidesPerView: 'auto',
        spaceBetween: 10,
        observer: true,
        observeParents: true,
        navigation: {
          nextEl: `.swipe-button-next-vert-${this._uid}`,
          prevEl: `.swipe-button-prev-vert-${this._uid}`
        },
        pagination: {
          el: '.swiper-pagination-vert',
          dynamicBullets: true,
          clickable: true
        },
        // nextButton: ".swiper-button-next-vert",
        // prevButton: ".swiper-button-prev-vert",
        //touchEventsTarget: 'container'
        showAll: true,
        breakpoints: {
          501: {
            slidesPerView: 'auto',
            spaceBetween: 25
          },
          0: {
            slidesPerView: 1.1,
            spaceBetween: 25
          }
        }
      }
    };
  },
  mounted() {
    if (this.$refs.container) this.ro = new ResizeObserver(this.onResize).observe(this.$refs.container);
    // const swiperContainerWidth = this.$refs[
    //   'swiper-class-id-' + this.metadata?.classId
    // ]?.$el.clientWidth;
    // if (swiperContainerWidth < this.items.length * 260) {
    //   this.showAll = true;
    // } else {
    // }
  },
  methods: {
    swipeTo(courseId) {
      const courseIndex = this.sortedSection.findIndex(offer => offer.courseId === courseId);
      this.$refs.buySectionSwiper.$swiper.slideTo(courseIndex >= 0 ? courseIndex : 0);
    },
    getBadges(item) {
      const badges = [
        { label: 'Conteúdo Premium', icon: 'fa fa-play' },
        { label: 'Oferta Especial', icon: 'fa fa-star' },
        { label: 'Não Expira', icon: 'fa fa-clock-o' },
        {
          label: 'Material Complementar',
          icon: 'fa fa-folder-open'
        }
      ];

      if (!item.flags) return [badges[0], badges[1]];

      const flags = item.flags?.split('-');
      return flags.map((flag) => badges[Number(flag) - 1]);
    },
    onResize() {
      clearTimeout(this.resizeDebounce);
      this.resizeDebounce = setTimeout(() => {
        this.$refs.buySectionSwiper?.$swiper.update();
      }, 200);
    },
    updateCarousel() {},
    prevItem() {
      if (this.$refs.buySectionSwiper)
        this.$refs.buySectionSwiper.$swiper.slidePrev();
    },
    nextItem() {
      if (this.$refs.buySectionSwiper)
        this.$refs.buySectionSwiper.$swiper.slideNext();
    },
    // loadImage(item) {
    //   var img = new Image();
    //   img.src = item.horizontalImage;
    //   img.onload = function () {
    //     return img.src;
    //   };

    //   img.onerror = function () {
    //     return require('@/assets/images/default-course-image.png');
    //   };
    // },

    loadImage(item) {
      const fallbackImage = require('@/assets/images/posters/evf-generic-md.png');
      return  {
        src: item.horizontalImage || fallbackImage,
        error: item.myCheckoutImage || fallbackImage,
        loading: item.myCheckoutImage || fallbackImage,
      };
    },
    redirectBuyPage(item) {
      if (item.link) {
        const target = item.link + this.userDataQuery
        return window.open(target);
      }
    },
    redirectSeeMorePage(item) {
      const hasProtocol = /^https?:\/\//i.test(item.linkButtonSeeMore);
      return window.open((!hasProtocol ? 'https://' : '') +  item.linkButtonSeeMore);
    },

    itemImage(item) {
      if (item.broadcast) {
        return item.image + '?h=146';
      }
      return item.image;
    },
    click(item) {
      const eventData = {
        ...item,
        ...this.metadata
      };
      if (item.state == 'Published') this.$emit('click', eventData);
    },
    formatScheduleInfo(obj) {
      if (obj.state == 'Scheduled' && obj.releasedDate)
        return 'Liberado em ' + formatDate(new Date(obj.releasedDate));
      return obj.title;
    }
  },
  filters: {
    formatDuration: function (value) {
      if (value.broadcast !== null) {
        const duration = value.broadcast.duration;
        var hours = Math.floor(duration / 3600);
        var minutes = Math.floor((duration - hours * 3600) / 60);
        var seconds = duration - hours * 3600 - minutes * 60;

        if (hours < 10) {
          hours = '0' + hours;
        }
        if (minutes < 10) {
          minutes = '0' + minutes;
        }
        if (seconds < 10) {
          seconds = '0' + seconds;
        }

        if (hours != '00') {
          return hours + ':' + minutes + ':' + seconds;
        } else {
          return minutes + ':' + seconds;
        }
      } else return '';
    },
    formatDate: formatDate
  }
};
</script>

<style lang="scss" scoped>
$img-width: 370px;
$img-height: 208px;
/* 16x9 aspect ratio */
$aspect-ratio: 56.25%;

/* .item-name {
  color: #999 !important;
} */

.box-slider-courses {
  background: #141414;
  padding: 14px 0;
  padding-bottom: 10px;
  margin: 0 20px;
  border-radius: 8px;
}

/* trustflix */

.box-content {
  padding: 0 15px;
  position: relative;
}

.box-content-header {
  &,
  .header-textual {
    display: flex;
    align-items: center;
    flex-wrap: wrap;
  }
  .header-textual {
    width: 70%;
  }
  justify-content: space-between;

  i:first-child {
    margin-right: 10px;
  }

  .header-carousel-bts {
    display: flex;
  }
}

.content-title {
  margin: 0;
  font-weight: 600;
  font-size: 18px;
  display: flex;
  align-items: center;
}

.content-tag {
  margin-left: 12px;
  padding: 2px 4px;
  border: 2px solid var(--primary-color);
  border-radius: 5px;
  color: var(--primary-color);
  font-size: 11px;
  font-weight: 700;
}

.box-trustflix-itens {
  width: 100%;
  overflow: hidden;
  margin: 10px 0;
  display: flex;
  flex-direction: row;
  align-items: center;

  .trustflix-item {
    display: flex;
    flex-direction: column;
    padding: 20px;
    /* margin-right: 10px; */
    border-radius: 20px;
    background: #101010;
    overflow: hidden;
    transition: all 200ms ease-in-out;

    .trustflix-item-visual {
      display: flex;
      flex-direction: column;
    }

    img {
      width: 100%;
      border-radius: 12px;
    }

    .trustflix-item-name {
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }

    .trustflix-item-txt {
      min-height: $img-height;
    }

    .trustflix-item-badges {
      flex-wrap: wrap;

      font-size: 13px;
      color: #b7b7b7;
      margin-top: 12px;

      &.trustflix-item-badges-txt {
        display: none;
      }
      &.trustflix-item-badges-img {
        display: flex;
      }

      span {
        min-width: 50%;
        white-space: nowrap;
        margin: 4px 0;
        i {
          margin-right: 4px;
          color: var(--primary-color);
        }
      }
    }

    p {
      color: #d3d3d3;
      font-weight: 300;
      font-size: 14px;
      line-height: 140%;

      text-overflow: ellipsis;

      &.trustflix-item-sub {
        margin-bottom: 0;
      }

      &.trustflix-item-desc {
        display: -webkit-box;
        -webkit-line-clamp: 6;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }
    }

    .trustflix-item-see-more,
    .trustflix-item-prim-action {
      width: -webkit-fill-available;
      color: #ffffff;
      font-size: 14px;
      font-weight: bold;
      text-shadow: 0px 1px 5px rgb(100, 100, 100);
      border-radius: 5px;
      border-style: solid;
      padding: 8px 20px;
      margin: 4px 0;
      border: none;
      text-align: center;

      &:hover {
        filter: brightness(1.1);
      }
    }

    .trustflix-item-prim-action {
      background: #4dc30f;
    }

    .trustflix-item-see-more {
      background: #1f1f1f;
    }
  }
}

.swiper-slide {
  width: fit-content;
  /* max-width: 100%; */
}

.box-trustflix-itens {
  height: 100%;
  display: flex;
  align-items: center;

  .trustflix-item-img-container {
    position: relative;

    width: 370px;
    max-width: 100%;

    background-color: #1f1f1f;
    padding-top: $aspect-ratio;

    &,
    img {
      border-radius: 5px;
    }

    img {
      position: absolute;
      top: 0;
      width: 100%;
      height: 100%;

      object-fit: cover;
    }
  }
}

.swiper-container {
  /* padding-bottom: 40px; */
  width: 100%;
}

.swiper-pagination-vert {
  margin: 16px auto 0;
  width: fit-content;
  transform: unset !important;
}

@media screen and (max-width: 949px) {
  .box-trustflix-itens .trustflix-item {
    max-width: $img-width;

    .trustflix-item-badges {
      &.trustflix-item-badges-txt {
        display: flex;
      }
      &.trustflix-item-badges-img {
        display: none;
      }
    }
  }
}

@media screen and (min-width: 950px) {
  .box-trustflix-itens .trustflix-item {
    flex-direction: row;

    .trustflix-item-data {
      width: 100%;
      padding-left: 20px;
      overflow: auto;

      display: flex;
      justify-content: space-between;
      flex-direction: column;
    }
    .trustflix-item-name {
      margin-top: 0;
    }

    width: 810px !important;
  }

  .swiper-slide {
  }
}

@media screen and (max-width: 500px) {
  .box-slider-courses {
    margin: 0 10px;
  }

  .box-trustflix-itens .trustflix-item {
    margin: 0;

    .trustflix-item-visual {
      max-width: 100%;
      width: 370px;
    }
  }

  header {
    flex-wrap: wrap;
    justify-content: center;

    span {
      margin: 10px 0 0 !important;
      width: 100%;
      text-align: center;
    }
  }
}
</style>
