var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.section)?_c('div',{ref:"container",staticClass:"box-slider-courses"},[_c('div',{staticClass:"box-content"},[_c('header',{staticClass:"box-content-header"},[_c('div',{staticClass:"header-textual"},[_c('h4',{staticClass:"content-title"},[_vm._v(_vm._s(_vm.section.title))]),_c('p',{staticClass:"content-sub"},[_vm._v(_vm._s(_vm.section.subtitle))])]),_c('div',{staticClass:"header-carousel-bts"},[_c('CarouselNavPrevBT',{class:("swipe-button-prev-vert-" + _vm._uid)}),_c('CarouselNavNextBT',{class:("swipe-button-next-vert-" + _vm._uid)})],1)]),_c('div',{staticClass:"box-trustflix-itens"},[_c('swiper',{ref:"verticalSwiper",staticClass:"swiper-container",attrs:{"options":_vm.swiperOption}},[_vm._l((_vm.sortedContent),function(item,index){return _c('swiper-slide',{key:'dynamicSection_' + _vm.section.id + '_' + item.id + '_' + index,attrs:{"title":item.name +
              (!item.classLearningObjectDeleted
                ? ''
                : ' - Conteúdo não encontrado')}},[_c('a',{class:{
              'trustflix-item': true,
              'trustflix-item--unavailable': !_vm.getIsAvailable(item),
              'trustflix-item--buyable': _vm.showBuyOverlay(item) && _vm.hasBuySection,
              'trustflix-item--removed': item.classLearningObjectDeleted
            },attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.redirectCoursePage(item)}}},[_c('div',{staticClass:"item-img"},[(!item.autoGenerate)?_c('img',{directives:[{name:"lazy",rawName:"v-lazy",value:(_vm.loadImage(item)),expression:"loadImage(item)"}],attrs:{"alt":item.name}}):_c('GeneratedItem',{attrs:{"module":item.className,"title":item.name,"image":_vm.loadImage(item, 200)}}),(!item.classLearningObjectDeleted)?_c('ItemLabels',{attrs:{"item":item,"isBuyable":_vm.showBuyOverlay(item)}}):_vm._e()],1),(_vm.showBuyOverlay(item) && _vm.hasBuySection)?_c('div',{staticClass:"trustflix-item-buy-overlay",on:{"click":function($event){return _vm.redirectCoursePage(item, true)}}},[_c('i',{staticClass:"material-icons"},[_vm._v("shopping_cart")]),_c('strong',[_vm._v(_vm._s(item.courseName))]),_vm._v(" Ir para a "+_vm._s(_vm.buySectionTitle)+" ")]):_vm._e()])])}),_c('div',{staticClass:"swiper-pagination-vert",attrs:{"slot":"pagination"},slot:"pagination"})],2)],1)])]):_vm._e()}
var staticRenderFns = []

export { render, staticRenderFns }