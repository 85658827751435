import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import { AuthService } from '@/services/auth.service'
import Vue from 'vue'

export class UsersService extends BaseService {
  static get entity () {
    return 'users'
  }

  // vai pegar os dados do usuário
  static async getCurrent () {
    try {
      
      // pega parte dos dados do jwt
      var udata = AuthService.getUserData();

      // vai buscar no profile o restante
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/students`);

      udata = Vue._.defaults(response.data, udata)
      
      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, udata);

    } catch (error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }

  // atualiza perfil de usuário
  static async updateProfile(name, email, cpf, birthDate, phones) {

    try {

      const response = await this.request({ auth: true }).put(`${process.env.VUE_APP_EAD_API_URL}/students`,
      {
        "name": name,
        "email": email,
        "nature": null,
        "identification": null,
        "taxId": cpf,
        "password": null,
        "gender": null,
        "birthDate": birthDate, // "2020-06-04T18:38:50.734Z",
        "phones": phones
      });
      
      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);

    } catch (error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }

  static async changePassword(email, passowrd, newPassword) {
    try{
      const response = await this.request({ auth: true }).put(`${process.env.VUE_APP_EAD_API_URL}/students/change-password`,
      {
        "email": email,
        "password": passowrd,
        "newPassword": newPassword
      });

      if (response.status == 304) {
        throw {
          response: response
        };
      }

      return new ResponseWrapper({status:200, data:{success:true}}, response.data);
    }
    catch (error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      var ew =  new ErrorWrapper(error, message);
      ew.status = error.response.status;
      throw ew;
    }
  }

  static async updateTelegramId(telegramId) {
    try {
      const response = await this.request({ auth: true }).put(`${process.env.VUE_APP_EAD_API_URL}/students/telegram`, {
        "id": telegramId
      });

      return new ResponseWrapper({status:200, data:{success:true}}, response.data);
    } catch(error) {
      const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }
}
