<template>
    <iframe :src="'../../../../pdf/index.html?file=' + src"></iframe>
</template>

<script>
export default {
    props: ['src']
}
</script>

<style scoped>
iframe {
    width: 100%;
    height: 60vh;
    border: 0;
}
</style>
