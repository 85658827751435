<template>
    <div class="container">
        <div class="player">
            <coremedia-player v-if="!loading" :mute="devMute" :broadcastId="broadcastid" studentEmail="claudio.rondini@voxeldigital.com.br"></coremedia-player>
        </div>
        <h1>Conteúdos</h1>
        <ul>
            <li><a href="javascript:;" @click="changeBroadcast(176293)">horizontal</a></li>
            <li><a href="javascript:;" @click="changeBroadcast(737785)">vertical</a></li>
        </ul>
    </div>
</template>

<script>
import CoremediaPlayer from '@/components/Player.vue';

export default {
    data() {
        return {
            devMute: process.env.NODE_ENV == 'development' ? 'true' : '',
            loading: false,
            broadcastid: 176293
        };
    },
    components: {
        CoremediaPlayer
    },
    methods: {
        changeBroadcast(id) {
            if (this.broadcastid == id)
                return;

            this.loading = true;
            this.broadcastid = id;
            this.$nextTick(() => this.loading = false);
        }
    }
}
</script>