import { render, staticRenderFns } from "./MyCourses.vue?vue&type=template&id=03a346c3&scoped=true"
import script from "./MyCourses.vue?vue&type=script&lang=js"
export * from "./MyCourses.vue?vue&type=script&lang=js"
import style0 from "./MyCourses.vue?vue&type=style&index=0&id=03a346c3&prod&scoped=true&lang=scss"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "03a346c3",
  null
  
)

export default component.exports