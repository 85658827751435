<template>
  <div v-if="items !== null">
    <div
      class="box-next-content"
      :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'"
    >
      <div class="nextContentTitle" v-if="metadata.title" :style="({color: $theme.primaryColor})" >
        <div class="nextContentTitleLeft">
        <IconPlayScreen />
        <div
          class="line-clamp-2 "
          :style="({color: $theme.primaryColor})"
        >
          <img class="icon-carousel"
            v-if="metadata.isPreview && metadata.state == 'Published'"
            src="@/assets/icons/check-circle-copy.svg"
          />
          <img class="icon-carousel"
            v-if="metadata.isPreview && metadata.state == 'Draft'"
            src="@/assets/icons/edit.svg"
          />
          <img class="icon-carousel"
            v-if="metadata.state == 'Unpublished'"
            src="@/assets/icons/lock.svg"
          />
          <img class="icon-carousel"
            v-if="metadata.state == 'Scheduled' && metadata.releasedDate"
            src="@/assets/icons/lock.svg"
          />
          <span
            style="font-size: 12px; font-style: italic"
            v-if="metadata.state == 'Scheduled' && metadata.releasedDate"
            >(liberado em
            {{ new Date(metadata.releasedDate) | formatDate }})</span
          >
           {{ metadata.title }}
        </div>
        <div
          class="see-all"
          @click="openLeftMenu()"
          v-show="showAll"
          :style="({color: $theme.primaryColor, borderColor: $theme.primaryColor})"
        >
          <i class="material-icons">menu</i>Ver todos
        </div>
      </div>
      <div class="nav-btn">
        <div :class="'swiper-button-prev--'+_uid" class="swiper-button-prev" :style="({borderColor: $theme.primaryColor, color:$theme.primaryColor })" slot="button-prev"></div>
        <div :class="'swiper-button-next--'+_uid" class="swiper-button-next" :style="({borderColor: $theme.primaryColor, color:$theme.primaryColor })" slot="button-next"></div>
    </div>
      </div>


      <div class="nextCarousel">
        <div
          class="box-next-content"
          :style="$theme.darkTheme ? '' : 'background-color: #f2f3f7'"
        >
          <div class="nextCarousel">
            <swiper
              :ref="'swiper-class-id-' + metadata.classId"
              :class="'swiper-class-id-' + metadata.classId"
              :options="swiperOption"
            >
              <swiper-slide
                :class="['item', 'swiper-class-id-item-' + metadata.classId]"
                :style="$theme.darkTheme ? '' : 'background: #fff'"
                v-for="item in items"
                :key="item.id"
              >
                <a href="javascript:;" @click.prevent="click(item)">
                  <img v-lazy="itemImage(item)" class="poster" />
                  <!-- <div class="item-time" v-if="item.broadcast">
                    {{ item | formatDuration }}
                  </div> -->
                  <ProgressBar
                    class="item-progress-bar"
                    v-if="!hideProgress"
                    :value="item.progress"
                    :color="$theme.primaryColor"
                  ></ProgressBar>
                  <!-- <div class="item-class">
                    {{ item.category }} {{ item.progress }}
                  </div> -->
                  <div class="item-info">


                  
                  <!-- categoria -->
                  <div
                    class="item-module line-clamp-1"
                    style="font-size: 10px; color: #FFFFFF99"
                    :style="$theme.darkTheme ? '' : 'color: #A0A0A0'"
                  >
                    {{ item.category }}
                  </div>
                  <!-- Nome da aula -->
                  <div
                    class="item-name line-clamp-2"
                    style="font-size: 12px; color: #FFFFFFE5; font-weight: 800"
                    :style="({color: $theme.primaryColor})"
                  >

                    <img
                      v-if="item.isPreview && item.state == 'Published'"
                      src="@/assets/icons/check-circle-copy.svg"
                    />
                    <img
                      v-if="item.isPreview && item.state == 'Draft'"
                      src="@/assets/icons/edit.svg"
                    />
                    <img
                      v-if="item.state == 'Unpublished'"
                      src="@/assets/icons/lock.svg"
                    />
                    <img
                      v-if="item.state == 'Scheduled'"
                      src="@/assets/icons/lock.svg"
                      v-tooltip.top="{ content: formatScheduleInfo(item) }"
                    />
                    {{ item.title }}
                  </div>
                </div>
                <!-- dados da aula -->
                <div class="item-footer">

                  <div class="flex-between flex-v-center">
 
                    <div class="item-module"
                    :style="$theme.darkTheme ? '' : 'color: #A0A0A0'"
                    >
                    {{ item.progress }}%
                   </div>
                   <div class="item-module flex-v-center"
                   :style="$theme.darkTheme ? '' : 'color: #333333'"
                   >
                   <span :style="({color: $theme.primaryColor})" >
                    {{item.progress ? 'Continuar' : 'Assistir'}}
                  </span>
                   <IconPlay />
                   
                 </div>
               </div>
                </div>


               
                </a>
              </swiper-slide>

              <!-- <div class="swiper-button-prev" slot="button-prev"></div>
              <div class="swiper-button-next" slot="button-next"></div> -->
            </swiper>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from "@voxel/vue-awesome-swiper";
import { formatDate } from "@/filters/formatDate";
import ProgressBar from "@/components/UI/ProgressBar";
import { mapState } from "vuex";
import "@voxel/swiper/css/swiper.css";
import IconPlay from "@/components/UI/IconPlay";
import IconPlayScreen from "@/components/UI/IconPlayScreen";

export default {
  name: "Carousel",
  props: ["showCategory", "items", "metadata", "hideProgress"],
  components: { Swiper, SwiperSlide, ProgressBar, IconPlay, IconPlayScreen },
  computed: {
    ...mapState({
      selectedEnroll: (state) => state.enroll.current,
    }),
  },

  data() {
    return {
      swiperOption: {
        slidesPerView: "auto",
        spaceBetween: 15,
        //freeMode: true,
        // slidesOffsetAfter: 20,
        // slidesOffsetBefore: 50,
        // slidesPerGroup: 3,
        navigation: {
          nextEl: ".swiper-button-next--"+this._uid,
          prevEl: ".swiper-button-prev--"+this._uid,
        },
        // nextButton: ".swiper-button-next",
        // prevButton: ".swiper-button-prev",
        //touchEventsTarget: 'container'
      },
      showAll: false,
    };
  },
  mounted() {
    if (!this.items || !this.metadata.classId) return false;

    const swiperContainerWidth = this.$refs[
      "swiper-class-id-" + this.metadata.classId
    ].$el.clientWidth;
    if (swiperContainerWidth < this.items.length * 260) {
      this.showAll = true;
    } else {
      this.showAll = false;
    }
  },
  methods: {
    itemImage(item) {
      if (item.broadcast) {
        return item.image + "?h=146";
      }
      return item.image;
    },
    click(item) {
      const eventData = {
        ...item,
        ...this.metadata,
      };
      if (item.state == "Published") this.$emit("click", eventData);
    },
    openLeftMenu() {
      if (this.metadata) this.$emit("openLeftMenu", this.metadata.classId);
    },
    formatScheduleInfo(obj) {
      if (obj.state == "Scheduled" && obj.releasedDate)
        return "Liberado em " + formatDate(new Date(obj.releasedDate));
      return obj.title;
    },
  },
  filters: {
    formatDuration: function (value) {
      if (value.broadcast !== null) {
        const duration = value.broadcast.duration;
        var hours = Math.floor(duration / 3600);
        var minutes = Math.floor((duration - hours * 3600) / 60);
        var seconds = duration - hours * 3600 - minutes * 60;

        if (hours < 10) {
          hours = "0" + hours;
        }
        if (minutes < 10) {
          minutes = "0" + minutes;
        }
        if (seconds < 10) {
          seconds = "0" + seconds;
        }

        if (hours != "00") {
          return hours + ":" + minutes + ":" + seconds;
        } else {
          return minutes + ":" + seconds;
        }
      } else return "";
    },
    formatDate: formatDate,
  },
};
</script>

<style>
/* .item-name {
  color: #999 !important;
} */

img[lazy] {
  /* background: #222; */
}
.item-footer{
  position: absolute;
  width: -webkit-fill-available;
  padding: 0 10px;
  bottom: 10px;
  
  font-family: Roboto;
font-size: 12px;
font-style: normal;
font-weight: 400;
line-height: normal;
}

.nextContentTitle{
  display: flex;
  justify-content: space-between;
}

.nextContentTitleLeft{
  display: flex;
  align-items: center;
  justify-items: flex-start;

}
.nextContentTitleLeft svg{
  margin-right: 5px;
}

.nav-btn{
  display: flex;
  justify-content: flex-end;
  align-items: center;
}
.flex-between{
  display: flex;
  justify-content: space-between;
}

.flex-v-center{
  display: flex;
  align-items: center;
}
.flex-h-center{
  display: flex;
  justify-content: center;
}
.flex-h-start{
  display: flex;
  justify-content: flex-start;
}

@media only screen and (max-width: 992px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }
}

.swiper-button-prev.swiper-button-disabled,
.swiper-button-next.swiper-button-disabled {
  /* display: none; */
  opacity: 0.35;
}

.swiper-button-next,
.swiper-button-prev {
  position: relative;
  border: 2px solid #9747FF;
  /* background-color: #131318; */
  padding: 3px;
  box-sizing: border-box;
  border-radius: 100%;
  width: 30px;
  height: 30px;
  top: 10px;
}

.swiper-button-prev:after,
.swiper-button-next:after {
  /* color: #9747FF; */
  font-size: 13px;
}

.swiper-button-next:after {
  padding-left: 3px;
}

.swiper-button-prev:after {
  padding-right: 3px;
}
.swiper-button-prev{
  left:-20px !important;
}

.poster-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 146px;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  text-align: center;
}

.poster-overlay img {
  height: 100%;
  width: 100%;
}

.icon-carousel{
  vertical-align: middle;
      height: 19px;

}

</style>