import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'
import axios from 'axios'

export class ConfigService extends BaseService {
  static get entity () {
    return 'config'
  }

  static async getConfig (userId) {
    try {
        const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/Students/settings`);

        return new ResponseWrapper({status:200, data:{success:true}}, response.data);
  
      } catch (error) {
        const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
        throw new ErrorWrapper(error, message)
      }
  }

  static async update (userId, data) {
    try {
        const response = await this.request({ auth: true }).put(`${process.env.VUE_APP_EAD_API_URL}/Students/settings`, {
            "aboutMe": "",
            "office": "",
            "location": "",
            "startNextVideo": data.autoNext,
            "autoPlay": data.autoplay,
            "continueVideo": data.startFromLastSavedState
        });

        return new ResponseWrapper({status:200, data:{success:true}}, response.data);
  
      } catch (error) {
        const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
        throw new ErrorWrapper(error, message)
      }
  }
}
