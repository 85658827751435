<template>
    <div class="obj-status">
        <div v-if="icon" class="menu-icon">
            <i class="material-icons">
                {{icon}}
                <i class="fa fa-check-circle" v-if="percent == 100" />
            </i>
        </div>
        <svg v-else height="24" width="24">
            <circle cx="12" cy="12" r="9" :stroke="($theme.darkTheme ? '#666' : '#c5c5c5')" stroke-width="2" fill="" style="stroke-dashoffset: 0; fill: transparent"></circle>
            <circle cx="12" cy="12" r="9" :stroke="($theme.darkTheme ? '#2FCA6E': '#00c502')"  stroke-width="2" fill="" :style="{ 'stroke-dashoffset': offset, fill: 'transparent' }"></circle>
            <!-- #c5c5c5 #0ef910  -->
        </svg>
        <label>
            <input
                v-if="!icon"
                type="checkbox"
                id="btnStatus"
                class="filled-in"
                :checked="percent == 100"
                @change="(e)=>{changeCheck(e)}"
            />
            <span></span>
        </label>
    </div>
</template>

<script>
import M from "materialize-css";

export default {
    props: ['percent', 'icon'],
    computed: {
        offset() {
            return 57 - (57 * +this.percent / 100);
        }
    },
    methods:{
        changeCheck(event)
        {
            if(!this.icon)
                this.$emit('change', event);
        }
    }
}
</script>

<style scoped>

.obj-status {
    position: relative;
    width: 30px;
    height: 24px;
}

svg {
    position: absolute;
    left: 0;
    top: 0;
    transform: rotate(-90deg);
}

svg circle {
    stroke-dasharray: 57; /* (PI * Largura) */
}

label {
    position: absolute;
    left: 2px;
    top: 2px;
}

[type="checkbox"].filled-in:not(:checked) + span:not(.lever):after {
    background: transparent;
    border: 0;
}

.menu-icon {
    width: 100%;
    height: 100%;
}

.menu-icon>i {
    font-size: 24px;
    padding: 2px;
}

.menu-icon>i>i {
    font-size: 12px;
    position: absolute;
    top:15px;
    left:20px;
    color: #2FCA6E;
    margin:0px;
}

</style>