<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <path
        id="icon-logout"
        d="M8.176 18.333c0 .5-.334.834-.834.834H3.17c-1.419 0-2.503-1.084-2.503-2.5V3.333c0-1.416 1.084-2.5 2.503-2.5h4.172c.5 0 .834.334.834.834 0 .5-.334.833-.834.833H3.17c-.5 0-.835.333-.835.833v13.334c0 .5.334.833.835.833h4.172c.5 0 .834.333.834.833zm9.095-8c.083-.166.083-.416 0-.666-.084-.084-.084-.167-.167-.25l-3.338-3.334c-.333-.333-.834-.333-1.168 0-.334.334-.334.834 0 1.167l1.92 1.917h-8.01c-.501 0-.835.333-.835.833 0 .5.334.833.834.833h8.01l-1.919 1.917c-.334.333-.334.833 0 1.167.167.166.417.25.584.25.167 0 .417-.084.584-.25l3.338-3.334c.083-.083.167-.166.167-.25z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(1)">
      <mask id="icon-logout__b" fill="#fff">
        <use xlink:href="#icon-logout" />
      </mask>
      <use :fill="(!selectedEnroll ? '#0564b1' : $theme.primaryColor)" fill-rule="nonzero" xlink:href="#icon-logout" />
      <g fill="none" mask="url(#icon-logout__b)">
        <path d="M0 0H20V20H0z" transform="translate(-1)" />
      </g>
    </g>
  </svg>

</template>

<script>
export default {
  props: {
    selectedEnroll: { default: 0 },
  },
};
</script>