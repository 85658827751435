<template>
  <modal
    name="mycertificate"
    :classes="$theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light'"
    height="100%"
    :width="calculatedWidth"
    :adaptive="true"
    :pivotX="1"
    :shiftX="1"
    :pivotY="0"
    :shiftY="0"
    :scrollable="true"
    @opened="load()"
    @closed="loading = true"
    transition="slide-left"
    overlayTransition=""
  >
    <HeaderPage
      ref="header"
      pageName="Meus Certificados"
      modalName="mycertificate"
    />
    <main
      v-if="loading"
      :class="$theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light'"
    >
      <content-placeholders>
        <content-placeholders-text
          class="carousel-title-placeholder"
          :lines="2"
          :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'"
        />
        <content-placeholders-text
          class="carousel-title-placeholder"
          :lines="4"
          :class="$theme.darkTheme ? 'loading-dark' : 'loading-light'"
        />
      </content-placeholders>
    </main>
    <main
      v-else
      :class="$theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light'"
    >

      <div class="container">
        <!-- Exibição Página Curso -->
        <div v-if="currentEnroll"> 
            <ul class="collapsible accordion-bg-4" v-for="certificado in certificate" :key="certificado.courseId">
              <li  class="container">
                <div class="row" v-show="certificado.courseId == currentEnroll.courseId">
                        <div v-if="certificado.availableForIssue">
                            <div class=" item-description grey-text center-align"> 
                                  <span class="row" style="font-weight: 600" >Parabéns!</span>  <br>
                                  Você concluiu o curso <span style="font-weight: 500" :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }" >{{ certificado.courseName }}</span>, e seu certificado já está disponível! <br><br> 
                           
                              
                              </div>
                            <div class=" item-description grey-text row center" >
                              <a  href="#!" 
                                class="waves-effect btn-profile btn-certificate" 
                                :style=" !hasCustomTheme? '#0564b1': {background: $theme.primaryColor, color: $theme.primaryText,}"
                                @click="emitirCertificado(certificado)">
                                   <template v-if="!emiting">Emitir Certificado</template>
                                    <template v-else>
                                      <div class="preloader-wrapper small active">
                                        <div class="spinner-layer">
                                          <div class="circle-clipper left">
                                            <div class="circle"></div>
                                          </div>
                                          <div class="gap-patch">
                                            <div class="circle"></div>
                                          </div>
                                          <div class="circle-clipper right">
                                            <div class="circle"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                              </a>
                            </div>
                      </div>    
                  <div v-else>
                    <div class=" item-description grey-text center-align"><span> O certificado estará disponível após a conclusão de 100% do curso. <br> Você concluiu <span style="font-weight: 600"  :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }"> {{ certificado.progress }}% </span> até o momento.</span></div>
                    
                  </div>
              </div>
              </li>
            </ul>
        </div>
        <!-- Exibição Vitrine -->
        <div v-else>
            <ul class="collapsible accordion-bg-4" v-for="certificado in filterCertificate()" :key="certificado.courseId">
              <li  >
                <div class="row" >
                        <div v-if="certificado.availableForIssue">
                          <div class="item-title center" :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }">{{ certificado.courseName }}</div>
                          <div class=" item-description grey-text center-align"> 
                                  <span style="font-weight: 600" >Parabéns!</span>  <br>
                                  Você concluiu este curso e seu certificado já está disponível! <br><br> 
                              </div>
                            <div class=" item-description grey-text row center" >

                              <a href="#!"  
                                class="waves-effect btn-profile btn-certificate" 
                                :style=" !hasCustomTheme? '#0564b1': {background: $theme.primaryColor, color: $theme.primaryText,}"
                                @click="emitirCertificado(certificado)">
                                 <template v-if="!emiting">Emitir Certificado</template>
                                    <template v-else>
                                      <div class="preloader-wrapper small active">
                                        <div class="spinner-layer">
                                          <div class="circle-clipper left">
                                            <div class="circle"></div>
                                          </div>
                                          <div class="gap-patch">
                                            <div class="circle"></div>
                                          </div>
                                          <div class="circle-clipper right">
                                            <div class="circle"></div>
                                          </div>
                                        </div>
                                      </div>
                                    </template>
                              </a>
                            </div>
                      </div>    
                  <div v-else>
                    <div class="item-title center" :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }">{{ certificado.courseName }}</div>
                    <div class=" item-description grey-text center-align"><span> O certificado deste curso estará disponível após a conclusão de 100% do curso. <br> Você concluiu <span style="font-weight: 600" :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }" > {{ certificado.progress }}% </span> deste curso até o momento.</span></div>
                    
                  </div>
              </div>
              <div class="divider row"></div>
              </li>
            </ul>

        </div>
      </div>

      
    </main>
  </modal>
</template>

<script>
import HeaderPage from "@/layout/HeaderPage";
import { mapState } from "vuex";
import { isMobile } from "mobile-device-detect";
import certificate from '../../store/modules/certificate';

export default {
  name: "Mycertificate",
  props: ["layoutTheme"],
  components: {
    HeaderPage,
  },
  data() {
    return {
      loading: true,
      emiting: false,
    };
  },
  async mounted() {
  },

  computed: {
    ...mapState({
      currentEnroll: (state) => state.enroll.current,
      shopWindow: (state) => state.shopWindow,
      enrolledCoursesIds : (state) => state.shopWindow.enrolledCoursesIds ,
      shopWindowCoursesGroups: (state) => state.shopWindow.coursesGroups,
      certificate: (state) => state.certificate.certificateList,
      enrolls: (state) => state.enroll.enrolls,
    }),

    isTrustflix() {
      return this.$route.name === "trustflix";
    },
    calculatedWidth() {
      if (isMobile) return "100%";
      else return "20%";
    },
    hasCustomTheme() {
      return Boolean(this.isTrustflix || this.currentEnroll);
    },


  },
  methods: {
    async load() {
      this.loading = true;
      
      await this.$store.dispatch("certificate/getCertificate");

      this.loading = false;
           

      this.$nextTick(() => {
        // this.filterCertificate();
      });
    },
    
    filterCertificate() {
      let certificadoVitrine = this.certificate.filter(o1 => this.enrolledCoursesIds.some(o2 => o1.courseId === o2));


      // var filtrados =  this.enrolls.filter(item => item.settings.enableCertificate == true);
      // var progressSectionCourses = this.shopWindow.progressSection.content.map(x => x.courseId);
      // var dinamicSectionCourses = this.shopWindow.dynamicSections[0].content.map(x => x.courseId);
      // var certificatesUnique = this.certificate.map(x => x.courseId).filter((v,i,s) => s.indexOf(v) === i);
      // var progressSectionCoursesUnique = this.shopWindow.progressSection.content.map(x => x.courseId).filter((v,i,s) => s.indexOf(v) === i);
      // var dynamicSectionCoursesUnique = this.shopWindow.dynamicSections[0].content.map(x => x.courseId).filter((v,i,s) => s.indexOf(v) === i);

      // var cur = this.currentEnroll.courseId;
      // if(cur){
        //   var cursoCertif = cer.filter((cobj) => {
      //     return cobj.courseId == cur;
      //     });
      //     // console.log('resultado: ', cursoCertif)
      //     return cursoCertif;
      // }
        return certificadoVitrine;
     
    },

    async emitirCertificado(cert){
      if (this.emiting) return;
      this.emiting = true;
      var send = await this.$store.dispatch("certificate/emitCertificate", cert);


      if(send){
         this.$store.commit("toast/NEW", {
          type: "success",
          message: `Certificado será emitido em instantes e será enviado para o email cadastrado.`,
        });
        this.emiting = false;
        return false;
      }else{
        this.emiting = false;
         this.$store.commit("toast/NEW", {
          type: "error",
          message: `Ocorreu um erro! Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.`,
        });
      }
    },

  },
};
</script>

<style scoped>
.group-id {
  display: flex;
}

.mail-group {
  word-wrap: break-word;
}

.btn-certificate{
  width: 100% !important;
  font-size: 14px !important;
}

.preloader-wrapper {
  margin-top: 7px;
}

.spinner-layer {
  border-color: #eee !important;
}
</style>
