<template>
  <div id="box-login" ref="boxLogin">
    <UiToastList />
    <div class="login-logo">
      <div class="logo-cliente">
        <img id="logoImg" ref="logoImg" @error="defaultImg" />
      </div>
    </div>
    <form class="login-form" v-if="showForm">
      <div class="input-field s6">
        <div class="input-field lighten-4 s6 row">
          <input
            id="login"
            :type="!showPass ? 'password' : 'text'"
            v-model="password"
            class="validate lighten-4"
          />
          <label for="login">Nova senha</label>
        </div>
      </div>
      <div class="input-field s12 row">
        <input
          id="password"
          :type="!showPass ? 'password' : 'text'"
          v-model="confirm"
          class="validate"
        />
        <label for="password">Confirmar senha</label>
      </div>
      <div class="login-password-options">
        <div class="login-mostrar-senha s6">
          <label>
            <input type="checkbox" class="filled-in" v-model="showPass" />
            <span>Mostrar senhas</span>
          </label>
        </div>
      </div>
      <div class="row">
        <a
          v-if="!loading"
          class="btn-full waves-effect btn-large"
          @click="confirmPasswordChange"
        >CONFIRMAR</a>
        <a v-else class="btn-full waves-effect btn-large">
          <div class="preloader-wrapper small active">
            <div class="spinner-layer">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div>
              <div class="gap-patch">
                <div class="circle"></div>
              </div>
              <div class="circle-clipper right">
                <div class="circle"></div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </form>

    <form class="login-form" v-else>
      <div class="row">
        <p class="message title">Link inválido.</p>
        <p
          class="message"
        >Por favor, volte e clique em 'Esqueci minha senha' novamente para receber um novo link.</p>
      </div>
      <div class="row">
        <a v-if="!loading" class="btn-full waves-effect btn-large" @click="toLogin">VOLTAR</a>
        <a v-else class="btn-full waves-effect btn-large">
          <div class="preloader-wrapper small active">
            <div class="spinner-layer">
              <div class="circle-clipper left">
                <div class="circle"></div>
              </div>
              <div class="gap-patch">
                <div class="circle"></div>
              </div>
              <div class="circle-clipper right">
                <div class="circle"></div>
              </div>
            </div>
          </div>
        </a>
      </div>
    </form>
    <iframe
      v-if="isCourseUrl"
      ref="courseIframe"
      :src="appUrl + 'login-integration'"
      width="0"
      height="0"
      style="display: none"
    ></iframe>
    <div class="login-powered" v-show="false">
      Powered by
      <AppLogo monochrome width="113" />
    </div>
    <iframe
      v-if="isCourseUrl"
      ref="courseIframe"
      :src="appUrl + 'login-integration'"
      width="0"
      height="0"
      style="display: none"
    ></iframe>
  </div>
</template>

<script>
import AppLogo from '@/components/UI/AppLogo.vue';
import { AuthService } from "@/services/auth.service";
import { mapState } from "vuex";
import UiToastList from "@/components/UI/UiToastList";

export default {
  name: "Login",
  data() {
    return {
      showForm: true,
      hash: "",
      password: "",
      confirm: "",
      showPass: false,
      loading: false,
      error: "",
      appUrl: process.env.VUE_APP_EAD_URL,
      isCourseUrl:
        window.location.protocol + "//" + window.location.host + "/" !=
        process.env.VUE_APP_EAD_URL,
      redirectToCourse: null,
    };
  },

  components: {
    AppLogo,
    UiToastList,
  },
  async mounted() {
    this.hash = this.$router.currentRoute.query.h;
    this.loadCustomBackgrounds();

    if (!this.hash) {
      this.$router.push("/");
    } else {
      var response = await AuthService.checkResetHash(this.hash);
      if (response.status == 204) {
        this.showForm = false;
      }
    }
  },
  computed: {
    ...mapState({
      enrolls: (state) => state.enroll.enrolls,
    }),
  },
  methods: {
    postToIframe(isCourseLogin, shopWindow, accessToken) {
      this.$refs.courseIframe.contentWindow.postMessage(
        {
          courseUrl: isCourseLogin ? window.location.href : undefined,
          shopWindow: shopWindow || undefined,
          accessToken: accessToken,
          type: isCourseLogin ? "course-login" : "trustflix-login",
        },
        this.appUrl
      );
    },
    async confirmPasswordChange() {
      if (!this.validateChangePasswordFields()) return;

      try {
        this.loading = true;
        await AuthService.forgotPasswordConfirm({
          hash: this.hash,
          password: this.password,
        });
        this.$store.commit("toast/NEW", {
          message: "Nova senha cadastrada com sucesso",
        });
        this.error = "";

        await this.$store.dispatch("user/getCurrent"); // busca o usuário
        await this.$store.dispatch("enroll/getUserEnroll"); // busca a matricula
        await this.$store.dispatch("notification/getNotifications"); // busca as notificações
        await this.$store.dispatch("config/getConfig");
        await this.$store.dispatch("user/updateProfilePic");
        await this.$store.dispatch("notification/initLoopTimeout");
        await this.$store.dispatch("enroll/getUserEnroll");

        let shopWindow;
        let host = window.location.protocol + "//" + window.location.host + '/';

        if (host !== process.env.VUE_APP_EAD_URL) {
          try {
            shopWindow = await this.$store.dispatch("shopWindow/getCurrent", window.location.host);
            if (!shopWindow)
              shopWindow = await this.$store.dispatch("shopWindow/getCurrent");
          } catch (error) {}
        }

        let redirectUrl = "";
        if (shopWindow || this.isCourseUrl) {
          const accessToken = AuthService.getAccessToken();

          const isCourseLogin = this.isCourseUrl && !shopWindow;
          this.postToIframe(isCourseLogin, shopWindow, accessToken);

          if (shopWindow) {
            redirectUrl = "p/trustflix";
            await this.$store.dispatch("shopWindow/setCurrent", shopWindow.id);
          } else if (this.isCourseUrl) {
            const courseId = this.redirectToCourse.courseId;
            const enroll = this.enrolls.find((e) => {
              return e.courseId == courseId;
            });

            redirectUrl = enroll ? `p/course/${enroll.id}` : "p/my-courses";
          }

          if (redirectUrl) {
            return (window.location = `${process.env.VUE_APP_EAD_URL}${redirectUrl}`);
          }
        }

        if (this.enrolls.length == 1 && this.enrolls[0].shopWindowId) {
          await this.$store.dispatch("shopWindow/setCurrent", this.enrolls[0].shopWindowId);
          redirectUrl = "p/trustflix";
          return (window.location = `${process.env.VUE_APP_EAD_URL}${redirectUrl}`);
        }

        this.$router.push("/p/my-courses");

        /*
        var shopWindows;
        try {
          shopWindows = await this.$store.dispatch("shopWindow/getAllEnabled");
        } catch (error) {}

        await this.$store.dispatch("shopWindow/clearCurrent");
        if (
          this.enrolls.length == 1 &&
          this.enrolls[0].shopWindowId &&
          shopWindows
        ) {
          var shopWindow = shopWindows.find((e) => {
            return e.id == this.enrolls[0].shopWindowId;
          });
          if (!shopWindow) {
            this.$router.push("/p/my-courses");
            return;
          }

          await this.$store.dispatch("shopWindow/setCurrent", shopWindow.id);
          this.$router.push("/p/trustflix");
          return;
        } else {
          if (this.isCourseUrl) {
            var accessToken = AuthService.getAccessToken();
            this.$refs.courseIframe.contentWindow.postMessage(
              {
                courseUrl: window.location.href,
                accessToken: accessToken,
                type: "course-login",
              },
              this.appUrl
            );

            const courseId = this.redirectToCourse.courseId;
            const enroll = this.enrolls.find((e) => {
              return e.courseId == courseId;
            });
            if (!enroll || enroll.id == 0) {
              this.$store.commit("toast/NEW", {
                type: "error",
                message: "Você não tem uma matrícula válida para esse curso.",
                page: "login",
              });
              this.loading = false;
              return;
            }
            var redirectUrl = `${process.env.VUE_APP_EAD_URL}p/course/${enroll.id}`;
            window.location = redirectUrl;
          } else {
            this.$router.push("/p/my-courses");
          }
        }
        */
      } catch (error) {
        if ((error && error.status === 401) || error.status === 404) {
          this.showForm = false;
          // this.error = "Link inválido. Por favor, clique em esqueci minha senha novamente para receber um novo link.";
          // this.$store.commit("toast/NEW", { message: this.error });
          // this.$router.push("/");
          return;
        }

        this.$store.commit("toast/NEW", {
          type: "error",
          message: error.message,
          page: "login",
        });
      }
      this.loading = false;
    },
    async loadCustomBackgrounds() {
      if (!this.isCourseUrl) {
        this.$refs.logoImg.src = this.$appLogoSrc;
        return;
      }

      var resp = await AuthService.getAppIdCourseId();
      this.redirectToCourse = resp.data;

      if (
        this.redirectToCourse.applicationId &&
        this.redirectToCourse.courseId
      ) {
        // var bgPath = `${process.env.VUE_APP_CDN_URL}${resp.data.applicationId}/backgrounds/${resp.data.courseId}.jpg`;
        var logoPath = `${process.env.VUE_APP_CDN_URL}${resp.data.applicationId}/logos/${resp.data.courseId}.png`;

        this.$refs.logoImg.src = logoPath;
        // this.$refs.boxLogin.style = `background-image: url(${bgPath})`;
      } else {
        this.$refs.logoImg.src = this.$appLogoSrc;
      }
    },
    defaultImg(event) {
      event.target.src = this.$appLogoSrc;
    },
    setElementValid(elementId, valid) {
      var element = document.getElementById(elementId);
      element.className =
        valid === true ? "validation valid" : "validation invalid";
    },
    showFieldsErrors(messages) {
      this.$store.commit("toast/CLEAR_LIST");
      setTimeout(() => {
        for (var i = 0, l = messages.length; i < l; i++) {
          this.$store.commit("toast/NEW", {
            message: messages[i],
            type: "error",
            page: "login",
          });
        }
      }, 500);
    },
    validateChangePasswordFields() {
      let changePasswordValid = true;
      let elementToFocus = "";
      let errorMessages = [];

      this.setElementValid("password", true);
      this.setElementValid("login", true);

      if (this.password != this.confirm) {
        errorMessages.push("Nova senha não confere com a confirmação");
        elementToFocus = "password";
        this.setElementValid("password", false);
        changePasswordValid = false;
      }

      if (this.confirm.length == 0) {
        errorMessages.push("A confirmação da nova senha é obrigatória");
        elementToFocus = "password";
        this.setElementValid("password", false);
        changePasswordValid = false;
      }

      if (this.password.length < 6) {
        errorMessages.push("A nova senha deve conter, no mínimo, 6 caracteres");
        elementToFocus = "login";
        this.setElementValid("login", false);
        changePasswordValid = false;
      }

      if (this.password.length == 0) {
        errorMessages.push("A nova senha é obrigatória");
        elementToFocus = "login";
        this.setElementValid("login", false);
        changePasswordValid = false;
      }

      if (errorMessages.length > 0) {
        document.getElementById(elementToFocus).focus();
        document.getElementById(elementToFocus).select();
        this.showFieldsErrors(errorMessages.reverse());
      }

      return changePasswordValid;
    },
    toLogin() {
      this.$router.push("/");
    },
  },
};
</script>

<style lang="scss" scoped>
.login {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;

  .main {
    padding: 30px 15px;
    background: #fff;
    width: 400px;
    border-radius: 2px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

    .header {
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    .error {
      background-color: red;
      padding: 10px;
      font-size: 12px;
      opacity: 1;
      transition: all 0.5s;
    }
  }
}
.spinner-layer {
  border-color: #eee !important;
}

.login-form {
  z-index: 1;
  width: 100%;
  max-width: 600px;

  .input-field input[type="password"] + label,
  .input-field input[type="text"] + label,
  .input-field input[type="password"]:focus + label,
  .input-field input[type="text"]:focus + label {
    color: #0d0d13;
  }

  .input-field input[type="password"],
  .input-field input[type="text"],
  .input-field input[type="password"]:focus,
  .input-field input[type="text"]:focus {
    border-bottom: 1px solid #0d0d13;
    box-shadow: 0 0px 0 0 #0d0d13;
    color: #0d0d13;
  }
  .input-field input[type="password"].invalid,
  .input-field input[type="password"].invalid:focus {
    border-bottom: 1px solid #f44336;
    -webkit-box-shadow: 0 1px 0 0 #f44336;
    box-shadow: 0 1px 0 0 #f44336;
  }
  .login-password-options {
    margin-bottom: 20px;
    font-family: Montserrat;
    font-size: 11px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #0d0d13;

    .login-esqueceu-senha {
      text-align: right;
      cursor: pointer;
      color: #0d0d13;
    }

    [type="checkbox"] + span:not(.lever) {
      padding-left: 20px;
      margin-right: 0px;
      height: 15px;
      font-family: Montserrat;
      font-size: 11px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
      color: #0d0d13;
    }

    [type="checkbox"].filled-in + span:not(.lever):after {
      border-radius: 0%;
      width: 14px;
      height: 14px;
      border: solid 1px #979797;
      background-color: #fff;
    }

    [type="checkbox"].filled-in:checked + span:not(.lever):after {
      background-color: #034f8d;
      border: 2px solid #034f8d;
    }

    [type="checkbox"].filled-in:checked + span:not(.lever):before {
      top: -2px;
      left: 0px;
      width: 6px;
      height: 11px;
    }
  }

  .btn-full {
    height: 44px;
    line-height: 45px !important;
    width: 100%;
    background-image: linear-gradient(98deg, #034f8d 1%, #0564b1 93%);
    border-radius: 4px;
    box-shadow: -2px 5px 15px 0 rgba(0, 0, 0, 0.25);

    font-family: Montserrat;
    font-size: 11px;
    font-weight: 600;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    text-align: center;
    color: #ffffff;
  }
  .message {
    color: black;
    text-align: center;
    font-size: 16px;
  }
  .message.title {
    color: red;
    font-weight: bold;
    font-size: 18px;
  }
}
</style>
