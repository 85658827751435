<template>
    <main v-if="loading">
          <!-- <div class="loading">
      <div class="lds-ripple">
        <div :style="{ borderColor: $theme.primaryColor }"></div>
        <div :style="{ borderColor: $theme.primaryColor }"></div>
      </div>
    </div> -->
      <content-placeholders>
        <content-placeholders-img class="course-detail-placeholder" :class="($theme.darkTheme ? 'loading-dark' : 'loading-light')"/>
        <div class="container">
          <content-placeholders-text class="carousel-title-placeholder" :lines="1" :class="($theme.darkTheme ? 'loading-dark' : 'loading-light')"/>
          <content-placeholders-img class="carousel-image-placeholder" :class="($theme.darkTheme ? 'loading-dark' : 'loading-light')"/>
          <content-placeholders-text class="carousel-title-placeholder" :lines="1" :class="($theme.darkTheme ? 'loading-dark' : 'loading-light')"/>
          <content-placeholders-img class="carousel-image-placeholder" :class="($theme.darkTheme ? 'loading-dark' : 'loading-light')"/>
          <content-placeholders-text class="carousel-title-placeholder" :lines="1" :class="($theme.darkTheme ? 'loading-dark' : 'loading-light')"/>
          <content-placeholders-img class="carousel-image-placeholder" :class="($theme.darkTheme ? 'loading-dark' : 'loading-light')" />
        </div>
      </content-placeholders>
    </main>
    <main
    :class="{
      'page-main-content': isPreview,
      'page-main-content-compressed': isPreview && isCompressed(),
    }"
    v-else>
      <div class="row" :id="'content-'+ classData.id"  v-for="classData in carouselItemsFiltered" :key="classData.id">
        <div :ref="'swiper-container-' + classData.id" class="container">
          <Carousel
            :items="classData.learningObjects"
            :metadata="{
                title: classData.title,
                isPreview: true,
                state: classData.state,
                releasedDate: classData.releasedDate,
                classId: classData.id,
                moduleId: classData.moduleId ? classData.moduleId : null,
                disciplineId: classData.disciplineId ? classData.disciplineId : null
              }"
            @click="navigateToClass"
            @openLeftMenu="openLeftMenu"
          />
        </div>
      </div>

      <div class="load-more center">
        <a
          href="javascript:;"
          class="waves-effect btn-load-more center"
          v-if="carouselItemsFiltered.length != carouselItems.length"
          @click="loadMore()"
        >
          <i class="material-icons">add_box</i>Carregar mais
        </a>
      </div>
    </main>
    <!-- <ModalCourseFiles :layout-theme="currentLayoutTheme"></ModalCourseFiles> -->
</template>

<script>
import Carousel from "@/components/Carousel";
import { mapState } from "vuex";

export default {
  name: "Course",
  components: {
    Carousel
  },
  data() {
    return {
      tabIndex: 1,
      pageCount: process.env.VUE_APP_COURSE_PAGE_COUNT,
      currentLayoutTheme: 'dark',
      loading: true
    };
  },
  computed: {
    ...mapState({
      currentEnroll: state => state.enroll.current,
      isPreview: (state) => state.enroll.isPreview,
      sidenavIsOpen: state => state.config.sidenavIsOpen
    }),
    carouselItems() {
      if (this.currentEnroll == null) return [];

      const structureType = this.currentEnroll.structureType.toLowerCase();

      var classes = [{}];
      switch (structureType) {
        case "modulesanddisciplines":
          //TODO: Nada no layout suporta essa organização
          break;
        case "modules":
        case "disciplinas":
          classes = this.currentEnroll[structureType].reduce(
            (result, modDisc) => result.concat(modDisc.classes),
            []
          );
          break;
        case "classes":
          classes = this.currentEnroll.classes;
          break;
      }

      return classes;
    },
    carouselItemsFiltered() {
      // paginação
      for (let i = 0; i < this.carouselItems.length; i++) {
        this.carouselItems[i].pageShow = true;
        if (i + 1 > this.pageCount - 1) break;
      }

      return this.carouselItems.filter(clss => {
        return clss.pageShow;
      });
    }
  },
  async mounted() {
    window.addEventListener('message', function(e) {
        if (e.data.type == 'course-preview') {
            e.data.payload.isPreview = true;
            this.$store.dispatch("enroll/clearCurrentEnroll");
            this.$nextTick(() => {
              this.$store.dispatch(
                  "enroll/setCurrentEnroll",
                  {
                      preview: e.data.payload
                  }
              );
              this.loading = false;
            });
        }
    }.bind(this));

  },
  methods: {
    loadMore() {
      this.pageCount += this.pageCount;
    },
    openLeftMenu(classId) {
      this.$emit("openLeftMenu", classId);
    },
    navigateToClass(eventData) {
    if (this.currentEnroll.isPreview)
        return;
      this.$router.push({
        name: "class",
        params: { enrollId: this.currentEnroll.id, id: eventData.id }
      });
    },
    findNextLearningObjectsInClass(classes) {
      var nextLearningObjs = [];

      for (var i = 0; i < classes.length; i++) {
        classes[i].learningObjects.forEach(lo => {
          if (lo.id != this.lastAccess.learningObjectId) {
            nextLearningObjs.push(lo);
          }
        });
        if (nextLearningObjs.length > 3) break;
      }

      return nextLearningObjs;
    },
    findNextLearningObjectInDiscipline(disciplines) {
      var nextLearningObjs = [];

      for (var i = 0; i < disciplines.classes.length; i++) {
        nextLearningObjs = this.findNextLearningObjectsInClass(
          disciplines.classes[i]
        );
        if (nextLearningObjs.length > 0) break;
      }

      return nextLearningObjs;
    },
    findNextLearningObjectInModule(modules) {
      var nextLearningObjs = [];

      if (modules.disciplines) {
        for (var i = 0; i < modules.disciplines.length; i++) {
          nextLearningObjs = this.findNextLearningObjectInDiscipline(
            modules.disciplines[i]
          );
          if (nextLearningObjs.length > 0) break;
        }
      } else {
        nextLearningObjs = this.findNextLearningObjectsInClass(modules.classes);
      }

      return nextLearningObjs;
    },
    isCompressed() {
      return (
        this.sidenavIsOpen
      );
    }
  }
};
</script>
