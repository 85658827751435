export default {
  computed: {
    $currentUser () {
      return this.$store.state.user.currentUser
    },

    $profilePic () {
      return this.$store.state.user.currentUser.studentPhoto;
    },

    $firstLetter(){
      return this.$store.state.user.currentUser.studentName ? this.$store.state.user.currentUser.studentName.substr(0,1).toUpperCase() : ''
    }
  }
}
