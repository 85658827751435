<template>
  <main>
    <div class="gridCourses container">
      <div class="box-main" v-for="enroll in enrolls" :key="enroll.id">
        <course-box :enroll="enroll"></course-box>
      </div>
    </div>
  </main>
</template>

<script>
import CourseBox from "@/components/MyCoursesCourseBox";
import { mapState } from "vuex";

export default {
  name: "MyCourses",
  components: {
    CourseBox
  },
  computed: {
    ...mapState({
      enrolls: state => state.enroll.enrolls
    })
  },
  mounted() {
     this.$updateTheme({
      darkTheme: false,
      primary: "#0564b1",
      secondary: "#ffffff"
    });
    this.$gtag.pageview({
      page_title: 'meus cursos',
      page_path: '/p/my-courses'
    });
    this.$store.dispatch('enroll/clearCurrentEnroll');
  }
};
</script>

<style scoped lang="scss">
#app {
  header,
  header nav,
  main,
  footer {
    background-color: #fff !important;
  }

  nav i {
    color: #7f7f7f;
  }

  .menu-right-header {
    background-color: #f5f5f5;
  }

  .gridCourses{
    display: flex;
    flex-wrap: wrap;
    justify-content: center;
  }

  .box-main{
    // border:1px solid red;

    width: 296px;
  height: 353px;
  margin: 17px 16px 0 2px;
  border-radius: 10px;
  box-shadow: 4px 4px 15px 0 rgba(0, 0, 0, 0.2);
  background-color: var(--main-surface-white);
  overflow: hidden;
  }


  @media only screen and (max-width: 992px) {

             .gridCourses{
               justify-content: center;
             }

             .box-main{
               margin: 0 0 20px 0 !important;
               height: 296px !important;
               // width: 100%  !important;
             }

             .course-info-top{
               padding: 10px 15px 0 15px !important;
             }
     }


}


</style>
