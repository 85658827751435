import Vue from 'vue'
import Router from 'vue-router'

import { cachePreviousPath, checkAccessMiddleware, setPageTitleMiddleware, resetChatbox } from './middlewares'
import { routes } from './routes'


const originalPush = Router.prototype.push;
Router.prototype.push = function push(location) {
  return originalPush.call(this, location).catch(err => err)
};

Vue.use(Router)

const router = new Router({
  linkActiveClass: 'is-active',
  mode: 'history',
  routes,
  scrollBehavior (to, from, savedPosition) {
    if(to.name === 'course' || to.name == 'class' || to.name == 'my-courses' || to.name === 'trustflix') {
      return { x:0, y:0 };
    }
  }
})

router.beforeEach(cachePreviousPath)
router.beforeEach(checkAccessMiddleware)
router.beforeEach(setPageTitleMiddleware)
router.beforeEach(resetChatbox)

export default router
