<template>
  <div class="row">
    <div class="col s0 m12 l12">
      <div class="row">
        <div class="col s0 m12 l12 global-result__item-label">
          No módulo {{ module.title }}
        </div>
      </div>

      <div
        class="row clickable"
        v-for="loResult in module.learningObjects"
        :key="'hsrlo_' + loResult.id"
        @click.prevent="click(loResult)"
      >
        <span class="col m10 l12 global-result__item-title">
          {{ loResult.title }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalSearchResultLearningObjects",
  emits: ["click"],
  props: {
    module: {
      type: Object,
      required: true,
    },
  },
  methods: {
    click(learningObject) {
      this.$emit("click", { id: learningObject.id });
    },
  },
};
</script>
