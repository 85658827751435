import { CommentService } from '@/services/comment.service';

export default {
  namespaced: true,
  state: {
    comments: [],
    showReplyBoxId: 0
  },
  getters: {
    //
  },
  actions: {
    getComments ({ commit }, payload) {
      return CommentService.getComments(payload.CourseId, payload.LearningObjectId)
        .then(response => commit('SET_COMMENTS', response.data))
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },
  
    like({commit}, arg){
      commit('SET_COMMENT_LIKE', { commentId: arg.commentId, like: arg.like });
      return CommentService.like(arg.commentId, arg.like, arg.learningObjectId, arg.enrollId)
        .then(response => {
          //this.dispatch('comment/getComments', { CourseId: arg.courseId, LearningObjectId: arg.learningObjectId } )
        })
        .catch(error => {
          commit('SET_COMMENT_LIKE', { commentId: arg.commentId, like: !arg.like });
          commit('toast/NEW', { type: 'error', message: error.message }, { root: true })
        });
    },
  
    comment({commit, state}, payload){
      return CommentService.comment(payload.comment, payload.courseId, payload.parentId, payload.learningObjectId, payload.enrollId)
        .then(response => {
          this.dispatch('comment/getComments', { CourseId: payload.courseId, LearningObjectId: payload.learningObjectId } );
        })
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
        
    },
    setShowReplyBoxId({commit}, id){
      commit('SET_SHOWREPLYBOXID', id);
    }
  },
  mutations: {
    SET_COMMENTS (state, data) {
      state.comments = data
    },
    SET_COMMENT_LIKE(state, data) {
      var setCommentLike = function(comments, data) {
        for (var i = 0; i < comments.length; i++) {
          if (comments[i].id == data.commentId) {
            comments[i].like = data.like;
            break;
          }
          
          if (comments[i].replies && comments[i].replies.length > 0) {
            setCommentLike(comments[i].replies, data);
          }
        }
      }
      setCommentLike(state.comments, data);
    },
    SET_SHOWREPLYBOXID(state, data) {
      state.showReplyBoxId = data
    }
  }
}
