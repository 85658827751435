function formatTimeSection(time, minSize = 2) {
  return time.toFixed(0).padStart(minSize, "0");
}

export function formatTimestamp(timestamp) {
  if (!timestamp) return timestamp;

  const hours = Math.floor(timestamp / 3600);
  const minutes = Math.floor((timestamp - hours * 3600) / 60);
  const seconds = timestamp - hours * 3600 - minutes * 60;

  if (hours)
    return (
      formatTimeSection(hours, 1) +
      ":" +
      formatTimeSection(minutes) +
      ":" +
      formatTimeSection(seconds)
    );

  return formatTimeSection(minutes, 1) + ":" + formatTimeSection(seconds);
}
