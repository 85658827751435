import axios from 'axios'

import { BaseService } from './base.service'
import { ResponseWrapper, ErrorWrapper } from './util'
import $store from '../store'
import $router from '../router'

import jwt_decode from 'jwt-decode';

export class AuthService extends BaseService {
  static async integrationLogin(integrationToken) {
    try {
      const response = await this.request().post(`${process.env.VUE_APP_EAD_API_URL}/Marketplace/auth`,
        {          
          GrantType: "integration_token",
          IntegrationToken: integrationToken
        });


      var exDate = new Date((new Date()).getTime() + process.env.VUE_APP_REFRESH_TOKEN_TIME); // add a data de expiração

      _setAuthData({
        accessToken: response.data.token,
        refreshToken: response.data.refreshToken,
        exp: (+(exDate))// data de expiração do token
      });

      this.startKeepAlive();

      return new ResponseWrapper({ status: 200, data: { success: true } });
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeLogin({ email, password }) {
    try {
      const response = await this.request().post(`${process.env.VUE_APP_EAD_API_URL}/Marketplace/auth`,
        {
          email: email,
          password: password,
          grantType: "password"
        });


      var exDate = new Date((new Date()).getTime() + process.env.VUE_APP_REFRESH_TOKEN_TIME); // add a data de expiração

      _setAuthData({
        accessToken: response.data.token,
        refreshToken: response.data.refreshToken,
        exp: (+(exDate))// data de expiração do token
      });

      this.startKeepAlive();

      return new ResponseWrapper({ status: 200, data: { success: true } });
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async makeLogout() {
    try {
      this.stopKeepAlive();

      const hasAccessToken = Boolean(AuthService.getAccessToken());
      const response = hasAccessToken
        ? await this.request({ auth: true }).post("Marketplace/auth/logout", {})
        : { data: {} };

      _resetAuthData();

      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async refreshTokens() {
    try {
      const response = await this.request().post(`${process.env.VUE_APP_EAD_API_URL}/Marketplace/auth`, {
        refreshToken: this.getRefreshToken(),
        grantType: 'refresh_token'
      })

      _setAuthData({
        accessToken: response.data.token,
        refreshToken: response.data.refreshToken
      })

      return new ResponseWrapper(response, response.data.token)
    } catch (error) {
      _resetAuthData()

      // limpa o intervalo
      $store.dispatch('notification/clearLoop')

      AuthService.clearToLogin()

      throw new ErrorWrapper(error)
    }
  }

  static clearToLogin() {
    _resetAuthData()
    this.stopKeepAlive()

    // limpa o intervalo
    $store.dispatch('notification/clearLoop')

    $router.push({ name: 'login' }).catch(() => { })
  }

  static async forgotPasswordRequest(payload) {
    try {
      const response = await this.request().post(
        `${process.env.VUE_APP_EAD_API_URL}/Marketplace/auth/forgot-password-request`,
        payload
      );

      return new ResponseWrapper({ status: 200, data: { success: true } }, { a: 1 }); //
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  };

  static async forgotPasswordConfirm(postData) {
    try {
      const response = await this.request().post(`${process.env.VUE_APP_EAD_API_URL}/Marketplace/auth/forgot-password-commit`, postData);

      _setAuthData({
        accessToken: response.data.token,
        refreshToken: response.data.refreshToken
      });

      return new ResponseWrapper({ status: 200, data: { success: true } }, { a: 1 }); //
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  };


  static async checkResetHash(hash) {
    try {
      const response = await this.request().get(`${process.env.VUE_APP_EAD_API_URL}/Marketplace/auth/validate-token/${hash}`);
      return new ResponseWrapper(response, response.data)
    } catch (error) {
      throw new ErrorWrapper(error)
    }
  };

  static async getAppIdCourseId() {
    try {
      const response = await this.request({ auth: false }).get(`${process.env.VUE_APP_EAD_API_URL}/courses/${window.location.host}`);

      return new ResponseWrapper(response, response.data)
    } catch (error) {
      //throw new ErrorWrapper(error)
      return new ResponseWrapper();
    }
  }

  /**
   ******************************
   * @METODOS
   ******************************
   */

  static hasRefreshToken() {
    return Boolean(localStorage.getItem('refreshToken'))
  }

  static getRefreshToken() {
    return localStorage.getItem('refreshToken');
  }

  static setRefreshToken(refreshToken) {
    localStorage.setItem('refreshToken', refreshToken)
  }

  static hasAccessToken() {
    return Boolean(sessionStorage.getItem('accessToken'));
  }

  static getAccessToken() {
    return sessionStorage.getItem('accessToken');
  }

  static setAccessToken(accessToken) {
    //ACCESSTOKEN = accessToken;
    sessionStorage.setItem('accessToken', accessToken);

    // seta a autorização
    axios.defaults.headers.common['authorization'] = `bearer ${accessToken}`;
  }

  static getUserData() {
    var accessToken = AuthService.getAccessToken();
    if (accessToken == '') {
      throw new ErrorWrapper('O usuário não esta autenticado!');
    }

    return jwt_decode(accessToken);
  }

  static _keepAlive;
  static _keepAliveInterval = 10 * 60 * 1000; //10 min
  //static _keepAliveInterval = 10  * 1000;

  static startKeepAlive() {
      var selfRef = this;
      this._keepAlive = setInterval(function () {
        if (selfRef.hasAccessToken()) {
          selfRef.requestKeepAlive();
        }
      }, this._keepAliveInterval);
  }

  static stopKeepAlive() {
    clearInterval(this._keepAlive);
  }

  static async requestKeepAlive() {
    await this.request({ auth: true }).post(`${process.env.VUE_APP_EAD_API_URL}/marketplace/keep-alive`);
  }
}

function _resetAuthData() {
  $store.commit('user/SET_CURRENT_USER', {})

  AuthService.setRefreshToken('')
  AuthService.setAccessToken('')
}

function _setAuthData({ accessToken, refreshToken, exp } = {}) {

  AuthService.setRefreshToken(refreshToken)
  AuthService.setAccessToken(accessToken)
}