<template>
  <div>
    <div class="video-container" id="player-banner">
      <div id="player"></div>

      <div class="overlayPlayer" @contextmenu.prevent></div>
      <transition name="fade">
        <div class="player-bg" v-show="!isPlaying">
          <img v-lazy="image" alt="" />
        </div>
      </transition>

      <div class="controls">
        <i
          @click="togglePlayPause"
          :class="isPlaying ? 'fa fa-pause' : 'fa fa-play'"
        ></i>
        <i
          @click="toggleMute"
          v-show="isPlaying"
          :class="isMuted ? 'fa fa-volume-off' : 'fa fa-volume-up'"
        ></i>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "YouTubePlayer",
  props: {
    videoID: {
      type: String,
      required: true,
    },
    image: {
      type: String,
      required: true,
    },
    primaryColor: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      player: null,
      isApiReady: false,
      isPlaying: false,
      isMuted: true,
    };
  },
  methods: {
    initializePlayer() {
      if (window.YT && window.YT.Player) {
        this.player = new YT.Player("player", {
          videoId: this.videoID,
          playerVars: {
            autoplay: 1,
            mute: 1,
            controls: 0,
            modestbranding: 1,
            rel: 0,
            showinfo: 1,
            loop: 1,
            playsinline: 1,
            playlist: this.videoID,
          },
          events: {
            onReady: this.onPlayerReady,
            onStateChange: this.onPlayerStateChange,
          },
        });
        this.isApiReady = true;
      }
    },
    onPlayerReady(event) {
      event.target.playVideo();
    },
    onPlayerStateChange(event) {
      if (event.data === YT.PlayerState.PLAYING) {
        this.isPlaying = true;
      } else {
        this.isPlaying = false;
      }
    },

    togglePlayPause() {
      if (this.player && this.isApiReady) {
        if (this.isPlaying) {
          this.player.pauseVideo();
        } else {
          this.player.playVideo();
        }
      }
    },

    toggleMute() {
      if (this.player && this.isApiReady) {
        if (this.isMuted) {
          this.player.unMute();
          this.isMuted = false;
        } else {
          this.player.mute();
          this.isMuted = true;
        }
      }
    },
    loadYouTubeApi() {
      if (!window.YT) {
        const tag = document.createElement("script");
        tag.src = "https://www.youtube.com/iframe_api";
        const firstScriptTag = document.getElementsByTagName("script")[0];
        firstScriptTag.parentNode.insertBefore(tag, firstScriptTag);

        window.onYouTubeIframeAPIReady = () => {
          this.initializePlayer();
        };
      } else {
        this.initializePlayer();
      }
    },
  },
  mounted() {
    this.loadYouTubeApi();
  },
};
</script>

<style>
#player-banner {
  top: -7vh;
  position: relative;
  width: 100%;
  height: 80vh;
}

#player-banner iframe {
  position: absolute !important;
  width: 100vw;
  height: 56vw;
  top: 0 !important;
  left: 50% !important;
  transform: translateX(-50%) !important;
  object-fit: cover;
}

.video-container {
  padding-bottom: 0 !important;
}

.overlayPlayer {
  position: absolute;
  bottom: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: transparent;
  z-index: 10;
  background: linear-gradient(
    180deg,
    rgba(4, 4, 54, 0) 75%,
    rgba(0, 0, 0, 1) 95%
  );
}

.controls {
  text-align: center;
  z-index: 99999;
  position: absolute;
  bottom: 8vh;
  float: right;
  width: fit-content;
  right: 3vh;
}

.controls i {
  margin: 0 10px;
  color: white;
}

.controls i:hover {
  color: var(--primary-color);
  transition: transform 0.3s ease, color 0.3s ease;
}

@media screen and (max-width: 992px) {
  #player-banner {
    top: -6vh;
    position: relative;
    width: 100%;
    height: 80vh;
  }

  #player-banner iframe {
    width: 200.78vh;
    height: 100%;
  }
  .player-bg img {
    height: 100%;
    object-fit: cover;
  }
}

button {
  padding: 10px 20px;
  margin-right: 10px;
  background-color: #ff0000;
  color: white;
  border: none;
  cursor: pointer;
}

button:hover {
  background-color: #cc0000;
}

.player-bg {
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}

.player-bg img {
  width: 100%;
}

.fade-enter-active,
.fade-leave-active {
  transition: opacity 0.5s ease-in-out;
}

.fade-enter,
.fade-leave-to {
  opacity: 0;
}
</style>
