<template>
  <div v-if="thisStudentId && thisCourseId">
    <div class="comment-sendmsg row" v-if="first">
      <!--TODO PEGAR O AVATAR DA PESSOA LOGADA-->
      <div class="comment-avatar left col s1" :style="{ background: $theme.primaryColor, color: $theme.primaryText }" >{{thisStudentName | initialsStudentName}}</div>
      <div class="comment-input left col s9 l11" :style="($theme.darkTheme ? '' : 'background-color: #efefef')">
        <div class="input-field">
          <textarea
            id="sendMsg"
            type="input"
            placeholder="Escreva um comentário"
            :style="($theme.darkTheme ? '' : 'color: #333333')"
            @input="setComment($event)"
            :value="newCommentText"
            @focus="showReply(0)"
          />
          <label class="label-icon" for="search"></label>
        </div>
        <div v-show="newCommentText" class="input-send right">
          <span :style="($theme.darkTheme ? '' : 'color: #333333')" @click.prevent="cancelComment()">Cancelar</span>
          <span :style="($theme.darkTheme ? '' : 'color: #333333')" @click.prevent="sendComment($event, null)">Enviar</span>
        </div>
      </div>
    </div>
    <div class v-for="comment in comments" :key="comment.id">      
      <div class="row">
        <div :class="[level == 0 ? '' : 'col s10 offset-s1']">
          <div
            :style="[level == 0 ? {background: $theme.primaryColor, color: $theme.primaryText} :'' ]"

            :class="[level == 0 ? 'comment-avatar left col s1' : 'comment-avatar-reply left col s1']"
            
          >{{ comment.studentName || comment.producerName | initialsStudentName }}</div>
          <div class="col s10">
            <div class="comment-name" :style="($theme.darkTheme ? '' : 'color: #333333')">
              {{ formatCommentName(comment) }}
              <!-- {{ comment.studentName || comment.producerName | clipStudentName }} -->
              <!--{{ parentStudentName ? '@' + parentStudentName : ''}}-->
            </div>
            <div class="comment-text" :style="($theme.darkTheme ? '' : 'color: #333333')">{{ comment.comment }}</div>
            <div class="comment-footer" >
              <div class="comment-like" >
                <a
                  :class="{ 'active': comment.like }"
                  :style="($theme.darkTheme ? '' : 'color: #7a7a7a')"
                  href
                  @click.prevent="likeComment(comment.id, !comment.like)"
                >Curtir</a>
              </div>
              <span>•</span>
              <div class="comment-reply">
                <a :style="($theme.darkTheme ? '' : 'color: #7a7a7a')" href @click.prevent="showReply(comment.id)">Responder</a>
              </div>
              <span>•</span>
              <div class="comment-sent-time" :style="($theme.darkTheme ? '' : 'color: #7a7a7a')">{{ comment.createAt | formatCommentDate }}</div>
            </div>
          </div>
        </div>
      </div>
      <div class="row" v-if="showReplyBoxId == comment.id">
        <div class="comment-sendReply col s10 offset-s1">
          <div
            class="comment-avatar-send-reply left col s1"
          >{{ thisStudentName | initialsStudentName }}</div>
          <div class="comment-input col s10">
            <div class="input-field left-align" id="inputSendReply">
              <textarea
                type="input"
                id="sendReply"
                placeholder="Responder"    
                @input="setCommentReply($event)"
                :value="replyCommentText"
                @keydown.enter.prevent="sendComment($event, comment.id)"
              />
            </div>
            <div v-show="replyCommentText" class="input-send right">
              <span @click.prevent="cancelComment()">Cancelar</span>
              <span @click.prevent="sendComment($event, comment.id)">Enviar</span>
            </div>
          </div>
        </div>
      </div>
      <CommentsBox
        v-if="comment.replies.length > 0"
        :comments="comment.replies"
        :level="Number.parseInt(level) + 1"
        :first="false"
        :studentId="thisStudentId"
        :studentName="thisStudentName"
        :courseId="thisCourseId"
        :learningObjectId="thisLearningObjectId"
        :parentStudentName="comment.studentName"
        :enrollId="thisEnrollId"
      />
    </div>
  </div>
</template>

<script>
import { mapState } from "vuex";

export default {
  name: "CommentBox",
  props: [
    "comments",
    "level",
    "first",
    "studentId",
    "studentName",
    "courseId",
    "learningObjectId",
    "parentStudentName",
    "enrollId"
  ],
  data() {
    return {
      newCommentText: "",
      replyCommentText: "",
      thisStudentId: this.studentId,
      thisStudentName: this.studentName,
      thisCourseId: this.courseId,
      thisLearningObjectId: this.learningObjectId,
      thisEnrollId : this.enrollId
    };
  },
  methods: {
    setComment(ev) {
      var element = ev.target;

      this.newCommentText = element.value;
      this.$nextTick(() => {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px";
      });
    },
    setCommentReply(ev) {
      var element = ev.target;

      this.replyCommentText = element.value;
      this.$nextTick(() => {
        element.style.height = "5px";
        element.style.height = (element.scrollHeight)+"px";
      });
    },
    sendComment(ev, parentId) {
      const commentText = parentId
        ? this.replyCommentText
        : this.newCommentText;

      var postData = {
        parentId: parentId,
        courseId: this.thisCourseId,
        enrollId : this.enrollId,
        learningObjectId: this.thisLearningObjectId,
        comment: commentText,
        enableTopicAutoPublishing: this.courseSettings.enableTopicAutoPublishing,
        enableAnswersAutoPublishing: this.courseSettings.enableAnswersAutoPublishing
      };

      this.$store.dispatch("comment/comment", postData);

      this.newCommentText = "";
      this.replyCommentText = "";
      this.$store.dispatch("comment/setShowReplyBoxId", 0);
    },
    cancelComment() {
      this.newCommentText = "";
      this.replyCommentText = "";
    },
    showReply(id) {
      this.$store.dispatch("comment/setShowReplyBoxId", id);
    },
    likeComment(id, like) {
      this.$store.dispatch("comment/like", {
        courseId: this.thisCourseId,
        enrollId : this.enrollId,
        learningObjectId: this.thisLearningObjectId,
        commentId: id,
        like: like
      });
    },
    formatCommentName(comment) {
      if(!comment) return '';

      if(comment.producerName) {
        return comment.producerName + ' (Produtor)'
      }
      else {
        const names = comment.studentName.split(" ");
        if (names.length == 1) {
          return names[0];
        } else if (names.length > 1) {
          return names[0] + " " + names[names.length-1].substr(0, 1) + ".";
        }
      }
    }
  },
  computed: {
    ...mapState({
      courseSettings: state => state.enroll.current.settings,
      showReplyBoxId: state => state.comment.showReplyBoxId
    }),
    currentStudentId() {
      if (!this.comments || this.comments.length == 0) return "";
      else return this.comments[0].studentId;
    },
  },
  filters: {
    formatCommentDate: function(commentDate) {
      if (!commentDate) return "";

      commentDate = new Date(commentDate);
      const now = new Date();
      const diffInSeconds = (now - commentDate) / 1000;

      const diffInYears = Math.floor(diffInSeconds / (60 * 60 * 24 * 365));
      if (diffInYears > 0) {
        return diffInYears == 1 ? diffInYears + " ano" : diffInYears + " anos";
      }

      const diffInMonths = Math.floor(diffInSeconds / (60 * 60 * 24 * 30));
      if (diffInMonths > 0) {
        return diffInMonths == 1
          ? diffInMonths + " mês"
          : diffInMonths + " meses";
      }

      const diffInDays = Math.floor(diffInSeconds / (60 * 60 * 24));
      if (diffInDays > 0) {
        return diffInDays == 1 ? diffInDays + " dia" : diffInDays + " dias";
      }

      const diffInHours = Math.floor(diffInSeconds / (60 * 60));
      if (diffInHours > 0) {
        return diffInHours == 1
          ? diffInHours + " hora"
          : diffInHours + " horas";
      }

      const diffInMinutes = Math.floor(diffInSeconds / 60);
      if (diffInMinutes > 5) {
        return diffInMinutes + " min";
      }

      return "agora";
    },
    clipStudentName: function(name) {
      if (!name) return "";

      const names = name.split(" ");
      if (names.length == 1) {
        return names[0];
      } else if (names.length > 1) {
        return names[0] + " " + names[1].substr(0, 1) + ".";
      } else {
        return "John D.";
      }
    },
    initialsStudentName: function(name) {
      if (!name) return "";

      return name.substr(0, 1);
    }
  }
};
</script>

<style scoped>
.comment-like a.active {
  color: #0564b1 !important;
}
</style>