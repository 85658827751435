<template>
  <modal
    :classes="
      hasCustomTheme
        ? $theme.darkTheme
          ? 'bg-color-modal-dark'
          : 'bg-color-modal-light'
        : 'bg-color-modal-light'
    "
    name="profile"
    height="auto"
    :width="calculatedWidth"
    :adaptive="true"
    :pivotX="1"
    :shiftX="1"
    :scrollable="true"
    @opened="load()"
    @before-close="closing()"
    transition="slide-left"
    overlayTransition

  >
    <HeaderPage pageName="Perfil" modalName="profile" />

    <main
      :class="
        hasCustomTheme
          ? $theme.darkTheme
            ? 'bg-color-modal-dark'
            : 'bg-color-modal-light'
          : 'bg-color-modal-light'
      "
    >
      <div id="user" class="container profile">
        <div
          class="item-title first"
          :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }"
        >
          Avatar
        </div>

        <div class="row item-description grey-text">
          Recomendamos um tamanho acima de 300px em .jpg ou .png.
        </div>

        <div
          :class="{
            'row user-photo': true,
            'user-photo--updating': profilePicUpdating
          }"
        >
          <!-- <template v-if="profilePicUpdating">
            <div class="preloader-wrapper small active">
              <div class="spinner-layer">
                <div class="circle-clipper left">
                  <div class="circle">
                    <img
                      class="photo-update"
                      src="@/assets/icons/change_user_img.svg"
                      alt
                      @click="updateUserImage()"
                    />
                  </div>
                </div>
                <div class="gap-patch">
                  <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                  <div class="circle"></div>
                </div>
              </div>
            </div>
          </template> -->

          <template>
            <img
              class="photo-update"
              src="@/assets/icons/change_user_img.svg"
              alt
              @click="updateUserImage()"
            />

            <img
              v-if="userLogoVisible"
              :src="$profilePic | imageHandler"
              class="photo-user"
              @click="updateUserImage()"
              @error="userLogoVisible = false"
              alt
            />
            <div
              v-else
              class="menu-right-icon"
              style="
                width: 150px;
                height: 150px;
                font-size: 180px;
                position: relative;
                left: -40px;
              "
            >
              {{ $firstLetter }}
            </div>
          </template>
        </div>

        <div
          class="item-title"
          :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }"
        >
          Dados pessoais
        </div>
        <div class="item-description grey-text">
          Esses dados são utilizados na identificação.
        </div>

        <div class="row">
          <form class="col s12 no-padding">
            <div class="input-field no-padding col s12">
              <input
                id="user-name"
                v-model="userProfile.name"
                type="text"
                class="validate"
                :style="$theme.darkTheme ? 'color: #ffffff' : 'color: #333333'"
              />
              <label class="no-margin active" for="user-name">Nome</label>
            </div>

            <div class="input-field no-padding col s12">
              <input
                id="email"
                type="email"
                v-model="userProfile.email"
                class=""
                readonly
                :style="$theme.darkTheme ? 'color: #ffffff' : 'color: #333333'"
              />
              <label class="no-margin active" for="email">E-mail</label>
            </div>

            <div class="input-field no-padding col s12">
              <input
                id="user-cpf"
                type="tel"
                v-model="userProfile.taxId"
                class="validate"
                v-mask="['###.###.###-##']"
                :style="$theme.darkTheme ? 'color: #ffffff' : 'color: #333333'"
              />
              <label class="no-margin active" for="user-cpf">CPF</label>
            </div>

            <div class="input-field no-padding col s12">
              <input
                id="user-datebirth"
                type="tel"
                v-model="userBirthDate"
                class="validate"
                v-mask="['##/##/####']"
                :style="$theme.darkTheme ? 'color: #ffffff; border-bottom: 1px solid #9e9e9e;' : 'color: #333333; border-bottom: 1px solid #9e9e9e;'"
              />
              <label class="no-margin active" for="user-datebirth"
                >Data de Nascimento</label
              >
            </div>

            <div class="input-field no-padding col s2">
              <input
                id="user-cel-ddi"
                type="tel"
                class="validate"
                v-model="ddi"
                v-mask="['+#', '+##', '+###']"
                :style="$theme.darkTheme ? 'color: #ffffff' : 'color: #333333'"/>
                <label class="no-margin active" for="user-cel-ddi">Celular</label>
            </div>
            <div class="input-field no-padding col s1">
            </div>
            <div class="input-field no-padding col s9">
              <input
                id="user-cel"
                type="tel"
                class="validate"
                v-model="userMobilePhone"
                v-mask="['## ####-####', '## #####-####']"
                :style="$theme.darkTheme ? 'color: #ffffff' : 'color: #333333'"
              />
            </div>
            <div class="row"></div>
            <div class="row"></div>
            <!-- <img
              class="btn-profile-img"
              src="@/assets/images/btn-salvar.png"
              alt
              @click.prevent="saveProfile"
            /> -->
            <a
              class="waves-effect btn-profile"
              @click.prevent="saveProfile"
              :style="
                !hasCustomTheme
                  ? '#0564b1'
                  : {
                      background: $theme.primaryColor,
                      color: $theme.primaryText,
                    }
              "
              >SALVAR</a
            >
          </form>
        </div>

        <div
          class="row item-title"
          :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }"
        >
          Senha de acesso
        </div>
        <div class="item-description grey-text">
          Para alterar sua senha, confirme sua senha atual e insira
        </div>

        <div class="row">
          <form class="col s12 no-padding">
            <div class="input-field no-padding col s12">
              <input
                id="old-password"
                type="password"
                class="validate"
                v-model="changePassword.current"
                :style="$theme.darkTheme ? 'color: #ffffff' : 'color: #333333'"
              />
              <label class="no-margin active" for="old-password"
                >Senha atual</label
              >
            </div>

            <div class="input-field no-padding col s12">
              <input
                id="new-password"
                type="password"
                class="validate"
                v-model="changePassword.new"
                :style="$theme.darkTheme ? 'color: #ffffff' : 'color: #333333'"
              />
              <label class="no-margin active" for="new-password"
                >Nova senha</label
              >
            </div>

            <div class="input-field no-padding col s12">
              <input
                id="confirm-password"
                type="password"
                class="validate"
                v-model="changePassword.confirm"
                :style="$theme.darkTheme ? 'color: #ffffff' : 'color: #333333'"
              />
              <label class="no-margin active" for="confirm-password"
                >Confirmar nova senha</label
              >
            </div>
            <div class="row"></div>
            <div class="row"></div>

            <!-- <img
              src="@/assets/images/btn-salvar-senha.png"
              class="btn-profile-img"
              alt
              @click.prevent="alterarSenha"
            /> -->

            <a
              href
              class="waves-effect btn-profile"
              @click.prevent="alterarSenha"
              :style="
                !hasCustomTheme
                  ? '#0564b1'
                  : {
                      background: $theme.primaryColor,
                      color: $theme.primaryText,
                    }
              "
              >SALVAR SENHA</a
            >
            <div class="row"></div>
          </form>
        </div>
      </div>
    </main>

    <CropperModal
      name="Imagem de perfil"
      ref="profileCropper"
      round
      :width="150"
      :height="150"
      :image="$profilePic"
      :colors="cropperModalColors"
      @cropped="uploadProfileImage"
      @cancel="cancelProfileImage"
    />
  </modal>
</template>

<script>
import HeaderPage from "@/layout/HeaderPage";
import { mapState, mapGetters } from "vuex";
import { resizeImage } from "@/services/util";
import * as VDUploader from "@voxel/vd-uploader";
import { isMobile, isIOS } from "mobile-device-detect";
import CropperModal from "@/components/CropperModal.vue";

export default {
  name: "Profile",
  props: ["layoutTheme"],
  components: {
    HeaderPage,
    CropperModal
  },
  data() {
    return {
      userProfile: {},
      userLogoVisible: true,
      profilePicUpdating: false,
      ddi: '',
      userMobilePhone: '',
      userBirthDate: '',
      phoneIndex: -1,
      changePassword: {
        current: "",
        new: "",
        confirm: "",
      },
    };
  },
  watch: {
    $profilePic() {
      this.userLogoVisible = true;
    },
  },
  methods: {
    load() {
      this.userProfile = this.currentProfile;

      var mobilePhone = this.currentProfile.phones.find(
        (p) => p.type == "Mobile"
      );
      if (mobilePhone) {
        this.ddi = String(mobilePhone.ddi);
        this.userMobilePhone = `${mobilePhone.area} ${mobilePhone.number}`;
        this.phoneIndex = this.currentProfile.phones
          .map((p) => p.type)
          .indexOf("Mobile");
      } else {
        this.userMobilePhone = "";
        this.ddi = '55';
      }

      if (this.userProfile.birthDate) {
        this.userBirthDate = new Date(this.userProfile.birthDate).toLocaleDateString('pt-BR');
      }

      if (this.userProfile.taxId) {
        this.userProfile.taxId = this.pad(this.userProfile.taxId, 11, "0");
      }

      if(isIOS) {
        this.iosModalHack(true);
      }
    },

    closing() {
      if(isIOS) {
        this.iosModalHack(false);
      }
    },

    iosModalHack(fixedBody) {
      document.querySelector('body').style.position = fixedBody ? 'fixed' : '';
    },

    pad(strToReplace, width, fill) {
      fill = fill || "0";
      strToReplace = strToReplace + "";
      return strToReplace.length >= width
        ? strToReplace
        : new Array(width - strToReplace.length + 1).join(fill) + strToReplace;
    },

    updateUserImage() {
      var input = document.createElement("input");
      input.type = "file";
      document.body.append(input);

      input.addEventListener("change", (e) => {
        // se não selecionou imagem
        if (!e.target.files.length) return;

        this.profilePicUpdating = true;
        var file = e.target.files[0]; // pega a primeira

        const url = URL.createObjectURL(file)

        this.$refs.profileCropper?.open({ image: url })
      });

      document.body.removeChild(input)
      input.click();
    },
    async uploadProfileImage(ev) {
      this.uploader.event.add(
        VDUploader.E.OnProgress,
        function(e, progress, total) {
          // console.log('ProgressEvent', progress, total);
        },
        this
      );

      const profileImgSize = 150;
      const imgType = "image/jpeg";

      try {
        // blobify image and resizes it
        ev.canvas.toBlob(async (blob) => {
          const file = new File([blob], this.profileImageName, { type: imgType });
          const resizedImage = await resizeImage({
            file: file,
            maxSize: profileImgSize
          });

          const resizedFile = new File([resizedImage], this.profileImageName);

          await this.uploader.send(resizedFile, {
            customName: this.profileImageName,
            cacheClear: true,
          });

          // creates img local url and updates users profile pic
          const imageURL = URL.createObjectURL(blob)
          this.$store.commit("user/SET_CURRENT_USER_PIC", imageURL);
          this.profilePicUpdating = false;
          this.userLogoVisible;
          // já coloca a imagem no html
        }, imgType)
      } catch (err) {
        this.$store.commit("toast/NEW", {
          message: "Erro ao converter a imagem",
          type: "error",
        });
        this.profilePicUpdating = false;
      }
    },
    cancelProfileImage(e) {
      this.profilePicUpdating = false;
    },
    validateEmail(email) {
      const re = /^(([^<>()[\]\\.,;:\s@\"]+(\.[^<>()[\]\\.,;:\s@\"]+)*)|(\".+\"))@((\[[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\.[0-9]{1,3}\])|(([a-zA-Z\-0-9]+\.)+[a-zA-Z]{2,}))$/;
      return re.test(email);
    },

    validateCPF(cpf) {
      if (!cpf || typeof cpf.replace !== "function") return false;

      cpf = cpf.replace(/[^\d]+/g, "");
      if (cpf == "") return false;
      // Elimina CPFs invalidos conhecidos
      if (
        cpf.length != 11 ||
        cpf == "00000000000" ||
        cpf == "11111111111" ||
        cpf == "22222222222" ||
        cpf == "33333333333" ||
        cpf == "44444444444" ||
        cpf == "55555555555" ||
        cpf == "66666666666" ||
        cpf == "77777777777" ||
        cpf == "88888888888" ||
        cpf == "99999999999"
      )
        return false;
      // Valida 1o digito
      var add = 0;
      for (var i = 0; i < 9; i++) add += parseInt(cpf.charAt(i)) * (10 - i);
      var rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(9))) return false;
      // Valida 2o digito
      add = 0;
      for (var i = 0; i < 10; i++) add += parseInt(cpf.charAt(i)) * (11 - i);
      rev = 11 - (add % 11);
      if (rev == 10 || rev == 11) rev = 0;
      if (rev != parseInt(cpf.charAt(10))) return false;
      return true;
    },

    validateDate(dateString) {
      if(!/^\d{1,2}\/\d{1,2}\/\d{4}$/.test(dateString))
          return false;

      // Parse the date parts to integers
      var parts = dateString.split("/");
      var day = parseInt(parts[0], 10);
      var month = parseInt(parts[1], 10);
      var year = parseInt(parts[2], 10);

      // Check the ranges of month and year
      if (year < 1000 || year > 3000 || month == 0 || month > 12) return false;

      var monthLength = [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31];

      // Adjust for leap years
      if (year % 400 == 0 || (year % 100 != 0 && year % 4 == 0))
        monthLength[1] = 29;

      // Check the range of the day
      if (day < 0 || day > monthLength[month - 1]) {
        return false;
      }

      var aux = new Date(year, month - 1, day);
      return +new Date() > +aux;
    },

    validatePhone(phone) {
      if(phone.length <= 7 || phone.length > 13) {
        return false;
      }

      return true;
    },

    validateDdi(ddi) {
      if(ddi.length < 2) {
        return false;
      }
      return true;
    },

    setElementValid(elementId, valid) {
      var element = document.getElementById(elementId);
      element.className = valid === true ? 'validation valid' : 'validation invalid';
    },

    showProfileFieldsErrors(messages) {
      for(var i = 0; i < messages.length; i++) {
        this.$store.commit("toast/NEW", {
          message: messages[i],
          type: "error",
        });
      }
    },

    validateProfileFields() {
      let formValid = true;
      let elementToFocus = '';
      let errorMessages = [];

      if (!this.validatePhone(this.userMobilePhone)) {
        errorMessages.push("Telefone inválido");
        this.setElementValid('user-cel', false);
        elementToFocus = 'user-cel';
        formValid = false;
      }
      else {
        this.setElementValid('user-cel', true);
      }

      if (!this.validateDdi(String(this.ddi).trim())) {
        errorMessages.push("DDI inválido");
        this.setElementValid('user-cel-ddi', false);
        elementToFocus = 'user-cel-ddi';
        formValid = false;
      }
      else {
        this.setElementValid('user-cel-ddi', true);
      }

      if (!this.validateDate(this.userBirthDate)) {
        errorMessages.push("Data de nascimento inválida");
        this.setElementValid('user-datebirth', false);
        elementToFocus = 'user-datebirth';
        formValid = false;
      }
      else {
        this.setElementValid('user-datebirth', true);
      }

      if (!this.validateCPF(this.userProfile.taxId)) {
        errorMessages.push("CPF inválido");
        this.setElementValid('user-cpf', false);
        elementToFocus = 'user-cpf';
        formValid = false;
      }
      else {
        this.setElementValid('user-cpf', true);
      }

      if (!this.validateEmail(this.userProfile.email)) {
        errorMessages.push("E-mail obrigatório");
        this.setElementValid('email', false);
        elementToFocus = 'email';
        formValid = false;
      }
      else {
        this.setElementValid('email', true);
      }

      if (this.userProfile.name.length == 0) {
        errorMessages.push("Nome obrigatório");
        this.setElementValid('user-name', false);
        elementToFocus = 'user-name';
        formValid = false;
      }
      else {
        this.setElementValid('user-name', true);
      }

      if(errorMessages.length > 0) {

        if(isIOS) {
          document.querySelector('.vm--modal').scrollTop = document.getElementById(elementToFocus).offsetTop;
        }

        document.getElementById(elementToFocus).focus();
        document.getElementById(elementToFocus).select();

        this.showProfileFieldsErrors(errorMessages.reverse());
      }

      return formValid;
    },

    async saveProfile() {
      if (!this.validateProfileFields()) return;

      var profilePayload = _.cloneDeep(this.userProfile);

      var arrDate = this.userBirthDate.match(/\d+/g);

      var day = +arrDate[0];
      var month = +arrDate[1];
      var year = +arrDate[2];

      var timezone = new Date().getTimezoneOffset() / 60;

      if (month < 10) month = "0" + month;
      if (day < 10) day = "0" + day;
      if (Math.abs(timezone) < 10) timezone = "0" + timezone;

      profilePayload.birthDate = year + "-" + month + "-" + day + "T10:00:00Z";
      var phoneArray = this.userMobilePhone.split(' ');

      const phoneDdi = (this.ddi.indexOf('+') > -1) ? parseInt(this.ddi.substr(1, this.ddi.length - 1)) : parseInt(this.ddi);

      const phoneArea = parseInt(phoneArray[0]);
      const phoneNumber = phoneArray[1];

      profilePayload.taxId = parseInt(
        profilePayload.taxId.match(/\d+/g).join("")
      );

      if (this.phoneIndex > -1) {
        profilePayload.phones[this.phoneIndex].ddi = phoneDdi;
        profilePayload.phones[this.phoneIndex].area = phoneArea;
        profilePayload.phones[this.phoneIndex].number = phoneNumber;
      } else {
        profilePayload.phones.push({
          id: 0,
          ddi: phoneDdi,
          area: phoneArea,
          number: phoneNumber,
          type: "Mobile",
        });
      }

      await this.$store
        .dispatch("user/updateProfile", profilePayload)
        .then((ok) => {
          if (ok) {
            this.$store.commit("toast/NEW", {
              type: "success",
              message: "Perfil salvo com sucesso!",
            });
          } else {
            this.$store.commit("toast/NEW", {
              type: "error",
              message: "Ocorreu um erro ao salvar o perfil. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.",
            });
          }
        });
    },

    validateChangePasswordFields() {
      let changePasswordValid = true;
      let elementToFocus = '';
      let errorMessages = [];

      if (this.changePassword.new != this.changePassword.confirm) {
        errorMessages.push("Nova senha não confere com a confirmação");
        elementToFocus = 'confirm-password';
        this.setElementValid('confirm-password', false);
        changePasswordValid = false;
      }
      else {
        this.setElementValid('confirm-password', true);
      }

      if (this.changePassword.confirm.length == 0) {
        errorMessages.push("A confirmação da nova senha é obrigatória");
        elementToFocus = 'confirm-password';
        this.setElementValid('confirm-password', false);
        changePasswordValid = false;
      }
      else {
        this.setElementValid('confirm-password', true);
      }

      if (this.changePassword.new.length < 6) {
        errorMessages.push("A nova senha deve conter, no mínimo, 6 caracteres");
        elementToFocus = 'new-password';
        this.setElementValid('new-password', false);
        changePasswordValid = false;
      }
      else {
        this.setElementValid('new-password', true);
      }

      if (this.changePassword.new.length == 0) {
        errorMessages.push("A nova senha é obrigatória");
        elementToFocus = 'new-password';
        this.setElementValid('new-password', false);
        changePasswordValid = false;
      }
      else {
        this.setElementValid('new-password', true);
      }

      if (this.changePassword.current.length == 0) {
        errorMessages.push("A senha atual é obrigatória");
        elementToFocus = 'old-password';
        this.setElementValid('old-password', false);
        changePasswordValid = false;
      }
      else {
        this.setElementValid('old-password', true);
      }

      if(errorMessages.length > 0) {
        document.getElementById(elementToFocus).focus();
        document.getElementById(elementToFocus).select();
        this.showProfileFieldsErrors(errorMessages.reverse());
      }

      return changePasswordValid;
    },

    async alterarSenha() {
      if (!this.validateChangePasswordFields()) return;

      this.$store
        .dispatch("user/changePassword", {
          email: this.userProfile.email,
          current: this.changePassword.current,
          new: this.changePassword.new,
        })
        .then((ok) => {
          if (ok) {
            this.changePassword.current = "";
            this.changePassword.new = "";
            this.changePassword.confirm = "";

            this.$store.commit("toast/NEW", {
              type: "success",
              message: "Senha alterada com sucesso!",
            });
          }
        });
    },
  },
  mounted() {},
  computed: {
    ...mapGetters({
      currentProfile: "user/currentProfile",
    }),
    ...mapState({
      selectedEnroll: (state) => state.enroll.current,
    }),

    profilePic() {
      return this.userProfilePic;
    },

    profileImageName() {
      // image name
      var imageName = this.$store.state.user.currentUser.email;
      imageName = imageName.split("@").join("_").split(".").join("_") + ".jpg"; // filename
      imageName = "marketplace/users/" + imageName;

      return imageName;
    },
    calculatedWidth() {
      if (isMobile) return "100%";
      else return "20%";
    },

    hasCustomTheme() {
      const isTrustflix = this.$route.name === "trustflix";
      return this.selectedEnroll || isTrustflix;
    },
    cropperModalColors() {
      return { primary: !this.hasCustomTheme ? "#0564b1" : this.$theme.primaryColor };
    }
  },
  filters: {
    imageHandler(url) {
      const isStored = url.startsWith('https://');

      return isStored ? url + '?h=150' : url;
    }
  }
};
</script>
<style scoped>
.container.profile input:not(.browser-default)[readonly="readonly"] + label {
  color: #9e9e9e;
}
.container.profile input:not(.browser-default)[readonly="readonly"] {
  border-bottom: 1px solid #9e9e9e;
}

@keyframes rotate {
  from {
    transform: rotate(0);
  }
  to {
    transform: rotate(180deg);
  }
}

.user-photo--updating {
  pointer-events: none;
}

.user-photo--updating .photo-update {
  animation: rotate 1s cubic-bezier(0.165, 0.84, 0.44, 1) infinite;
}
</style>
