var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('div',{class:{
  container: true,
  'page-main-content': true,
  'page-main-content-compressed': _vm.currentEnroll && _vm.isCompressed(),
}},[_c('content-placeholders',[_c('content-placeholders-img',{staticClass:"prev-next-placeholder",class:_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light'}),_c('content-placeholders-img',{staticClass:"video-placeholder",class:_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light'}),_c('content-placeholders-img',{staticClass:"video-info-placeholder",class:_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light'}),_c('content-placeholders-text',{staticClass:"class-title-placeholder",class:_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light',attrs:{"lines":2}}),_c('content-placeholders-img',{staticClass:"accordion-image-placeholder",class:_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light'})],1)],1):_c('div',{class:{
  'page-main-content': true,
  'page-main-content-compressed': _vm.isCompressed(),
},style:([
  _vm.$theme.darkTheme
    ? 'border-left-color: #0d0d13'
    : 'background-color: #f2f3f7; border-left-color: #F2F3F7',
  {
    '--primary-color': _vm.$theme.primaryColor,
    '--primary-text': _vm.$theme.primaryText,
  } ])},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.inFullscreen &&
      (_vm.currentLearningObject.previousId || _vm.currentLearningObject.nextId)
      ),expression:"!inFullscreen &&\n      (currentLearningObject.previousId || currentLearningObject.nextId)\n      "}],staticClass:"box-menu-navigation",style:(_vm.$theme.darkTheme ? '' : 'background-color: #ffffff; color: #ff6600')},[_c('div',{staticClass:"container box-menu-center"},[_c('div',{staticClass:"menu-previous",style:(_vm.currentLearningObject.previousId
        ? 'cursor:pointer;'
        : 'cursor:default'),on:{"click":_vm.navigatePreviousClass}},[(_vm.currentLearningObject.previousId)?[_c('IconPrevious'),_c('span',{style:(_vm.$theme.darkTheme ? '' : 'color: #333333')},[_vm._v("Anterior")])]:_vm._e()],2),_c('div',{staticClass:"menu-next",style:(_vm.currentLearningObject.nextId ? 'cursor:pointer;' : 'cursor:default'),on:{"click":function($event){return _vm.navigateNextClass(false)}}},[(_vm.currentLearningObject.nextId)?[_c('span',{style:(_vm.$theme.darkTheme ? '' : 'color: #333333')},[_vm._v("Próximo")]),_c('IconNext')]:_vm._e()],2)])]),(!_vm.checkCurrentLearningObjectState)?_c('div',{staticClass:"quiz-result container"},[_vm._m(0)]):_vm._e(),_c('div',{staticClass:"container info-title"},[_c('div',{staticClass:"content-name",style:(_vm.$theme.darkTheme ? '' : 'color: #333333')},[_vm._v(" "+_vm._s(_vm.currentLearningObject.title)+" ")]),_c('div',{staticClass:"master-name line-clamp-3",style:(_vm.$theme.darkTheme ? '' : 'color: #333333')},[_vm._v(" "+_vm._s(_vm.currentLearningObject.category)+" ")])]),(_vm.checkCurrentLearningObjectState && _vm.currentLearningObject.broadcast)?_c('div',{staticClass:"player2 container"},[_c('coremedia-player',{attrs:{"mute":_vm.devMute,"customerId":_vm.currentEnroll.webcastIntegration.applicationId,"customerToken":_vm.currentEnroll.webcastIntegration.accessToken,"learningObject":_vm.currentLearningObject,"studentEmail":_vm.currentUser.studentMail},on:{"ended":_vm.handleEnded,"ready":function($event){return _vm.coremediaReady($event)}}})],1):_vm._e(),(_vm.checkCurrentLearningObjectState && _vm.currentLearningObject.pdf)?_c('div',{staticClass:"container"},[_c('pdf-viewer',{attrs:{"src":_vm.getPDFUrl()}})],1):_vm._e(),(_vm.checkCurrentLearningObjectState && _vm.currentLearningObject.forms)?_c('div',{},[_c('Quiz',{attrs:{"formId":_vm.currentLearningObject.forms.id}})],1):_vm._e(),(
    _vm.checkCurrentLearningObjectState && _vm.currentLearningObject.externalContent
  )?_c('div',{staticClass:"container"},[_c('div',{staticClass:"row"},[_c('iframe',{staticClass:"col s12 m12 l12",staticStyle:{"min-height":"554px"},attrs:{"src":'//' + _vm.currentLearningObject.externalContent.link,"frameborder":"0"}})])]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.checkCurrentLearningObjectState &&
    !_vm.inFullscreen &&
    !_vm.currentLearningObject.forms
    ),expression:"checkCurrentLearningObjectState &&\n    !inFullscreen &&\n    !currentLearningObject.forms\n    "}],staticClass:"box-info container",style:(_vm.$theme.darkTheme ? '' : 'background-color: #f2f3f7')},[(
      _vm.checkCurrentLearningObjectState && _vm.currentLearningObject.htmlContent
    )?_c('div',{staticClass:"html-content",style:(_vm.$theme.darkTheme ? '' : 'color: #333333'),domProps:{"innerHTML":_vm._s(_vm.currentLearningObject.htmlContent.content)}}):_vm._e(),_c('div',{staticClass:"info-content"},[_c('div',{staticClass:"content-flex"},[_c('div',{staticClass:"content-rating"},[_c('div',{staticClass:"rating-love"},_vm._l((5),function(i){return _c('i',{key:i,class:_vm.getRatingsClass(i),on:{"mouseover":function($event){_vm.ratingsHover = i},"mouseleave":function($event){_vm.ratingsHover = null},"click":function($event){return _vm.setLearningObjectRating(i)}}})}),0)]),_c('div',{staticClass:"master-status"},[_c('label',[_c('input',{staticClass:"filled-in",attrs:{"type":"checkbox","id":"btnStatus"},domProps:{"checked":_vm.currentLearningObject.progress == 100},on:{"click":_vm.setProgress}}),_c('span',{style:(_vm.$theme.darkTheme ? '' : 'color: #333333')},[_vm._v(_vm._s(_vm.currentLearningObject.progress == 100 ? "Concluído" : "Marcar como concluído")+" ")])])])]),_c('div',{staticClass:"content-flex"},[(
            _vm.currentLearningObject.files && _vm.currentLearningObject.files.length
          )?_c('div',{staticClass:"content-downloads dropdown-trigger",style:(_vm.$theme.darkTheme ? '' : 'color: #333333'),attrs:{"id":"ddx","data-target":"dropdown-materiais"}},[_c('IconDownload'),_c('p',[_vm._v("Arquivos")])],1):_vm._e(),(
          _vm.currentLearningObject.files && _vm.currentLearningObject.files.length
        )?_c('ul',{staticClass:"dropdown-content",attrs:{"id":"dropdown-materiais"}},_vm._l((_vm.currentLearningObject.files),function(file,fileIndex){return _c('li',{key:file.id},[_c('a',{style:(_vm.$theme.darkTheme ? '' : 'background-color: #f2f3f7'),attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.downloadFile(
                file,
                fileIndex,
                _vm.currentLearningObject,
                $event.target
              )}}},[(!file.loadingItem && !file.generatedItemLink)?_c('span',[_vm._v("["+_vm._s(file.fileType.toUpperCase())+"] "+_vm._s(file.name)+" ("+_vm._s(_vm._f("bytes")(file.size))+")")]):_vm._e(),(file.loadingItem)?_c('span',{staticClass:"spinner-small"},[_c('SpinnerWave')],1):_vm._e(),(file.generatedItemLink)?_c('span',[_c('a',{attrs:{"href":file.generatedItemLink,"target":"_blank"}},[_vm._v("Clique aqui para abrir ["+_vm._s(file.name)+"]")])]):_vm._e()])])}),0):_vm._e(),_c('NavPrevBt',{attrs:{"hasPreview":"","visible":Boolean(_vm.currentLearningObject.previousId),"onClick":_vm.navigatePreviousClass,"previewImage":_vm.currentLearningObject.previousImage,"previewTitle":_vm.currentLearningObject.previousName}}),_c('NavNextBt',{attrs:{"hasPreview":"","visible":Boolean(_vm.currentLearningObject.nextId),"onClick":_vm.navigateNextClass,"previewImage":_vm.currentLearningObject.nextImage,"previewTitle":_vm.currentLearningObject.nextName}})],1)]),_c('div',{class:{
      'info-details': true,
    }},[_c('div',{staticClass:"btn-info"},[(!_vm.currentLearningObject.htmlContent)?_c('button',{staticClass:"tablink",class:{ active: _vm.tabIndex == 1 },style:(_vm.tabIndex == 1
          ? {
            background: _vm.$theme.primaryColor,
            color: _vm.$theme.primaryText,
          }
          : _vm.$theme.darkTheme
            ? {
              color: '#ffffff',
            }
            : {
              color: '#ff6600',
            }),attrs:{"id":"defaultOpen"},on:{"mouseover":_vm.changeColor,"mouseleave":function($event){return _vm.changeColorToOriginal({ $event: $event })},"click":function($event){return _vm.setActive(1, { $event: $event })}}},[_vm._v(" Informações ")]):_vm._e(),(_vm.enableComments)?_c('button',{staticClass:"tablink",class:{ active: _vm.tabIndex == 2 },style:(_vm.tabIndex == 2
          ? {
            background: _vm.$theme.primaryColor,
            color: _vm.$theme.primaryText,
          }
          : _vm.$theme.darkTheme
            ? {
              color: '#ffffff',
            }
            : {
              color: '#131318',
            }),on:{"mouseover":_vm.changeColor,"mouseleave":function($event){return _vm.changeColorToOriginal({ $event: $event })},"click":function($event){return _vm.setActive(2, { $event: $event })}}},[_vm._v(" Comentários ")]):_vm._e(),(_vm.currentLearningObject.broadcast)?_c('button',{staticClass:"tablink",class:{ active: _vm.tabIndex == 3 },style:(_vm.tabIndex == 3
            ? {
              background: _vm.$theme.primaryColor,
              color: _vm.$theme.primaryText,
            }
            : _vm.$theme.darkTheme
              ? {
                color: '#ffffff',
              }
              : {
                color: '#131318',
              }),attrs:{"id":"btn-ia"},on:{"mouseover":_vm.changeColor,"mouseleave":function($event){return _vm.changeColorToOriginal({ $event: $event })},"click":function($event){return _vm.setActive(3, { $event: $event })}}},[_c('IconAI'),_vm._v(" Inteligência Artificial ")],1):_vm._e()]),(!_vm.currentLearningObject.htmlContent)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex === 1),expression:"tabIndex === 1"}],staticClass:"tabcontent",style:(_vm.$theme.darkTheme ? '' : 'background-color: #f2f3f7'),attrs:{"id":"Informacoes"}},[_c('div',{staticClass:"tabcontent-text",style:(_vm.$theme.darkTheme ? '' : 'color: #333333 !important'),domProps:{"innerHTML":_vm._s(_vm.currentLearningObject.description)}})]):_vm._e(),(_vm.enableComments)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex === 2),expression:"tabIndex === 2"}],staticClass:"tabcontent",style:(_vm.$theme.darkTheme ? '' : 'background-color: #f2f3f7'),attrs:{"id":"Comentarios"}},[_c('div',{staticClass:"tabcontent-text",style:(_vm.$theme.darkTheme ? '' : 'color: #333333 !important')},[_c('CommentsBox',{attrs:{"level":"0","first":"true","comments":this.currentLearningObjectComents,"studentId":_vm.currentUser.studentId,"studentName":_vm.currentUser.studentName,"showReplyBoxId":_vm.showReplyBoxId,"courseId":_vm.currentEnroll.courseId,"learningObjectId":_vm.currentLearningObject.id,"enrollId":this.currentEnroll.id}})],1)]):_vm._e(),(_vm.currentLearningObject.broadcast)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.tabIndex === 3),expression:"tabIndex === 3"}],staticClass:"tabcontent sub-tabcontent",class:{
          'no-transcript':
            !_vm.currentLearningObject.broadcast || !_vm.transcriptionsVisible,
        },style:(_vm.$theme.darkTheme ? '' : 'background-color: #f2f3f7'),attrs:{"id":"IA"}},[(_vm.currentLearningObject.broadcast && _vm.transcriptionsVisible)?_c('button',{staticClass:"sub-tablink",class:{ active: _vm.tabIndex == 3 && _vm.subtabIndex == 4 },style:(_vm.subtabIndex == 4
            ? {
              background: _vm.$theme.primaryColor,
              color: _vm.$theme.primaryText,
              borderTopColor: _vm.$theme.primaryColor,
            }
            : _vm.$theme.darkTheme
              ? {
                color: '#ffffff',
                borderBottomColor: _vm.$theme.primaryColor,
                borderTopColor: _vm.$theme.primaryColor,
              }
              : {
                color: '#131318',
                borderBottomColor: _vm.$theme.primaryColor,
                borderTopColor: _vm.$theme.primaryColor,
              }),attrs:{"id":_vm.transcriptionsVisible ? 'defaultOpen' : ''},on:{"mouseover":_vm.changeColor,"mouseleave":function($event){return _vm.changeColorToOriginal({ $event: $event })},"click":function($event){return _vm.subSetActive(4, { $event: $event })}}},[_vm._v(" Transcrição ")]):_vm._e(),_c('button',{staticClass:"sub-tablink",class:{ active: _vm.tabIndex == 3 && _vm.subtabIndex == 5 },style:(_vm.subtabIndex == 5
            ? {
              background: _vm.$theme.primaryColor,
              color: _vm.$theme.primaryText,
              borderTopColor: _vm.$theme.primaryColor,
            }
            : _vm.$theme.darkTheme
              ? {
                color: '#ffffff',
                borderTopColor: _vm.$theme.primaryColor,
              }
              : {
                color: '#131318',
                borderTopColor: _vm.$theme.primaryColor,
              }),attrs:{"id":_vm.transcriptionsVisible ? '' : 'defaultOpen'},on:{"mouseover":_vm.changeColor,"mouseleave":function($event){return _vm.changeColorToOriginal({ $event: $event })},"click":function($event){return _vm.subSetActive(5, { $event: $event })}}},[_vm._v(" Resumo ")]),_c('button',{staticClass:"sub-tablink",class:{ active: _vm.tabIndex == 3 && _vm.subtabIndex == 6 },style:(_vm.subtabIndex == 6
          ? {
            background: _vm.$theme.primaryColor,
            color: _vm.$theme.primaryText,
            borderTopColor: _vm.$theme.primaryColor,
          }
          : _vm.$theme.darkTheme
            ? {
              color: '#ffffff',
              borderTopColor: _vm.$theme.primaryColor,
            }
            : {
              color: '#131318',
              borderTopColor: _vm.$theme.primaryColor,
            }),attrs:{"id":"btn-mapa"},on:{"mouseover":_vm.changeColor,"mouseleave":function($event){return _vm.changeColorToOriginal({ $event: $event })},"click":function($event){return _vm.subSetActive(6, { $event: $event })}}},[_vm._v(" Mapa Mental ")]),_c('button',{staticClass:"sub-tablink",class:{ active: _vm.tabIndex == 3 && _vm.subtabIndex == 7 },style:(_vm.subtabIndex == 7
          ? {
            background: _vm.$theme.primaryColor,
            color: _vm.$theme.primaryText,
            borderTopColor: _vm.$theme.primaryColor,
          }
          : _vm.$theme.darkTheme
            ? {
              color: '#ffffff',
              borderTopColor: _vm.$theme.primaryColor,
            }
            : {
              color: '#131318',
              borderTopColor: _vm.$theme.primaryColor,
            }),attrs:{"id":"btn-quiz"},on:{"mouseover":_vm.changeColor,"mouseleave":function($event){return _vm.changeColorToOriginal({ $event: $event })},"click":function($event){return _vm.subSetActive(7, { $event: $event })}}},[_vm._v(" Quiz ")]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.subtabIndex === 4),expression:"subtabIndex === 4"}],staticClass:"tabcontent",style:(_vm.$theme.darkTheme ? '' : 'background-color: #f2f3f7'),attrs:{"id":"Transcricao"}},[_c('div',{staticClass:"tabcontent-text",style:(_vm.$theme.darkTheme ? '' : 'color: #333333 !important')},[_c('vd-transcript',{on:{"selectedchanged":_vm.handleTranscriptSelectedChange,"searchresultindexchanged":_vm.handleSearchResultChanged},scopedSlots:_vm._u([{key:"default",fn:function(ref){
            var search = ref.search;
            var transcripts = ref.transcripts;
            var currentIndex = ref.currentIndex;
            var seek = ref.seek;
return [_c('div',{staticClass:"transc-box"},[_c('div',{staticClass:"transc-search pesquisa",style:(_vm.$theme.darkTheme
                  ? ''
                  : 'background-color: #efefef ; color: #333333')},[_c('div',{staticClass:"nav-wrapper search-box"},[_c('form',{on:{"submit":function($event){$event.preventDefault();}}},[_c('div',{staticClass:"input-field"},[_c('input',{staticClass:"white-text",style:(_vm.$theme.darkTheme
                          ? ''
                          : 'background-color: #efefef; color: #333333 !important'),attrs:{"id":"search-trans","type":"search","placeholder":"","required":""},domProps:{"value":_vm.searchQuery},on:{"input":function($event){return _vm.debounceSearch(
                              $event.target.value,
                              search.setText
                            )}}}),_c('label',{staticClass:"label-icon",attrs:{"for":"search-trans"}},[_c('i',{staticClass:"material-icons",style:({ color: _vm.$theme.primaryColor })},[_vm._v("search")])])])])]),(search.text)?_c('div',{staticClass:"search-results",style:(_vm.$theme.darkTheme ? '' : 'color: #333333')},[_vm._v(" "+_vm._s(search.currentCount)+"/"+_vm._s(search.totalCount)+" ")]):_vm._e()]),_c('div',{staticClass:"transc-clear"},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return search.setText('')}}},[_c('i',{staticClass:"material-icons",style:({ color: _vm.$theme.primaryColor })},[_vm._v("clear")])])]),_c('div',{staticClass:"transc-up"},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return search.previousResult()}}},[_c('i',{staticClass:"material-icons",style:({ color: _vm.$theme.primaryColor })},[_vm._v("expand_less")])])]),_c('div',{staticClass:"transc-down"},[_c('a',{attrs:{"href":"javascript:;"},on:{"click":function($event){return search.nextResult()}}},[_c('i',{staticClass:"material-icons",style:({ color: _vm.$theme.primaryColor })},[_vm._v("expand_more")])])])]),_c('div',{staticClass:"tabcontent-text",style:(_vm.$theme.darkTheme ? '' : 'color: #333333 !important')},[_c('ul',{ref:"transcripts",staticClass:"transcripts",class:_vm.$theme.darkTheme ? 'dark' : 'light'},_vm._l((transcripts),function(item,index){return _c('li',{key:'transcript' + index,staticClass:"segment",class:{ current: index == currentIndex },on:{"click":function($event){return seek(item.begin)}}},[_c('span',{staticClass:"segment-timestamp"},[_vm._v(" "+_vm._s(_vm._f("formatTimestamp")(item.begin))+" ")]),(item.searchResult)?_c('p',_vm._l((item.parts),function(part,partIndex){return _c('span',{key:'phrase' + index + 'part' + partIndex,class:{
                          filtered:
                            part.text.toLowerCase() ==
                            search.text.toLowerCase(),
                          highlight:
                            index == search.currentIndexes.phraseIndex &&
                            partIndex == search.currentIndexes.partIndex,
                        }},[_vm._v(_vm._s(part.text))])}),0):_c('p',{staticClass:"segment-text"},[_vm._v(_vm._s(item.text))])])}),0)])]}}],null,false,2745791527)})],1)]),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.subtabIndex === 5),expression:"subtabIndex === 5"}],staticClass:"tabcontent",style:(_vm.$theme.darkTheme ? '' : 'background-color: #f2f3f7'),attrs:{"id":"Resumo"}},[_c('div',{staticClass:"tabcontent-text",style:(_vm.$theme.darkTheme ? '' : 'color: #333333 !important')},[(_vm.aiContent && _vm.aiContent.shortSummary)?[_c('div',{staticClass:"aiTitle"},[_vm._v("Síntese")]),_c('p',{staticClass:"aiContent"},[_vm._v(" "+_vm._s(_vm.aiContent.shortSummary)+" ")])]:_vm._e(),(_vm.aiContent && _vm.aiContent.summary)?[_c('div',{staticClass:"aiTitle"},[_vm._v("Resumo")]),_c('p',{staticClass:"aiContent"},[_vm._v(" "+_vm._s(_vm.aiContent.summary)+" ")])]:_vm._e(),(
              !_vm.aiContent || !(_vm.aiContent.shortSummary || _vm.aiContent.summary)
            )?_c('StudentClassEmptyTabFallback'):_vm._e()],2)]),(_vm.subtabIndex === 6)?_c('div',{staticClass:"tabcontent",style:(_vm.$theme.darkTheme ? '' : 'background-color: #f2f3f7'),attrs:{"id":"MapaMental"}},[_c('div',{staticClass:"tabcontent-text",style:(_vm.$theme.darkTheme ? '' : 'color: #333333 !important')},[(_vm.aiContent && _vm.aiContent.mindMap)?_c('AiMindMap',{attrs:{"content":_vm.aiContent.mindMap}}):_c('StudentClassEmptyTabFallback')],1)]):_vm._e(),_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.subtabIndex === 7),expression:"subtabIndex === 7"}],staticClass:"tabcontent",style:(_vm.$theme.darkTheme ? '' : 'background-color: #f2f3f7'),attrs:{"id":"Quiz"}},[_c('div',{staticClass:"tabcontent-text",style:(_vm.$theme.darkTheme ? '' : 'color: #333333 !important')},[(_vm.aiContent && _vm.aiContent.quiz)?_c('AiQuiz',{attrs:{"quiz":_vm.aiContent.quiz}}):_c('StudentClassEmptyTabFallback')],1)])]):_vm._e()])]),(_vm.nextLearningObjects.length)?_c('div',{directives:[{name:"show",rawName:"v-show",value:(!_vm.inFullscreen),expression:"!inFullscreen"}],staticClass:"box-next-content container",style:(_vm.$theme.darkTheme ? '' : 'background-color: #f2f3f7')},[_c('div',{staticClass:"nextContentTitle",style:({ color: _vm.$theme.primaryColor })},[_vm._v(" Próximos conteúdos ")]),_c('div',{staticClass:"nextCarousel"},[_c('Carousel',{attrs:{"show-category":true,"items":_vm.nextLearningObjects,"metadata":{ title: '' }},on:{"click":_vm.navigateToClass}})],1)]):_vm._e()])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"row"},[_c('div',{staticClass:"result-label result-survey"},[_vm._v(" Esta aula ainda não está liberada. ")])])}]

export { render, staticRenderFns }