import { render, staticRenderFns } from "./CoursePreview.vue?vue&type=template&id=356f9179"
import script from "./CoursePreview.vue?vue&type=script&lang=js"
export * from "./CoursePreview.vue?vue&type=script&lang=js"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports