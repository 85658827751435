<template>
  <transition-group name="slide" tag="div" :class="['ui-toast-list', 'component', aditionalClass ]">
    <UiToast
      @remove="onRemove"
      v-for="(item, index) in toastsList"
      :item="item"
      :key="item.id + `_` + index"
    />
  </transition-group>
</template>

<script>
import UiToast from './UiToast'
import { isMobile } from "mobile-device-detect";

export default {
  name: 'UiToastList',

  components: {
    UiToast
  },

  watch: {
    $route: {
      handler: 'clearToastList',
      deep: 'true'
    }
  },

  computed: {
    toastsList () {
      return this.$store.state.toast.toastsList
    },
    aditionalClass() {
      return isMobile ? 'mobile' : 'desktop';
    }
  },

  methods: {
    onRemove (id) {
      this.$store.commit('toast/REMOVE', { id })
    },
    clearToastList () {
      if (this.toastsList.length) {
        this.$store.commit('toast/CLEAR_LIST')
      }
    }
  }
}
</script>

<style lang="scss" scoped>
.ui-toast-list.component {
  position: fixed;
  z-index: 9999999999;
}

.ui-toast-list.component.desktop {
  top: 50%;
  left: 50%;
  width: 300px;
  margin-left: -150px;
}
.ui-toast-list.component.mobile {
  top: 50%;
  left: 50%;
  width: 300px;
  margin-left: -150px;
}

.slide-leave-active,
.slide-enter-active {
  transition: .3s;
}
.slide-enter {
  transform: translate(0, 100%);
}
.slide-leave-to {
  transform: translate(-200%, 0);
}

</style>
