window.Downloader = function() {
    var userAgent = navigator.userAgent;
    var platform = navigator.platform;
    var maxTouchPoints = navigator.maxTouchPoints;
    var isIE = /Trident/.test(userAgent);
    var isIOS = /\b(iPad|iPhone|iPod)(?=;)/.test(userAgent) || (platform === "MacIntel" && maxTouchPoints > 1);
    var isIOSChrome = /\b(CriOS)(?=;)/.test(userAgent);
    var fileData = null;

    var startDownload = function(url) {

        var a = document.createElement("a");
        a.style.display = "none";
        document.body.appendChild(a);

        if ('download' in a) {
            a.download = fileData.filename;
        }

        if (isIOSChrome) {
            a.href = 'javascript:;';
            a.onclick = function() { window.open(url); };
        } else {
            a.href = url;
        }

        a.target = '_blank';
        a.click();

        document.body.removeChild(a);

        if (typeof(this.onfinished) === 'function')
            this.onfinished(fileData.name);
    };

    var sendXhr = function(options) {

        var oReq = new XMLHttpRequest();

        oReq.open("GET", options.url, true);
        if('headers' in options){
          for(i in options.headers) oReq.setRequestHeader(i, options.headers[i]);
        }

        oReq.onloadstart = function() {
            oReq.responseType = "blob";
        };
        oReq.ontimeout = function(ev) {
            if (typeof(options.ontimeout) === 'function')
                options.ontimeout.apply(this);
        }.bind(this);
        oReq.onprogress = function(ev) {
            if (typeof(options.onprogress) === 'function')
                options.onprogress.apply(this, [ev]);
        }.bind(this);
        oReq.onload = function() {
            if (typeof(options.onload) === 'function')
                options.onload.apply(this, [oReq.response]);
        }.bind(this);
        oReq.send();
    };

    var start;
    var handleTimeout = function() {
        if (typeof(this.ontimeout) === 'function')
            this.ontimeout({filename: fileData.name});
    };

    var handleProgress = function(ev) {
        if (start == -1) {
            start = ev.timeStamp
        }

        var loaded = ev.loaded;
        var total = ev.total;
        var elapsed = ((ev.timeStamp - start) / 1000).toFixed(1);

        var progress = Math.floor(ev.loaded / ev.total * 100);

        if (typeof(this.onprogress) === 'function')
            this.onprogress({
                filename: fileData.name,
                loaded: loaded,
                total: total,
                elapsed: elapsed,
                progress: progress
            });
    };

    var handleLoad = function(response) {
            if (isIOS) {
                var URL = window.URL || window.webkitURL;
                if (URL.createObjectURL) {
                    var blob = new Blob([response], { type: response.type });
                    var blobUrl = URL.createObjectURL(blob);
                    startDownload.apply(this, [blobUrl]);

                    setTimeout(function() {
                        URL.revokeObjectURL(blobUrl);
                    }, 100);
                } else {
                    var reader = new FileReader();
                    reader.readAsBinaryString(response);
                    reader.onloadend = function() {
                        var bdata = btoa(reader.result);
                        var datauri = 'data:application/octet-stream;base64,' + bdata;
                        startDownload.apply(this, [datauri]);
                    }.bind(this);
                }
            }
            else
            {
                var blob;
                if (typeof File === 'function') {
                    try {
                        blob = new File([response], fileData.name, { type: 'application/octet-stream' });
                    } catch (e) { }
                };
                if (typeof blob === 'undefined') {
                    blob = new Blob([response], { type: "application/octet-stream" });
                }

                if (navigator.msSaveBlob) {
                    // IE10 / IE11
                    if (!navigator.msSaveBlob(blob, fileData.filename)) {
                        startDownload.apply(this, [fileData.url]);
                    }
                    return;
                }

                var URL = window.URL || window.webkitURL;
                var blobUrl = URL.createObjectURL(blob);
                startDownload.apply(this, [blobUrl]);

                setTimeout(function() {
                    URL.revokeObjectURL(blobUrl);
                }, 100);
            }
    };

    this.download = function(file) {
        fileData = file;
        start = -1;

        if (isIOS && fileData.size > 100000000) {
            if (typeof(this.onfinished) === 'function')
                this.onfinished(fileData.name);

            window.location.assign(fileData.url);
        }
        else {

            var options = {
                url: fileData.url,
                ontimeout: handleTimeout,
                onprogress: handleProgress,
                onload: handleLoad
            }

            if('headers' in fileData) options.headers = fileData.headers;

            sendXhr.apply(this, [options]);
        }
    };
}
