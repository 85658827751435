import { NotificationService } from '@/services/notification.service';

export default {
  namespaced: true,
  state: {
    notifications: [],
    intervalId: 0,
  },
  getters: {
    //
  },
  actions: {
    getNotifications({ commit }) {
      
      return NotificationService.getNotifications()
        .then(response => {
          commit('SET_NOTIFICATIONS', response.data);          
          //this.dispatch('notification/initLoopTimeout');          
        })
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },
    initLoopTimeout(){
      setTimeout(()=>{
        this.dispatch('notification/getNotifications');
      }, +process.env.VUE_APP_NOTIFICATION_REFRESH_TIME || 60000);
    },
   
    clearLoop({ commit }) {
      // clear interval
      clearInterval(this.state.notification.intervalId);
    },

    read({ commit }, notificationId) {

      return NotificationService.read(notificationId)
        .then(response => {

          // atualiza a notificação lida
          commit('MARK_READ', notificationId)


          // this.$store.dispatch('notification/getNotifications');
        })
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))

    },
  },
  mutations: {
    SET_NOTIFICATIONS(state, data) {
      state.notifications = data
    },

    SET_INTERVALID(state, intervarId) {
      state.intervalId = intervarId
    },

    MARK_READ(state, notificationId) {
      for (var i in state.notifications) {
        if (state.notifications[i].id == notificationId) {
          state.notifications[i].readOn = (new Date()).toString()
        }
      }
    }
  }
}
