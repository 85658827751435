import { NoteService } from '@/services/note.service';

export default {
  namespaced: true,
  state: {
    notes: []
  },
  getters: {
    //
  },
  actions: {
    getNotes({ commit }, enroll) {
      return NoteService.getNotes(enroll.id)
        .then(response => {
          var data = response.data;
          if (!data) data = [];

          // var hierarchicalNotes = [];          
          // switch (enroll.structureType.toLowerCase()) {
          //   case "modulesanddisciplines":
          //     for (var i = 0, len = data.length; i < len; i++) {
          //       var moduleId = data[i].moduleId;
          //       var moduleName = data[i].moduleName;
          //       var moduleImage = data[i].moduleImage || data[i].courseImage;
          //       var disciplineId = data[i].disciplineId;
          //       var disciplineName = data[i].disciplineName;
          //       var disciplineImage = data[i].disciplineImage;
          //       var classId = data[i].classId;
          //       var className = data[i].className;
          //       var classImage = data[i].classImage;
          //       var learningObjectId = data[i].learningObjectId;
          //       var learningObjectName = data[i].learningObjectName;
          //       var learningObjectImage = data[i].learningObjectImage;

          //       var module = hierarchicalNotes.find(x => x.id == moduleId);
          //       if (module == null) {
          //         module = {
          //           id: moduleId,
          //           name: moduleName,
          //           image: moduleImage,
          //           disciplines: []
          //         };
          //         hierarchicalNotes.push(module);
          //       }

          //       var discipline = module.disciplines.find(x => x.id == disciplineId);
          //       if (discipline == null) {
          //         discipline = {
          //           id: disciplineId,
          //           name: disciplineName,
          //           image: disciplineImage,
          //           classes: []
          //         };
          //         module.disciplines.push(discipline);
          //       }

          //       var classData = discipline.classes.find(x => x.id == classId);
          //       if (classData == null) {
          //         classData = {
          //           id: classId,
          //           name: className,
          //           image: classImage,
          //           learningObjects: []
          //         };
          //         discipline.classes.push(classData);
          //       }

          //       var learningObject = classData.learningObjects.find(x => x.id == learningObjectId);
          //       if (learningObject == null) {
          //         learningObject = {
          //           id: learningObjectId,
          //           name: learningObjectName,
          //           image: learningObjectImage,
          //           notes: []
          //         };
          //         classData.learningObjects.push(learningObject);
          //       }

          //       learningObject.notes.push(data[i]);
          //     }
          //     break;
          //   case "modules":
          //     for (var i = 0, len = data.length; i < len; i++) {
          //       var moduleId = data[i].moduleId;
          //       var moduleName = data[i].moduleName;
          //       var moduleImage = data[i].moduleImage || data[i].courseImage;
          //       var classId = data[i].classId;
          //       var className = data[i].className;
          //       var classImage = data[i].classImage;
          //       var learningObjectId = data[i].learningObjectId;
          //       var learningObjectName = data[i].learningObjectName;
          //       var learningObjectImage = data[i].learningObjectImage;

          //       var module = hierarchicalNotes.find(x => x.id == moduleId);
          //       if (module == null) {
          //         module = {
          //           id: moduleId,
          //           name: moduleName,
          //           image: moduleImage,
          //           classes: []
          //         };
          //         hierarchicalNotes.push(module);
          //       }

          //       var classData = module.classes.find(x => x.id == classId);
          //       if (classData == null) {
          //         classData = {
          //           id: classId,
          //           name: className,
          //           image: classImage,
          //           learningObjects: []
          //         };
          //         module.classes.push(classData);
          //       }

          //       var learningObject = classData.learningObjects.find(x => x.id == learningObjectId);
          //       if (learningObject == null) {
          //         learningObject = {
          //           id: learningObjectId,
          //           name: learningObjectName,
          //           image: learningObjectImage,
          //           notes: []
          //         };
          //         classData.learningObjects.push(learningObject);
          //       }

          //       learningObject.notes.push(data[i]);
          //     }
          //     break;
          //   case "disciplines":
          //     for (var i = 0, len = data.length; i < len; i++) {
          //       var disciplineId = data[i].disciplineId;
          //       var disciplineName = data[i].disciplineName;
          //       var disciplineImage = data[i].disciplineImage || data[i].courseImage;
          //       var classId = data[i].classId;
          //       var className = data[i].className;
          //       var classImage = data[i].classImage;
          //       var learningObjectId = data[i].learningObjectId;
          //       var learningObjectName = data[i].learningObjectName;
          //       var learningObjectImage = data[i].learningObjectImage;

          //       var discipline = hierarchicalNotes.find(x => x.id == disciplineId);
          //       if (discipline == null) {
          //         discipline = {
          //           id: disciplineId,
          //           name: disciplineName,
          //           image: disciplineImage,
          //           classes: []
          //         };
          //         hierarchicalNotes.push(discipline);
          //       }

          //       var classData = discipline.classes.find(x => x.id == classId);
          //       if (classData == null) {
          //         classData = {
          //           id: classId,
          //           name: className,
          //           image: classImage,
          //           learningObjects: []
          //         };
          //         discipline.classes.push(classData);
          //       }

          //       var learningObject = classData.learningObjects.find(x => x.id == learningObjectId);
          //       if (learningObject == null) {
          //         learningObject = {
          //           id: learningObjectId,
          //           name: learningObjectName,
          //           image: learningObjectImage,
          //           notes: []
          //         };
          //         classData.learningObjects.push(learningObject);
          //       }

          //       learningObject.notes.push(data[i]);
          //     }
          //     break;
          //   case "classes":
          //     for (var i = 0, len = data.length; i < len; i++) {
          //       var classId = data[i].classId;
          //       var className = data[i].className;
          //       var classImage = data[i].classImage || data[i].courseImage;
          //       var learningObjectId = data[i].learningObjectId;
          //       var learningObjectName = data[i].learningObjectName;
          //       var learningObjectImage = data[i].learningObjectImage;

          //       var classData = hierarchicalNotes.find(x => x.id == classId);
          //       if (classData == null) {
          //         classData = {
          //           id: classId,
          //           name: className,
          //           image: classImage,
          //           learningObjects: []
          //         };
          //         hierarchicalNotes.push(classData);
          //       }

          //       var learningObject = classData.learningObjects.find(x => x.id == learningObjectId);
          //       if (learningObject == null) {
          //         learningObject = {
          //           id: learningObjectId,
          //           name: learningObjectName,
          //           image: learningObjectImage,
          //           notes: []
          //         };
          //         classData.learningObjects.push(learningObject);
          //       }

          //       learningObject.notes.push(data[i]);
          //     }
          //     break;
          // }

          /*
          //EX: Módulo, Aula, Objetos
          hierarchicalNotes = [
            {
              id: 1,
              name: 'Módulo 1',
              classes: [
                {
                  id: 1,
                  name: 'Aula 1',
                  learningObjects: [
                    {
                      id: 1,
                      name: 'Objeto 1',
                      notes: [
                        {
                          id: 1,
                          timerVideo: '01:01:01',
                          annotation: 'Anotação 1'
                        },
                        {
                          id: 2,
                          timerVideo: '02:02:02',
                          annotation: 'Anotação 2'
                        }
                      ]
                    }
                  ]
                }
              ]
            }
          ]

          //EX: Aula, Objetos
          hierarchicalNotes = [
            {
              id: 1,
              name: 'Aula 1',
              learningObjects: [
                {
                  id: 1,
                  name: 'Objeto 1',
                  notes: [
                    {
                      id: 1,
                      timerVideo: '01:01:01',
                      annotation: 'Anotação 1'
                    },
                    {
                      id: 2,
                      timerVideo: '02:02:02',
                      annotation: 'Anotação 2'
                    }
                  ]
                }
              ]
            }
          ]*/

          commit('SET_NOTES', response.data)
        })
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },

    create({ commit }, arg) {
      return NoteService.create(arg.note, arg.time, arg.enrollId, arg.courseId, arg.moduleId, arg.disciplineId, arg.classId, arg.learningObjectId)
        .then(response => {
          // --
        })
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },

    remove({commit}, arg) {
      return NoteService.remove(arg.EnrollId, arg.NoteId)
      .then(response => {
        commit('REMOVE_NOTE', arg.NoteId);
      })
      .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }));
    }
  },
  mutations: {
    SET_NOTES(state, data) {
      state.notes = data
    },
    REMOVE_NOTE(state, data) {
      const note = state.notes.find(n => n.id == data);
      const index = state.notes.indexOf(note);
      state.notes.splice(index, 1);
    }
  }
}
