<template>
  <div>
    <HeaderPage pageName="Sobre"/>
  </div>
</template>

<script>
export default {
  name: 'About',
  data () {
    return {
      msg: 'Teste!!!'
    }
  }
}
</script>
