<template>
  <div class v-click-outside="clickOutside">
    <div class="input-field">
      
      <input
        class="header-search-input col s0 m12 l12 "
        type="search"
        placeholder="Pesquise aqui"
        v-model="search"
        @input="filterItems()"
        @keyup.delete="filterItems()"
        data-target="dropdown1"
         :style="($theme.darkTheme ? 'color: #efefef' : 'color: #333333')"
      />
       <label class="label-icon icon-search-header" for="search">
                <i class="material-icons" :style="{ color: $theme.primaryColor }">search</i>
              </label>
      <ul id="dropdown1" :style="($theme.darkTheme ? 'background: #131318' : 'background: #efefef')">
        <li
          class="header-search-result autocomplete-no-results "
          :style="($theme.darkTheme ? 'background: #131318; color: #efefef' : 'background: #efefef; color: #333333')"
          v-if="searchResults.length == 0 && search.length > 0"
        >
          <div class="row">
            <div class="col s0 m12 l12">
              <div class="row">
                <div class="col s0 m12 l12 center-align">Nenhum resultado foi encontrado</div>
              </div>
            </div>
          </div>
        </li>
        <li
          class="header-search-result"
           :style="($theme.darkTheme ? 'background: #131318; color: #efefef' : 'background: #efefef; color: #333333')"
          v-for="(classResult, index) in searchResults"
          :key="'opt_' + index"
        >
          <div class="row">
            <div class="col s0 m12 l12">
              <div class="row">
                <div class="col s0 m12 l12 autocomplete-class-title">Em: {{classResult.title}}</div>
              </div>
              <div
                class="row clickable"
                v-for="loResult in classResult.learningObjects"
                :key="'hsrlo_' + loResult.id"
                @click.prevent="itemClick(loResult)"
              >
                <img v-lazy="learningObjectImageByType(loResult)" class="col m2 l2 autocomplete-object-img"/>
                <span class="col m10 l10 autocomplete-object-title">{{loResult.title}}</span>
              </div>
            </div>
          </div>
        </li>
      </ul>
    </div>
  </div>
</template>
<script>
import M from "materialize-css";
import clickOutside from "@/directives/clickOutside";

export default {
  name: "Autocomplete",
  props: [
    "items",
    "itemSearchProperty",
    "itemLabelProperty",
    "itemValueProperty",
    "limit"
  ],
  directives:{
    clickOutside:clickOutside
  },
  mounted() {
    if (!this.limit) this.limit = 0;
  },
  data() {
    return {
      search: "",
      searchResults: []
    };
  },
  methods: {
    clearSearch() {
      this.search = "";
      this.searchResults = [];
    },
    clickOutside(){
      this.clearSearch()
    },
    filterItems() {
      const searchInput = this.search.trim().toLowerCase();
      if (searchInput.length == 0) {
        this.searchResults = [];
        return;
      }

      const propertyName = this.itemSearchProperty;

      var itemsClone = _.cloneDeep(this.items);

      const searchRes = itemsClone.filter(item => {
        var los = item.learningObjects.filter(lo => {

          // se houver ultimo acesso ou acesso recente, ignora esse tbm
          var excludeLOId = this.$store.state.enroll.lastAccess ? this.$store.state.enroll.lastAccess.learningObjectId : 0;

          return lo.title.toLowerCase().indexOf(searchInput) > -1 && excludeLOId != lo.id;
        });
        item.learningObjects = los;
        return los.length > 0;
      });

      if (this.limit > 0) this.searchResults = searchRes.splice(0, this.limit);
      else this.searchResults = searchRes;
    },
    itemClick(learningObject) {
      this.clearSearch();
      this.$emit("click", learningObject);
    },
    learningObjectImageByType(lo){
      if(lo.broadcast)
        return lo.image + '?h=48';

      return lo.image;
    }
  }
};
</script>
<style scoped>


#dropdown1 {
  display: block;
  max-height: 300px;
  overflow: auto;
}

.header-search-input {
  border: none !important;
  padding: 0 10px !important;
}

.header-search-result {
  background-color: #131318;
  width: 100% !important;
}

.pesquisa .input-field input[type="search"]:focus:not(.browser-default) {
    width: 80%;
    border-radius: 10px;
}

.input-field {
    border-radius: 5px;
    height: 45px;
  }

  .input-field input[type="search"] {
    padding: 0 10px !important;
    /* color: #bfbfbf !important; */
    padding: 0 10px !important;
    
  }

  input[type=search]:not(.browser-default){
    border-radius: 5px;
    width: 97%;
  }

.icon-search-header{
  /* border: 1px solid red; */
  
}
.pesquisa .input-field input[type="search"]{
  margin-left: 25px;
}

.pesquisa .input-field input[type="search"] + .icon-search-header {
    left: 5px !important;
    top: -5px;
    
}

.clickable {
  cursor: pointer;
}

.autocomplete-class-title {
  font-size: 13px;
}

.autocomplete-object-title {
  font-size: 17px;
  display: block;
}

.autocomplete-object-img {
  min-width:57px
}

.autocomplete-no-results {
  width: 100%;
}
</style>