<template>
    <div>
        <div class="row">
            <div
              class="item-title col s10 left"
              :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }"
            >
              Grupo de {{ type }}
            </div>
            <i
              v-if="
                ($currentUser.phones || []).length > 0 &&
                !entering &&
                (type == 'WhatsApp' || (type == 'Telegram' && !requestTelegramAuth))
              "
              @click="enterClicked"
              class="material-icons no-padding left icon-link"
              :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }"
            >
              exit_to_app
            </i>
            <div
              v-else-if="
                ($currentUser.phones || []).length > 0 &&
                entering &&
                (type == 'WhatsApp' ||
                (type == 'Telegram' && requestTelegramAuth))
              "
              class="preloader-wrapper small active">
              <div class="spinner-layer" :style="{ borderColor: !hasCustomTheme ? '#0564b1' : $theme.primaryColor }">
                <div class="circle-clipper left">
                  <div class="circle"></div>
                </div>
                <div class="gap-patch">
                  <div class="circle"></div>
                </div>
                <div class="circle-clipper right">
                  <div class="circle"></div>
                </div>
              </div>
            </div>
            <div class="item-description col s10 left" :style="hasCustomTheme ? $theme.darkTheme? 'color: #bfbfbf': 'color: #333333': 'color: #333333'">{{name}}</div>
        </div>
        <div
          class="row"
          v-if="type == 'Telegram' && requestTelegramAuth"
          ref="TelegramContainer"
        >
          <a
            v-if="isMobile && this.hash"
            :href="
              this.env == 'staging'
                ? 'https://t.me/pagtruststbot?start=' + this.hash + '_' + this.id
                : 'https://t.me/pagtrustbot?start=' + this.hash + '_' + this.id
            "
            class="waves-effect btn-profile"
            :style="{ backgroundColor: $theme.primaryColor, color: $theme.color }"
          >
            entrar
          </a>
          <a
            v-else-if="telegramId"
            href="javascript:;"
            class="waves-effect btn-profile"
            @click="enterTelegram"
            :style="{ backgroundColor: $theme.primaryColor, color: $theme.color }"
          >
            entrar
          </a>
          <div
            v-else
            class="item-description col s10 left"
            :style="{ color: $theme.primaryColor }"
            >
            faça o login acima para solicitar o convite
          </div>
        </div>
        <div class="row valign-wrapper" v-else>
          <div v-if="($currentUser.phones || []).length > 0" class="group-id col s12">
              <i class="material-icons no-padding left icon-color">phone</i>
              <div
                class="item-description no-padding col s10"
                :style="hasCustomTheme ? $theme.darkTheme? 'color: #bfbfbf': 'color: #333333': 'color: #333333'"
              >
                + {{ $currentUser.phones[0].ddi }} {{$currentUser.phones[0].area}} {{$currentUser.phones[0].number}}
              </div>
              <i
                class="material-icons no-padding left icon-color mouse-pointer"
                @click="$modal.show('profile')"
              >
                create
              </i>
          </div>
          <div v-else class=" col s12">
            <a
              class="waves-effect btn-profile left"
              @click="$modal.show('profile');"
              :style="!hasCustomTheme ? '#0564b1': {background: $theme.primaryColor,color: $theme.primaryText}"
            >
              Adicionar telefone
            </a>
          </div>
        </div>
    </div>
</template>

<script>
import { mapState } from "vuex";
import { isMobile } from "mobile-device-detect";

export default {
  props: {
    type: String,
    id: Number,
    name:  String,
    telegramId:  String,
    hash: String,
    courseId: Number,
    enrollId: Number,
    requestTelegramAuth: Boolean
  },
  mounted() {
  },
  data: function () {
    return {
      entering: false,
      env: process.env.VUE_APP_ENV
    };
  },
  computed: {
    ...mapState({
      currentEnroll: (state) => state.enroll.current,
    }),
    hasCustomTheme() {
      const isTrustflix = window.location.pathname === "/p/trustflix";
      return isTrustflix || this.currentEnroll
    }
  },
    methods: {
        validatePhoneNumber() {
            var phoneNumber = `${this.$currentUser.phones[0].area}${this.$currentUser.phones[0].number}`;
            
            if (phoneNumber.length <= 7) {
                return false;
            }
            if (
                this.$currentUser.phones[0].ddi == null ||
                this.$currentUser.phones[0].ddi == undefined ||
                this.$currentUser.phones[0].ddi.toString().length == 0
            ) {
                return false;
            }

            return true;
        },

        async enterClicked() {
            if (!this.validatePhoneNumber()) {
                this.$store.commit("toast/NEW", {
                message: "Telefone inválido!",
                type: "error",
                });

                $modal.show("profile");
                return;
            }

            this.entering = true;

            switch (this.type) {
                case 'Telegram':
                    await this.$store.dispatch('groupmaster/enterTelegram', {
                        courseId: this.courseId || this.currentEnroll.courseId,
                        groupId: +this.id,
                        enrollId: this.enrollId || this.currentEnroll.enrollId
                    });
                    break;
                case 'WhatsApp':
                    /*
                    await this.$store.dispatch('groupmaster/enterWhatsApp', {
                        courseId: this.courseId ||  this.currentEnroll.courseId,
                        groupId: +this.id,
                        phoneNumber: `${this.$currentUser.phones[0].ddi}${this.$currentUser.phones[0].area}${this.$currentUser.phones[0].number}`
                    });
                    */

                    var link = await this.$store.dispatch('groupmaster/getInviteLinkWhatsApp', {
                        courseId: this.courseId ||  this.currentEnroll.courseId,
                        groupId: +this.id,
                        phoneNumber: `${this.$currentUser.phones[0].ddi}${this.$currentUser.phones[0].area}${this.$currentUser.phones[0].number}`
                    });

                    setTimeout(()=>{
                      if(link)
                      {
                        window.open(link, '_blank').focus();
                      }
                    }, 2*1000);
                    
                    break;
            }

            this.entering = false;
        },

        async enterTelegram(user) {
            await this.$store.dispatch('groupmaster/inviteTelegram', {
                courseId: this.courseId || this.currentEnroll.courseId,
                groupId: +this.id,
                userId: this.telegramId
            });
        }
    }
}

</script>

<style scoped>
.group-id {
  display: flex;
}

.mail-group {
  word-wrap: break-word;
}

.preloader-wrapper.small {
  width: 20px;
  height: 20px;
}
</style>
