<template>
  <div :class="classList" @click="remove">
    <div class="right ml10">X</div>
    <div class="center-align toast-center">{{ item.message }}</div>
  </div>
</template>

<script>

export default {
  name: "UiToast",
  props: {
    item: {
      type: Object
    }
  },

  methods: {
    remove() {
      this.$emit("remove", this.item.id);
    }
  },

  computed: {
    classList() {
      var classList = {
        default: this.item.type === "default",
        success: this.item.type === "success",
        info: this.item.type === "info",
        warning: this.item.type === "warning",
        error: this.item.type === "error",
        'toast-fill': true,
        'ui-toast': true,
        'component': true
        //, 'pulse':true
      };

      return classList;
    }
  },

  mounted() {
    if (this.item.duration) {
      const timer = setTimeout(() => {
        clearTimeout(timer);
        this.remove();
      }, this.item.duration);
    }
  }
};
</script>

<style lang="scss">
.toast-fill {
  height: 100%;
  width: 100%;
  margin: 5px 0;
}

.ui-toast.component {
  cursor: pointer;
  padding: 20px;
  &.default {
    border-left: 10px solid $color-line;
    border-right: 10px solid $color-line;
  }
  &.success {
    border-left: 10px solid green;
    border-right: 10px solid green;
  }
  &.info {
    border-left: 10px solid blue;
    border-right: 10px solid blue;
  }
  &.warning {
    border-left: 10px solid orange;
    border-right: 10px solid orange;
  }
  &.error {
    border-left: 10px solid red;
    border-right: 10px solid red;
  }
}

.ml10 {
  margin-left: 10px;
}
</style>
