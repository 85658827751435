var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('li',{staticClass:"ai-quiz-question"},[_c('div',[_c('p',{staticClass:"question-label"},[_vm._v(" "+_vm._s(_vm.question)+" ")]),_c('ul',{staticClass:"question-alternatives-list"},_vm._l((_vm.alternatives),function(alternative,$alternativeIndex){return _c('li',{key:_vm._f("formatQuestionOptionId")(_vm.questionId,$alternativeIndex),staticClass:"question-alternative",class:_vm.isSubmitted
            ? {
                'question-alternative--correct':
                  alternative === _vm.correctAnswer,
                'question-alternative--correct-chosen':
                  alternative === _vm.userChoice && alternative === _vm.correctAnswer,
                'question-alternative--incorrect':
                  alternative === _vm.userChoice && alternative !== _vm.correctAnswer,
              }
            : {},attrs:{"title":_vm.isSubmitted && alternative === _vm.correctAnswer
            ? 'Alternativa correta'
            : ''}},[_c('div',{staticClass:"question-alternative-container"},[_c('label',[_c('input',{directives:[{name:"show",rawName:"v-show",value:(!_vm.isSubmitted),expression:"!isSubmitted"}],staticClass:"question-alternative-input",attrs:{"disabled":_vm.isSubmitted,"type":"radio","id":_vm._f("formatQuestionOptionId")(_vm.questionId,$alternativeIndex),"name":'question#' + _vm.questionId},domProps:{"value":alternative,"checked":_vm.userChoice === alternative},on:{"input":function($event){return _vm.$emit('update:userChoice', alternative)}}}),(_vm.isSubmitted)?_c('div',{staticStyle:{"width":"32px"}},[(alternative === _vm.userChoice)?_c('i',{staticClass:"material-icons no-padding right icon-color",staticStyle:{"font-size":"16px"}},[_vm._v(_vm._s(alternative === _vm.userChoice && alternative === _vm.correctAnswer ? "check_circle" : "cancel"))]):_vm._e()]):_vm._e(),_c('span',{staticClass:"question-alternative-label"},[_vm._v(" "+_vm._s(alternative)+" ")])])])])}),0)])])}
var staticRenderFns = []

export { render, staticRenderFns }