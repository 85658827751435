import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'


export class LearningObjectService extends BaseService {
  static get entity () {
    return 'learningObject'
  }

  // altera o progresso
  static async changeProgress (enrollId, courseId, moduleId, disciplineId, classId, learningObjectId, complete) {

    try {

      const response = await this.request({ auth: true }).put(`${process.env.VUE_APP_EAD_API_URL}/enrolls/${enrollId}/progress`, {
        "hierarchy": {
          "courseId": courseId,
          "moduleId": moduleId,
          "disciplineId": disciplineId,
          "classId": classId,
          "learningObjectId": learningObjectId
        },
        "complete": complete
      });

      // já pega pelo jwt
      return new ResponseWrapper({status:200, data:{success:true}}, response.data);

    } catch (error) {
      throw new ErrorWrapper(error)
    }
  }

  static async getAI(learningObjectId){
    try{
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/learning-objects/${learningObjectId}/aicontent`);
      return new ResponseWrapper({status: 200, data: {success: true}}, response.data);
    }
    catch(error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getRatings(learningObjectId){
    try{
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/learning-objects/${learningObjectId}/ratings`);
      return new ResponseWrapper({status: 200, data: {success: true}}, response.data);
    }
    catch(error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getDMRAuth(courseId, fileId){
    try{

      // process.env.VUE_APP_EAD_API_URL + '/courses/' + this.currentEnroll.courseId + '/file/' + file.id + '/pdf';
      // https://ead-hm.st.voxeldigital.com.br/api-front/courses/9125/learning-obj/70224/pdf
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/courses/${courseId}/file/${fileId}/pdf-auth`);
      return new ResponseWrapper({status: 200, data: {success: true}}, response.data);
    }
    catch(error) {
      throw new ErrorWrapper(error);
    }
  }

  static async getDMRConfig(courseId){
    try{
      const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/courses/${courseId}/drm`);
      return new ResponseWrapper({status: 200, data: {success: true}}, response.data);
    }
    catch(error) {
      throw new ErrorWrapper(error);
    }
  }


  static async updateRatings(learningObjectId, rating) {
    try{
      const response = await this.request({ auth: true }).put(`${process.env.VUE_APP_EAD_API_URL}/learning-objects/${learningObjectId}/ratings`, {
        rating
      });
      return new ResponseWrapper({status: 200, data: {success: true}}, response.data);
    }
    catch(error) {
      throw new ErrorWrapper(error);
    }
  }

  static async insertRatings(learningObjectId, rating) {
    try{
      const response = await this.request({ auth: true }).post(`${process.env.VUE_APP_EAD_API_URL}/learning-objects/${learningObjectId}/ratings`, {
        rating
      });
      return new ResponseWrapper({status: 200, data: {success: true}}, response.data);
    }
    catch(error) {
      throw new ErrorWrapper(error);
    }
  }

}
