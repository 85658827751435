<template>
  <div class="box-course" :style="{ backgroundColor: '#fff'}" @click.prevent="selectEnroll(enroll)">
    <div class="d1">
      <div class="course-images">
        <img :src="backgroundImage" alt />
      </div>
      <div class="progress progress-course">
        <div
          class="determinate primary-bg-gradient-color"
          :style="{ width: enroll.progress + '%' }"
        ></div>
      </div>
     
    </div>
    <div class="d2">
      <div class="course-info-top">
        <div class="course-qty right">
          {{enroll.totalLearningObjectsVizualized}}/{{enroll.totalLearningObjects}}
        </div>
        <div class="course-name line-clamp-2">{{ enroll.name }}</div>
      </div>
      <div class="course-info-bottom">
        <div class="course-percent left">{{Math.ceil(enroll.progress)}}%</div>
        <div class="course-play">
       
          Continuar
          <IconPlay />
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { ShopWindowsService } from '@/services/shopWindow.service';
import IconPlay from "@/components/UI/IconPlay";


export default {
  name: "MyCourses",
  props: ["enroll"],
  components: { 
    IconPlay,
   },
  data() {
    return {
      backgroundImage: "",
    };
  },
  mounted() {
    var self = this;
    var img = new Image();
    img.src = this.enroll.image;
    img.onload = function () {
      self.backgroundImage = img.src;
    };

    img.onerror = function () {
      self.backgroundImage = require("@/assets/images/default-course-image.png");
    };
  },
  methods: {
    async selectEnroll(enroll) {
      if(enroll.totalLearningObjects == 0){
        this.$store.commit("toast/CLEAR_LIST");
        this.$store.commit("toast/NEW", {
          type: "info",
          message: "Este curso ainda não possui conteúdo publicado."
        });
        return;
      }

      if (enroll.pendingPayment) {
        this.$store.commit("toast/NEW", {
          type: "error",
          message: enroll.paymentPendingMessage
        });
        return;
      }

      if (enroll.shopWindowId) {
        // this.$store.commit('shopWindow/SET_CURRENT_SHOPWINDOW', { id: enroll.shopWindowId });
        ShopWindowsService.setCurrentShopWindow({ id: enroll.shopWindowId });
        this.$router.push({ name: "trustflix" });
      } else {
        this.$updateTheme({
          darkTheme: enroll.settings.darkMode,
          primary: enroll.settings.primaryColor,
          secondary: enroll.settings.secondaryColor,
        });
        await this.$store.dispatch(
          "enroll/presetCurrentEnroll",
          {
            enrollId: enroll.id
          }
        );
        this.$router.push({ name: "course", params: { id: enroll.id } });
      }
    },
  },
};
</script>

<style scoped lang="scss">

@media only screen and (max-width: 1366px) {
  .course-info-top {
    font-size: 20px !important;
  }

  .course-name{
    font-size: 20px !important;
  }

}

@media only screen and (max-width: 400px) {
  .course-info-top {
    font-size: 16px !important;
  }

  .course-name{
    font-size: 16px !important;
  }

}

@media only screen and (max-width: 992px) {
             
             .gridCourses{
               justify-content: center;
             }

             .d2{
               height: 125px !important;
             }

             .course-info-top{
               padding: 10px 15px 0 15px !important;

               .course-name{
                 font-size: 16px !important;
               }
             }
     }



.box-course {
  color: #717171;
  position: relative;

  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  overflow: hidden;
  cursor: pointer;

  img {
    width: 296px;
    height: 167px;
    top: 0;
    object-fit: cover;
    position: absolute;
  }

  .d1,
  .d2 {
    width: 100%;
  }

  .d2{
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    height: 181px;
  }

  .d1 {
    height: 0;
    height: 171px;
    overflow: hidden;
    display: flex;
    flex-direction: column;
  }
  .course-images {
    width: 100%;
    height: 167px;
    overflow: hidden;

  }
  .course-image {
    position: absolute;
    height: 100%;
    width: auto;
    overflow: hidden;
    display: flex;
    align-items: center;
    justify-content: center;
    background: gray;

    img {
      width: 80%;
      height: 80%;
      object-fit: contain;
    }
  }

  .course-info-top {
    
    display: flex;
    flex-direction: column;
    color: #717171;
    width: 100%;
    padding: 20px 15px 0 15px;

    .course-qty{
      font-family: robotomedium;
      font-size: 14px;
    }
    .course-name{
      font-family: robotomedium;
      font-size: 20px;
      font-weight: 500;
      font-stretch: normal;
      font-style: normal;
      line-height: normal;
      letter-spacing: normal;
    }
  }

  .course-info-bottom {
    
    padding: 0 15px 10px 15px;
    display: flex;
    align-items: center;
    justify-content: space-between;

    .course-percent{
      font-family: robotomedium;
      font-size: 14px;
      
    }
    .course-play{
      font-family: robotomedium;
      font-size: 14px;
      color:#9747ff;
      display: flex;
      align-items: center;
    }
  }

  .degrade {
    position: absolute;
    width: 100%;
    height: 105%;
    left: 0;
    bottom: 0;
    background: linear-gradient(
      0deg,
      #000000b8 0%,
      rgba(0, 0, 0, 0.25) 65%,
    );

  }

  .progress-course {
    margin: 0.5rem 0 0rem 0;
    background-color: #bfbfbf !important;
  }

  .progress{
    height: 5px;
    border-radius: 0;
    .determinate{
      border-radius: 0px 5px 5px 0px;
    }
  }

  .btn-view-course {
    background-color: #ffffff;
    margin-bottom: 5px;
  }

  .course-info-bottom-left {
    width: 75%; /*100%;*/
  }

  .course-info-bottom-right {
    border: 1px solid red;
    font-size:  11px; //8px;
    font-weight: 500;
    font-stretch: normal;
    font-style: normal;
    line-height: normal;
    letter-spacing: normal;
    color: #ffffff;
    display: flex;
    flex-direction: column;
    align-items: center;
  }
}
</style>
