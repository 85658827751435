import { BaseService } from './base.service'
import { ErrorWrapper, ResponseWrapper } from './util'

export class FormsService extends BaseService {
    static get entity() {
        return 'forms'
    }

    static async getForm(id) {
        try {
            
            const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/forms/${id}/details`);

            return new ResponseWrapper({ status: 200, data: { success: true } }, response.data);

        } catch (error) {
            const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
            throw new ErrorWrapper(error, message);
        }
    }

    static async canAnswer(id){
        try {
            const response = await this.request({ auth: true }).get(`${process.env.VUE_APP_EAD_API_URL}/forms/${id}/check-possibility-of-response`);
            return new ResponseWrapper({ status: 200, data: { success: true }}, response.data);
        }
        catch (error){
            const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
            throw new ErrorWrapper(error, message);
        }
    }

    static async createResponseInstance(id) {
        try {
            const response = await this.request({ auth: true }).post(`${process.env.VUE_APP_EAD_API_URL}/forms/${id}/instance`);
            return new ResponseWrapper({ status: 200, data: { success: true }}, response.data);
        }
        catch (error){
            const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
            throw new ErrorWrapper(error, message);
        }
    }

    static async answerQuestion(id, questionId, answers) {
        try {
            const response = await this.request({ auth: true }).post(`${process.env.VUE_APP_EAD_API_URL}/forms/${id}/questions/${questionId}/reply`, answers);
            return new ResponseWrapper({ status: 200, data: { success: true }}, response.data);
        }
        catch (error){
            const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
            throw new ErrorWrapper(error, message);
        }
    }

    static async answerForm(id, answers) {
        try {
            const response = await this.request({ auth: true }).post(`${process.env.VUE_APP_EAD_API_URL}/forms/${id}/reply`, answers);
            return new ResponseWrapper({ status: 200, data: { success: true }}, response.data);
        }
        catch (error) {
            const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
            throw new ErrorWrapper(error, message);
        }
    }

    static async getFormResult(id) {
        try{
            const response = await this.request({ auth:true }).get(`${process.env.VUE_APP_EAD_API_URL}/forms/${id}/result`)
            return new ResponseWrapper({ status: 200, data: { success: true  }}, response.data);
        }
        catch(error) {
            const message = error.response.data && typeof(error.response.data) === 'object' ? error.response.data.error : error.message
            throw new ErrorWrapper(error, message);
        }
    } 
}