<template>
  <div>
    <div
      ref="modalCropImage"
      class="modal ead-modal nav-modal image-cropper-modal "
      :class="{
        'image-cropper-modal--dark': $theme.darkTheme
      }"
    >
      <div class="modal-header">
        <div class="row">
          <div class="col s4">
            <h4
              :style="{'color': colors.primary}"
              v-if="name"
            >
              {{ name }}
            </h4>
            <h4
              :style="{'color': colors.primary}"
              v-if="showDimensions"
            >
              {{ width }} x {{ height }}
            </h4>
          </div>
          <div class="col s6"></div>
          <div class="col s1">
            <i
              class="material-icons"
              style="cursor: pointer"
              :style="{'color': colors.primary}"
              @click="zoomIn"
            >
              zoom_in
            </i>
          </div>
          <div class="col s1">
            <i
              class="material-icons"
              style="cursor: pointer"
              :style="{'color': colors.primary}"
              @click="zoomOut"
            >
              zoom_out
            </i>
          </div>
        </div>
      </div>
      <div class="modal-content">
        <img
          :class="{
            'cropper-modal--round': round
          }"
          ref="imageToCrop"
          :src="imageSt || image"
          style="max-width: 50vw; max-height: 50vh"
        />
      </div>
      <div
        class="modal-footer"
        :style="{ 'background-color': $theme.darkTheme ? '#0d0d13' : '#f2f3f7' }"
      >
        <div class="row">
          <div class="col s12 m6">
            <button
              :style="{
                'background': '#dbdbdb',
                'color': colors.primary
              }"
              class="waves-effect btn-profile"
              @click="cancel"
            >Cancelar</button>
          </div>
          <div class="col s12 m6">
            <button
              :style="{
                'background': colors.primary,
                'color': $theme.primaryText
              }"
              class="waves-effect btn-profile"
              @click="confirm"
            >Salvar</button>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import Cropper from 'cropperjs';

export default {
  props: {
    name: String,
    showDimensions: Boolean,
    width: {
      type: Number,
      required: true
    },
    height: {
      type: Number,
      required: true
    },
    image: {
      type: String,
      required: true
    },
    keepAspectRatio: {
      type: Boolean,
      default: true
    },
    round: {
      type: Boolean
    },
    colors: {
      type: Object,
      default: () => ({ primary: '#0564b1', secondary: '#fff' })
    }
  },
  data() {
    return {
      modalRefImageCrop: null,
      cropper: null,
      imageSt: '',
      cropped: false
    };
  },
  computed: {
    imgSrc() {
      return this.imageSt || this.image;
    }
  },
  methods: {
    createCropper() {
      var aspect = this.width / this.height;

      this.cropper = new Cropper(this.$refs.imageToCrop, {
        dragMode: 'move',
        zoomable: true,
        viewMode: 1,
        // data: {
        //   width: this.width,
        //   height: this.height
        // },
        aspectRatio: this.keepAspectRatio ? aspect : NaN,
        toggleDragModeOnDblclick: false,
        cropmove: (evt) => {},
        cropend: (evt) => {
          var cropBox = this.cropper.getData();
          if (cropBox.width < this.width || cropBox.height < this.height) {
            this.cropper.setData({
              width: this.width,
              height: this.height
            });
          }
        },
        zoom: () => {
          this.$nextTick(() => {
            this.cropper.moveTo(0, 0);
          });
        }
      });
    },
    zoomIn() {
      this.cropper.zoom(0.1);
      this.cropper.moveTo(0, 0);
    },
    zoomOut() {
      this.cropper.zoom(-0.1);
      this.cropper.moveTo(0, 0);
    },
    confirm() {
      this.cropped = true;
      var canvas = this.cropper.getCroppedCanvas();
      this.$emit('cropped', {
        canvas: canvas
      });
      this.close();
      this.cropped = false;
    },
    cancel() {
      this.modalRefImageCrop.close();
      this.$emit('cancel');
      this.cropped = false;
    },
    open(data) {
      this.$nextTick(() => {
        this.imageSt = data.image;

        this.modalRefImageCrop = M.Modal.init(this.$refs.modalCropImage, {
          preventScrolling: true,
          onOpenEnd: () => {
            this.createCropper();
          },
          onCloseStart: () => {
            if (!this.cropped) this.cancel();
            this.destroyCropper();
          }
        });
        this.modalRefImageCrop.open();
      });
    },
    close() {
      this.modalRefImageCrop.close();
    },
    destroyCropper() {
      if (this.cropper) {
        this.cropper.destroy();
        this.cropper = null;
      }
      if (this.modalRefImageCrop) {
        this.modalRefImageCrop.destroy();
      }
    }
  }
};
</script>

<style scoped>
h4 {
  min-width: max-content;
  color: var(--primary-color);
}

button.btn-profile {
  margin-top: 12px;
  border: none;

  width: 100%;
}

.image-cropper-modal {
  background-color: #f2f3f7;
}

.image-cropper-modal--dark {
  background-color: #0d0d13;
}
</style>


<style lang="scss">
.cropper-modal--round ~ * {
  .cropper-view-box,
  .cropper-face {
    border-radius: 50%;
  }
}
</style>
