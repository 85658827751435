import * as VDUploader from '@voxel/vd-uploader';

export default {
    date(){
        return {
            uploader:null
        }
    },
    created: function () {
        this.uploader = new VDUploader.VDUploader({cidtk:process.env.VUE_APP_UPLOADER_CTK, apiUrl:process.env.VUE_APP_UPLOADER_URL}) 
    },
    methods: {
        upload: async function (file, filename) {

            new Promise((success, reject)=>{
                this.uploader.send(file, {customName:filename}).then((response) => {
                    success()
                });
            });
            
        }
    }
}