<template>
  <svg width="30" height="30" viewBox="0 0 30 30" fill="none" xmlns="http://www.w3.org/2000/svg">
    <g clip-path="url(#qhnws4bjma)" :stroke="(!selectedEnroll ? '#9747ff' : $theme.primaryColor)" stroke-width="2">
        <path d="M15 26.25c6.213 0 11.25-5.037 11.25-11.25S21.213 3.75 15 3.75 3.75 8.787 3.75 15 8.787 26.25 15 26.25z" stroke-miterlimit="10"/>
        <path d="m18.75 15-5.625-3.75v7.5L18.75 15z" stroke-linecap="round" stroke-linejoin="round"/>
    </g>
    <defs>
        <clipPath id="qhnws4bjma">
            <path fill="#fff" d="M0 0h30v30H0z"/>
        </clipPath>
    </defs>
</svg>

  
</template>


<script>
import { mapState } from "vuex";

export default {
  computed: {
    ...mapState({
      selectedEnroll: (state) => state.enroll.current,
    })
  },
};
</script>