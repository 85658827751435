import Vue from 'vue';

// mods
import VueIcons from 'vue-icon';
import VueLodash from 'vue-lodash';
import lodash from 'lodash';
import VueTheMask from './directives/mask';
import VueLazyload from 'vue-lazyload';
import VueContentPlaceholders from 'vue-content-placeholders';
import CoreMedia from '@/assets/plugins/coremedia/coremedia.js';
import CommentsBox from '@/components/CommentsBox.vue';
import VueGtag from "vue-gtag";

// pages
import AppLayout from './layout/Index.vue';

import router from './router';
import store from './store';
import './mixins/currentUser.js';
import './mixins/index.js';

import 'cropperjs/dist/cropper.css';
import 'materialize-css/dist/css/materialize.min.css';
import 'material-icons-font/material-icons-font.css';
import '@/assets/plugins/coremedia/coremedia.css';
import '@/assets/plugins/coremedia/coremedia-vjs-theme-vxl.css';
import '@/assets/plugins/downloader/downloader.js';
import '@/services/onlineoffline.service.js'
import VModal from 'vue-js-modal';
import VueDirectiveTooltip from 'vue-directive-tooltip';
import VueTheme from '@/themes';

import 'vue-directive-tooltip/dist/vueDirectiveTooltip.css'

Vue.use(VueIcons, 'v-icon');
Vue.use(require("vue-materialize/side-nav"));
Vue.use(VueLodash, { lodash: lodash });
Vue.use(VueTheme);

Vue.use(VueTheMask);
Vue.use(VueContentPlaceholders);
Vue.use(VueLazyload, {
  error: require('@/assets/images/posters/video.png'),
  attempt: 1
});
Vue.use(CoreMedia, store);
Vue.use(VModal);
Vue.use(VueDirectiveTooltip);
Vue.use(VueGtag, {
  config: {
    id: process.env.VUE_APP_UA,
    params: {
      send_page_view: false
    }
  }
});

Vue.component('CommentsBox', CommentsBox);

import './scss/style.scss'
import './assets/fonts/fonts.css'
import './assets/css/font-awesome.min.css'

new Vue({
  name: 'Root',
  router,
  store,
  beforeMount() {
    const linkEl = document.createElement("link");
    linkEl.rel = "icon";
    linkEl.href ="/favicon-pagtrust.ico";

    window.document.head.appendChild(linkEl);
  },
  mounted() {
    store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth)
    window.addEventListener('resize', () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth))
    Vue.prototype.$appName = process.env.VUE_APP_DOMAIN_TITLE;
    Vue.prototype.$appLogoSrc = require(`./assets/images/${process.env.VUE_APP_LOGO_FILE_NAME}`);
  },

  beforeDestroy() {
    window.removeEventListener('resize', () => store.commit('dom/SET_WINDOW_WIDTH', window.innerWidth))
  },
  render: h => h(AppLayout)
}).$mount('#app');
