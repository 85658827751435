var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return (_vm.loading)?_c('main',[_c('content-placeholders',[_c('content-placeholders-img',{staticClass:"course-detail-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light')}),_c('div',{staticClass:"container"},[_c('content-placeholders-text',{staticClass:"carousel-title-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light'),attrs:{"lines":1}}),_c('content-placeholders-img',{staticClass:"carousel-image-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light')}),_c('content-placeholders-text',{staticClass:"carousel-title-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light'),attrs:{"lines":1}}),_c('content-placeholders-img',{staticClass:"carousel-image-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light')}),_c('content-placeholders-text',{staticClass:"carousel-title-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light'),attrs:{"lines":1}}),_c('content-placeholders-img',{staticClass:"carousel-image-placeholder",class:(_vm.$theme.darkTheme ? 'loading-dark' : 'loading-light')})],1)],1)],1):_c('main',{class:{
  'page-main-content': _vm.isPreview,
  'page-main-content-compressed': _vm.isPreview && _vm.isCompressed(),
}},[_vm._l((_vm.carouselItemsFiltered),function(classData){return _c('div',{key:classData.id,staticClass:"row",attrs:{"id":'content-'+ classData.id}},[_c('div',{ref:'swiper-container-' + classData.id,refInFor:true,staticClass:"container"},[_c('Carousel',{attrs:{"items":classData.learningObjects,"metadata":{
            title: classData.title,
            isPreview: true,
            state: classData.state,
            releasedDate: classData.releasedDate,
            classId: classData.id,
            moduleId: classData.moduleId ? classData.moduleId : null,
            disciplineId: classData.disciplineId ? classData.disciplineId : null
          }},on:{"click":_vm.navigateToClass,"openLeftMenu":_vm.openLeftMenu}})],1)])}),_c('div',{staticClass:"load-more center"},[(_vm.carouselItemsFiltered.length != _vm.carouselItems.length)?_c('a',{staticClass:"waves-effect btn-load-more center",attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.loadMore()}}},[_c('i',{staticClass:"material-icons"},[_vm._v("add_box")]),_vm._v("Carregar mais ")]):_vm._e()])],2)}
var staticRenderFns = []

export { render, staticRenderFns }