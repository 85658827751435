<template>
  <header class=" header-page" :style="((selectedEnroll || isTrustflix) ? ($theme.darkTheme ? 'bg-color-modal-dark' : 'bg-color-modal-light') : 'bg-color-modal-light')">
    <div class="container">
      <nav :class="{'header':true, 'z-depth-0':true, 'valign-wrapper':true}" :style="((selectedEnroll || isTrustflix) ? ($theme.darkTheme ? 'background: #0d0d13' : 'background: #ffffff') : 'background: #ffffff')">


          <a href="javascript:;" @click="navigateBack()" >
            <i class="material-icons" :style="(!(selectedEnroll  || isTrustflix) ? 'color: #0564b1' : {color: $theme.primaryColor})">arrow_back</i>


          </a>
          <div class="page-title center" :style="($theme.darkTheme ? 'color: #bfbfbf' : 'color: #0d0d13')" >{{pageName}}</div>
      </nav>
      </div>
  </header>
</template>

<script>

import UiToastList from '@/components/UI/UiToastList';
import { mapState, mapGetters } from "vuex";

export default {
  name: 'HeaderPage',
  components:{
    UiToastList
  },
  props:{
      pageName:{ type:[String], require: true },
      modalName: {type: [String] },
      layoutTheme: {type: [String] },
  },
   computed:{
   ...mapState({
      selectedEnroll: state => state.enroll.current,
    }),
    isTrustflix() {
      return this.$router.history.current.name === "trustflix";
    }
},
  data () {
    return {

    }
  },
  methods: {
    navigateBack() {
      this.$modal.hide(this.modalName);
    }
  }

}


</script>
