var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('header',{class:{ 'trustflix-header': true, 'navbar-fixed': this.$store.state.config.fixedMenu }},[_c('nav',{staticClass:"main-menu"},[(!_vm.loading)?_c('div',{staticClass:"box-menu-top container navbar-content"},[_c('div',{class:{ 'nav-wrapper': true, 'menu-align': true }},[_c('a',{class:{ 'menu-align ml0 mr1': true, 'sidenav-trigger': !_vm.isDesktop && !_vm.sidenavIsOpen },attrs:{"href":"javascript:;","data-target":_vm.isDesktop ? '' : 'trustflix-left-menu'},on:{"click":_vm.toggleSideMenu}},[_c('IconMenu')],1),(_vm.logo && !_vm.shopwindowIsLoading)?_c('img',{ref:"shopwindowLogo",staticClass:"valign-wrapper img-logo-header",attrs:{"src":(_vm.logo),"alt":""},on:{"error":_vm.onLogoError,"load":_vm.onLogoLoad}}):_vm._e()]),_c('div',{attrs:{"id":"menu-right-group"}},[_c('a',{staticClass:"sidenav-trigger",attrs:{"href":"javascript:;","data-target":"notifications-sidenav"},on:{"click":function($event){return _vm.readNotifications()}}},[_c('div',{directives:[{name:"show",rawName:"v-show",value:(_vm.unreadNotifications.length > 0),expression:"unreadNotifications.length > 0"}],staticClass:"notify-count"},[_vm._v(" "+_vm._s(_vm.unreadNotifications.length)+" ")]),_c('i',{class:{ 'material-icons': true },style:({ color: _vm.$theme.secondaryText })},[_vm._v("notifications_none")])]),_c('a',{staticClass:"sidenav-trigger",attrs:{"href":"javascript:;","data-target":"sidenav-3","id":"sidenav3"}},[_c('div',{staticClass:"menu-right-icon",style:({ background: _vm.shopwindowIsLoading ? '#0D0D13' : '' })},[(_vm.userLogoVisible)?_c('img',{staticClass:"menu-user-img",attrs:{"src":_vm._f("imageHandler")(_vm.$profilePic),"alt":""},on:{"error":function($event){_vm.userLogoVisible = false}}}):[_vm._v(_vm._s(_vm.$firstLetter))]],2)])])]):_vm._e()]),_c('ul',{class:{
      'sidenav sidenav-left': true,
      'sidenav-expanded': _vm.sidenavIsOpen && !_vm.shopwindowIsLoading
    },attrs:{"id":"trustflix-left-menu"}},[_c('trustflix-sidenav',{on:{"showModal":_vm.showModal,"clickInside":_vm.closeIfMobile}})],1),_c('ul',{class:{ sidenav: true, 'side-right': true },style:(_vm.selectedEnroll
        ? _vm.$theme.darkTheme
          ? 'background-color: #0D0D13'
          : 'background-color: #ffffff'
        : 'background-color: #0D0D13'),attrs:{"id":"notifications-sidenav"}},[_c('li',[_c('div'),_c('div',{staticClass:"menu-notify-header container center-align",style:(_vm.selectedEnroll
            ? _vm.$theme.darkTheme
              ? 'color: #bfbfbf'
              : 'color: #333333'
            : 'color: #bfbfbf')},[_vm._m(0),_c('span',[_vm._v("NOTIFICAÇÕES")])])]),_vm._l((_vm.notifications),function(notification){return _c('li',{key:'notification_' + notification.id},[_c('div',{staticClass:"container notify",on:{"click":function($event){return _vm.redirectToNotification(notification)}}},[_c('div',[_c('div',{staticClass:"notify-title",style:(!_vm.selectedEnroll ? 'green' : { color: _vm.$theme.primaryColor })},[_vm._v(" "+_vm._s(notification.title)+" ")]),_c('div',{staticClass:"notify-text line-clamp-4",style:(_vm.selectedEnroll
                ? _vm.$theme.darkTheme
                  ? 'color: #bfbfbf'
                  : 'color: #333333'
                : 'color: #bfbfbf')},[_vm._v(" "+_vm._s(notification.text)+" ")]),_c('div',{staticClass:"notify-date",style:(_vm.selectedEnroll
                ? _vm.$theme.darkTheme
                  ? 'color: #bfbfbf'
                  : 'color: #333333'
                : 'color: #bfbfbf')},[_vm._v(" "+_vm._s(_vm.brDate(notification.createAt))+" ")])])])])})],2),_c('ul',{class:{ sidenav: true, 'side-right': true },style:(_vm.selectedEnroll
        ? _vm.$theme.darkTheme
          ? 'background: #0d0d13'
          : 'background: #ffffff'
        : 'background: #0d0d13'),attrs:{"id":"sidenav-3"}},[_c('li',[_c('div',{class:{
          'user-view': false,
          'center-align': true,
          'menu-right-header': true
        }},[_c('AppLogo',{staticClass:"img-logo-header-g",attrs:{"darkMode":""}})],1)]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.selectedEnroll),expression:"selectedEnroll"}]},[_c('a',{class:{ 'sidenav-close': true },style:(_vm.selectedEnroll
            ? _vm.$theme.darkTheme
              ? 'color: #ffffff'
              : 'color: #333333'
            : 'color: #ffffff'),attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"material-icons"},[_c('IconHome')],1),_vm._v(" Página inicial ")])]),_c('li',[_c('a',{class:{ 'sidenav-close': true },style:(_vm.selectedEnroll
            ? _vm.$theme.darkTheme
              ? 'color: #ffffff'
              : 'color: #333333'
            : 'color: #ffffff'),attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.showModal('profile')}}},[_c('i',{staticClass:"material-icons"},[_c('IconProfile',{attrs:{"selectedEnroll":_vm.selectedEnroll}})],1),_vm._v(" Perfil ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(!_vm.uniqueShopWindowId),expression:"!uniqueShopWindowId"}]},[_c('a',{class:{ 'sidenav-close': true },style:(_vm.selectedEnroll
            ? _vm.$theme.darkTheme
              ? 'color: #ffffff'
              : 'color: #333333'
            : 'color: #ffffff'),attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.navTo('/p/my-courses')}}},[_c('i',{staticClass:"material-icons"},[_c('IconMyCourses')],1),_vm._v(" Meus Cursos ")])]),_c('li',[_c('a',{class:{ 'sidenav-close': true },style:(_vm.selectedEnroll
            ? _vm.$theme.darkTheme
              ? 'color: #ffffff'
              : 'color: #333333'
            : 'color: #ffffff'),attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.showModal('configuration')}}},[_c('i',{staticClass:"material-icons"},[_c('IconConfig',{attrs:{"selectedEnroll":_vm.selectedEnroll}})],1),_vm._v(" Configurações ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.hasGroups),expression:"hasGroups"}]},[_c('a',{class:{ 'sidenav-close': true },style:(_vm.selectedEnroll
            ? _vm.$theme.darkTheme
              ? 'color: #ffffff'
              : 'color: #333333'
            : 'color: #ffffff'),attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.showModal('mygroup')}}},[_c('i',{staticClass:"material-icons"},[_c('IconMyGroup')],1),_vm._v(" Meus Grupos ")])]),_c('li',{directives:[{name:"show",rawName:"v-show",value:(_vm.emiteCertificado.length),expression:"emiteCertificado.length"}]},[_c('a',{class:{ 'sidenav-close': true },style:(_vm.selectedEnroll
            ? _vm.$theme.darkTheme
              ? 'color: #ffffff'
              : 'color: #333333'
            : 'color: #ffffff'),attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.showModal('mycertificate')}}},[_c('i',{staticClass:"material-icons icon-certificate"},[_c('IconMyCertificate')],1),_vm._v(" Meus Certificados ")])]),_c('div',{staticClass:"menu-right-footer"},[_c('div',[_c('ul',[_c('li',[_c('a',{style:(_vm.selectedEnroll
                  ? _vm.$theme.darkTheme
                    ? 'color: #ffffff'
                    : 'color: #333333'
                  : 'color: #ffffff'),attrs:{"href":"javascript:;"},on:{"click":function($event){return _vm.logout()}}},[_c('i',{staticClass:"material-icons"},[_c('IconLogout',{attrs:{"selectedEnroll":_vm.selectedEnroll}})],1),_vm._v(" Sair ")])])])]),_c('div',{staticClass:"footer-version",style:(_vm.selectedEnroll
            ? _vm.$theme.darkTheme
              ? 'color: #ffffff'
              : 'color: #333333'
            : 'color: #ffffff'),attrs:{"title":'Tema: ' + _vm.layoutTheme}},[_vm._v(" "+_vm._s(_vm.version)+" ")])])])])}
var staticRenderFns = [function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('a',{attrs:{"href":"javascript:;"}},[_c('i',{staticClass:"material-icons left sidenav-close"},[_vm._v("arrow_back")])])}]

export { render, staticRenderFns }