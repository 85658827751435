<template>
  <svg
    xmlns="http://www.w3.org/2000/svg"
    xmlns:xlink="http://www.w3.org/1999/xlink"
    width="20"
    height="20"
    viewBox="0 0 20 20"
  >
    <defs>
      <path
        id="icon-profile"
        d="M15.5 14.833V16.5c0 .5-.333.833-.833.833-.5 0-.834-.333-.834-.833v-1.667c0-1.416-1.083-2.5-2.5-2.5H4.667c-1.417 0-2.5 1.084-2.5 2.5V16.5c0 .5-.334.833-.834.833-.5 0-.833-.333-.833-.833v-1.667c0-2.333 1.833-4.166 4.167-4.166h6.666c2.334 0 4.167 1.833 4.167 4.166zm-11.667-10C3.833 2.5 5.667.667 8 .667c2.333 0 4.167 1.833 4.167 4.166C12.167 7.167 10.333 9 8 9 5.667 9 3.833 7.167 3.833 4.833zm1.667 0c0 1.417 1.083 2.5 2.5 2.5s2.5-1.083 2.5-2.5c0-1.416-1.083-2.5-2.5-2.5s-2.5 1.084-2.5 2.5z"
      />
    </defs>
    <g fill="none" fill-rule="evenodd" transform="translate(2 1)">
      <mask id="icon-profile-b" fill="#fff">
        <use xlink:href="#icon-profile" />
      </mask>
      <use :fill="(!selectedEnroll ? '#0564b1' : $theme.primaryColor)" fill-rule="nonzero" xlink:href="#icon-profile" />
      <g fill="none" mask="url(#icon-profile-b)">
        <path d="M0 0H20V20H0z" transform="translate(-2 -1)" />
      </g>
    </g>
  </svg>
</template>

<script>
export default {
  props: {
    selectedEnroll: { default: 0 },
  },
};
</script>