import {
  BaseService
} from './base.service'
import {
  ErrorWrapper,
  ResponseWrapper
} from './util'

export class CertificateService extends BaseService {
  static get entity() {
    return 'certificate'
  }


  static async getCertificate() {
    try {
      const response = await this.request({
        auth: true
      }).get(`${process.env.VUE_APP_EAD_API_URL}/certificates`);

      return new ResponseWrapper({
        status: 200,
        data: {
          success: true
        }
      }, response.data);


    } catch (error) {
      const message = error.response.data && typeof (error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }


  static async emitCertificate(applicationId, courseId, courseName, enrollId, studentId, progress, createAt, availableForIssue) {
    try {
      const response = await this.request({
        auth: true
      }).post(`${process.env.VUE_APP_EAD_API_URL}/certificates`,
        { ApplicationId: applicationId, CourseId: courseId, CourseName: courseName, EnrollId: enrollId, StudentId: studentId, Progress: progress, CreateAt: createAt, availableForIssue: availableForIssue });
        var x = new ResponseWrapper({ status: response.status, data: { success: true } }, response.data);
        window.open(x.data.url);
        return x;
    } catch (error) {
      const message = error.response.data && typeof (error.response.data) === 'object' ? error.response.data.error : error.message
      throw new ErrorWrapper(error, message)
    }
  }
}
