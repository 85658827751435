<template>
  <modal
  :classes="layoutTheme == 'dark' ? 'bg-color-modal-dark' : 'bg-color-modal-light'"
  name="support" height="100%" :width="calculatedWidth" :adaptive="true" :pivotX="1"     :shiftX="1" :scrollable="true" @opened="load()" transition="slide-left" overlayTransition="">
    <main :class="{'main-support':true, 'bg-color-modal-dark': layoutTheme == 'dark', 'bg-color-modal-light': layoutTheme == 'light'}">
      <div class="main-support">
        <HeaderPage pageName="Suporte" modalName="support" :layoutTheme="layoutTheme" />

        <div class="container">
          <div class="item-title">Introdução</div>
        </div>
        <div class="row menu-aco">
          <ul class="collapsible">
            <li>
              <div class="collapsible-header container">
                <div class="support-header">Como usar o aplicativo EAD no iPhone e iPad</div>
                <i class="material-icons iconadd menu-arrow">keyboard_arrow_right</i>
                <i class="material-icons iconremove menu-arrow">keyboard_arrow_down</i>
              </div>

              <div class="collapsible-body container icon-updown">
                <div class="support-body">[Texto em definição]</div>
              </div>
            </li>
            <li>
              <div class="collapsible-header container">
                <div class="support-header">Como usar o aplicativo EAD no iPhone e iPad</div>
                <i class="material-icons iconadd menu-arrow">keyboard_arrow_right</i>
                <i class="material-icons iconremove menu-arrow">keyboard_arrow_down</i>
              </div>

              <div class="collapsible-body container">
                <div class="support-body">[Texto em definição]</div>
              </div>
            </li>
          </ul>
        </div>

        <div class="container">
          <div class="item-title">Solução de Problemas</div>
        </div>
        <div class="row menu-aco">
          <ul class="collapsible">
            <li>
              <div class="collapsible-header container">
                <div class="support-header">A legenda não aparece</div>
                <i class="material-icons iconadd menu-arrow">keyboard_arrow_right</i>
                <i class="material-icons iconremove menu-arrow">keyboard_arrow_down</i>
              </div>

              <div class="collapsible-body container">
                <div class="support-body">[Texto em definição]</div>
              </div>
            </li>
            <li>
              <div class="collapsible-header container">
                <div class="support-header">Por que não consigo fazer download dos materiais?</div>
                <i class="material-icons iconadd menu-arrow">keyboard_arrow_right</i>
                <i class="material-icons iconremove menu-arrow">keyboard_arrow_down</i>
              </div>

              <div class="collapsible-body container">
                <div class="support-body">[Texto em definição]</div>
              </div>
            </li>
          </ul>
        </div>

        <div class="container">
          <div class="item-title">Gerenciar minha conta</div>
        </div>

        <div class="row menu-aco">
          <ul class="collapsible">
            <li>
              <div class="collapsible-header container">
                <div class="support-header">Não consigo acessar minha conta</div>
                <i class="material-icons iconadd menu-arrow">keyboard_arrow_right</i>
                <i class="material-icons iconremove menu-arrow">keyboard_arrow_down</i>
              </div>

              <div class="collapsible-body container">
                <div class="support-body">[Texto em definição]</div>
              </div>
            </li>
            <li>
              <div class="collapsible-header container">
                <div class="support-header">Não consigo redefinir minha senha</div>
                <i class="material-icons iconadd menu-arrow">keyboard_arrow_right</i>
                <i class="material-icons iconremove menu-arrow">keyboard_arrow_down</i>
              </div>

              <div class="collapsible-body container">
                <div class="support-body">[Texto em definição]</div>
              </div>
            </li>
          </ul>
        </div>

        <div class="fixed-action-btn">
          <a class="btn-floating btn-large waves-circle btn-chat" @click="sendMessage">
            <i class="large material-icons">chat_bubble_outline</i>
          </a>
        </div>
      </div>
    </main>
  </modal>
</template>

<script>
import HeaderPage from "@/layout/HeaderPage";
import M from "materialize-css";
import { mapState } from "vuex";
import { isMobile } from 'mobile-device-detect';

export default {
  name: "Support",
  props:['layoutTheme'],
  components: {
    HeaderPage
  },
  data() {
    return {};
  },
  async mounted() {

  },
  computed: {
    ...mapState({
      currentEnroll: state => state.enroll.current,
      notes: state => state.note.notes
    }),
    calculatedWidth() {
            if(isMobile)
                return '100%';
            else
                return '30%';
        }
  },
  methods: {
    async load() {
      this.loading = true;

      this.loading = false;

      this.$nextTick(() => {
        var accordion = document.querySelectorAll(".collapsible");
        M.Collapsible.init(accordion);
      });
    },
    sendMessage() {
      //this.$store.dispatch('toast/NEW', {message: 'Não implementado'});
    }
  }
};
</script>

<style scoped>
.collapsible {
  /*background-color: #131318 !important;*/
}

.main-support {
  /*height: 100vh;*/
}

.support-header {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 5rem;
  /*background-color: #131318;*/

  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  /*color: #bfbfbf;*/
}

.support-body {
  font-family: Montserrat;
  font-size: 15px;
  font-weight: normal;
  font-stretch: normal;
  font-style: normal;
  line-height: 1.6;
  letter-spacing: normal;
  /*color: #797979;*/
  /*background-color: #131318;*/
  margin-bottom: 2rem;
}

.btn-chat {
  background-color: #0564b1;
}

li .collapsible-header {
  justify-content: space-between;
  background-color: inherit !important;
  color: inherit !important;
  align-items: center;
}

li .collapsible-header .menu-arrow {
  /*color: #bfbfbf;*/
  margin-right: 0;
  transition: transform 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 2rem;
  height: 2rem;
}

.btn-floating:hover {
  background-color: #0564b1;
}

.fixed-action-btn {
  bottom: 50px;
}
</style>
