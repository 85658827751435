import { FormsService } from '@/services/forms.service';
import Vue from 'vue';

export default {
    namespaced: true,
    state: {
        current: null,
        answerFormId: null,
        currentAnswers: null,
        allowAnswer: null,
        result: null
    },
    getters: {
        getCurrent(state) {
            return Object.assign({}, state.current);
        }
    },
    actions: {
        getForm({ commit }, payload) {
            return FormsService.getForm(payload.formId)
                .then(response => {
                    commit('SET_CURRENT', response.data);
                })
                .catch(error => {
                    console.log(error.message);
                    commit('toast/NEW', { type: 'error', message: 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.' }, { root: true });
                });
        },

        canAnswer({commit}, payload) {
            commit('CLEAR_CURRENT');
            return FormsService.canAnswer(payload.formId)
                .then(response => {
                    commit('SET_ALLOW_ANSWER', response.data);
                    if(response.data) {
                        FormsService.getForm(payload.formId)
                            .then(responses => {
                                commit('SET_CURRENT', responses.data);
                                return true;
                            });
                    }
                    else {
                        commit('SET_ALLOW_ANSWER', false);
                    }
                })
                .catch(error => {
                    commit('SET_ALLOW_ANSWER', false);
                    console.log(error.message);
                    commit('toast/NEW', { type: 'error', message: 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.' }, { root: true });
                });
        },

        createResponseInstance({commit}, payload) {
            return FormsService.createResponseInstance(payload.formId)
                .then(response => {
                    commit('SET_ANSWER_FORM_ID', response.data);
                })
                .catch(error => {
                    console.log(error.message);
                    commit('toast/NEW', { type: 'error', message: 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.' }, { root: true });
                });
        },

        answerQuestion({commit}, payload) {
            var answer = {
                AnswerFormId: payload.AnswerFormId, //int
                Answer: payload.Answer, //string
                Alternatives: payload.Alternatives //[ { Id: 0, Value: false } ] //apenas a resposta selecionada
            };
            return FormsService.answerQuestion(payload.FormId, payload.QuestionId, answer)
                .then(response => {
                    commit('SET_QUESTION_ANSWER', response.data);
                })
                .catch(error => {
                    console.log(error.message);
                    commit('toast/NEW', { type: 'error', message: 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.' }, { root: true });
                    commit('SET_QUESTION_ANSWER', null);
                });
        },
        
        clearAnswer({commit}) {
            commit('CLEAR_ANSWER');
        },
        
        answerForm({commit}, payload) {
            return FormsService.answerForm(payload.FormId, payload.Answers)
                .then(response => {
                    return response.status == 200;
                })
                .catch(error => {
                    console.log(error.message);
                    commit('toast/NEW', { type: 'error', message: 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.' }, { root: true });
                });
        },

        getFormResult({commit}, payload) {
            return FormsService.getFormResult(payload.FormId)
                .then(response => {
                    commit('SET_RESULT', response.data);
                })
                .catch(error => {
                    console.log(error.message);
                    commit('toast/NEW', { type: 'error', message: 'Ocorreu uma falha. Por favor, tente novamente. Caso o erro persista, entre em contato com o suporte.' }, { root: true });
                });
        },

        setCurrentForm({ commit }, form) {
            commit('SET_CURRENT', form);
        }
    },
    mutations: {
        SET_CURRENT(state, data) {
            Vue.set(state, 'current', data);
        },
        CLEAR_CURRENT(state) {
            Vue.set(state, 'current', null);
            Vue.set(state, 'answerFormId', null);
            Vue.set(state, 'currentAnswers', null);
            Vue.set(state, 'allowAnswer', null);
        },
        SET_QUESTION_ANSWER(state, data) {
            Vue.set(state, 'currentAnswers', data);
        },
        SET_WAIT(state, data) {
            Vue.set(state, 'waitApiResponse', data);
        },
        SET_ALLOW_ANSWER(state, data) {
            Vue.set(state, 'allowAnswer', data);
        },
        SET_ANSWER_FORM_ID(state, data){
            Vue.set(state, 'answerFormId', data);
        },
        CLEAR_ANSWER(state) {
            Vue.set(state, 'allowAnswer', null);
            Vue.set(state, 'currentAnswers', null);
            Vue.set(state, 'result', null);
        },
        SET_RESULT(state, data) {
            Vue.set(state, 'result', data);
        }
    }
}