<template>
  <div class="row">
    <div class="col s0 m12 l12">
      <div class="row">
        <div class="col s0 m12 l12 global-result__item-label">Curso</div>
      </div>

      <div class="row clickable" @click.prevent="click(course)">
        <span class="col m10 l12 global-result__item-title">
          {{ course.name }}
        </span>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "GlobalSearchResultCourse",
  emits: ["click"],
  props: {
    course: {
      type: Object,
      required: true,
    },
  },
  methods: {
    click(module) {
      this.$emit("click", { id: module.id });
    },
  },
};
</script>
