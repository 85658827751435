<template>
  <div
    class="trustflix-progress-section"
    ref="container"
    v-if="section != null && section.content.length > 0"
  >
    <div class="box-content">
      <header class="box-content-header">
        <div class="header-textual">
          <div class="content-title">
            Continue assistindo
          </div>
          <!-- <div class="content-title">{{ section.title }}</div> -->
          <p class="content-sub">{{ section.subtitle }}</p>
        </div>

        <div class="header-carousel-bts">
          <CarouselNavPrevBT
            :class="`swiper-bt-prev swipe-button-prev-${_uid}`"
          />
          <CarouselNavNextBT
            :class="`swiper-bt-next swipe-button-next-${_uid}`"
          />
        </div>
      </header>

      <div class="box-trustflix-itens">
        <swiper ref="swiper" :options="swiperOption" @slideChange="paginate">
          <swiper-slide
            v-for="(item, index) in visibleContent"
            :key="'progressItem_' + item.Id + '_' + index"
            :style="{ 'animation-delay': `${(index % 10) * 100}ms` }"
            :title="item.name"
          >
            <a
              class="trustflix-item"
              href="javascript:;"
              @click="redirectCoursePage(item)"
            >
              <div
                :class="{
                  'item-img': true,
                  'item-img--unavailable': !getIsAvailable(item)
                }"
              >
                <img
                  v-lazy="loadImage(item)"
                  alt=""
                  srcset=""
                  loading="lazy"
                />
                <ItemLabels
                  :item="item"
                  :showProgress="false"
                  :small="true"
                  :isBuyable="showBuyOverlay(item)"
                />
              </div>
              <div class="item-name line-clamp-2">
                {{ item.name }}
              </div>
              <div class="item-percent">{{ Math.ceil(item.progress) }}%</div>
              <ProgressBar
                :value="Math.ceil(item.progress)"
                :color="primaryColor"
              />
            </a>
          </swiper-slide>
        </swiper>
      </div>
    </div>
  </div>
</template>

<script>
import { Swiper, SwiperSlide } from '@voxel/vue-awesome-swiper';
import { formatDate } from '@/filters/formatDate';
import ProgressBar from '@/components/UI/ProgressBar';
import { mapState } from 'vuex';
import '@voxel/swiper/css/swiper.css';
import CarouselNavPrevBT from '@/components/UI/CarouselNavPrevBT.vue';
import CarouselNavNextBT from '@/components/UI/CarouselNavNextBT.vue';
import ItemLabels from './ItemLabels.vue';

export default {
  name: 'ProgressSection',
  components: {
    Swiper,
    SwiperSlide,
    ProgressBar,
    CarouselNavPrevBT,
    CarouselNavNextBT,
    ItemLabels
  },
  computed: {
    ...mapState({
      section: (state) => state.shopWindow.progressSection
    }),
    sortedContent() {
      const nonCompleteContent = this.section.content.filter(
        (co) => co.progress < 100
      );
      const sortedContent = _.orderBy(nonCompleteContent, 'progress', 'desc');
      return _.uniqWith(sortedContent, this.itemComparator);
    },
    visibleContent() {
      return this.sortedContent.slice(0, this.visibleContentQtd);
    },
    isUltraWide() {
      return window.innerWidth > 1920;
    }
  },
  props: {
    primaryColor: {
      type: String
    }
  },
  mounted() {
    // this.visibleContent = this.sortedContent?.slice(0, 25);
    if (this.$refs.container)
      new ResizeObserver(this.onResize).observe(this.$refs.container);
  },
  data() {
    return {
      resizeDebounce: 0,
      visibleContentQtd: 30,
      swiperOption: {
        observer: true,
        observeParents: true,
        // ro: this.ro,
        navigation: {
          nextEl: `.swipe-button-next-${this._uid}`,
          prevEl: `.swipe-button-prev-${this._uid}`
        },
        breakpoints: {
          450: { slidesPerView: 'auto', spaceBetween: 25 },
          350: { slidesPerView: 'auto', spaceBetween: 15 },
          0: { slidesPerView: 1.1, spaceBetween: 15 }
        }
      },
      showAll: true,
      // visibleContent: []
    };
  },
  methods: {
    paginate() {
      const activeIndex = this.$refs.swiper.$swiper.activeIndex;
      const diff = this.visibleContent.length - activeIndex;

      if (diff < 20 && this.sortedContent.length > this.visibleContentQtd) {
        this.visibleContentQtd += 15;
      }
    },
    onResize() {
      clearTimeout(this.resizeDebounce);
      this.resizeDebounce = setTimeout(() => {
        this.$refs.swiper?.$swiper.update();
      }, 200);
    },
    prevItem() {
      this.$refs.swiper?.$swiper.slidePrev();
    },
    nextItem() {
      this.$refs.swiper?.$swiper.slideNext();
    },
    itemComparator(a, b) {
      return (
        a.courseId === b.courseId &&
        a.classId === b.classId &&
        a.classLearningObjectId === b.classLearningObjectId
      );
    },
    getFallbackImage(loType, formType = 'Exercise') {
      switch (loType) {
        case 'BdQ':
          switch (formType) {
            case 'Proof':
            case 'Simulated':
              return require('@/assets/images/posters/proof-simulated.png');
            case 'SurveyResearch':
              return require('@/assets/images/posters/surveyresearch.png');
            case 'Exercise':
              return require('@/assets/images/posters/exercise.png');
          }
        case 'Pdf':
          return require('@/assets/images/posters/evf-pdf-sm.png');

        case 'HtmlContent':
          return require('@/assets/images/posters/evf-html-sm.png');

        case 'ExternalContent':
          return require('@/assets/images/posters/evf-generic-sm.png');

        default:
          return require('@/assets/images/posters/evf-video-sm.png');
      }
    },
    loadImage(item) {
      const fallbackImg = this.getFallbackImage(
        item.classLearningObjectType,
        item.formType
      );
      const itemImg = item.learningObjectImage
        ? item.learningObjectImage + '?h=144'
        : fallbackImg;
      return {
        src: itemImg,
        loading: fallbackImg,
        error: fallbackImg
      };
    },
    getIsAvailable(item) {
      return (
        this.getIsPublished(item) &&
        item.enrollId &&
        !item.comingSoon &&
        !item.pendingPayment
      );
    },
    getIsPublished(item) {
      return (
        item.state === 'Published' ||
        (item.state === 'Scheduled' && !this.getIsFutureScheduled(item))
      );
    },
    getIsFutureScheduled(item) {
      return (
        item.state === 'Scheduled' &&
        (item.daysOfReleased ||
          (item.releaseDate && this.dateIsFuture(item.releaseDate)))
      );
    },
    showBuyOverlay(item) {
      return item.type === 'Course' && !item.enrollId;
    },
    dateIsFuture(date) {
      if (!date) return false;
      return this.getTimeDiff(date) > 0;
    },
    getTimeDiff(date) {
      return Number(new Date(date)) - Number(new Date());
    },
    async redirectCoursePage(item) {
      if (!this.getIsAvailable(item)) return;

      const isLearningObject = Boolean(item.learningObjectId);
      if (isLearningObject) {
        return this.$router.push({
          name: 'class',
          params: {
            enrollId: item.enrollId,
            id: item.learningObjectId
          }
        });
      }
      this.$updateTheme({
        darkTheme: true,
        secondary: '#0d0d13'
      });

      return this.$router.push({
        name: 'course',
        params: { id: item.enrollId },
        query: { redirect: item.classId ?? 'last' }
      });
    }
  }
};
</script>

<style lang="scss" scoped>
$card-width: 255px;
/* 16x9 aspect ratio */
$aspect-ratio: 56.25%;

/* .item-name {
  color: #999 !important;
} */


@keyframes slideIn {
  from {
    transform: translateY(20px);
    opacity: 0;
  }
  to {
    transform: translateY(0);
    opacity: 1;
  }
}

.progress-bar-container{
  top: 0;
}

.swiper-slide {
  height: unset;
  opacity: 0;
  animation: slideIn 300ms forwards;
}

.poster-overlay {
  position: absolute;
  top: 0;
  left: 0;
  height: 146px;
  background: rgba(0, 0, 0, 0.5);
  width: 100%;
  text-align: center;
}

.poster-overlay img {
  height: 100%;
  width: 100%;
}

.icon-carousel {
  vertical-align: middle;
  height: 19px;
}

/* trustflix */

.box-content {
  background: rgb(0, 0, 0);
  padding: 15px;
  position: relative;
}

.box-content-header {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding-right: 20px;

  .header-carousel-bts {
    display: flex;
  }
}

.content-title {
  font-weight: 600;
  border-left: 3px solid var(--primary-color);
  padding-left: 20px;
  font-size: 18px;
}

.content-sub {
  color: #ffffff80;
  margin: 5px 0 0;
  font-size: 15px;
  font-weight: 300;
}

.box-content .box-trustflix-itens {
  width: 100%;
  background: rgb(0, 0, 0);
  overflow: hidden;
  margin: 16px 0 10px;
  /* background: rgb(22, 22, 22); */
  display: flex;
  flex-direction: row;
  align-items: center;
}

.trustflix-item {
  display: flex;
  flex-direction: column;
  height: 100%;
}

.box-trustflix-itens .trustflix-item {
  /* border: 1px solid rgb(116, 116, 116); */

  width: $card-width;
  max-width: 100%;
  overflow: hidden;
}

.box-trustflix-itens .item-name {
  font-size: 20px;
  color: #bebebe;
  font-weight: 600;
}

.item-percent {
  width: 100%;
  text-align: right;
  font-size: 12px;
  color: #bebebe;
  margin-top: auto;
}

.item-img {
  position: relative;

  display: flex;
  align-items: center;
  justify-content: center;

  border-radius: 5px;
  max-width: 100%;
  background-color: #1f1f1f;

  padding-top: $aspect-ratio;

  &:hover,
  &:focus {
    cursor: pointer;
    &::before {
      background: rgba(0, 0, 0, 0.6);
    }

    &::after {
      opacity: 1;
      top: 50%;
    }
  }

  &::before,
  &::after {
    transition: all 200ms ease-in-out;
    position: absolute;
  }

  &::before {
    content: '';

    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    border-radius: 5px;
    background: rgba(0, 0, 0, 0);
    z-index: 1;
  }

  &::after {
    content: '\f04b';
    opacity: 0;
    font-family: FontAwesome;
    color: white;
    font-size: 28px;
    top: 70%;
    left: 50%;
    z-index: 2;
    transform: translate(-50%, -50%);
  }

  &.item-img--unavailable {
    filter: grayscale(1);
    cursor: not-allowed;
    &::after {
      content: '\f023';
    }
  }
}

.trustflix-item img {
  position: absolute;
  top: 0;
  width: 100%;
  height: 100%;

  border-radius: 5px;
  object-fit: cover;
}


@media screen and (max-width: 500px) {
  .box-slider-courses {
    margin: 0 10px;
  }
}

@media screen and (min-width: 350px) {
  .swiper-container {
    width: 100%;
    .swiper-slide {
      width: fit-content !important;
    }
  }
}
@media screen and (max-width: 300px) {
  .box-trustflix-itens .item-name {
    font-size: 16px;
  }
}
</style>
