<template>
  <div class="container">
    <div
      class="row item-title"
      v-if="course && course.courseName"
      :style="{
        'font-size': '20px',
        'font-weight': 'bold',
        'color': hasCustomTheme ? $theme.primaryColor : '#0564b1'
        }"
    >
      {{ course.courseName | parseCourseName }}
    </div>

    <!-- TELEGRAM -->
    <div v-if="(telegramGroups || []).length">
      <PhoneGroup
        v-for="group in telegramGroups"
        :key="group.telegramGroupId"
        type="Telegram"
        :id="group.telegramGroupId"
        :name="group.name"
        :telegramId="telegramUserId"
        :hash="group.telegramHash"
        :courseId="course && course.courseId"
        :enrollId="course && course.enrollId"
        :requestTelegramAuth="requestTelegramAuth"
      />
      <div class="row"></div>
    </div>

    <!-- WHATSAPP -->
    <div v-if="(whatsAppGroups || []).length">
      <PhoneGroup
        v-for="group in whatsAppGroups"
        :key="group.whatsAppGroupId"
        type="WhatsApp"
        :id="group.whatsAppGroupId"
        :name="group.name"
        :courseId="course && course.courseId"
        :enrollId="course && course.enrollId"
      />
      <div class="row"></div>
    </div>

    <!-- FACEBOOK -->
    <div v-if="false">
      <div v-for="group in facebookGroups" :key="group.whatsappGroupId">
        <div class="row">
          <div class="item-title col s10 left" :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }">Grupo de Facebook</div>
          <i class="material-icons no-padding left icon-link" :style="!hasCustomTheme ? '#0564b1' : { color: $theme.primaryColor }">exit_to_app</i>
          <div class="item-description col s10 left" :style="hasCustomTheme? $theme.darkTheme? 'color: #bfbfbf': 'color: #333333': 'color: #333333'">{{group.name}}</div>
        </div>
        <div class="row valign-wrapper">
          <div class="group-id col s12">
            <i class="material-icons no-padding left icon-color">mail</i>
            <div class="item-description no-padding col s11 mail-group" :style="(hasCustomTheme ? ($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333') : ('color: #333333'))">aluno@pagtrust.com.br</div>
          </div>
        </div>
        <div class="row"></div>
      </div>
    </div>

    <!-- ZOOM -->
    <div v-if="false">
      <div>
        <div class="row">
          <div class="item-title col s10 left" :style="(!hasCustomTheme ? '#0564b1' : {color: $theme.primaryColor})">Live Zoom</div>
          <i class="material-icons no-padding left icon-link" :style="(!hasCustomTheme ? '#0564b1' : {color: $theme.primaryColor})">exit_to_app</i>
        </div>
        <div class="row valign-wrapper">
          <div class="group-id col s12">
            <i class="material-icons no-padding left icon-color">mail</i>
            <div class="item-description no-padding col s11 mail-group" :style="(hasCustomTheme ? ($theme.darkTheme ? 'color: #bfbfbf' : 'color: #333333') : ('color: #333333'))">aluno@pagtrust.com.br</div>
          </div>
        </div>
        <div class="row"></div>
      </div>
    </div>

    <hr v-if="!isLast" class="course-divisor" :style="{ 'border-color': $theme.primaryColor}" />
  </div>
</template>

<script>
import { mapState } from 'vuex'
import { isMobile } from 'mobile-device-detect'
import PhoneGroup from "@/components/PhoneGroup";

export default {
  name: "CourseGroups",
  components: {
    PhoneGroup
  },
  props: {
    course: Object,
    whatsAppGroups: Array,
    telegramGroups: Array,
    facebookGroups: Array,
    hasCustomTheme: Boolean,
    requestTelegramAuth: Boolean,
    telegramUserId: Number,
    isLast: {
      type: Boolean,
      default: true
    }
  },
  computed: {
    ...mapState({
      currentEnroll: (state) => state.enroll.current,
    }),
  },
  filters: {
    parseCourseName(courseName) {
      if (!courseName) return '';

      const startsWithCourse = courseName?.toLowerCase().startsWith("curso")
      return (!startsWithCourse ? "Curso " : '') + courseName;
    }
  }
}
</script>
