import Vue from 'vue';
import $store from '../store'
import { AuthService } from "@/services/auth.service";

class OnlineOffline  {

    constructor(){
        window.addEventListener('online', this.updateOnlineStatus);
        window.addEventListener('offline', this.updateOnlineStatus);
    }

    updateOnlineStatus(e) {

        const {
            type
        } = e;
        var onLine = type === 'online';

        if(onLine)
        {
            $store.commit("toast/NEW", {
                type: "success",
                message: "Você está online"
            });
            AuthService.startKeepAlive();

        }else{
            $store.commit("toast/NEW", {
                type: "warning",
                message: "Você está offline"
            });
            AuthService.stopKeepAlive();
        }
    }
}

// singleton
Vue.prototype.$onlineOffline = Vue.prototype.$onlineOffline ? Vue.prototype.$onlineOffline : new OnlineOffline();

export default Vue.prototype.$onlineOffline;