<template>
  <LoginTemplate
    :email="email"
    :password="password"
    :isExternalUrl="isCourseUrl"
    :fetching="fetchingData"
    :submitting="loading"
    :submittingPasswordResetLink="sendingResetPasswordLink"
    :customLogoSrc="customLogoSrc"
    :showPlatformLogo="showPlatformLogo"
    :loadingLogo="loadingLogo"
    :colorsVars="defaultStyle"
    :showLogo="logoVisible"
    ref="loginTemplate"
    @input="handleInput"
    @forgotPassword="forgotPasswordRequest"
    @submit="makeLogin"
  />
</template>

<script>
import LoginTemplate from "@/components/Login.vue";
import AppLogo from "@/components/UI/AppLogo.vue";
import { AuthService } from "@/services/auth.service";
import UiToastList from "@/components/UI/UiToastList";
import { mapState } from "vuex";

export default {
  name: "Login",
  data() {
    return {
      email: process.env.VUE_APP_DEV_USER,
      password: process.env.VUE_APP_DEV_PASS,

      isCourseUrl: !window.location.href.startsWith(
        process.env.VUE_APP_EAD_URL
      ),
      error: "",
      loading: false,
      enableResetPassworLink: true,
      redirectToCourse: null,
      sendingResetPasswordLink: false,
      logoVisible: false,
      fetchingData: true,
      showPlatformLogo: window.location.href.startsWith(
        process.env.VUE_APP_EAD_URL
      ),
      customLogoSrc: null,
      loadingLogo: true,
      defaultStyle: {
        "--primary-color": "#272727"
      }
    };
  },

  components: {
    LoginTemplate,
    AppLogo,
    UiToastList
  },

  mounted() {
    if (!this.isCourseUrl) return this.$router.push({ name: "login" });

    this.loadCustomLoginPage();
    this.$gtag.pageview({
      page_title: "login",
      page_path: "/"
    });
  },

  computed: {
    ...mapState({
      enrolls: state => state.enroll.enrolls
    }),

    isTrustflix() {
      return this.$router.history.current.name === "trustflix";
    }
  },

  methods: {
    postToIframe(isCourseLogin, shopWindow, accessToken, refreshToken) {
      this.$refs.loginTemplate.sendMessageToPlatform({
        isExternalLogin: isCourseLogin,
        shopWindow,
        accessToken,
        refreshToken
      });
    },
    handleInput({ target, value }) {
      if (this[target] === undefined) return;
      this[target] = value;
    },
    async makeLogin() {
      if (this.loading) return;

      if (!this.email.trim() || !this.password.trim()) {
        this.$store.commit("toast/NEW", {
          message: "E-mail e senha obrigatórios"
        });
        return;
      }

      try {
        this.loading = true;
        await AuthService.makeLogin({
          email: this.email,
          password: this.password
        });

        this.error = "";

        await this.$store.dispatch("user/getCurrent");
        await this.$store.dispatch("notification/getNotifications");
        await this.$store.dispatch("config/getConfig");
        await this.$store.dispatch("user/updateProfilePic");
        await this.$store.dispatch("notification/initLoopTimeout");

        let shopWindow;
        try {
          shopWindow = await this.$store.dispatch(
            "shopWindow/getCurrent",
            window.location.host
          );
          if (!shopWindow)
            shopWindow = await this.$store.dispatch("shopWindow/getCurrent");
        } catch (error) {}

        await this.$store.dispatch("enroll/getUserEnroll");

        if (shopWindow || this.isCourseUrl) {
          const accessToken = AuthService.getAccessToken();
          const refreshToken = AuthService.getRefreshToken();

          const isCourseLogin = this.isCourseUrl && !shopWindow;
          this.postToIframe(
            isCourseLogin,
            shopWindow,
            accessToken,
            refreshToken
          );

          let redirectUrl = "";

          if (shopWindow) {
            redirectUrl = "p/trustflix";
            await this.$store.dispatch("shopWindow/setCurrent", shopWindow.id);
          } else if (this.isCourseUrl) {
            const courseId = this.redirectToCourse.courseId;
            const enroll = this.enrolls.find(e => {
              return e.courseId == courseId;
            });

            redirectUrl = enroll ? `p/course/${enroll.id}` : "p/my-courses";
          }

          if (redirectUrl) {
            return (window.location = `${process.env.VUE_APP_EAD_URL}${redirectUrl}`);
          }
        }

        this.$router.push("/p/my-courses");
      } catch (error) {
        if (error?.status === 401 || error?.status === 404) {
          this.error = "Usuário e/ou senha não encontrado";
          this.$store.commit("toast/NEW", { message: this.error });
          this.loading = false;
          return;
        }

        this.$store.commit("toast/NEW", {
          type: "error",
          message: error.message
        });
        this.loading = false;
      }
    },

    async forgotPasswordRequest() {
      if (!this.enableResetPassworLink) return;

      if (!this.email.trim()) {
        this.$store.commit("toast/NEW", { message: "Informe seu e-mail" });
        return;
      }

      this.sendingResetPasswordLink = true;

      try {
        const forgotPwdPayload = { email: this.email, sendEmail: true };

        if (this.redirectToCourse) forgotPwdPayload.url = window.location.host;

        await AuthService.forgotPasswordRequest(forgotPwdPayload);
        this.$store.commit("toast/NEW", {
          message:
            "Um e-mail foi enviado com o link para cadastrar uma nova senha"
        });

        this.enableResetPassworLink = false;

        setTimeout(
          function() {
            this.enableResetPassworLink = true;
          }.bind(this),
          20000
        );
      } catch (error) {
        if ((error && error.status === 401) || error.status === 404) {
          this.error = "Usuário não encontrado";
          this.$store.commit("toast/NEW", { message: this.error });
          return;
        }

        this.$store.commit("toast/NEW", {
          type: "error",
          message: error.message
        });
      }

      this.sendingResetPasswordLink = false;
    },
    setPrimaryColor(color) {
      this.defaultStyle["--primary-color"] = color || "#06BA94";
    },
    async loadCustomLoginPage() {
      if (!this.isCourseUrl) {
        this.logoVisible = true;
        this.showPlatformLogo = true;
        this.loadingLogo = false;
        this.setPrimaryColor();
        this.fetchingData = false;
        return;
      }
      this.fetchingData = true;

      const resp = await AuthService.getAppIdCourseId();
      this.redirectToCourse = resp.data;

      const shopWindowData =
        (resp.data.shopWindow?.enabled && resp.data.shopWindow) || undefined;

      const logoPath = `${process.env.VUE_APP_CDN_URL}${resp.data.applicationId}/courses/${resp.data.courseId}.png`;
      this.setPrimaryColor(
        shopWindowData?.primaryColor || resp.data?.primaryColor
      );

      if (this.imageExists(logoPath)) {
        this.customLogoSrc = logoPath;
        this.showPlatformLogo = false;
      } else {
        this.showPlatformLogo = true;
      }

      this.logoVisible = true;
      this.loadingLogo = false;
      this.fetchingData = false;
    },

    onEmailEnter() {
      if (this.password && this.password.length) this.makeLogin();
      else if (this.$refs.password) this.$refs.password.focus();
    },
    defaultImg() {
      this.showPlatformLogo = true;
    },

    imageExists(image_url) {
      const http = new XMLHttpRequest();
      // Bypassing the cache according to Mozilla docs
      const key = (/\?/.test(image_url) ? "&" : "?") + new Date().getTime();
      http.open("HEAD", `${image_url}?key=${key}`, false);

      try {
        http.send();
      } catch (err) {}

      return http.status == 200 || http.status == 304;
    }
  }
};
</script>

<style lang="scss" scoped>
@keyframes fadeIn {
  from {
    opacity: 0;
  }

  to {
    opacity: 1;
  }
}

@keyframes walkIn {
  from {
    transform: translateY(50px);
    opacity: 0;
  }

  to {
    transform: translateY(0);
    opacity: 1;
  }
}

@-webkit-keyframes fadeIn {
  from {
    -webkit-opacity: 0;
  }

  to {
    -webkit-opacity: 1;
  }
}

@-webkit-keyframes walkIn {
  from {
    -webkit-transform: translateY(50px);
    -webkit-opacity: 0;
  }

  to {
    -webkit-transform: translateY(0);
    -webkit-opacity: 1;
  }
}

@font-face {
  font-family: "Material Icons";
  font-display: block !important;
}

#box-login {
  background-color: black;
  height: unset;
  min-height: 100vh;
  padding: 0;

  .login-container {
    width: 500px;
    max-width: 90vw;

    animation: fadeIn 1s forwards;
    -webkit-animation: fadeIn 1s forwards;

    .login-logo {
      height: 120px;

      .vue-content-placeholders {
        width: 180px;
        height: 100px;

        .vue-content-placeholders-img {
          height: 100px;
        }
      }

      .logo-cliente {
        max-width: 180px;
        animation: walkIn 1s forwards;
        -webkit-animation: walkIn 1s forwards;
      }
    }

    .login-main {
      background-color: #141414;
      box-shadow: rgba(29, 29, 29, 0.81) -15px 15px 90px 10px;
      border: 1px solid rgba(255, 255, 255, 0.05);

      border-radius: 30px;

      h1 {
        user-select: none;
        padding: 0 48px;
        font-weight: 300;
        font-size: 36px;
        margin: 38px 0;
        text-align: center;
      }

      .btn-login-trustflix,
      #form-divider {
        transition: background 300ms ease;
      }

      #form-divider {
        height: 6px;
        width: 100%;
        background: linear-gradient(
          90deg,
          black 18%,
          var(--primary-color) 100%
        );
        background-size: 200%;
        display: block;

        &:not(.waiting-data) {
          background-size: 100%;
        }
      }

      .login-form {
        padding: 32px 72px 48px;
      }

      span,
      .overlay-txt {
        color: white !important;
      }

      input {
        background-color: rgba(255, 255, 255, 0.05);
        border: 1px solid transparent !important;

        &#login,
        &#password {
          border-radius: 12px;
        }

        &:-webkit-autofill,
        &:-webkit-autofill:hover,
        &:-webkit-autofill:focus,
        &:-webkit-autofill:active {
          border: 1px solid gray !important;
          -webkit-text-fill-color: white !important;
          -webkit-box-shadow: #292929 0px 0px 0px 1000px inset !important;
          background-color: #292929 !important;
          background-clip: content-box !important;
        }

        &:focus,
        &:-webkit-autofill:focus {
          border-color: var(--primary-color) !important;
        }

        & ~ label {
          margin-left: 3rem;
          user-select: none;

          &.active {
            color: var(--primary-color) !important;
            filter: contrast(0.5);
          }
        }

        & ~ span.checkbox-box::after {
          background-color: transparent;
        }

        &:checked ~ span.checkbox-box::after {
          background-color: var(--primary-color);
          border-color: var(--primary-color);
        }
      }

      .input-field {
        position: relative;

        & > label:not(.label-icon).active {
          transform: translateY(-18px) scale(0.8);
        }

        & i,
        & svg {
          position: absolute;
          width: 3rem;
          font-size: 1.5rem;
          height: 1.5rem;
          top: 0.85rem;
          text-align: center;
        }

        input {
          padding-left: 3rem;
          width: calc(100% - 3rem);
        }
      }

      @media screen and (max-width: 500px) {
        h1 {
          font-size: 6vw;
        }

        .login-form {
          padding: 32px 28px;
        }
      }

      @media screen and (max-width: 320px) {
        .login-password-options {
          flex-direction: column;

          & > div {
            margin: 6px 0;
          }
        }

        .btn-login-trustflix {
          margin-top: 28px;
        }
      }
    }
  }
}

.login-container {
  user-select: none;
}

.login {
  width: 100%;
  height: 70%;
  display: flex;
  justify-content: center;
  align-items: center;

  .main {
    padding: 30px 15px;
    background: #fff;
    width: 400px;
    border-radius: 2px;
    box-shadow: 0 11px 15px -7px rgba(0, 0, 0, 0.2),
      0 24px 38px 3px rgba(0, 0, 0, 0.14), 0 9px 46px 8px rgba(0, 0, 0, 0.12);

    .header {
      text-align: center;
    }

    .buttons {
      display: flex;
      justify-content: flex-end;
    }

    .error {
      background-color: red;
      padding: 10px;
      font-size: 12px;
      opacity: 1;
      transition: all 0.5s;
    }
  }
}

.spinner-layer {
  border-color: #eee !important;
}

.forgot {
  border-color: #0564b1 !important;
}

.btn-login-trustflix {
  background-color: var(--primaryColor) !important;
  background-image: linear-gradient(
    180deg,
    var(--primary-color) 1%,
    rgba(0, 0, 0, 0.1) 400%
  );
  width: 100%;
  border-radius: 7px;
  height: 3rem;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 15px;
  line-height: 1.38;
  letter-spacing: normal;
  color: #ffffff;
  padding: 0 40px;
  font-weight: 500;
  white-space: nowrap;
  margin-top: 32px;

  .preloader-wrapper.small {
    width: 24px;
    height: 24px;
    margin-top: 0;
  }
}
</style>
