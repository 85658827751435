<template>
  <li class="ai-quiz-question">
    <div>
      <p class="question-label">
        {{ question }}
      </p>

      <ul class="question-alternatives-list">
        <li
          v-for="(alternative, $alternativeIndex) in alternatives"
          class="question-alternative"
          :class="
            isSubmitted
              ? {
                  'question-alternative--correct':
                    alternative === correctAnswer,
                  'question-alternative--correct-chosen':
                    alternative === userChoice && alternative === correctAnswer,
                  'question-alternative--incorrect':
                    alternative === userChoice && alternative !== correctAnswer,
                }
              : {}
          "
          :title="
            isSubmitted && alternative === correctAnswer
              ? 'Alternativa correta'
              : ''
          "
          :key="questionId | formatQuestionOptionId($alternativeIndex)"
        >
          <div class="question-alternative-container">
            <label>
              <input
                v-show="!isSubmitted"
                :disabled="isSubmitted"
                type="radio"
                class="question-alternative-input"
                :id="questionId | formatQuestionOptionId($alternativeIndex)"
                :name="'question#' + questionId"
                :value="alternative"
                :checked="userChoice === alternative"
                @input="$emit('update:userChoice', alternative)"
              />
              <div v-if="isSubmitted" style="width: 32px">
                <i
                  v-if="alternative === userChoice"
                  class="material-icons no-padding right icon-color"
                  style="font-size: 16px"
                  >{{
                    alternative === userChoice && alternative === correctAnswer
                      ? "check_circle"
                      : "cancel"
                  }}</i
                >
              </div>

              <span class="question-alternative-label">
                {{ alternative }}
              </span>
            </label>
          </div>
        </li>
      </ul>
    </div>
  </li>
</template>

<script>
export default {
  name: "AiQuizQuestion",
  props: {
    questionId: Number,

    question: String,
    alternatives: { type: Array, default: () => [] },
    correctAnswer: String,

    userChoice: String,

    isSubmitted: Boolean,
  },
  filters: {
    formatQuestionOptionId(questionIndex, alternativeIndex) {
      return `question#${questionIndex + 1}-alternative#${
        alternativeIndex + 1
      }`;
    },
  },
};
</script>

<style scoped lang="scss">
.question-alternatives-list {
  padding-left: 0 !important;

  li {
    list-style: none !important;

    &.question-alternative--correct {
      span,
      i {
        color: #317033;
      }
    }

    &.question-alternative--correct-chosen {
      span,
      i {
        color: #4caf50;
      }
    }

    &.question-alternative--incorrect {
      span {
        text-decoration: line-through;
      }
      span,
      i {
        color: #af4c4c;
      }
    }

    .question-alternative-input:disabled,
    .question-alternative-input:disabled + span {
      cursor: default;
    }

    .question-alternative-label {
      margin-left: 1rem;
      min-height: fit-content !important;
      display: inline-table;
    }

    .question-alternative-container label {
      display: flex;
      align-items: center;
    }
  }
}
</style>
