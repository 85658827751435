<template>
    <div class="loading-page">
        <SpinnerWave color="#666" />
        <iframe
            ref="courseIframe"
            :src="appUrl + 'login-integration'"
            width="0"
            height="0"
            style="display: none"
        ></iframe>
    </div>
</template>

<script>
import SpinnerWave from "@/components/SpinnerWave.vue";
import { AuthService } from "@/services/auth.service";
import { mapState } from "vuex";

export default {
  name: "Loading",
  data() {
    return {
      appUrl: process.env.VUE_APP_EAD_URL
    };
  },

  components: {
    SpinnerWave
  },

  computed: {
    ...mapState({
        enrolls: state => state.enroll.enrolls
    })
  },

  async mounted() {
    let hash = this.$route.query.h;
    let courseId = this.$route.query.c;
    //this.isExternalUrl = window.location.href != process.env.
    if(hash && courseId){
       await AuthService.integrationLogin(hash);        
       await this.$store.dispatch("user/getCurrent");
       await this.$store.dispatch("notification/getNotifications");
       await this.$store.dispatch("config/getConfig");
       await this.$store.dispatch("user/updateProfilePic");
       await this.$store.dispatch("notification/initLoopTimeout");
       await this.$store.dispatch("enroll/getUserEnroll");
      
      const enroll = this.enrolls.find(e => {
        return e.courseId == courseId;
      });
      if (!enroll || !enroll.id) {
        this.$store.commit("toast/NEW", {
          type: "error",
          message: "Você não tem uma matrícula válida para esse curso.",
          page: "login"
        });
        this.$router.push({ name: "login" });
        return;
      }

      const accessToken = AuthService.getAccessToken();
      const refreshToken = AuthService.getRefreshToken();

      this.sendMessageToPlatform({
        accessToken: accessToken,
        refreshToken: refreshToken
      });

      window.location = `${process.env.VUE_APP_EAD_URL}p/course/${enroll.id}`;
    }
  },

  methods: {
    sendMessageToPlatform({
      accessToken,
      refreshToken
    }) {
      this.$refs.courseIframe.contentWindow.postMessage(
        {
          courseUrl: window.location.href,
          shopWindow: undefined,
          accessToken,
          refreshToken,
          type: "course-login"
        },
        this.appUrl
      );
    }
  }
}
</script>

<style>
.loading-page {
    background: #000;
    width: 100vw;
    height: 100vh;
}
</style>