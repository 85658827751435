<template>
  <div class="generated-item">
    <div class="generated-item__overlay" />
    <div class="generated-item__container">
      <img alt="" class="generated-item__image" v-lazy="image" />
    </div>

    <div class="items-titles">
      <span class="generated-item__module" >{{ module }}</span>
      <span class="generated-item__title">{{ title }}</span>
    </div>
  </div>
</template>

<script>
export default {
  name: "DynamicGeneratedItem",
  props: {
    module: String,
    title: String,
    image: Object,
  },
};
</script>

<style scoped lang="scss">
.generated-item {
  --height: 504px;
  --width: 237px;
  --background-primary: #1f1f1f;
  --max-lines: 5;
  z-index: 0;

  position: relative;

  display: grid;
  grid-template-rows: 1.5fr 1fr;
  justify-content: center;
  grid-gap: 1rem;
  overflow: hidden;

  height: var(--height);
  width: var(--width);
  background-color: var(--background-primary);
  border-radius: 20px;

  box-sizing: border-box;

  background: var(--background-primary);

  .generated-item__overlay {
    position: absolute;
    top: 0;
    bottom: 0;
    right: 0;
    left: 0;
    z-index: 1;

    background: linear-gradient(
        30deg,
        var(--primary-color) 0,
        transparent 40%,
        transparent 70%,
        var(--primary-color) 100%
    );

    opacity: 0.3;
  }

  .generated-item {
    &__container,
    &__image {
      height: 270px;
      width: var(--width);
    }

    &__container {
      position: relative;
      z-index: 0;
    }

    &__container::after {
      content: "";
      position: absolute;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      z-index: 1;

      background: linear-gradient(to bottom, transparent 60%, #1f1f1f);
    }

    &__image {
      object-fit: cover;
      width: inherit;

      justify-self: center;
      align-self: end;
    }
  }

  .generated-item__module {
    // border: 1px solid red;
    z-index: 1;
    padding-inline: 1.8rem;
    color: var(--primary-color);
    margin-top: 0rem;
    font-size: 0.75rem;
    font-weight: 600;
    
    // font-family: 'Nexa';
    word-break: break-word;

    height: fit-content;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: 2 !important;
    line-clamp: 2 !important;
    -webkit-box-orient: vertical;
  }

  .generated-item__title {
    z-index: 1;
    padding-inline: 1.8rem;
    color: white;
    margin-top: 2rem;
    font-size: 1.125rem;
    font-weight: bold;
    font-family: Arial, Helvetica, sans-serif;
    // font-family: 'Nexa';
    word-break: break-word;

    height: fit-content;

    overflow: hidden;
    text-overflow: ellipsis;
    display: -webkit-box;
    -webkit-line-clamp: var(--max-lines);
    line-clamp: var(--max-lines);
    -webkit-box-orient: vertical;
  }
}
</style>
