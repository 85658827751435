import { render, staticRenderFns } from "./IconPrevious.vue?vue&type=template&id=21264961&scoped=true"
var script = {}
import style0 from "./IconPrevious.vue?vue&type=style&index=0&id=21264961&prod&scoped=true&lang=css"


/* normalize component */
import normalizer from "!../../../node_modules/@vue/vue-loader-v15/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  "21264961",
  null
  
)

export default component.exports