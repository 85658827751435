import { ConfigService } from '@/services/config.service';

export default {
  namespaced: true,
  state: {
    autoplay: true,
    autoNext: false,
    fixedMenu:localStorage.getItem('fixedMenu') === "true",
    startFromLastSavedState: true,
    initialPlaybackRate: 1,
    sidenavIsOpen: window.innerWidth > 1300
  },
  getters: {
    //
  },
  actions: {
    getConfig({ commit }) {
      return ConfigService.getConfig(this.state.user.currentUser.studentId)
        .then(response => commit('SET_CONFIG', response.data))
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },
    async autoplay({ commit }, val) {
      commit('SET_AUTOPLAY', val)

      ConfigService.update(this.state.user.currentUser.studentId, this.state.config)
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },
    autoNext({ commit }, val) {
      commit('SET_AUTONEXT', val)

      ConfigService.update(this.state.user.currentUser.studentId, this.state.config)
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },
    fixedMenu({ commit }, val) {
      commit('SET_FIXEDMENU', val)

    },
    startFromLastSavedState({ commit }, val) {
      commit('SET_STARFROMLAST', val)

      ConfigService.update(this.state.user.currentUser.studentId, this.state.config)
        .catch(error => commit('toast/NEW', { type: 'error', message: error.message }, { root: true }))
    },
    setInitialPlaybackRate({commit}, playbackRate){
      commit('SET_INITIAL_PLAYBACK_RATE', playbackRate);
    },
    setSidenavState({commit}, newState){
      commit('SET_SIDENAV_STATE', newState);
    }
  },
  mutations: {
    SET_CONFIG(state, data) {
      state.autoplay = data.autoPlay;
      state.autoNext = data.startNextVideo;
      state.startFromLastSavedState = data.continueVideo;
      state.initialPlaybackRate = state.initialPlaybackRate || 1;
    },
    SET_AUTOPLAY(state, autoplay) {
      state.autoplay = autoplay
    },
    SET_AUTONEXT(state, autoNext) {
      state.autoNext = autoNext
    },
    SET_FIXEDMENU(state, fixed) {
      state.fixedMenu = fixed
      localStorage.setItem('fixedMenu', state.fixedMenu)
    },
    SET_STARFROMLAST(state, startFromLastSavedState) {
      state.startFromLastSavedState = startFromLastSavedState
    },
    SET_INITIAL_PLAYBACK_RATE(state, pbRate){
      state.initialPlaybackRate = pbRate;
    },
    SET_SIDENAV_STATE(state, exactState){
      const newState = typeof exactState === 'boolean' ? exactState : !state.sidenavIsOpen
      state.sidenavIsOpen = newState;
    }
  }
}
